import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import store from '@/store';
import Button from '@/components/Button';
import Svg from '@/components/Svg';

import './style.css';

const CookieBanner = props => {
  const [closed, setClosed] = useState(false);
  const { cookiePolicyAccepted, acceptCookiePolicy } = props;

  const title = 'Cookies';
  const description =
    'We use cookie-banner on Memories.com to analyze and measure activity across \
  the website, provide content from third parties and assist with our \
  marketing efforts. Please be aware that your experience may be disrupted \
  until you accept cookie-banner.';

  const onClickAccept = () => acceptCookiePolicy();
  const onClickClose = () => setClosed(true);

  return (
    <CSSTransition
      in={!(cookiePolicyAccepted || closed)}
      classNames="cookie-banner-"
      timeout={800}
      appear={true}
    >
      <div className="cookie-banner">
        <div className="cookie-banner__upper-wrapper">
          <p className="cookie-banner__title">{title}</p>
          <Svg name="cross" onClick={onClickClose} />
        </div>
        <div className="cookie-banner__bottom-wrapper">
          <p className="cookie-banner__description">{description}</p>
          <div className="cookie-banner__buttons">
            <Button
              className="cookie-banner__buttons__accept"
              variant="primary"
              size="medium"
              onClick={onClickAccept}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

const mapState = state => ({
  cookiePolicyAccepted: store.select.user.isCookiePolicyAccepted(state),
});

const mapDispatch = ({ user: { acceptCookiePolicy } }) => ({
  acceptCookiePolicy,
});

export default connect(
  mapState,
  mapDispatch
)(CookieBanner);
