import * as THREE from 'three';
import moment from 'moment';
import DatePicker from '@/components/DatePicker';
import { getColorGroup } from '@/data/adjectives';

const getLineGapValue = (value, axeIndex = 0) =>
  0.05 + (axeIndex % 2 ? 1 : -0.5) * 0.05 * Math.min(Math.max(value, 0), 1);

const getRadiusValue = value => 0.3 + 0.5 * Math.min(Math.max(value, 0), 1);

const getNormalizedValue = value => Math.min(Math.max(value, 0), 1);

const getValueFromString = string => {
  let v = 0;
  for (let i = 0; i < string.length; i++) {
    v += string.charCodeAt(i);
  }
  while (v > 1) {
    v /= 10;
  }

  return v;
};

export function getAgeInfo(birthDate, deathDate) {
  let age = 0;
  let ageType = 'years';

  // TODO: ensure that deathMoment is greater than birthMoment.
  if (birthDate) {
    let deathMoment =
      (deathDate && moment(deathDate, DatePicker.dateFormat)) || moment();
    let birthMoment = moment(birthDate, DatePicker.dateFormat);

    age = deathMoment.diff(birthMoment, ageType);
    if (!age) {
      ageType = 'months';
      age = deathMoment.diff(birthMoment, ageType);
    }
    if (!age) {
      ageType = 'days';
      age = deathMoment.diff(birthMoment, ageType);
    }
  }

  return { age: age, ageType: age == 1 ? ageType.slice(0, -1) : ageType };
}

//14

const gradients = {
  none: [['#CCCCCC', '#999999']],
  bright: [
    ['#f94e00', '#ffb900'],
    ['#019e79', '#c7f65a'],
    ['#00b2be', '#5ecaf0'],
    ['#ffdb00', '#fffc01'],
    ['#fe207d', '#f94da7'],
    ['#00f1d3', '#fffc01'],
    ['#fe8230', '#e30613'],
    ['#f94da7', '#fcd425'],
    ['#e8ff01', '#009bc7'],
    ['#6cbb83', '#009640'],
    ['#00efc0', '#453894'],
  ],
  cool: [
    ['#2e7eff', '#4e3ef2'],
    ['#00ffff', '#0080ff'],
    ['#2e7eff', '#4bddb7'],
    ['#f5f4ce', '#70a2f9'],
    ['#a98fef', '#0080ff'],
    ['#b6c3ff', '#ff40ff'],
    ['#009d84', '#006655'],
    ['#48c4d4', '#b7c35b'],
    ['#6950b2', '#5f8bd3'],
    ['#50438c', '#2e2757'],
    ['#299cd7', '#006db7'],
  ],
  pastel: [
    ['#f2c3fb', '#fffe6d'],
    ['#f7ca6d', '#f2c4fb'],
    ['#67a0f9', '#f0c1fb'],
    ['#d9dffd', '#ed67f8'],
    ['#bbff80', '#a98fef'],
    ['#eae2f2', '#ccb9db'],
    ['#e2f3fe', '#a7d5f4'],
    ['#daedeb', '#a1d5ce'],
    ['#b5c4d4', '#6791ad'],
    ['#989aa2', '#dedfe7'],
  ],
};

Object.keys(gradients).forEach(type => {
  gradients[type].forEach(grad =>
    grad.splice(
      1,
      0,
      new THREE.Color(grad[0]).lerp(new THREE.Color(grad[1]), 0.5).getStyle()
    )
  );
});

/*
const eases = [
  {
    ease: 'bounceIn',
    ease2: 'cubicIn',
  },
  {
    ease: 'cubicIn',
    ease2: 'quadIn',
  },
  {
    ease: 'sineIn',
    ease2: 'sineIn',
  },
  {
    ease: 'quadIn',
    ease2: 'cubicIn',
  },
  {
    ease: 'cubicIn',
    ease2: 'sineIn',
  },
];
*/
const eases = [
  {
    ease: 'cubicIn',
    ease2: 'quadIn',
  },
  {
    ease: 'bounceIn',
    ease2: 'cubicIn',
  },
  {
    ease: 'linear',
    ease2: 'sineIn',
  },
  {
    ease: 'bounceIn',
    ease2: 'cubicIn',
  },
  {
    ease: 'cubicIn',
    ease2: 'sineIn',
  },
];

function shuffleArray(array, seeds) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(seeds[i] * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function resetGradientIndex(gradientsIndex, seeds) {
  shuffleArray(gradientsIndex.brightMap, seeds);
  shuffleArray(gradientsIndex.pastelMap, seeds);
  shuffleArray(gradientsIndex.coolMap, seeds);
}

function getNextGradientIndex(gradientsIndex, type) {
  // we get the next index of the gradients based on a seeded randomised map
  const ret = gradientsIndex[type]++;

  gradientsIndex[type] = gradientsIndex[type] % gradients[type].length;

  return gradientsIndex[`${type}Map`][ret];
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function setGhosted() {
  //we call this just for the editor
  //check if we have a not enabled petal followed by an enabled one
  let found = false;
  for (let i = this.options.axes - 1; i > 0; i--) {
    if (this.options.axesParams[i].enabled) {
      found = true;
    } else if (found || !this.options.editor) {
      //console.log(`setting axe ${i} as ghosted`);

      this.options.axesParams[i].changed =
        !this.prevOptions || !this.prevOptions.axesParams[i].ghosted;

      this.options.axesParams[i].enabled = true;
      this.options.axesParams[i].ghosted = true;
      this.options.axesParams[i].radius = 0.5;
      this.options.axesParams[i].numLines = 15;
      this.options.axesParams[i].color0 = '#DDDDDD';
      this.options.axesParams[i].color1 = '#EAEAEA';
      this.options.axesParams[i].color2 = '#EAEAEA';
      this.options.axesParams[i].ease = 'quadIn';
      this.options.axesParams[i].ease2 = 'cubicIn';
    }
  }
}

export default function calculateAxesParams() {
  const { data: lifeline = {} } = this.props;
  //console.log(lifeline);

  const minLines = 15;
  const deltaLines = 35;

  const lineGap = 0.06;

  // reset enabled axes count
  this.options.enabledAxes = 0;

  //All radius is first / last name

  const remembering = lifeline.remembering;
  const prevRemembering =
    (this.prevLifeline && this.prevLifeline.remembering) || {};

  let changed = prevRemembering.firstName !== remembering.firstName;
  changed = changed || prevRemembering.lastName !== remembering.lastName;
  changed = changed || prevRemembering.birthDate !== remembering.birthDate;
  changed = changed || prevRemembering.deathDate !== remembering.deathDate;
  changed =
    changed ||
    JSON.stringify(prevRemembering.adjectives) !==
      JSON.stringify(remembering.adjectives);

  this.options.axesParams[0].changed = changed;

  let enabled = !!(
    remembering.firstName &&
    remembering.lastName &&
    remembering.birthDate
  );

  this.options.axesParams[0].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  const firstName = (enabled && remembering.firstName) || 'nofirstname';
  const lastName = (enabled && remembering.lastName) || 'nolastname';
  const birthDate = (enabled && remembering.birthDate) || 'nobirthdate';
  const deathDate = (enabled && remembering.deathDate) || 'nodeathdate';
  const adjectives = (enabled && remembering.adjectives) || [];

  const gradientsIndex = {
    bright: 0,
    brightMap: gradients.bright.map((el, index) => index),
    pastel: 0,
    pastelMap: gradients.pastel.map((el, index) => index),
    cool: 0,
    coolMap: gradients.cool.map((el, index) => index),
    none: 0,
    noneMap: gradients.none.map((el, index) => index),
  };

  resetGradientIndex(gradientsIndex, [
    getValueFromString(firstName + deathDate),
    getValueFromString(lastName + firstName),
    getValueFromString(birthDate + lastName),
    getValueFromString(deathDate + firstName),
    getValueFromString(firstName + lastName),
    getValueFromString(firstName + birthDate),
    getValueFromString(firstName + deathDate),
    getValueFromString(lastName + firstName),
    getValueFromString(birthDate + lastName),
    getValueFromString(deathDate + firstName),
    getValueFromString(firstName + lastName),
  ]);

  const colorGroups = [];
  adjectives.forEach(adj => {
    colorGroups.push(getColorGroup(adj.label));
  });
  while (colorGroups.length !== 3) {
    colorGroups.push('none');
  }

  const petalColors = [];

  colorGroups.forEach((item, index) => {
    switch (index) {
      case 0:
        petalColors[0] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        petalColors[1] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        break;
      case 1:
        petalColors[2] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        petalColors[3] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        petalColors[4] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        break;
      case 2:
        petalColors[5] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        petalColors[6] =
          gradients[item][getNextGradientIndex(gradientsIndex, item)];
        break;
    }
  });

  const age = Math.min(((enabled && this.options.age) || 85) / 85, 1);

  this.options.axesParams[0].enabled = !!enabled;
  this.options.axesParams[0].ghosted = false;
  this.options.axesParams[0].reveal =
    this.options.axesParams[0].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[0].enabled) &&
      this.options.axesParams[0].enabled);
  this.options.axesParams[0].radius = getRadiusValue(age);
  this.options.axesParams[0].lineGap = lineGap;
  this.options.axesParams[0].numLines = minLines + Math.round(deltaLines * age);
  this.options.axesParams[0].ease =
    eases[Math.round(4 * getValueFromString(firstName))].ease;
  this.options.axesParams[0].ease2 =
    eases[Math.round(4 * getValueFromString(lastName))].ease2;
  this.options.axesParams[0].sinFreq = 1 + getValueFromString(firstName);
  this.options.axesParams[0].sinOffset = 0; //-2 + 4 * getValueFromString(lastName);
  this.options.axesParams[0].color0 = petalColors[0][0];
  this.options.axesParams[0].color1 = petalColors[0][1];
  this.options.axesParams[0].color2 = petalColors[0][2];
  this.options.axesParams[0].random1 = getValueFromString(firstName);
  this.options.axesParams[0].random2 = getValueFromString(lastName);

  changed = prevRemembering.bornCountry !== remembering.bornCountry;
  changed = changed || prevRemembering.bornState !== remembering.bornState;
  changed = changed || prevRemembering.bornTown !== remembering.bornTown;
  changed =
    changed || prevRemembering.deathCountry !== remembering.deathCountry;
  changed = changed || prevRemembering.deathState !== remembering.deathState;
  changed = changed || prevRemembering.deathTown !== remembering.deathTown;
  changed =
    changed ||
    ((!this.prevOptions && this.options.distance) ||
      (this.prevOptions &&
        this.prevOptions.distance !== this.options.distance));

  this.options.axesParams[1].changed = changed;

  const distance = (enabled && this.options.distance) || 0;
  const bornState = (enabled && remembering.bornState) || 'nostate';
  // const deathState = (enabled && remembering.deathState) || 'nostate';
  const bornTown = (enabled && remembering.bornTown) || 'noTown';
  const deathTown = (enabled && remembering.deathTown) || 'noTown';

  enabled = remembering.bornPlaceGeoJSON;

  this.options.axesParams[1].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  this.options.axesParams[1].enabled = !!enabled;
  this.options.axesParams[1].ghosted = false;
  this.options.axesParams[1].reveal =
    this.options.axesParams[1].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[1].enabled) &&
      this.options.axesParams[1].enabled);
  this.options.axesParams[1].radius = getRadiusValue(distance);
  this.options.axesParams[1].lineGap = lineGap;
  this.options.axesParams[1].numLines =
    minLines + Math.round(deltaLines * distance);
  this.options.axesParams[1].ease =
    eases[Math.round(4 * getValueFromString(bornState))].ease;
  this.options.axesParams[1].ease2 =
    eases[Math.round(4 * getValueFromString(deathTown))].ease2;
  this.options.axesParams[1].sinFreq = 1 + getValueFromString(bornState);
  this.options.axesParams[1].sinOffset = 0; //-2 + 4 * getValueFromString(deathState);
  this.options.axesParams[1].color0 = petalColors[1][0];
  this.options.axesParams[1].color1 = petalColors[1][1];
  this.options.axesParams[1].color2 = petalColors[1][2];
  this.options.axesParams[1].random1 = getValueFromString(bornTown);
  this.options.axesParams[1].random2 = getValueFromString(deathTown);

  //world traveller
  const places = remembering.placesLived || [];
  const prevPlaces = prevRemembering.placesLived || [];
  const town = (places && places[0] && places[0].town) || 'notown';
  const state = (places && places[0] && places[0].state) || 'nostate';

  changed = JSON.stringify(prevPlaces) !== JSON.stringify(places);
  changed =
    changed ||
    ((prevPlaces && prevPlaces[0] && prevPlaces[0].town) || 'notown') !== town;
  changed =
    changed ||
    ((prevPlaces && prevPlaces[0] && prevPlaces[0].state) || 'nostate') !==
      state;

  this.options.axesParams[2].changed = changed;

  enabled =
    /*!this.options.editor ||*/
    remembering.placesLived && remembering.placesLived.length;

  this.options.axesParams[2].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  const specification =
    (places && places[0] && places[0].specification) || 'nospecification';
  let travelled = Math.min(places.length / 10, 1);

  //console.log('travelled', travelled);

  this.options.axesParams[2].enabled = !!enabled;
  this.options.axesParams[2].ghosted = false;
  this.options.axesParams[2].reveal =
    this.options.axesParams[2].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[2].enabled) &&
      this.options.axesParams[2].enabled);
  this.options.axesParams[2].radius = getRadiusValue(travelled);
  this.options.axesParams[2].lineGap = lineGap;
  this.options.axesParams[2].numLines =
    minLines + Math.round(deltaLines * travelled);
  this.options.axesParams[2].ease =
    eases[Math.round(4 * getValueFromString(town))].ease;
  this.options.axesParams[2].ease2 =
    eases[Math.round(4 * getValueFromString(state))].ease2;
  this.options.axesParams[2].sinFreq = 1 + getValueFromString(state);
  this.options.axesParams[2].sinOffset = 0; //-2 + 4 * getValueFromString(specification);
  this.options.axesParams[2].color0 = petalColors[2][0];
  this.options.axesParams[2].color1 = petalColors[2][1];
  this.options.axesParams[2].color2 = petalColors[2][2];
  this.options.axesParams[2].random1 = getValueFromString(town);
  this.options.axesParams[2].random2 = getValueFromString(state);

  //relations
  const relationships = lifeline.relationships || {};
  const prevRelationships =
    (this.prevLifeline && this.prevLifeline.relationships) || {};

  changed =
    JSON.stringify(prevRelationships.photos) !==
    JSON.stringify(relationships.photos);
  changed =
    changed ||
    JSON.stringify(prevRelationships.importantRelationships) !==
      JSON.stringify(relationships.importantRelationships);
  changed =
    changed ||
    (prevRelationships.importantRelationships &&
      prevRelationships.importantRelationships[0] &&
      prevRelationships.importantRelationships[0].relationship) !==
      (relationships.importantRelationships &&
        relationships.importantRelationships[0] &&
        relationships.importantRelationships[0].relationship);
  changed =
    changed ||
    (prevRelationships.importantRelationships &&
      prevRelationships.importantRelationships[0] &&
      prevRelationships.importantRelationships[0].relationshipGroup) !==
      (relationships.importantRelationships &&
        relationships.importantRelationships[0] &&
        relationships.importantRelationships[0].relationshipGroup);

  this.options.axesParams[3].changed = changed;

  let relations = 0;
  // relations += (relationships.photos && relationships.photos.length) || 0;
  relations +=
    (relationships.importantRelationships &&
      relationships.importantRelationships.length) ||
    0;

  enabled = /*!this.options.editor ||*/ relations;

  this.options.axesParams[3].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  relations = Math.min(relations / 30, 1);

  // const relImportantName =
  //   (relationships.importantRelationships &&
  //     relationships.importantRelationships[0] &&
  //     relationships.importantRelationships[0].firstName) ||
  //   'nofirstName';
  const relImportantRel =
    (relationships.importantRelationships &&
      relationships.importantRelationships[0] &&
      relationships.importantRelationships[0].relationship) ||
    'norelationship';
  const relImportantRelGroup =
    (relationships.importantRelationships &&
      relationships.importantRelationships[0] &&
      relationships.importantRelationships[0].relationshipGroup) ||
    'norelationshipgroup';

  this.options.axesParams[3].enabled = !!enabled;
  this.options.axesParams[3].ghosted = false;
  this.options.axesParams[3].reveal =
    this.options.axesParams[3].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[3].enabled) &&
      this.options.axesParams[3].enabled);
  this.options.axesParams[3].radius = getRadiusValue(relations);
  this.options.axesParams[3].lineGap = lineGap;
  this.options.axesParams[3].numLines =
    minLines + Math.round(deltaLines * relations);
  this.options.axesParams[3].ease =
    eases[Math.round(4 * getValueFromString(relImportantRel))].ease;
  this.options.axesParams[3].ease2 =
    eases[Math.round(4 * getValueFromString(relImportantRelGroup))].ease2;
  this.options.axesParams[3].sinFreq =
    1 + getValueFromString(relImportantRelGroup);
  this.options.axesParams[3].sinOffset = 0; //-2 + 4 * getValueFromString(relImportantName);
  this.options.axesParams[3].color0 = petalColors[3][0];
  this.options.axesParams[3].color1 = petalColors[3][1];
  this.options.axesParams[3].color2 = petalColors[3][2];
  this.options.axesParams[3].random1 = getValueFromString(relImportantRel);
  this.options.axesParams[3].random2 = getValueFromString(relImportantRelGroup);

  //memories
  changed = prevRelationships.memories !== relationships.memories;
  changed =
    changed ||
    (prevRelationships.memories &&
      prevRelationships.memories[0] &&
      prevRelationships.memories[0].description) !==
      (relationships.memories &&
        relationships.memories[0] &&
        relationships.memories[0].description);

  this.options.axesParams[4].changed = changed;

  let memories = 0;
  memories += (relationships.memories && relationships.memories.length) || 0;

  enabled = /*!this.options.editor ||*/ memories;

  this.options.axesParams[4].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  memories = Math.min(memories / 10, 1);

  const relMemAuthor =
    (relationships.memories &&
      relationships.memories[0] &&
      relationships.memories[0].author) ||
    'noauthor';
  const relMemDescription =
    (relationships.memories &&
      relationships.memories[0] &&
      relationships.memories[0].description) ||
    'nodescription';

  this.options.axesParams[4].enabled = !!enabled;
  this.options.axesParams[4].ghosted = false;
  this.options.axesParams[4].reveal =
    this.options.axesParams[4].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[4].enabled) &&
      this.options.axesParams[4].enabled);
  this.options.axesParams[4].radius = getRadiusValue(memories);
  this.options.axesParams[4].lineGap = lineGap;
  this.options.axesParams[4].numLines =
    minLines + Math.round(deltaLines * memories);
  this.options.axesParams[4].ease =
    eases[Math.round(4 * getValueFromString(relMemAuthor))].ease;
  this.options.axesParams[4].ease2 =
    eases[Math.round(4 * getValueFromString(relMemDescription))].ease2;
  this.options.axesParams[4].sinFreq =
    1 + getValueFromString(relMemDescription);
  this.options.axesParams[4].sinOffset = 0; //-2 + 4 * getValueFromString(relMemRelationship);
  this.options.axesParams[4].color0 = petalColors[4][0];
  this.options.axesParams[4].color1 = petalColors[4][1];
  this.options.axesParams[4].color2 = petalColors[4][2];
  this.options.axesParams[4].random1 = getValueFromString(relMemAuthor);
  this.options.axesParams[4].random2 = getValueFromString(relMemDescription);

  //achievements
  const achievements = lifeline.achievements || { achievements: {} };
  if (!achievements.achievements) {
    achievements.achievements = [];
  }
  const prevAchievements = (this.prevLifeline &&
    this.prevLifeline.achievements) || {
    achievements: {},
  };
  if (!prevAchievements.achievements) {
    prevAchievements.achievements = [];
  }

  changed =
    prevAchievements.achievements &&
    achievements.achievements &&
    prevAchievements.achievements !== achievements.achievements;
  changed =
    changed ||
    (prevAchievements.achievements &&
      prevAchievements.achievements[0] &&
      prevAchievements.achievements[0].text) !==
      (achievements.achievements &&
        achievements.achievements[0] &&
        achievements.achievements[0].text);
  changed =
    changed ||
    (prevAchievements.achievements &&
      prevAchievements.achievements[0] &&
      prevAchievements.achievements[0].from) !==
      (achievements.achievements &&
        achievements.achievements[0] &&
        achievements.achievements[0].from);

  this.options.axesParams[5].changed = changed;

  let ach =
    (achievements.achievements && achievements.achievements.length) || 0;

  enabled = /*!this.options.editor ||*/ ach;

  this.options.axesParams[5].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  ach = Math.min(ach / 10, 1);

  const achText =
    (achievements.achievements &&
      achievements.achievements[0] &&
      achievements.achievements[0].text) ||
    'notext';
  const achFrom =
    (achievements.achievements &&
      achievements.achievements[0] &&
      achievements.achievements[0].from) ||
    'nofrom';
  const achTo =
    (achievements.achievements &&
      achievements.achievements[0] &&
      achievements.achievements[0].to) ||
    'noto';

  this.options.axesParams[5].enabled = !!enabled;
  this.options.axesParams[5].ghosted = false;
  this.options.axesParams[5].reveal =
    this.options.axesParams[5].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[5].enabled) &&
      this.options.axesParams[5].enabled);
  this.options.axesParams[5].radius = getRadiusValue(ach);
  this.options.axesParams[5].lineGap = lineGap;
  this.options.axesParams[5].numLines = minLines + Math.round(deltaLines * ach);
  this.options.axesParams[5].ease =
    eases[Math.round(4 * getValueFromString(achText))].ease;
  this.options.axesParams[5].ease2 =
    eases[Math.round(4 * getValueFromString(achFrom))].ease2;
  this.options.axesParams[5].sinFreq = 1 + getValueFromString(achFrom);
  this.options.axesParams[5].sinOffset = 0; //-2 + 4 * getValueFromString(achTo);
  this.options.axesParams[5].color0 = petalColors[5][0];
  this.options.axesParams[5].color1 = petalColors[5][1];
  this.options.axesParams[5].color2 = petalColors[5][2];
  this.options.axesParams[5].random1 = getValueFromString(achText);
  this.options.axesParams[5].random2 = getValueFromString(achFrom);

  //interests
  const interests = lifeline.interests || { interests: {} };
  if (!interests.interests) {
    interests.interests = {};
  }
  const prevInterests = (this.prevLifeline && this.prevLifeline.interests) || {
    interests: {},
  };
  if (!prevInterests.interests) {
    prevInterests.interests = {};
  }

  changed =
    JSON.stringify(prevInterests.interests) !==
    JSON.stringify(interests.interests);

  this.options.axesParams[6].changed = changed;

  let int = 0;

  Object.values(interests.interests).forEach(v => (int += v + 1));

  enabled = /*!this.options.editor ||*/ int;

  this.options.axesParams[6].enabledIndex = this.options.enabledAxes;
  //if (enabled || this.options.editor) {
  this.options.enabledAxes++;
  //}

  int = Math.min(Math.log(int) / Math.log(80), 1);

  const keys = Object.keys(interests.interests);

  const key0 = keys[0] || 'nokey0';
  const key1 = keys[1] || 'nokey1';

  this.options.axesParams[6].enabled = !!enabled;
  this.options.axesParams[6].ghosted = false;
  this.options.axesParams[6].reveal =
    this.options.axesParams[6].reveal ||
    (this.options.editor &&
      !(this.prevOptions && this.prevOptions.axesParams[6].enabled) &&
      this.options.axesParams[6].enabled);
  this.options.axesParams[6].radius = getRadiusValue(int);
  this.options.axesParams[6].lineGap = lineGap;
  this.options.axesParams[6].numLines = minLines + Math.round(deltaLines * int);
  this.options.axesParams[6].ease =
    eases[Math.round(4 * getValueFromString(key0))].ease;
  this.options.axesParams[6].ease2 =
    eases[Math.round(4 * getValueFromString(key1))].ease2;
  this.options.axesParams[6].sinFreq = 1 + getValueFromString(key1);
  this.options.axesParams[6].sinOffset = 0; //-2 + 4 * getValueFromString(keys[2] || 'nokey2');
  this.options.axesParams[6].color0 = petalColors[6][0];
  this.options.axesParams[6].color1 = petalColors[6][1];
  this.options.axesParams[6].color2 = petalColors[6][2];
  this.options.axesParams[6].random1 = getValueFromString(key0);
  this.options.axesParams[6].random2 = getValueFromString(key1);

  //if (this.options.editor) {
  setGhosted.apply(this);
  //}
}
