import React, { Component } from 'react';

import Container from '@/components/Container';
import Svg from '@/components/Svg';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import NavItem from '@/components/NavItem';
import LoadingShape from '@/components/LoadingShape';
import About from '@/components/About';
import Modal from '@/components/Modal';
import store from '@/store';

import './style.css';
import { connect } from 'react-redux';

export class Footer extends Component {
  state = {
    about: false,
  };

  showAbout = () => {
    this.setState({
      about: true,
    });
  };

  hideAbout = () => {
    this.setState({
      about: false,
    });
  };

  render() {
    const { userLogged } = this.props;
    const { about } = this.state;

    return (
      <div className="footer">
        <Modal
          size="large"
          variant="white"
          onClose={this.hideAbout}
          visible={about}
          hideClose
        >
          <About onClose={this.hideAbout} visible={about} />
        </Modal>

        <Container size="large">
          <div className="section main">
            <div
              className="loading-shape-wrapper"
              onClick={() => this.props.history.push('/')}
            >
              <LoadingShape
                isActive={true}
                name="loading-shape__blue"
                variant="smalt"
              />
            </div>

            <div className="footer-menu-contacts-wrapper">
              <div className="footer-menu-wrapper">
                <ul>
                  <NavItem variant="primary" to="/add-lifeline">
                    Create a lifeline
                  </NavItem>

                  <NavItem variant="primary" to="/search">
                    Find a lifeline
                  </NavItem>

                  <NavItem variant="primary" to="/about">
                    About us
                  </NavItem>
                </ul>

                <ul>
                  {userLogged ? (
                    <NavItem
                      variant="secondary"
                      to="/my-account"
                      textOnHover="My account"
                    >
                      My account
                    </NavItem>
                  ) : (
                    <NavItem
                      variant="secondary"
                      to="/signup"
                      textOnHover="Sign up / Log in"
                    >
                      Sign up / Log in
                    </NavItem>
                  )}

                  <li className="menu-link">
                    <a href="http://a.memories.com" target="_blank">
                      <span title="Archive">Archive</span>
                    </a>
                  </li>

                  <NavItem
                    variant="secondary"
                    to="/services-faqs"
                    textOnHover="FAQs"
                  >
                    FAQs
                  </NavItem>
                </ul>
              </div>

              <div className="contacts">
                <h3>
                  <a
                    className="footer-link__simple"
                    href="mailto:contact@memories.com"
                  >
                    Contact us
                  </a>
                </h3>
                <div className="buttons-wrapper">
                  <div className="button-wrapper">
                    <a href="https://twitter.com" target="_blank">
                      <div className="icons-wrapper">
                        <Svg name="twitter_blue" />
                        <Svg name="twitter_green" />
                      </div>
                    </a>
                  </div>

                  <div className="button-wrapper">
                    <a href="https://facebook.com" target="_blank">
                      <div className="icons-wrapper">
                        <Svg name="facebook_blue" />
                        <Svg name="facebook_green" />
                      </div>
                    </a>
                  </div>

                  <div className="button-wrapper">
                    <a href="https://linkedin.com" target="_blank">
                      <div className="icons-wrapper">
                        <Svg name="linkedin_blue" />
                        <Svg name="linkedin_green" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section bottom">
            <div>
              <Link className="footer-link__simple" to="/privacy-policy">
                Privacy policy
              </Link>
              <Link className="footer-link__simple" to="/copyright-policy">
                Copyright policy
              </Link>
              <Link className="footer-link__simple" to="/use-terms">
                Terms of Use
              </Link>
            </div>

            <p>
              Credits:{' '}
              <a
                className="footer-link__simple"
                href="https://www.rollstudio.co.uk/"
                target="_blank"
              >
                ROLL Studio
              </a>
            </p>
          </div>
        </Container>
      </div>
    );
  }
}

const mapState = state => ({ userLogged: store.select.user.isLogged(state) });

export default connect(mapState)(withRouter(Footer));
