import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Button from '@/components/Button';
import LoadingShape from '@/components/LoadingShape';

import './style.css';

const LoadingScreen = ({
  isActive,
  loading = false,
  loadingTitle = 'Loading...',
  error,
  className,
}) => (
  <div className={classnames('loading-screen', className)}>
    <div className="loading-screen__content">
      <div className="loading-screen__shape">
        <LoadingShape variant="white" isActive={loading && !error} />
      </div>
      <div
        className={classnames('loading-screen__title', {
          'loading-screen__title__error': error,
        })}
      >
        {error ? 'Error' : loadingTitle}
      </div>
      {error && <div className="loading-screen__message">{error}</div>}
    </div>

    {error && (
      <div className="loading-screen__button">
        <Button to="/my-account" variant="primary">
          Return to My Account
        </Button>
      </div>
    )}
  </div>
);

export const FullwidthLoadingScreen = ({ isActive = false, ...props }) => (
  <CSSTransition
    in={isActive}
    timeout={1000}
    classNames="loading-screen-"
    appear={true}
    mountOnEnter
    unmountOnExit
  >
    <LoadingScreen
      {...props}
      isActive={isActive}
      className={classnames('fullscreen-loading-screen', props.className)}
    />
  </CSSTransition>
);

export default LoadingScreen;
