import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from '@/components/Button';
import ShareLifelineModal from '@/components/ShareLifelineModal';
import DashboardCTA from '@/components/DashboardCTA';

import store from '@/store';

import Cover from '../Cover';
import Footer from '@/components/Footer';

import './style.css';

const NOTHING_VISIBLE = 0;
const HEADING_VISIBLE = 1;
const COVER_VISIBLE = 2;

const LifelineEnd = ({
  lifeline,
  isLogged,
  active: isActive,
  exiting: _,
  entering: __,
  dispatch: ___,
  ...props
}) => {
  const firstName = lifeline.data.remembering.firstName;

  const [animationState, setAnimationState] = useState(NOTHING_VISIBLE);
  const [footerVisible, setFooterVisible] = useState(false);

  const handleHeadingTransitionEnd = useCallback(() => {
    if (isActive && animationState === HEADING_VISIBLE) {
      setAnimationState(COVER_VISIBLE);
    }
  }, [isActive, animationState]);

  const handleCoverTransitionEnd = useCallback(() => {
    if (isActive && animationState === COVER_VISIBLE) {
      setFooterVisible(true);
    }
  }, [isActive, animationState]);

  useEffect(() => {
    if (isActive && animationState !== HEADING_VISIBLE) {
      setAnimationState(HEADING_VISIBLE);
    } else if (!isActive && animationState !== NOTHING_VISIBLE) {
      setAnimationState(NOTHING_VISIBLE);
      setFooterVisible(false);
    }
  }, [isActive]);

  return (
    <>
      <div
        className="lifeline-end"
        style={{
          marginBottom: footerVisible ? '10rem' : 0,
        }}
        {...props}
      >
        <h2
          className={classnames('lifeline-end__heading', {
            'lifeline-end__heading--visible':
              animationState === HEADING_VISIBLE,
          })}
          onTransitionEnd={handleHeadingTransitionEnd}
        >
          Remembering you is easy, {firstName}.
        </h2>
        <div
          className={classnames('lifeline-end__cover-wrapper', {
            'lifeline-end__cover-wrapper--visible':
              animationState === COVER_VISIBLE,
          })}
          onTransitionEnd={handleCoverTransitionEnd}
        >
          <Cover end lifeline={lifeline} active={isActive} />
          <div className="lifeline-end__actions">
            <Button
              to={isLogged ? '/my-account' : '/'}
              variant="alt-primary"
              size="medium"
            >
              {isLogged && `Go to My Account`}
              {!isLogged && `Go to the homepage`}
            </Button>
            {lifeline.isOwner && lifeline.status === 'public' && (
              <DashboardCTA
                modalSize="medium"
                trigger={
                  <Button variant="secondary" size="medium" to="#">
                    Share
                  </Button>
                }
                modal={
                  <ShareLifelineModal
                    uuid={lifeline.uuid}
                    slug={lifeline.slug}
                    status={lifeline.status}
                  />
                }
              />
            )}
            {!lifeline.isOwner && (
              <Button size="medium" variant="secondary" to="/add-lifeline">
                Create a lifeline
              </Button>
            )}
          </div>
          <div className="lifeline-end__credits">
            <p>
              Made possible with <span>Memories.com</span>
            </p>
          </div>
        </div>
      </div>
      {animationState === COVER_VISIBLE && footerVisible && <Footer />}
    </>
  );
};

const mapState = state => ({
  isLogged: store.select.user.isLogged(state),
});

export default connect(mapState)(LifelineEnd);
