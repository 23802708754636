import apiFetch from '@/api/apiFetch';

export default async data => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data,
    }),
  };

  const response = await apiFetch('lifelines', options, 201);

  return await response.json();
};
