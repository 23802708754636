export const INTERESTS = {
  Science: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Science Seeker',
      default: 'Always hungry for testable explanations about the universe.',
      legacy: 'Always hungry for testable explanations about the universe.',
    },
  },
  'Mindfulness and wellbeing': {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Mind Over Atter',
      default: 'A spiritual someone who sought presence in the moment.',
      legacy: 'A spiritual someone who seeks presence in the moment.',
    },
  },
  Travel: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Wanderluster',
      default:
        'An adventure-hungry wanderer, with wind in {possessivePronoun} sails.',
      legacy:
        'An adventure-hungry wanderer, with wind in {possessivePronoun} sails.',
    },
  },
  Sport: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Sports Superfan',
      default: 'Match-day madness? {firstName} was always one to succumb.',
      legacy: 'Match-day madness? {firstName} is always one to succumb.',
    },
  },
  Literature: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Bibliomaniac',
      default: 'Bookworm. Literature lover. Prolific page-turner.',
      legacy: 'Bookworm. Literature lover. Prolific page-turner',
    },
  },
  Business: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Boardroom Brainiac',
      default: 'Hyper-informed on all things industry and enterprise.',
      legacy: 'Hyper-informed on all things industry and enterprise.',
    },
  },
  DIY: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Do-it-yourselfer',
      default:
        'Toolbelt and tape measure: that was {firstName}’s favorite weekend wardrobe.',
      legacy:
        'Toolbelt and tape measure: that is {firstName}’s favorite weekend wardrobe.',
    },
  },
  'Arts and culture': {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Culture Vulture',
      default:
        'An avid explorer of intellectual achievements, from the arts to the stage.',
      legacy:
        'An avid explorer of intellectual achievements, from the arts to the stage.',
    },
  },
  'Design and architecture': {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Design Minded',
      default:
        'An appreciator of thoughtful design and beautifully built environments.',
      legacy:
        'An appreciator of thoughtful design and beautifully built environments.',
    },
  },
  Technology: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Techno-junkie',
      default:
        'Coder, cybernaut, human-hard-disk: {firstName} was techie, to a T.',
      legacy:
        'Coder, cybernaut, human-hard-disk: {firstName} is techie, to a T',
    },
  },
  Religion: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Man Of Faith',
      default:
        'Religion was a big part of {firstName}’s life — a believer in something bigger than ourselves.',
      legacy:
        'Religion is a big part of {firstName}’s life — a believer in something bigger than ourselves.',
    },
  },
  Food: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Manifestly Foodie',
      default:
        'Good food, for {firstName}, was the foundation of genuine happiness.',
      legacy:
        'Good food, for {firstName}, is the foundation of genuine happiness.',
    },
  },
  Socializing: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Social Butterfly',
      default: 'Convivial. Companionable. Life and soul of the party.',
      legacy: 'Convivial. Companionable. Life and soul of the party.',
    },
  },
  Education: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Blackboard Believer',
      default:
        'Someone who placed trust in the cultivation of learning — and actively advanced {possessivePronoun} own.',
      legacy:
        'Someone who place trust in the cultivation of learning — and actively advanced {possessivePronoun} own.',
    },
  },
  Craft: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Crafty Cat',
      default:
        'Challenge {objectPronoun} with stone carving, crochet or cabinetry: {firstName} made ‘artisan’ look easy.',
      legacy:
        'Challenge {objectPronoun} with stone carving, crochet or cabinetry: {firstName} makes ‘artisan’ look easy.',
    },
  },
  Politics: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Sociopolitical Thinker',
      default:
        'A person who engaged with politics, public affairs and the corridors of power.',
      legacy:
        'A person who engages with politics, public affairs and the corridors of power.',
    },
  },
  Fashion: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Fervent Fashionista',
      default:
        '{firstName} adored fashion, as we adored {objectPronoun} for {possessivePronoun} own wonderful sense of style.',
      legacy:
        '{firstName} adores fashion, as we adore {objectPronoun} for {possessivePronoun} own wonderful sense of style.',
    },
  },
  Life: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Lover Of Life',
      default:
        '{firstName} saw each day as a gift and took great pleasure in the simple things.',
      legacy:
        '{firstName} sees each day as a gift and takes great pleasure in the simple things.',
    },
  },
  Nature: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Great Outdoorist',
      default:
        '{firstName} was a nature lover who found freedom in the fresh, open air.',
      legacy:
        '{firstName} is a nature lover who finds freedom in the fresh, open air.',
    },
  },
  Music: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Music Lover',
      default: 'Music. {firstName} loved it, lived it, breathed it.',
      legacy: 'Music. {firstName} loves it, lives it, breaths it.',
    },
  },
  'Interested in all things': {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Hobby Horse',
      default:
        'Too many interests and pastimes to count… {firstName} could turn {possessivePronoun} attention to pretty much anything.',
      legacy:
        'Too many interests and pastimes to count… {firstName} could turn {possessivePronoun} attention to pretty much anything.',
    },
  },
  Family: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Family First',
      default:
        'Family, to {firstName}, was everything, placing the happiness of {possessivePronoun} own above all else.',
      legacy:
        'Family, to {firstName}, is everything, placing the happiness of {possessivePronoun} own above all else.',
    },
  },

  'No interests in particular': {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {
      description: 'Open Book',
      default:
        'Not one to get geeky about anything in particular. More a man who was open to experiences of all kinds.',
      legacy:
        'Not one to get geeky about anything in particular. More a man who is open to experiences of all kinds.',
    },
  },
};
