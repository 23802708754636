import React from 'react';
import classnames from 'classnames';

import { Consumer as OutputSectionContextConsumer } from '@/contexts/OutputSection';
import getThirdPersonPronoun from '@/utils/getThirdPersonPronoun';

import './style.css';

export default ({
  lifeline,
  active: isActive,
  exiting: _,
  entering: __,
  ...props
}) => {
  const {
    data: {
      remembering: { firstName, sex },
      interests: { photo },
    },
  } = lifeline;
  const pronoun = getThirdPersonPronoun(sex);

  const introHasPhoto = !!photo;

  const verb = pronoun == 'they' ? 'were' : 'was';

  return (
    <div className="lifeline-interests" {...props}>
      <h2 className="lifeline-interests__title anim-slide-in">Interests</h2>

      <div className="lifeline-interests__claim anim-slide-in">
        Be it cooking, camping or competitive dog grooming, interests are a
        great insight to character.
      </div>

      <div
        className={classnames('lifeline-interests__intro', {
          'lifeline-interests__intro--text-only': !introHasPhoto,
        })}
      >
        <div
          className="lifeline-interests__autoscroll-waypoint"
          data-autoscroll-waypoint
        ></div>

        <div className="lifeline-interests__intro-text anim-slide-in anim-slide-in--delay-1">
          Here are some of the things that kept {firstName} curious, and that
          reveal the unique person {pronoun} {verb}.
        </div>

        {introHasPhoto && (
          <div className="lifeline-interests__intro-picture anim-slide-in anim-slide-in--delay-1">
            <OutputSectionContextConsumer>
              {({ updateScrollMetrics }) => (
                <img src={photo.remoteUrl} onLoad={updateScrollMetrics} />
              )}
            </OutputSectionContextConsumer>
          </div>
        )}
      </div>
    </div>
  );
};
