export default () => {
  switch (process.env.CURRENT_ENV) {
    case 'local':
      return 'http://localhost:8000/api/';
    case 'dev':
      return 'https://memories-backend.dev.rollstudio.it/api/';
    case 'stage':
      return 'https://memories-backend-stage.dev.rollstudio.it/api/';
    case 'prod':
      return '/backend/api/';
  }
};
