import React, { Component, createRef } from 'react';
import classnames from 'classnames';

import capitalize from '@/utils/capitalize';
import Svg from '@/components/Svg';

import './style.css';

const DEFAULT_ERROR_MESSAGE = 'Invalid';
const REQUIRED_INPUT_ERROR_MESSAGE = 'This is a required field';

export default class Input extends Component {
  static defaultProps = {
    placeholder: '',
    center: true,
    size: 'medium',
    variant: 'white',
    required: false,
    block: true,
    onBackspacePressed: _ => null,
    onSpacebarPressed: _ => null,
    showTag: false,
    showWordCounter: false,
    onTagArrowClick: _ => null,
    useFakePlaceholder: false,
    requiredMark: '*',
    reset: false,
    capitalize: false,
    isValid: _ => undefined,
    getErrorMessage: _ => undefined,
    validateError: false,
  };

  state = {
    hadFocus: false,
    hasFocus: false,
  };

  input = createRef();
  measureInput = createRef();
  measureSuggestion = createRef();
  inputSuggestion = createRef();

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.reset
      ? { ...prevState, hadFocus: false, hasFocus: false }
      : { ...prevState };
  }

  onFocus = e => {
    window.dispatchEvent(new CustomEvent('inputActive'));

    this.setState({ hasFocus: true });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  onBlur = e => {
    const { onBlur } = this.props;
    this.setState({
      hasFocus: false,
      hadFocus: true,
    });

    if (onBlur) {
      onBlur(e);
    }
  };

  onKeyDown = e => {
    const {
      suggestion,
      onChange,
      onKeyDown,
      onBackspacePressed,
      onSpacebarPressed,
    } = this.props;

    if (suggestion) {
      if (e.key == 'ArrowRight' || e.key == 'Tab') {
        if (this.input.current.value !== suggestion) {
          e.preventDefault();
        }

        this.input.current.value = suggestion;
        onChange(e);
        return;
      }
    }

    if (onKeyDown) {
      onKeyDown(e);
    }

    if (e.key == 'Backspace') {
      onBackspacePressed(e);
    }

    if (e.key === ' ') {
      onSpacebarPressed(e);
    }
  };

  onInput = e => {
    if (this.props.capitalize) {
      let input = e.target;
      const p = input.selectionStart;
      input.value = capitalize(input.value, { ignorePrepositions: true });
      input.setSelectionRange(p, p);
    }
    this.props.onInput && this.props.onInput(e);
  };

  componentDidUpdate() {
    if (this.measureSuggestion.current) {
      if (this.props.suggestion && this.props.center) {
        const delta =
          this.measureSuggestion.current.clientWidth -
          this.measureInput.current.clientWidth;
        this.inputSuggestion.current.style.left = `${delta / 2}px`;
      } else {
        this.inputSuggestion.current.style.left = '';
      }
    }
  }

  onLabelClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  getLabel = () => {
    const { required, label, requiredMark } = this.props;
    return label && required ? `${label}${requiredMark}` : label;
  };

  getPlaceholder = () => {
    const { required, placeholder, requiredMark } = this.props;
    const label = this.getLabel();

    return placeholder && required && !label
      ? `${placeholder}${requiredMark}`
      : placeholder;
  };

  isValid = () => {
    const { hadFocus, hasFocus } = this.state;
    const { isValid, validateError, value, required } = this.props;

    // clear error if the input field is focused or the validation was not called and the field never used
    if (hasFocus || (!validateError && !hadFocus)) {
      return true;
    }

    // fails if the input field is required and its current value is empty and any custom validation was in place
    let valueIsValid = isValid(value);
    if (valueIsValid === undefined) {
      valueIsValid = !required || value !== '';
    }

    return valueIsValid;
  };

  getErrorMessage = () => {
    const { getErrorMessage, value, required } = this.props;

    let errorMessage = getErrorMessage(value);
    if (errorMessage === undefined) {
      errorMessage =
        required && (!value || !value.toString().trim())
          ? REQUIRED_INPUT_ERROR_MESSAGE
          : DEFAULT_ERROR_MESSAGE;
    }
    return errorMessage;
  };

  render() {
    const {
      className: inputClassName,
      style,
      center,
      size,
      variant,
      required,
      block,
      value,
      label: _,
      password,
      suggestion,
      onBackspacePressed: __,
      bottomWrapper,
      showTag,
      onTagArrowClick,
      placeholder: ___,
      maxLength,
      showWordCounter,
      useFakePlaceholder,
      requiredMark: ____,
      onSpacebarPressed: _____,
      reset,
      children,
      capitalize: ______,
      isValid: _______,
      getErrorMessage: ________,
      validateError: _________,
      ...rest
    } = this.props;

    const label = this.getLabel();
    const placeholder = this.getPlaceholder();
    const invalid = !this.isValid();
    const errorMessage = this.getErrorMessage();

    const className = classnames(
      'input',
      {
        'input--center': center,
        'input--block': block,
        'input--labeled': label,

        'input--size-small': size === 'small',
        'input--size-large': size === 'large',
        'input--size-medium': size === 'medium',

        'input--smalt': variant === 'smalt',
        'input--alt-smalt': variant === 'alt-smalt',
        'input--black': variant === 'black',

        'input--wrong': invalid,
      },
      inputClassName
    );

    const inputWrapperClassName = classnames('input__wrapper', {
      'input__wrapper-tag': showTag,
      'input__wrapper-counter': showWordCounter,
    });

    const wordCount =
      maxLength && Math.max(0, maxLength - (value ? value.length : 0));

    return (
      <label onClick={this.onLabelClick} className={className} style={style}>
        {label && <div className="input__label">{label}</div>}
        <div className={inputWrapperClassName}>
          <input
            ref={this.input}
            required={required}
            className="input__field"
            type={password ? 'password' : 'text'}
            value={value}
            placeholder={useFakePlaceholder ? undefined : placeholder}
            reset={reset.toString()}
            {...rest}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onInput={this.onInput}
            maxLength={maxLength}
          />
          {useFakePlaceholder && value.length === 0 && (
            <span className="input__fake-placeholder">{placeholder}</span>
          )}
          {showWordCounter && maxLength && (
            <span className="word-counter">{wordCount}</span>
          )}
          {showTag && <Svg name="tag-arrow" onClick={onTagArrowClick} />}
          {suggestion && this.state.hasFocus && (
            <>
              <div
                className="input__field input__field__measure"
                ref={this.measureInput}
              >
                {value}
              </div>
              <input
                ref={this.inputSuggestion}
                className="input__field input__field__suggestion"
                type="text"
                placeholder={suggestion}
                readOnly
                tabIndex="-1"
              />
              <div
                className="input__field input__field__measure"
                ref={this.measureSuggestion}
              >
                {suggestion}
              </div>
            </>
          )}
        </div>
        {(!showTag || bottomWrapper) && (
          <div className="input__bottom">
            {(required || invalid) && (
              <span
                className={classnames('input__bottom__error', {
                  'input__bottom__error--visible': invalid && errorMessage,
                })}
              >
                <Svg name="cross" />
                {errorMessage}
              </span>
            )}

            {bottomWrapper}
          </div>
        )}
        {children}
      </label>
    );
  }
}
