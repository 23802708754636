import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';

import winterOutput from '#/animations/winter-output.json';
import springOutput from '#/animations/spring-output.json';
import summerOutput from '#/animations/summer-output.json';
import autumnOutput from '#/animations/autumn-output.json';

import SpotifyPlayer from './SpotifyPlayer';
import isSSR from '@/utils/isSSR';

import './style.css';

const Lottie = !isSSR ? require('react-lottie').default : null;

const summerAnimations = {
  wintry: winterOutput,
  spring: springOutput,
  summery: summerOutput,
  autumn: autumnOutput,
};

const SPOTIFY_MIN_YEAR = 1910;

class Birthday extends Component {
  monthToSeason() {
    const { lifeline } = this.props;

    if (!lifeline) {
      return ['', ''];
    }

    const { data } = lifeline;
    const birthAsMoment = moment(data.remembering.birthDate, 'YYYYMMDD');
    const month = birthAsMoment.month();
    const name = birthAsMoment.format('dddd');

    if (month === 11 || (month >= 0 && month < 2)) {
      return ['wintry', name];
    }

    if (month >= 2 && month < 5) {
      return ['spring', name];
    }

    if (month >= 5 && month < 8) {
      return ['summery', name];
    }

    if (month >= 8 && month < 11) {
      return ['autumn', name];
    }

    return ['', ''];

    // Wintery = season based on month of their birthday:
    // Dec-Feb = Wintery
    // Mar-May= Spring
    // Jun-Aug= Summery
    // Sep-Nov= Autumnl
    // Snowflake icon also changes with season
    // ‘Wednesday’ - Looked up from date of birth.
  }

  render() {
    const {
      lifeline,
      active: isActive,
      exiting: _,
      entering: __,
      ...props
    } = this.props;
    const {
      data: { remembering },
    } = lifeline;

    const [attrMonth, weekdayname] = this.monthToSeason();

    const artist =
      remembering.birthDateTopSong && remembering.birthDateTopSong.artist;
    const song =
      remembering.birthDateTopSong && remembering.birthDateTopSong.title;
    const song_url =
      remembering.birthDateTopSong &&
      remembering.birthDateTopSong.spotify &&
      remembering.birthDateTopSong.spotify.url;
    const birthDateMoment = moment(remembering.birthDate, 'YYYYMMDD');
    const year = birthDateMoment.year();

    return (
      <div
        className={classnames('lifeline-birthday', {
          'lifeline-birthday--enter': isActive,
        })}
        {...props}
      >
        <div className="lifeline-birthday__container">
          <p>
            {remembering.firstName}
            {remembering.firstName.endsWith('s') ? '’' : '’s'} story began on a{' '}
            {attrMonth} {weekdayname} in{' '}
            {remembering.birthDate && birthDateMoment.format('YYYY')}
            {artist && song && year >= SPOTIFY_MIN_YEAR
              ? `, to the sound of ${artist}${
                  artist.endsWith('s') ? '’' : '’s'
                } ${song} topping the singles charts.`
              : '.'}
          </p>

          <div
            className={classnames(
              'lifeline-birthday__date',
              attrMonth.toLowerCase()
            )}
          >
            {birthDateMoment.format('D MMMM YYYY')}
          </div>

          {song_url && (
            <SpotifyPlayer url={song_url} artist={artist} title={song} />
          )}
        </div>
        <div className="lifeline-birthday__season">
          {!isSSR && (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: summerAnimations[attrMonth],
              }}
              isStopped={!isActive}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Birthday;
