import cleanLifeline from '@/utils/cleanLifeline';

export default {
  state: global.injectedLifelines || {},
  reducers: {
    removeAll() {
      return {};
    },
    remove(state, slug) {
      const newState = { ...state };
      delete newState[slug];
      return newState;
    },
    loadLifeline(state, lifeline) {
      return {
        ...state,
        [lifeline.slug]: {
          ...lifeline,
          data: {
            ...cleanLifeline(lifeline.data),
          },
        },
      };
    },
  },
};
