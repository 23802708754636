export default {
  state: {
    loginOrSignupRunning: false,
  },

  reducers: {
    setLoginSignupRunningState(state, running) {
      return {
        ...state,
        loginOrSignupRunning: running,
      };
    },
  },
};
