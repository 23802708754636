import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getState } from '@/store';
import store from '@/store';

function isLogged(state, store) {
  return store.select.user.isLogged(state);
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { allowed = isLogged, redirectTo = '/login' } = rest;
  const routeAllowed = allowed(getState(), store);

  return (
    <Route
      {...rest}
      render={props =>
        routeAllowed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: redirectTo, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
