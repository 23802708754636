import React, { PureComponent } from 'react';

import getMapboxKey from '@/utils/getMapboxKey';
import isSSR from '@/utils/isSSR';

import './style.css';

let ReactMapboxGl, Marker, Cluster, Mapbox;
if (!isSSR) {
  const reactMapBox = require('react-mapbox-gl');
  ReactMapboxGl = reactMapBox.default;
  Marker = reactMapBox.Marker;
  Cluster = reactMapBox.Cluster;
  Mapbox = ReactMapboxGl({
    accessToken: getMapboxKey(),
    interactive: false,
    attributionControl: false,
  });
}

class PureComponentMap extends PureComponent {
  render() {
    const { onStyleLoad, useZoom, coords, markers, clusterMarker } = this.props;

    return (
      <Mapbox
        onStyleLoad={onStyleLoad}
        style="mapbox://styles/r-roll/ck1v3cn431d5z1cmxnn5c0fqr?optimize=true"
        containerStyle={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
        }}
        zoom={useZoom ? [useZoom] : [11]}
        center={coords}
      >
        <Cluster ClusterMarkerFactory={clusterMarker} radius={1}>
          {markers.map((m, index) => (
            <Marker coordinates={m.coordinates} key={index} anchor="top-left">
              {m.children}
            </Marker>
          ))}
        </Cluster>
      </Mapbox>
    );
  }
}

export default class Map extends PureComponent {
  static defaultProps = {
    useZoom: 1,
    coords: [0, 40],
    topBannerAnimation: false,
  };

  addMapIfDetached = () => {
    if (!this.map) {
      return;
    }
    const container = this.map._canvasContainer;
    if (!container.parentElement) {
      const parent = document
        .getElementById(this.props.id)
        .querySelector('.mapboxgl-map');

      parent.insertBefore(
        container,
        parent.hasChildNodes() ? parent.firstChild : null
      );
    }
  };

  clusterMarker = (coordinates, pointCount, getLeaves) => {
    const leaves = getLeaves(Infinity, 0);
    const titles = leaves.reduce(
      (p, m) => `${p}${m.props.children.props.label}<br />`,
      ''
    );
    const classes = leaves.reduce(
      (p, m) => `${p} map-pin${m.props.children.props.index}`,
      ''
    );

    return (
      <Marker key={classes} coordinates={coordinates}>
        <div className={`map-pin ${classes}`}>
          <h1
            onMouseOver={() => {
              if (this.props.onClusterMouseOver) {
                this.props.onClusterMouseOver(leaves);
              }
              // this.stopActivePlaceTimer();
              // this.setMarkerAsActive(index - 1);
            }}
            onMouseOut={() => {
              if (this.props.onClusterMouseOut) {
                this.props.onClusterMouseOut(leaves);
              }
              // this.restartActivePlaceTimer();
            }}
            style={{
              animationDelay: `${0.2 + Math.random() * (1 - 0.2)}s`,
            }}
            dangerouslySetInnerHTML={{ __html: titles }}
          />
        </div>
      </Marker>
    );
  };

  onMapLoaded = map => {
    this.map = map;
    if (this.props.setMap) {
      this.props.setMap(map);
    }
  };

  render() {
    const {
      id,
      topBanner,
      bottomBanner,
      useZoom,
      coords,
      markers,
    } = this.props;

    this.addMapIfDetached();

    return (
      <>
        <div className="lifeline-map__banner lifeline-map__banner--top">
          {topBanner}
        </div>

        <div className="lifeline-map" id={id}>
          <div className="lifeline-map__map-gradient lifeline-map__map-gradient--top" />

          {!isSSR && (
            <PureComponentMap
              onStyleLoad={this.onMapLoaded}
              useZoom={useZoom}
              coords={coords}
              markers={markers}
              clusterMarker={this.clusterMarker}
            />
          )}

          <div className="lifeline-map__map-gradient lifeline-map__map-gradient--bottom" />
        </div>
        <div className="lifeline-map__banner lifeline-map__banner--bottom">
          {bottomBanner}
        </div>
      </>
    );
  }
}
