import React, { Component, createRef } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import classnames from 'classnames';

import smoothScroll from '@/utils/smoothScroll';
import getGenitiveNoun from '@/utils/getGenitiveNoun';

import LifelineShape from '@/components/LifelineShape';
import Button from '@/components/Button';
import Svg from '@/components/Svg';
// import { OPTIONS } from '@/components/LifelineEditor/Remembering/ArmedForces/options';

import getLifelineDateText from '@/utils/getLifelineDateText';

import './style.css';

class Cover extends Component {
  static defaultProps = {
    end: false,
    onIntroComplete: f => f,
    onBadgeToggle: f => f,
  };

  constructor(props) {
    super(props);

    this.titleRef = createRef(null);
    this.titleInnerRef = createRef(null);

    this.state = {
      enter: props.end,
      militaryExit: false,
      shouldAnimate: props.end,
    };

    this.cloudComponent = createRef();
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      entries => {
        const newMilitaryExit = entries[0].intersectionRatio <= 0.25;

        if (this.state.militaryExit !== newMilitaryExit) {
          this.props.onBadgeToggle(!newMilitaryExit);

          this.setState({
            militaryExit: newMilitaryExit,
          });
        }
      },
      { threshold: [0, 0.25, 1] }
    );

    window.setTimeout(() => {
      this.setState({ enter: true });
    }, 1000);

    const mil = document.querySelector(
      this.props.end ? '.lifeline-cover--end' : '.lifeline-cover'
    );
    if (mil) {
      this.observer.observe(mil);
    }

    this.updateTitleSize();

    window.addEventListener('resize', this.updateTitleSize);
  }

  componentWillUnmount() {
    this.observer.disconnect();
    window.removeEventListener('resize', this.updateTitleSize);
  }

  updateTitleSize = () => {
    const currentFontSize = parseFloat(this.titleRef.current.style.fontSize);
    this.titleRef.current.style.fontSize =
      (currentFontSize * this.titleRef.current.offsetWidth) /
        this.titleInnerRef.current.offsetWidth +
      'px';
    this.titleInnerRef.current.style.opacity = 1;
  };

  onClick = e => {
    e.preventDefault();

    this.smoothScroll = new smoothScroll('#lifeline-intro', null, {
      scrollParent: document.querySelector('.lifeline-output-section--intro'),
    });
  };

  onIntroComplete = e => {
    this.setState({ shouldAnimate: true });
    this.props.onIntroComplete();
  };

  render() {
    const {
      lifeline,
      end,
      className,
      active: isActive,
      onIntroComplete: __,
      onBadgeToggle: ___,
      exiting: ____,
      entering: _____,
      ...props
    } = this.props;
    const {
      data,
      status,
      data: { remembering },
    } = lifeline;
    const { enter, militaryExit, shouldAnimate } = this.state;

    return (
      <div
        className={classnames(
          'lifeline-cover',
          {
            'lifeline-cover--end': end,
            enter,
          },
          className
        )}
        {...props}
      >
        <Helmet>
          <title>{`Discover ${remembering.firstName} ${getGenitiveNoun(
            remembering.lastName
          )} story`}</title>

          <meta
            property="og:title"
            content={`${data.remembering.firstName} ${data.remembering.lastName} | Memories`}
          />

          <meta
            property="og:description"
            content="Memories is a place to celebrate lives, share stories and remember loved ones."
          />

          {status && status === 'private' && (
            <meta name="robots" content="noindex" />
          )}

          {data.remembering.photo && (
            <meta
              property="og:image"
              content={data.remembering.photo.remoteUrl}
            />
          )}
        </Helmet>

        <LifelineShape
          data={data}
          paused={!isActive || !shouldAnimate}
          cloud={false}
          transparent
        />

        <div
          className="lifeline-cover__titles"
          onTransitionEnd={this.onIntroComplete}
        >
          <h1 ref={this.titleRef} style={{ fontSize: '40px' }}>
            <span ref={this.titleInnerRef}>
              {remembering.firstName} {remembering.lastName}
            </span>
          </h1>
          <h2>{getLifelineDateText(remembering)}</h2>
          {!end && (
            <div className="lifeline-cover__titles__cta-wrapper">
              <Button
                className="lifeline-cover__titles__cta"
                onClick={this.onClick}
                variant="resolution-blue"
                size="large"
              >
                Discover <span>{remembering.firstName}</span>'
                {remembering.firstName.endsWith('s') ? '' : 's'} story
              </Button>
            </div>
          )}
        </div>

        {!end && remembering.military && (
          <div
            className={classnames('lifeline-cover__service', { militaryExit })}
          >
            <p>
              Member of the <br />
              {remembering.militaryCategory}
            </p>

            <Svg name="military-badge" />
          </div>
        )}
      </div>
    );
  }
}

export default Cover;
