import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import models from '@/models';

let store;

if (process.env.SSR_RENDERING) {
  store = init({
    models,
    plugins: [selectPlugin()],
  });
} else {
  const createRematchPersist = require('@rematch/persist').default;
  const createLoadingPlugin = require('@rematch/loading').default;
  const localForage = require('localforage');

  const persistPlugin = createRematchPersist({
    storage: localForage,
    whitelist: ['user'],
    // throttle: 1000,
    version: 1,
    debug: process.env.CURRENT_ENV !== 'prod',
  });

  store = init({
    models,
    plugins: [persistPlugin, selectPlugin(), createLoadingPlugin()],
  });
}

export const { getState } = store;
export default store;
