import React, { Component, createRef } from 'react';
import classnames from 'classnames';

import { tablet } from '@/components/App/breakpoints';

import './style.css';

const mobilePatterns = {
  intro: [
    {
      width: 15,
      height: 15,
      top: 0,
      left: 0,
      zIndex: 1,
    },
    {
      width: 9,
      height: 15,
      top: 2,
      left: 10,
      zIndex: 1,
    },
    {
      width: 6,
      height: 10,
      top: 2,
      right: 0,
      zIndex: 2,
    },
    {
      width: 14,
      height: 17,
      top: 6,
      left: 5,
      zIndex: 0,
    },
    {
      width: 8,
      height: 12,
      top: 9,
      left: 12,
      zIndex: 1,
    },
  ],
  family: [
    {
      width: 10,
      height: 18,
      top: 0,
      left: 0,
      zIndex: 2,
    },
    {
      width: 18,
      height: 16,
      top: 2,
      left: 2,
      zIndex: 1,
    },
    {
      width: 8,
      height: 12,
      top: 5,
      left: 12,
      zIndex: 2,
    },
    {
      width: 18,
      height: 18,
      top: 10,
      left: 5,
      zIndex: 1,
    },
    {
      width: 10,
      height: 16,
      top: 12,
      left: 16,
      zIndex: 2,
    },
    {
      width: 10,
      height: 18,
      top: 10,
      left: 0,
      zIndex: 2,
    },
    {
      width: 18,
      height: 16,
      top: 11,
      left: 2,
      zIndex: 1,
    },
    {
      width: 8,
      height: 12,
      top: 14,
      left: 12,
      zIndex: 2,
    },
    {
      width: 18,
      height: 18,
      top: 16,
      left: 5,
      zIndex: 1,
    },
    {
      width: 10,
      height: 16,
      top: 18,
      left: 16,
      zIndex: 2,
    },
  ],
  important_people: [
    {
      width: 30,
      height: 22,
      top: 0,
      left: 0,
      zIndex: 1,
    },
    {
      width: 10,
      height: 17,
      top: 4,
      right: 2,
      zIndex: 2,
    },
    {
      width: 20,
      height: 13,
      top: 8,
      left: 5,
      zIndex: 0,
    },
    {
      width: 8,
      height: 14,
      top: 9,
      right: 0,
      zIndex: 1,
    },
    {
      width: 15,
      height: 11,
      top: 10,
      left: 2,
      zIndex: 2,
    },
  ],
  friends: [
    {
      width: 14,
      height: 13,
      top: 0,
      left: 0,
      zIndex: 0,
    },
    {
      width: 8,
      height: 12,
      top: 8,
      left: 8,
      zIndex: 2,
    },
    {
      width: 13,
      height: 23,
      top: 4,
      right: 0,
      zIndex: 1,
    },
    {
      width: 18,
      height: 17,
      top: 10,
      left: 2,
      zIndex: 1,
    },
    {
      width: 16,
      height: 20,
      top: 14,
      left: 10,
      zIndex: 2,
    },
  ],
  friends_quote: [
    {
      width: 12,
      height: 11,
      top: 1,
      right: 0,
      zIndex: 1,
    },
    {
      width: 12,
      height: 11,
      top: 6,
      left: 7,
      right: 50,
      zIndex: 2,
    },
    {
      width: 7,
      height: 12,
      top: 12,
      right: 0,
      zIndex: 2,
    },
    {
      width: 12,
      height: 11,
      top: 16,
      right: 0,
      zIndex: 1,
    },
    {
      width: 12,
      height: 11,
      top: 20,
      left: 7,
      right: 50,
      zIndex: -1,
    },
  ],
};

const patterns = {
  intro: [
    {
      width: 8,
      height: 8,
      top: 5,
      left: 2,
      zIndex: 2,
    },
    {
      width: 4.5,
      height: 8.5,
      top: 8,
      left: 9,
      zIndex: 1,
    },
    {
      width: 3,
      height: 5,
      top: 4,
      left: 11,
      zIndex: 0,
    },
    {
      width: 6,
      height: 8,
      top: 6,
      left: 15,
      zIndex: 1,
    },
    {
      width: 3,
      height: 5,
      top: 7,
      left: 20,
      zIndex: 2,
    },
  ],
  family: [
    {
      width: 5,
      height: 9,
      top: 3,
      left: 0,
      zIndex: 2,
    },
    {
      width: 9,
      height: 8,
      top: 9,
      left: 2,
      zIndex: 1,
    },
    {
      width: 4,
      height: 6,
      top: 6,
      left: 10,
      zIndex: 0,
    },
    {
      width: 10,
      height: 9,
      top: 2,
      left: 15,
      zIndex: 1,
    },
    {
      width: 5,
      height: 8,
      top: 8,
      left: 22,
      zIndex: 2,
    },
    {
      width: 5,
      height: 9,
      top: 3,
      left: 28,
      zIndex: 2,
    },
    {
      width: 9,
      height: 8,
      top: 9,
      left: 30,
      zIndex: 1,
    },
    {
      width: 4,
      height: 6,
      top: 6,
      left: 38,
      zIndex: 0,
    },
    {
      width: 10,
      height: 9,
      top: 2,
      left: 43,
      zIndex: 1,
    },
    {
      width: 5,
      height: 8,
      top: 8,
      left: 50,
      zIndex: 2,
    },
  ],
  important_people: [
    {
      width: 10,
      height: 9,
      top: 2,
      left: 1,
      zIndex: 1,
    },
    {
      width: 5,
      height: 8,
      top: 10,
      left: 8,
      zIndex: 2,
    },
    {
      width: 5,
      height: 9,
      top: 3,
      left: 14,
      zIndex: 0,
    },
    {
      width: 9,
      height: 8,
      top: 8,
      left: 16,
      zIndex: 1,
    },
    {
      width: 4,
      height: 6,
      top: 5,
      left: 24,
      zIndex: 2,
    },
  ],
  friends: [
    {
      width: 10,
      height: 9,
      top: 8,
      left: 1,
      zIndex: 0,
    },
    {
      width: 3,
      height: 4,
      top: 2,
      left: 8,
      zIndex: 2,
    },
    {
      width: 5,
      height: 9,
      top: 5,
      left: 9,
      zIndex: 1,
    },
    {
      width: 5,
      height: 9,
      top: 3,
      left: 16,
      zIndex: 1,
    },
    {
      width: 5,
      height: 6,
      top: 10,
      left: 20,
      zIndex: 2,
    },
  ],
  friends_quote: [
    {
      width: 9,
      height: 8,
      top: 12,
      left: 6,
      zIndex: 0,
    },
    {
      width: 9,
      height: 7,
      top: 0,
      left: 20,
      zIndex: 2,
    },
    {
      width: 5,
      height: 8,
      top: 12,
      left: 35,
      zIndex: 1,
    },
    {
      width: 9,
      height: 8,
      top: 12,
      left: 46,
      zIndex: 0,
    },
    {
      width: 9,
      height: 7,
      top: 0,
      left: 60,
      zIndex: 2,
    },
  ],
};

class ParallaxImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      offset: 0,
    };

    this.container = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    setTimeout(() => this.onResize(), 100);

    if (this.container.current) {
      this.parallaxContainer = this.container.current.closest(
        '.lifeline-family'
      );
    }

    if (this.props.active) {
      this.raf = requestAnimationFrame(this.update);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.raf = requestAnimationFrame(this.update);
    } else if (prevProps.active && !this.props.active) {
      this.raf = cancelAnimationFrame(this.raf);
    }

    this.backgroundImages = [
      ...this.container.current.querySelectorAll('.zIndexBackground'),
    ];
    this.middleImages = [
      ...this.container.current.querySelectorAll('.zIndexMiddle'),
    ];
    this.foregroundImages = [
      ...this.container.current.querySelectorAll('.zIndexForeground'),
    ];
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    cancelAnimationFrame(this.raf);
  }

  onResize = e => {
    const images = [];
    const isDesktop = window.innerWidth > tablet;

    this.windowWidth = window.innerWidth;

    const pattern = isDesktop
      ? patterns[this.props.pattern] || []
      : mobilePatterns[this.props.pattern] || [];

    this.gutterToColumnRatio = 0.5;

    if (this.props.maxWidth) {
      const last_img = pattern[this.props.images.length - 1];
      const max_columns = last_img.left + last_img.width;
      this.col_width =
        (1.3 * this.props.maxWidth) / (max_columns + 0.5 * (max_columns - 1));
    } else {
      this.col_width = this.windowWidth / (24 + this.gutterToColumnRatio * 23);
    }
    this.gutter_width = this.gutterToColumnRatio * this.col_width;

    this.row_height = this.col_width / 1.25;

    const src_images = [...this.props.images];

    let start = 0;

    src_images.forEach(img => {
      const pos = pattern[start] || {};
      images.push({
        ...pos,
        src: img.src || img,
        left: this.getWidth(pos.left) + this.gutter_width,
        width: this.getWidth(pos.width),
        top: (100 * pos.top) / 20,
        height: this.getHeight(pos.height),
        text: img.text,
      });

      start++;
    });

    this.setState({ images });
  };

  update = () => {
    if (this.parallaxContainer) {
      this.setParallax(this.parallaxContainer.scrollLeft);
    }

    requestAnimationFrame(this.update);
  };

  setParallax = left => {
    if (!this.container.current) return;

    let offset = 0;
    if (
      this.container.current.offsetLeft - left < this.windowWidth &&
      this.container.current.offsetLeft + this.container.current.offsetWidth >
        left
    ) {
      offset =
        this.container.current.offsetLeft < this.windowWidth
          ? -left
          : this.container.current.offsetLeft - left;
    }

    //move the three layers of images
    if (this.windowWidth > tablet) {
      this.backgroundImages.forEach(img => {
        img.style.transform = `translateX(${offset / 10}px)`;
      });
      this.middleImages.forEach(img => {
        img.style.transform = `translateX(${offset / 5}px)`;
      });
      this.foregroundImages.forEach(img => {
        img.style.transform = `translateX(${offset / 2.5}px)`;
      });
    } else {
      this.backgroundImages.forEach(img => {
        const imgTop = img.getBoundingClientRect().top;
        img.style.transform = `translateY(${imgTop / 20}px)`;
      });
      this.middleImages.forEach(img => {
        const imgTop = img.getBoundingClientRect().top;
        img.style.transform = `translateY(${imgTop / 10}px)`;
      });
      this.foregroundImages.forEach(img => {
        const imgTop = img.getBoundingClientRect().top;
        img.style.transform = `translateY(${imgTop / 5}px)`;
      });
    }
  };

  getWidth = columns => {
    return (
      this.col_width * (columns + this.gutterToColumnRatio * (columns - 1))
    );
  };

  getHeight = rows => {
    return this.row_height * (rows + 0.2 * (rows - 1));
  };

  render() {
    const { images } = this.state;
    const { headerColor, pattern } = this.props;
    const isDesktop = this.windowWidth > tablet;

    const positions = isDesktop ? patterns[pattern] : mobilePatterns[pattern];
    const last_position = positions[images.length - 1] || { left: 0, width: 0 };
    const col_width = last_position.left + last_position.width;
    const col_height = last_position.top + last_position.height;

    const isMobile = this.windowWidth < tablet;
    const styles = {
      flex: `0 0 calc(var(--column) * ${col_width} + var(--gutter) * ${col_width -
        1})`,
      width: `calc(var(--column) * ${col_width} + var(--gutter) * ${col_width -
        1})`,
    };
    const mobileStyles = {
      height: `calc(var(--row) * ${col_height} + var(--gutter-row) * ${col_height -
        10})`,
      width: '100%',
      marginBottom: '-10vh',
    };

    return (
      <div
        className={classnames(
          'parallax-container',
          `header-color--${headerColor}`
        )}
        style={isMobile ? mobileStyles : styles}
        ref={this.container}
      >
        {images.map((img, index) => {
          return (
            <React.Fragment key={`parallax-image--${index}`}>
              {img.text && (
                <p
                  className="parallax-container--friend_quote"
                  style={{
                    top: `${img.top - 10}%`,
                    zIndex: img.zIndex + 1,
                  }}
                >
                  {img.text}
                </p>
              )}
              <img
                data-index={index + 1}
                className={classnames('media', {
                  zIndexForeground: img.zIndex === 2,
                  zIndexMiddle: img.zIndex === 1,
                  zIndexBackground: img.zIndex === 0,
                })}
                key={`${index}-${img.src}`}
                src={img.src}
                style={{
                  zIndex: img.zIndex,
                  left: `${img.left}px`,
                  top: `${img.top}%`,
                  right: `${img.right}%`,
                  width: `${img.width}px`,
                  height: `${img.height}px`,
                }}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default ParallaxImages;
