import apiFetch from '@/api/apiFetch';

export default async ({
  node,
  firstName,
  lastName,
  email,
  relationship,
  lifelineId,
  social,
  ref,
}) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      node,
      first_name: firstName,
      last_name: lastName,
      email,
      relationship,
      lifelineId,
      ref,
      social: social,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch(`lifelines/${lifelineId}/help`, options, 201);

  return await response.json();
};
