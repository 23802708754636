import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import classnames from 'classnames';

import Svg from '@/components/Svg';
import config from '@/config';
import { getPersonality } from '@/data/adjectives';
import songsDataset from '@/data/songs';
import shuffle from '@/utils/shuffle';

import './style.css';

export default ({ lifeline, disabled: isDisabled }) => {
  const player = useRef(null);
  const closeTimeout = useRef(null);

  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const adjectives =
    (lifeline &&
      lifeline.data &&
      lifeline.data.remembering &&
      lifeline.data.remembering.adjectives) ||
    [];
  const lifelineUUID = (lifeline && lifeline.uuid) || '';
  const seed = useMemo(
    () =>
      lifelineUUID.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0),
    [lifelineUUID]
  );
  const songs = useMemo(() => {
    const songs = [];

    // get adjectives personalities from adjectives
    const personalities = adjectives
      // get label from adjectives
      .map(a => a.label)
      // get only unique adjectives
      .filter((value, index, self) => self.indexOf(value) === index)
      // get personality from adjectives
      .map(getPersonality)
      // exclude empty personality
      .filter(p => p);

    // shuffle songsDataset with seed
    const shuffledSongs = shuffle(songsDataset, seed);

    if (!personalities.length) {
      // if no personality, get 3 random songs
      return shuffledSongs.slice(0, 3);
    }

    // get songs from personalities
    let personalityIndex = 0;
    while (
      personalityIndex < personalities.length &&
      songs.length < config.musicPlayerMaxSongs
    ) {
      const personality = personalities[personalityIndex];

      let songIndex = 0;
      while (
        songIndex < shuffledSongs.length &&
        songs.length < config.musicPlayerMaxSongs
      ) {
        const songToAdd = shuffledSongs[songIndex];

        if (
          songToAdd.personalities.includes(personality) &&
          !songs.find(s => s.url === songToAdd.url)
        ) {
          songs.push(songToAdd);
        }

        songIndex++;
      }

      personalityIndex++;
    }

    return songs;
  }, [adjectives, seed]);

  const currentSong = useMemo(() => songs[currentSongIndex] || null, [
    songs,
    currentSongIndex,
  ]);

  const resetCloseTimeout = useCallback(() => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }

    closeTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, config.musicPlayerCloseDelay);
  }, []);

  const handleAudioPlay = useCallback(() => {
    setIsOpen(true);
    setIsPlaying(true);
  }, []);

  const handleAudioPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const handleOuterToggleClick = useCallback(() => {
    resetCloseTimeout();

    if (isOpen) {
      setIsOpen(false);
    } else {
      if (player.current.paused) {
        player.current.play();
        setIsPlaying(true);
      }

      setIsOpen(true);
    }
  }, [isOpen]);

  const handleInnerToggleClick = useCallback(() => {
    resetCloseTimeout();

    if (isPlaying) {
      player.current.pause();
      setIsPlaying(false);
    } else {
      player.current.play();
      setIsPlaying(true);
    }
  }, [isPlaying]);

  const handleGoToPreviousSong = useCallback(() => {
    resetCloseTimeout();

    if (currentSongIndex <= 0) {
      return;
    }

    setCurrentSongIndex(currentSongIndex - 1);
  }, [currentSongIndex, songs]);
  const handleGoToNextSong = useCallback(() => {
    resetCloseTimeout();

    if (currentSongIndex >= songs.length - 1) {
      return;
    }

    setCurrentSongIndex(currentSongIndex + 1);
  }, [currentSongIndex, songs]);

  // play current player on currentSongIndex change
  const handleKeepPlaying = useCallback(() => {
    if (isPlaying && player.current.paused) {
      player.current.play();
    }
  }, [isPlaying]);
  useEffect(() => {
    if (currentSong) {
      handleKeepPlaying();
    }
  }, [currentSong]);

  const handleAutoscrollStart = useCallback(() => {
    if (document.body.classList.contains('lifeline-output-recording')) {
      return;
    }

    if (!isOpen) {
      resetCloseTimeout();

      if (player.current.paused) {
        player.current.play();
        setIsPlaying(true);
      }

      setIsOpen(true);
    }
  }, [isOpen]);
  const handleAutoscrollStop = useCallback(() => {
    if (!player.current.paused) {
      player.current.pause();
      setIsPlaying(false);
    }

    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);
  useEffect(() => {
    window.addEventListener('autoscrollStart', handleAutoscrollStart);
    window.addEventListener('autoscrollStop', handleAutoscrollStop);

    return () => {
      window.removeEventListener('autoscrollStart', handleAutoscrollStart);
      window.removeEventListener('autoscrollStop', handleAutoscrollStop);
    };
  }, []);

  return (
    <div
      className={classnames('music-player', {
        'music-player--open': isOpen,
        'music-player--playing': isPlaying,
        'music-player--disabled': isDisabled || !lifeline,
      })}
    >
      <button
        className="music-player__outer-toggle"
        type="button"
        onClick={handleOuterToggleClick}
      >
        <Svg name="music" />
      </button>

      <div className="music-player__inner">
        <div className="music-player__player">
          <button
            className="music-player__inner-toggle"
            type="button"
            onClick={handleInnerToggleClick}
          >
            <Svg name="music-pause" />
            <Svg name="music-play" />
          </button>

          <div className="music-player__song-name">
            {currentSong ? currentSong.name : 'Untitled'}
          </div>

          <div className="music-player__controls">
            <button
              className={classnames(
                'music-player__controls-button music-player__controls-button--previous',
                {
                  'music-player__controls-button--disabled':
                    currentSongIndex <= 0,
                }
              )}
              onClick={handleGoToPreviousSong}
              type="button"
            >
              <Svg name="music-control" />
            </button>
            <button
              className={classnames(
                'music-player__controls-button music-player__controls-button--next',
                {
                  'music-player__controls-button--disabled':
                    currentSongIndex >= songs.length - 1,
                }
              )}
              onClick={handleGoToNextSong}
              type="button"
            >
              <Svg name="music-control" />
            </button>
          </div>
        </div>
      </div>

      {currentSong && (
        <audio
          ref={player}
          className="music-player__audio"
          data-id={currentSong.id}
          src={currentSong.url}
          onPlay={handleAudioPlay}
          onPause={handleAudioPause}
        />
      )}
    </div>
  );
};
