import React, { Fragment, Component } from 'react';

import SVGCatmullRomSpline from 'svg-catmull-rom-spline';

import Perlin from 'perlin-simplex';

import './style.css';

const SEGMENTS = 10;

class FamilyPaths extends Component {
  constructor(props) {
    super(props);
    this.points = [];
    this.points2 = [];
    this.points3 = [];

    this.tpoints = [];
    this.tpoints2 = [];
    this.tpoints3 = [];

    this.svg = React.createRef();
    this.path = React.createRef();
    this.path2 = React.createRef();
    this.path3 = React.createRef();

    this.state = {
      width: '100%',
      height: '100%',
      circles: [],
    };
  }

  componentDidMount() {
    if (this.svg.current) {
      this.container = this.svg.current.nextSibling;
      this.labels = [
        ...this.container.querySelectorAll('.lifeline-family--label'),
      ];

      this.seed = [];
      for (let i = 0; i < this.labels.length + 1; i++) {
        this.seed[i] = [];
        for (let j = 0; j < SEGMENTS; j++) {
          this.seed[i][j] = Math.random();
        }
      }

      this.handleResize();

      this.perlin = new Perlin();

      this.calcPoints();
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
  }

  componentDidUpdate() {
    this.calcPoints();
  }

  handleResize = () => {
    const circleY = this.container.offsetHeight * 0.95;

    const circles = [
      [
        window.innerWidth / 2,
        circleY,
        this.labels[0].querySelector('h3').style.color,
      ],
    ];

    this.labels.forEach(l => {
      const heading = l.querySelector('h3');
      circles.push([
        l.offsetLeft + heading.offsetHeight / 2,
        circleY,
        heading.style.color,
      ]);
    });

    this.setState({
      timestamp: Date.now(),
      width: `${this.container.scrollWidth}px`,
      height: `${this.container.offsetHeight}px`,
      circles,
    });
  };

  getLabelX = index => {
    const l = this.labels[index];
    if (l) {
      return l.offsetLeft + l.querySelector('h3').offsetHeight / 2;
    } else {
      return this.container.scrollWidth;
    }
  };

  calcPoints = () => {
    if (!this.path.current) return;

    const height = this.container.offsetHeight;

    const circleY = 0.95 * height;

    const numPoints = this.labels.length + 1;

    let y = circleY;
    let x = window.innerWidth / 2;

    this.points[0] = [x, y];

    for (let i = 0; i < numPoints; i++) {
      const currentLabelX = this.getLabelX(i);
      const deltaX = Math.round((currentLabelX - x) / SEGMENTS);
      if (i == 2) {
        this.points2[0] = [this.getLabelX(i - 1), circleY];
      }
      if (i == 3) {
        this.points3[0] = [this.getLabelX(i - 1), circleY];
      }
      for (let j = 0; j < SEGMENTS; j++) {
        const isFirstHalf = j < SEGMENTS / 2;
        const isLow = y > 0.85 * height;
        y +=
          (isFirstHalf || isLow ? -1 : 1) * height * (0.15 * this.seed[i][j]);
        x += deltaX;
        this.points[SEGMENTS * i + j + 1] =
          j == SEGMENTS - 1 ? [currentLabelX, circleY] : [x, y];
        if (i > 1) {
          this.points2[SEGMENTS * (i - 2) + j + 1] =
            j == SEGMENTS - 1
              ? [currentLabelX, circleY]
              : [
                  x + (-30 + 60 * this.seed[i - 2][j]),
                  y + (-30 + 60 * this.seed[i - 2][j]),
                ];
        }
        if (i > 2) {
          this.points3[SEGMENTS * (i - 3) + j + 1] =
            j == SEGMENTS - 1
              ? [currentLabelX, circleY]
              : [
                  x + (-30 + 60 * this.seed[i - 3][j]),
                  y + (-30 + 60 * this.seed[i - 3][j]),
                ];
        }
      }
    }

    if (this.points.length != numPoints * SEGMENTS + 1) {
      this.points = this.points.slice(0, numPoints * SEGMENTS + 1);
    }

    if (this.points2.length != (numPoints - 1) * SEGMENTS + 1) {
      this.points2 = this.points2.slice(0, (numPoints - 1) * SEGMENTS + 1);
    }

    if (this.points3.length != (numPoints - 2) * SEGMENTS + 1) {
      this.points3 = this.points3.slice(0, (numPoints - 2) * SEGMENTS + 1);
    }

    if (!this.raf) {
      this.update();
    }
  };

  update = () => {
    const time = Date.now() / 4000;
    const amp = 15;

    this.points.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(Math.cos(time + index), Math.sin(time + index));
      this.tpoints[index] = [p[0], p[1] + n];
    });

    this.points2.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(
              Math.cos(time + index * 2),
              Math.sin(time + index * 2)
            );
      this.tpoints2[index] = [p[0], p[1] + n];
    });

    this.points3.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(
              Math.cos(time + index * 4),
              Math.sin(time + index * 4)
            );
      this.tpoints3[index] = [p[0], p[1] + n];
    });

    /*
    Hacks ahead! Might break in future

    Apparently Windows/Chrome incorrectly handles the value of style#clipPath
    given in pixels for non-integer devicePixelRatios

    dprFixFactor corrects that issue
    */

    let dprFixFactor = 1;
    if (
      (navigator.vendor || '').toLowerCase().includes('google') &&
      (navigator.platform || '').toLowerCase().includes('win')
    ) {
      dprFixFactor = window.devicePixelRatio;
      // window.devicePixelRatio % 1 === 0 ? 1 : window.devicePixelRatio;
    }

    const baseInsetRight =
      parseFloat(this.state.width) - this.container.scrollLeft;

    if (this.tpoints.length) {
      const insetValue =
        (baseInsetRight - 0.7 * window.innerWidth) / dprFixFactor;

      this.d = SVGCatmullRomSpline.toPath(this.tpoints); //, 4, true);
      this.path.current.setAttribute('d', this.d);
      this.path.current.style.clipPath = `inset(0 ${insetValue}px 0 0)`;
    }

    if (this.tpoints2.length) {
      const insetValue =
        (baseInsetRight - 0.77 * window.innerWidth) / dprFixFactor;

      this.d2 = SVGCatmullRomSpline.toPath(this.tpoints2); //, 4, true);
      this.path2.current.setAttribute('d', this.d2);

      this.path2.current.style.clipPath = `inset(0 ${insetValue}px 0 0)`;
    }

    if (this.tpoints3.length) {
      const insetValue =
        (baseInsetRight - 0.64 * window.innerWidth) / dprFixFactor;

      this.d3 = SVGCatmullRomSpline.toPath(this.tpoints3); //, 4, true);
      this.path3.current.setAttribute('d', this.d3);
      this.path3.current.style.clipPath = `inset(0 ${insetValue}px 0 0)`;
    }

    this.raf = requestAnimationFrame(this.update);
  };

  render() {
    const { width, height, labels, circles } = this.state;

    return (
      <svg
        className="lifeline-family--paths"
        ref={this.svg}
        style={{ width }}
        width={parseFloat(width)}
        height={parseFloat(height)}
      >
        <path
          d={this.d}
          ref={this.path}
          fill="none"
          stroke={circles[1] && circles[1][2]}
        />
        <path
          d={this.d2}
          ref={this.path2}
          fill="none"
          stroke={circles[2] && circles[2][2]}
        />
        <path
          d={this.d3}
          ref={this.path3}
          fill="none"
          stroke={circles[3] && circles[3][2]}
        />
        {circles.map((c, index) => (
          <Fragment key={`path-fragment--${index}`}>
            <circle
              key={`c3-${index}`}
              cx={c[0]}
              cy={c[1]}
              r={3.5}
              fill={c[2]}
            />
            <svg
              width="30"
              height="30"
              x={c[0] - 15}
              y={c[1] - 15}
              className="circle"
              key="index"
            >
              <circle
                key={`c1-${index}`}
                cx="15"
                cy="15"
                r={13.5}
                fill="none"
                stroke={c[2]}
              />
              <circle
                key={`c2-${index}`}
                cx="15"
                cy="15"
                r={9.5}
                fill="none"
                stroke={c[2]}
              />
            </svg>
          </Fragment>
        ))}
        {/*this.points.map((c, index) => <circle key={`p${index}`} cx={c[0]} cy={c[1]} r={5} />)*/}
      </svg>
    );
  }
}

export default FamilyPaths;
