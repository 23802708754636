export const vertex = `
precision highp float;
precision highp int;

uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

uniform float time;
uniform float freq;
uniform float ampl;
uniform float time_freq;
uniform float size;
//uniform vec3 mouse;

attribute vec3 position;

attribute vec3 previous;
attribute vec3 next;
attribute float side;
attribute float width;
attribute float counters;

uniform vec2 resolution;
uniform float lineWidth;
uniform float near;
uniform float far;
uniform float sizeAttenuation;

vec2 fix( vec4 i, float aspect ) {
  vec2 res = i.xy / i.w;
  res.x *= aspect;
  return res;
}

//	Simplex 3D Noise
//	by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){
  return mod(((x*34.0)+1.0)*x, 289.0);
}
vec4 taylorInvSqrt(vec4 r){
  return 1.79284291400159 - 0.85373472095314 * r;
}

float snoise(vec3 v){
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

// First corner
  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

// Other corners
  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  //  x0 = x0 - 0. + 0.0 * C
  vec3 x1 = x0 - i1 + 1.0 * C.xxx;
  vec3 x2 = x0 - i2 + 2.0 * C.xxx;
  vec3 x3 = x0 - 1. + 3.0 * C.xxx;

// Permutations
  i = mod(i, 289.0 );
  vec4 p = permute( permute( permute(
             i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
           + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
           + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

// Gradients
// ( N*N points uniformly over a square, mapped onto an octahedron.)
  float n_ = 1.0/7.0; // N=7
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

//Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

// Mix final noise value
  vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                dot(p2,x2), dot(p3,x3) ) );
}

void main() {
  float aspect = resolution.x / resolution.y;
  float pixelWidthRatio = 1. / (resolution.x * projectionMatrix[0][0]);
  mat4 m = projectionMatrix * modelViewMatrix;
  vec4 finalPosition = m * vec4( position, 1.0 );

  float t = time * time_freq;
  float px = snoise(vec3(freq * finalPosition.xy, t));
  float py = snoise(vec3(freq * finalPosition.yz, t));
  float pz = snoise(vec3(freq * finalPosition.zx, t));
  //float distance = 4. / min(distance(mouse, finalPosition.xyz), 4.);
  //distance = 1. + 0.3 * smoothstep(1.0, 1.3, distance );
  //float distance = 1.0;
  finalPosition.xyz += ampl * vec3(px,py,pz);

  vec4 prevPos = m * vec4( previous, 1.0 );
  prevPos.xyz += ampl * vec3(px,py,pz);
  vec4 nextPos = m * vec4( next, 1.0 );
  nextPos.xyz += ampl * vec3(px,py,pz);
  vec2 currentP = fix( finalPosition, aspect );
  vec2 prevP = fix( prevPos, aspect );
  vec2 nextP = fix( nextPos, aspect );
  float pixelWidth = finalPosition.w * pixelWidthRatio;
  float w = 1.8 * pixelWidth * lineWidth * width;
  //if( sizeAttenuation == 1. ) {
  //    w = 1.8 * lineWidth * width;
  //}
  vec2 dir;
  if( nextP == currentP ) dir = normalize( currentP - prevP );
  else if( prevP == currentP ) dir = normalize( nextP - currentP );
  else {
      vec2 dir1 = normalize( currentP - prevP );
      vec2 dir2 = normalize( nextP - currentP );
      dir = normalize( dir1 + dir2 );
      vec2 perp = vec2( -dir1.y, dir1.x );
      vec2 miter = vec2( -dir.y, dir.x );
  }
  vec2 normal = vec2( -dir.y, dir.x );
  normal.x /= aspect;
  normal *= .5 * w;
  vec4 offset = vec4( normal * side, 0.0, 1.0 );
  finalPosition.xy += offset.xy;
  gl_Position = finalPosition;
}`;

export const fragment = `
precision highp float;
precision highp int;

uniform vec3 color;
uniform float opacity;

void main() {
  gl_FragColor = vec4(color, opacity);
 }`;
