import apiFetch from '@/api/apiFetch';

export default async uuid => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch(`lifelines/${uuid}`, options, 200);

  return await response.json();
};
