const WORD_SEPARATOR = ' ';
const PREPOSITIONS = ['from', 'of', 'by', 'on', 'in', 'at', 'off', 'to'];

function capitalize(
  text,
  options = {
    onlyFirstLetter: false,
    lowercaseOtherLetters: false,
    ignorePrepositions: false,
  }
) {
  if (!text) {
    return text;
  }

  const {
    onlyFirstLetter,
    lowercaseOtherLetters,
    ignorePrepositions,
  } = options;

  let words = (lowercaseOtherLetters ? text.toLowerCase() : text).split(
    WORD_SEPARATOR
  );

  if (!onlyFirstLetter) {
    words = words.map(word =>
      capitalize(word, { onlyFirstLetter: true, ignorePrepositions })
    );
  } else if (
    !ignorePrepositions ||
    PREPOSITIONS.indexOf(words[0].toLowerCase()) === -1
  ) {
    words[0] = words[0][0].toUpperCase() + words[0].slice(1);
  }

  return words.join(WORD_SEPARATOR);
}

export default capitalize;
