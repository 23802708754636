import React, { Component } from 'react';
import classnames from 'classnames';

import { tablet } from '@/components/App/breakpoints';
import { DEFAULT_PICTURES } from '@/components/DefaultPictureSelector';

import OutputSection from '@/contexts/OutputSection';

import ParallaxImages from './ParallaxImages';
import Memories from '../Memories';

import Label from './PartLabel';
import Profiles from './Profiles';
// import Video from './Video';
import FamilyPaths from './FamilyPaths';
import FamilyPathsMobile from './FamilyPathsMobile';

import './style.css';

const friendsQuoteText = [
  'A real friend is',
  'is one who walks in',
  'when the rest of the world',
  'walks out',
];

class Family extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.scroller = React.createRef();
    this.line = React.createRef();
    this.windowWidth = tablet + 1;
    this.isDesktop = this.windowWidth > tablet;

    this.state = { autoscrollWaypoints: [] };
  }

  componentDidMount() {
    if (this.scroller.current) {
      this.section = this.scroller.current.closest('section');
      this.section.addEventListener('scroll', this.onScroll);
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    const spans = [
      ...this.container.current.querySelectorAll(
        '.lifeline-family--friend_quote span'
      ),
    ];

    this.parentObserver = new IntersectionObserver(entries => {
      entries.forEach(e => {
        if (!e.isIntersecting) {
          spans.forEach(s => s.classList.remove('anim-active'));
        }
      });
    });

    this.spanObservers = [];
    const spansLength = spans.length;
    spans.forEach((sp, i) => {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(e => {
            //            console.log(e);
            if (e.isIntersecting) {
              e.target.classList.add('anim-active');
              if (e.target.classList.contains('anim-inactive')) {
                e.target.classList.remove('anim-inactive');
              }
            } else if (e.boundingClientRect.x > 100) {
              // spans.forEach(sp => sp.classList.remove('anim-active'));
              e.target.classList.add('anim-inactive');
              if (e.target.classList.contains('anim-active')) {
                e.target.classList.remove('anim-active');
              }
            }
          });
        },
        {
          // negative&decreasing right rootMargin so the trigger happens later
          rootMargin: `0px -${((1 - i / spansLength) * 30 + 10).toFixed(
            1
          )}% 0px 0px`,
        }
      );

      this.spanObservers.push(observer);

      const friendsQuoteBlock = this.container.current.querySelector(
        '.lifeline-family--friend_quote'
      );
      if (friendsQuoteBlock) {
        this.parentObserver.observe(friendsQuoteBlock);
      }
      observer.observe(sp);
    });
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
    window.removeEventListener('resize', this.handleResize);
    if (this.section) {
      this.section.removeEventListener('scroll', this.onScroll);
    }
    this.parentObserver.disconnect();
    this.spanObservers.forEach(observer => {
      observer.disconnect();
    });
  }

  handleResize = () => {
    if (window.innerWidth > tablet) {
      this.scroller.current.style.height = `${this.container.current.scrollWidth}px`;
    }

    this.context.updateScrollMetrics();

    if (this.line.current) {
      this.line.current.handleResize();
    }

    this.windowWidth = window.innerWidth;
    this.isDesktop = this.windowWidth > tablet;

    const autoscrollWaypoints = [];

    [...this.container.current.querySelectorAll('.lifeline-memories')].forEach(
      m => {
        if (
          !this.isDesktop ||
          this.container.current.scrollWidth - window.innerWidth === 0
        ) {
          return;
        }

        // calc waypoint y
        const memoriesCenterX =
          m.offsetLeft + (m.offsetWidth - window.innerWidth) / 2;

        const y = Math.round(
          memoriesCenterX *
            ((this.section.scrollHeight - window.innerHeight) /
              (this.container.current.scrollWidth - window.innerWidth))
        );

        const wait = Array.from(
          m.querySelectorAll(
            '.lifeline-memories__quote .lifeline-memories__quote-text'
          )
        )
          .map(q =>
            Math.max(
              (q.textContent.trim().split(' ').length / 200) * 60000,
              3000
            )
          )
          .reduce((a, b) => a + b, 0);

        autoscrollWaypoints.push({
          y,
          wait,
        });
      }
    );

    let updated =
      this.state.autoscrollWaypoints.length !== autoscrollWaypoints.length;
    let i = 0;

    while (!updated && i < autoscrollWaypoints.length) {
      updated =
        this.state.autoscrollWaypoints[i].y !== autoscrollWaypoints[i].y ||
        this.state.autoscrollWaypoints[i].wait !== autoscrollWaypoints[i].wait;
      i++;
    }

    if (updated) {
      this.setState({
        autoscrollWaypoints,
      });
    }
  };

  onScroll = () => {
    const perc =
      this.section.scrollTop / (this.section.scrollHeight - window.innerHeight);
    if (window.innerWidth > tablet) {
      this.container.current.scrollLeft =
        perc * (this.container.current.scrollWidth - window.innerWidth);
      this.line.current.svg.current.style.transform = `translateX(${-this
        .container.current.scrollLeft}px)`;
    } else {
      // TEMP uncomment this when mobile view is ready
      // this.line.current.svg.current.style.transform = `translateY(${-this
      //   .section.scrollTop}px)`;
    }
  };

  componentDidUpdate() {
    this.handleResize();
  }

  prepareMobileFriendsQuotesPhotos = photos => {
    const mobilePhotos = photos.map((el, i) => ({
      src: el,
      text: friendsQuoteText[i],
    }));
    return mobilePhotos;
  };

  render() {
    const {
      lifeline,
      active: isActive,
      exiting: _,
      entering: __,
      ...props
    } = this.props;
    const { autoscrollWaypoints } = this.state;
    const { relationships = {} } = lifeline.data;
    const {
      photos = [],
      importantRelationships = [],
      memories = [],
    } = relationships;

    const intro = photos.filter(p => p.isFav).map(p => p.image.remoteUrl);

    const familyProfiles = importantRelationships
      .filter(p => p.relationshipGroup === 'Family')
      .map(p => ({
        uuid: p.uuid,
        name: `${p.firstName} ${p.lastName || ''}`.trim(),
        firstName: p.firstName,
        gender: 'm',
        pic:
          (p.picture && p.picture.remoteUrl) ||
          DEFAULT_PICTURES[p.defaultPicture > -1 ? p.defaultPicture : 0],
        title: p.relationship,
        quote: p.quote,
        story: p.story,
        group: p.relationshipGroup,
        isPet: false,
      }));

    const family = photos
      .filter(p => !p.isFav && p.relationshipGroup === 'Family')
      .map(p => p.image.remoteUrl);

    const importantPeopleProfiles = importantRelationships
      .filter(p => p.relationshipGroup === 'Important people')
      .map(p => ({
        uuid: p.uuid,
        name: `${p.firstName} ${p.lastName || ''}`.trim(),
        firstName: p.firstName,
        gender: 'm',
        pic:
          (p.picture && p.picture.remoteUrl) ||
          DEFAULT_PICTURES[p.defaultPicture > -1 ? p.defaultPicture : 0],
        title: p.relationship,
        quote: p.quote,
        story: p.story,
        group: p.relationshipGroup,
        isPet: false,
      }));

    const importantPeople = photos
      .filter(p => !p.isFav && p.relationshipGroup === 'Important people')
      .map(p => p.image.remoteUrl);

    const friendshipProfiles = importantRelationships
      .filter(p => p.relationshipGroup === 'Friendship')
      .map(p => ({
        uuid: p.uuid,
        name: `${p.firstName} ${p.lastName || ''}`.trim(),
        firstName: p.firstName,
        gender: 'm',
        pic:
          (p.picture && p.picture.remoteUrl) ||
          DEFAULT_PICTURES[p.defaultPicture > -1 ? p.defaultPicture : 0],
        title: p.relationship,
        quote: p.quote,
        story: p.story,
        group: p.relationshipGroup,
        isPet: false,
      }));
    const friendshipIntroProfiles =
      friendshipProfiles.length > 4
        ? friendshipProfiles.slice(0, 3)
        : friendshipProfiles;
    const friendshipOutroProfiles =
      friendshipProfiles.length > 4 ? friendshipProfiles.slice(4) : [];

    const friendsPhotos = photos
      .filter(p => !p.isFav && p.relationshipGroup === 'Friendship')
      .map(p => p.image.remoteUrl);

    const friendsQuotePhotos =
      friendsPhotos.length > 5 ? friendsPhotos.slice(0, 4) : friendsPhotos;

    // fill up to 4 elements
    const fqpL = friendsQuotePhotos.length;
    if (friendsPhotos.length && fqpL < 4) {
      for (let index = fqpL; index < 4; index++) {
        friendsQuotePhotos.push(
          friendsQuotePhotos[Math.floor(Math.random() * fqpL)]
        );
      }
    }

    const friends = friendsPhotos.length > 4 ? friendsPhotos.slice(5) : [];

    const mainMemories = [
      ...memories,
      ...familyProfiles
        .filter(p => !!p.story)
        .map(p => ({
          description: p.story,
          author: p.firstName,
          relationship: p.title,
        })),
    ];

    const secondaryMemories = [
      ...importantPeopleProfiles,
      ...friendshipProfiles,
    ]
      .filter(p => !!p.story)
      .map(p => ({
        description: p.story,
        author: p.firstName,
        relationship: p.title,
      }));

    const nothingAfterQuote =
      !friendshipOutroProfiles.length &&
      !secondaryMemories.length &&
      !friends.length;

    return (
      <div className="lifeline-family--scroller" ref={this.scroller} {...props}>
        {autoscrollWaypoints.map(({ y, wait }, i) => (
          <div
            key={`lifeline-family__waypoint--${i}`}
            className="lifeline-family__waypoint"
            data-autoscroll-waypoint
            data-autoscroll-wait={wait}
            style={{
              top: `${y}px`,
            }}
          />
        ))}

        {this.isDesktop ? (
          <FamilyPaths ref={this.line} />
        ) : (
          <FamilyPathsMobile ref={this.line} />
        )}

        <div className={classnames('lifeline-family')} ref={this.container}>
          <div className="lifeline-family--cover">
            <h2>Family and friends</h2>
            <p>
              They may guide us, inspire us, support us or infuriate us. But, no
              matter what, they play their part in shaping who we are.
            </p>
          </div>

          {!!intro.length && (
            <ParallaxImages
              pattern="intro"
              images={intro}
              onUpdate={this.handleResize}
              active={isActive}
            />
          )}
          <div
            className={classnames(
              'lifeline-family__spacer',
              intro.length > 3 ? 'lifeline-family__spacer--25vw' : ''
            )}
          />

          {(!!familyProfiles.length || !!family.length) && (
            <>
              <div
                className={classnames(
                  'lifeline-family__spacer',
                  'lifeline-family__spacer--10vw'
                )}
              />
              <Label color="#FF004C">Family</Label>
              {!!familyProfiles.length && (
                <Profiles profiles={familyProfiles} />
              )}
              {!!family.length && (
                <ParallaxImages
                  pattern="family"
                  images={family}
                  onUpdate={this.handleResize}
                  active={isActive}
                />
              )}
              {!!mainMemories.length && (
                <Memories
                  ownerFirstName={lifeline.ownerFirstName}
                  ownerRelationship={
                    lifeline.data.remembering.personalRelationship
                  }
                  lifelineName={lifeline.data.remembering.firstName}
                  memories={mainMemories}
                  active={isActive}
                  color={'#FF004C'}
                />
              )}
            </>
          )}

          {(!!importantPeopleProfiles.length || !!importantPeople.length) && (
            <>
              <Label color="#1F28CF">Important People</Label>
              {!!importantPeopleProfiles.length && (
                <Profiles profiles={importantPeopleProfiles} />
              )}
              {!!importantPeople.length && (
                <ParallaxImages
                  pattern="important_people"
                  images={importantPeople}
                  onUpdate={this.handleResize}
                  active={isActive}
                />
              )}
            </>
          )}

          {(!!friendshipProfiles.length || !!friendsPhotos.length) && (
            <>
              <Label color="#FFA51E">Friendship</Label>
              {!!friendshipIntroProfiles.length && (
                <Profiles profiles={friendshipIntroProfiles} />
              )}

              <div
                className="lifeline-family--friend_quote-container"
                style={{
                  marginRight: this.isDesktop
                    ? `${16 + (friendsQuotePhotos.length > 3 ? 70 : 0)}vw`
                    : null,
                }}
              >
                {!!friendsQuotePhotos.length && (
                  <ParallaxImages
                    pattern="friends_quote"
                    images={
                      this.isDesktop
                        ? friendsQuotePhotos
                        : this.prepareMobileFriendsQuotesPhotos(
                            friendsQuotePhotos
                          )
                    }
                    onUpdate={this.handleResize}
                    active={isActive}
                  />
                )}
                <div className="lifeline-family--friend_quote">
                  {'A real friend is one who walks in when the rest of the world walks out.'
                    .split('')
                    .map((c, index) => (
                      <span key={index}>{c.replace(' ', '\u00A0')}</span>
                    ))}
                </div>
              </div>

              {!!friendshipOutroProfiles.length && (
                <Profiles profiles={friendshipOutroProfiles} />
              )}
              {!!friends.length && (
                <ParallaxImages
                  pattern="friends"
                  images={friends}
                  onUpdate={this.handleResize}
                  active={isActive}
                />
              )}
              {!!secondaryMemories.length && (
                <Memories
                  ownerFirstName={lifeline.ownerFirstName}
                  ownerRelationship={
                    lifeline.data.remembering.personalRelationship
                  }
                  lifelineName={lifeline.data.remembering.firstName}
                  memories={secondaryMemories}
                  active={isActive}
                  color={'#FFA51E'}
                />
              )}
            </>
          )}
          {nothingAfterQuote ? (
            <div
              className={classnames(
                'lifeline-family__spacer',
                'lifeline-family__spacer--25vw'
              )}
            />
          ) : null}
          {/* <div className="lifeline-family--video-container">
            <Video src={require('#/videos/family.mp4')} />
            <p>James and George - Mexico 1986</p>
          </div> */}
        </div>
      </div>
    );
  }
}

Family.contextType = OutputSection;

export default Family;
