import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import withRouterAndRef from '@/utils/withRouterAndRef';

import Svg from '@/components/Svg';

import './style.css';

class Button extends Component {
  static defaultPropTypes = {
    success: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit']),
  };

  static defaultProps = {
    icon: null,
    size: 'large',
    to: null,
    block: false,
    disabled: false,
    blockAlign: null,
    success: false,
    onDisabledClick: f => f,
    replace: false,
    iconAlignment: 'right',
    successCheckmark: true,
    forceButton: false,
    type: 'button',
    as: 'button',
    onFocus: () => null,
  };

  state = {
    shake: false,
  };

  shake = () => {
    if (this.state.shake) {
      return;
    }

    this.setState(
      {
        shake: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            shake: false,
          });
        }, 1000);
      }
    );
  };

  onClick = e => {
    const { forceButton, to, history } = this.props;

    if (this.props.disabled) {
      this.shake();
      this.props.onDisabledClick();
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    if (forceButton && to && !e.defaultPrevented) {
      history.push(to);
    }
  };

  renderContent() {
    const {
      children,
      icon,
      iconBackground,
      successCheckmark,
      success: isSuccess,
    } = this.props;

    return (
      <>
        <span className="button__children-wrapper">{children}</span>
        {icon !== null && (
          <Svg
            className={classnames('button__icon', {
              'button__icon--plus': icon === 'plus',
              'button__icon--tick': icon === 'tick',

              'button__icon--bg-relationships':
                iconBackground === 'relationships',
              'button__icon--bg-achievements':
                iconBackground === 'achievements',
              'button__icon--bg-white': iconBackground === 'white',
            })}
            name={icon}
          />
        )}
        {successCheckmark && <Svg className="button__checkmark" name="check" />}
      </>
    );
  }

  renderButton() {
    return <span className="button__loading"></span>;
  }

  render() {
    const {
      className,
      variant,
      size,
      to,
      block,
      icon,
      iconAlignment,
      disabled,
      blockAlign,
      success: isSuccess,
      id,
      replace,
      forceButton,
      type,
      loading,
      as,
      onFocus,
      href,
      downloadValue,
    } = this.props;
    const { shake } = this.state;

    const socialVariants = ['facebook', 'google', 'messenger'];

    const buttonClasses = classnames(
      'button',
      {
        'button--primary': variant === 'primary',
        'button--alt-primary': variant === 'alt-primary',
        'button--secondary': variant === 'secondary',
        'button--alt-secondary': variant === 'alt-secondary',
        'button--tertiary': variant === 'tertiary',
        'button--alt-tertiary': variant === 'alt-tertiary',
        'button--resolution-blue': variant === 'resolution-blue',
        'button--lifeline-relationships': variant === 'lifeline-relationships',
        'button--alt-lifeline-relationships':
          variant === 'alt-lifeline-relationships',
        'button--lifeline-relationships-no-border':
          variant === 'lifeline-relationships-no-border',
        'button--alt-lifeline-relationships-no-border':
          variant === 'alt-lifeline-relationships-no-border',
        'button--lifeline-achievements': variant === 'lifeline-achievements',
        'button--alt-lifeline-achievements':
          variant === 'alt-lifeline-achievements',
        'button--lifeline-interests': variant === 'lifeline-interests',
        'button--alt-lifeline-interests': variant === 'alt-lifeline-interests',

        'button--facebook': variant === 'facebook',
        'button--google': variant === 'google',
        'button--messenger': variant === 'messenger',

        'button--disabled': disabled,
        'button--shake': shake,

        'button--loading': loading,

        'button--block': block,
        'button--block-align-right': blockAlign === 'right',

        'button--has-icon': icon !== null && !socialVariants.includes(variant),

        'button--big-icon': icon && icon === 'messenger',
        'button--icon-right': icon && iconAlignment === 'right',
        'button--icon-left': icon && iconAlignment === 'left',

        'button--size-nano': size === 'nano',
        'button--size-small': size === 'small',
        'button--size-medium': size === 'medium',
        'button--size-large': size === 'large',

        'button--success': isSuccess,
      },
      className
    );

    const CustomTag = as;

    if (to === null || forceButton) {
      return (
        <CustomTag
          type={type}
          id={id}
          onClick={this.onClick}
          className={buttonClasses}
          {...(disabled ? { tabIndex: '-1' } : {})}
          href={href}
          download={downloadValue}
        >
          {this.renderContent()}
          {this.renderButton()}
        </CustomTag>
      );
    }

    return (
      <Link
        replace={replace}
        onClick={this.onClick}
        className={buttonClasses}
        to={to}
        tabIndex={disabled ? -1 : null}
        onFocus={onFocus}
      >
        {this.renderContent()}
        {this.renderButton()}
      </Link>
    );
  }
}

export default withRouterAndRef(Button);
