import React from 'react';

import { getColor, defaultColor } from '@/data/adjectives';

import './style.css';

const Closure = ({
  lifeline: {
    data: {
      remembering: { adjectives },
    },
  },
  active: _,
  exiting: __,
  entering: ___,
  ...props
}) => (
  <div className="lifeline-closure" {...props}>
    <div className="lifeline-closure__container">
      {adjectives && (
        <ul className="lifeline-closure__adjectives">
          {adjectives.map((adjective, index) => (
            <li
              className={`lifeline-closure__adjective anim-slide-in`}
              style={{
                '--slide-in-delay': `${(index + 1) * 0.2}s`,
                color: (adjective && getColor(adjective.label)) || defaultColor,
              }}
              key={`adjective--${index}--${adjective.label}`}
            >
              {adjective.label}.
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

export default Closure;
