import getSubjectPronoun from '@/utils/getThirdPersonPronoun';
import getObjectPronoun from '@/utils/getGenderPronoun';
import getPossessivePronoun from '@/utils/getPossessivePronoun';
import getGrownGender from '@/utils/getGrownGender';
import { INTERESTS as INTERESTS_MAP } from '@/components/LifelineEditor/Interests/Interests/dataset'; // **jc**

export default ({ interest, title, scale, lifeline }) => {
  // **jc**
  const ilc =
    interest.charAt(0).toUpperCase() + interest.slice(1).toLowerCase();
  const tlc = title
    .toString()
    .trim()
    .split(' ')
    .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');
  const s = parseInt(scale, 10);

  // **jc**
  if (![0, 1, 2, 3, 4].includes(s)) {
    return null;
  }

  if (!INTERESTS_MAP[ilc]) {
    return null;
  }

  // **jc**
  if (!INTERESTS_MAP[ilc][s].description) {
    if (!INTERESTS_MAP[ilc][4].description) {
      return null;
    } else {
      if (INTERESTS_MAP[ilc][4].description !== title) {
        return null;
      }
    }
  } else {
    if (INTERESTS_MAP[ilc][s].description !== title) {
      return null;
    }
  }

  const {
    data: {
      remembering: { firstName, sex: gender },
      isLegacy,
    },
  } = lifeline;

  let description = isLegacy // **jc**
    ? INTERESTS_MAP[ilc][s].legacy || INTERESTS_MAP[ilc][4].legacy
    : INTERESTS_MAP[ilc][s].default || INTERESTS_MAP[ilc][4].default;

  // replace {firstName}
  description = description.replace('{firstName}', firstName);
  // replace {subjectPronoun}
  description = description.replace(
    '{subjectPronoun}',
    getSubjectPronoun(gender)
  );
  // replace {objectPronoun}
  description = description.replace(
    '{objectPronoun}',
    getObjectPronoun(gender)
  );
  // replace {possessivePronoun}
  description = description.replace(
    '{possessivePronoun}',
    getPossessivePronoun(gender)
  );
  // replace {grownGender}
  description = description.replace('{grownGender}', getGrownGender(gender));

  return description;
};
