import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import Svg from '@/components/Svg';

import './style.css';

export default class NavItem extends Component {
  static defaultProps = {
    showActiveState: true,
    onFocus: () => null,
    whiteHover: false, // **jc**
  };

  render() {
    const {
      className: inputClassName,
      variant,
      to,
      children,
      textOnHover,
      showActiveState,
      onFocus,
      whiteHover, // **jc**
      ...rest
    } = this.props;

    const className = classnames(
      'navitem',
      {
        'navitem--orbiting': variant === 'primary',
        'navitem--dropdown': variant === 'secondary',
      },
      inputClassName
    );

    return (
      <li className={className}>
        <NavLink to={to} {...rest} tabIndex={0} onFocus={onFocus}>
          {variant === 'primary' && !whiteHover && (
            <Svg name="navbar-icon" />
          ) /* **jc** */}
          {variant === 'primary' && whiteHover && (
            <Svg name="navbar-icon-white" />
          ) /* **jc** */}
          <span title={textOnHover}>{children}</span>
        </NavLink>
      </li>
    );
  }
}
