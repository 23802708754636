import React, { PureComponent, createRef } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import Select from '@/components/Select';
import range from '@/utils/range';
import isIOS from '@/utils/isIOS';

import './style.css';

const MAX_YEARS_OF_LIFE = 130;

const MONTHS = range(1, 13);
const MONTHS_AS_STRING = MONTHS.map(m => m.toString());

function createDateFrom(year = '', month = '', day = '') {
  return moment(`${year}-${month}-${day}`, 'Y-M-D', true);
}

function getDatesRangeFrom(min = '', max = '') {
  let minDate, maxDate;

  const yearZero = createDateFrom(0, 1, 1);

  if (!min && !max) {
    minDate = yearZero;
    maxDate = moment();
  } else {
    if (!min) {
      minDate = moment.max(
        yearZero,
        moment(max, DatePicker.dateFormat).subtract(MAX_YEARS_OF_LIFE, 'years')
      );
      maxDate = moment(max, DatePicker.dateFormat);
    } else {
      minDate = moment(min, DatePicker.dateFormat);
      maxDate = moment.min(
        moment(),
        moment(min, DatePicker.dateFormat).add(MAX_YEARS_OF_LIFE, 'years')
      );
    }
  }

  return { minDate, maxDate };
}

export default class DatePicker extends PureComponent {
  static defaultProps = {
    block: true,
    variant: 'white',
    size: 'medium',
    disabled: false,
    showDay: true,
    showMonth: true,
    showYear: true,
    required: false,
    returnObject: false,
    onBlur: () => null,
    onFocus: () => null,
    reset: false,
    validateError: false,
  };

  state = {
    month: '',
    day: '',
    year: '',
  };

  static dateFormat = 'YYYYMMDD';

  constructor(props) {
    super(props);

    this.nativePicker = createRef();
    this.container = createRef();
  }

  setValueInState = value => {
    if (value) {
      const date = moment(value, DatePicker.dateFormat);
      let month =
        (date.month() + 1).toString().length === 1
          ? `0${(date.month() + 1).toString()}`
          : (date.month() + 1).toString();
      let day =
        date.date().toString().length === 1
          ? `0${date.date().toString()}`
          : date.date().toString();

      this.setState({
        month,
        day,
        year: date.year().toString(),
      });
    } else if (value === undefined) {
      /*
      TODO Check if this condition was needed somewhere
      We cannot reset everythng when `value` is empty
      because when you type incomplete dates (you are writing the date)
      `value` is empty because of the invalid date
      */
      this.setState({
        month: '',
        day: '',
        year: '',
      });
    }
  };

  componentWillMount() {
    const { value } = this.props;

    this.setValueInState(value);
  }

  componentDidUpdate(prevProp) {
    if (prevProp.value != this.props.value) {
      this.setValueInState(this.props.value);
    }
  }

  componentDidMount() {
    this.container.current.addEventListener(
      'touchstart',
      this.showNativePicker
    );

    this.nativePicker.current.defaultValue = '';
  }

  componentWillUnmount() {
    this.container.current.removeEventListener(
      'touchstart',
      this.showNativePicker
    );
  }

  getOnKeyDown = e => {
    const key = e.key;
    switch (key) {
      case '.':
      case ',':
      case '+':
      case '-':
      case 'e':
        e.preventDefault();
        break;
    }
  };

  checkValueIsNumberIn(options, value) {
    if (isNaN(value)) {
      return false;
    }

    if (value === '') {
      return true;
    }

    let index = 0;

    if (value === '0') {
      return !!options.filter(o => o < 10).length;
    } else {
      value = parseInt(value, 10);

      if (!value) {
        return false;
      }

      let matched = false;
      while (!matched && index < options.length) {
        const ref = `${options[index]}`;

        matched = ref.startsWith(value);
        index++;
      }

      return matched;
    }
  }

  getOnChange = propName => {
    return value => {
      let dataset;

      switch (propName) {
        case 'month':
          dataset = this.getAvailableMonths();
          break;
        case 'day':
          dataset = this.getAvailableDays();
          break;
        case 'year':
          dataset = this.getAvailableYears();
          break;
      }

      if (!this.checkValueIsNumberIn(dataset, value)) {
        return;
      }
      this.setState({ [propName]: value }, this.saveDate);
    };
  };

  getOnBlur = key => {
    return e => {
      let value = e.target.value;
      if (value.length === 1) {
        this.setState({ [key]: `0${value}` }, this.saveDate);
      }
    };
  };

  getOnFocus = key => {
    return e => {
      let value = e.target.value;
      if (value.startsWith('0')) {
        this.setState({ [key]: value.substr(1) }, this.saveDate);
      }
    };
  };

  showNativePicker = e => {
    const { showMonth, showDay } = this.props;
    if (!showMonth && !showDay) {
      return;
    }

    e.preventDefault();

    if (isIOS() || /MacIntel/.test(navigator.platform)) {
      this.nativePicker.current.focus();
    } else {
      this.nativePicker.current.click();
    }
  };

  onNativePickerChange = e => {
    if (!this.nativePicker.current || !this.nativePicker.current.value) {
      if (this.nativePicker.current) {
        this.setState({
          month: '',
          day: '',
          year: '',
        });
      }

      return;
    }

    const date = moment(this.nativePicker.current.value, DatePicker.dateFormat);

    if (moment().isBefore(date)) {
      return;
    }

    let month =
      (date.month() + 1).toString().length === 1
        ? `0${(date.month() + 1).toString()}`
        : (date.month() + 1).toString();
    let day =
      date.date().toString().length === 1
        ? `0${date.date().toString()}`
        : date.date().toString();

    this.setState(
      {
        month,
        day,
        year: date.year().toString(),
      },
      this.saveDate
    );

    /*
    Hitting Clear will indeed revert to the defaultValue DOM prop (representing the HTML attribute value) which works
    as expected for uncontrolled inputs. For controlled inputs, as React will update both value and defaultValue DOM
    props to the new value on every change, hitting Clear will have no noticeable effect. Setting the defaultValue DOM
    prop to the empty string manually after each change fixes this.

    Reference: https://github.com/facebook/react/issues/8938
    */
    setTimeout(() => {
      this.nativePicker.current.defaultValue = '';
    }, 100);
  };

  saveDate() {
    const date = this.createDateFromState();
    const isValid = this.isValidDate(date);

    const formattedValue = isValid ? date.format(DatePicker.dateFormat) : '';
    const value = !this.props.returnObject
      ? formattedValue
      : {
          date,
          isValid,
          formattedValue,
        };

    this.props.onChange(value);
  }

  isValidDate(date) {
    if (!date || !date.isValid()) {
      return false;
    }

    const { min, max } = this.props;
    const { minDate, maxDate } = getDatesRangeFrom(min, max);
    return date.isBetween(minDate, maxDate, 'days', '[]');
  }

  createDateFromState(fix = false) {
    const { month: m, year: y, day: d } = this.state;
    const { showMonth, showDay, showYear } = this.props;

    const day = parseInt(d, 10);
    const month = parseInt(m, 10);
    const year = parseInt(y, 10);

    const currentYear = moment().year();
    const defaultMonth = 1;
    const defaultDay = 1;

    const fixedYear = !showYear
      ? currentYear
      : !fix
      ? year
      : year || currentYear;
    const fixedMonth = !showMonth
      ? defaultMonth
      : !fix
      ? month
      : month || defaultMonth;
    const fixedDay = !showDay ? defaultDay : !fix ? day : day || defaultDay;

    return createDateFrom(fixedYear, fixedMonth, fixedDay);
  }

  getAvailableYears() {
    const { min, max } = this.props;
    const { minDate, maxDate } = getDatesRangeFrom(min, max);
    return range(minDate.year(), maxDate.year() + 1);
  }

  getAvailableMonths() {
    return MONTHS;
  }

  getAvailableDays() {
    const date = this.createDateFromState(true);
    const days = date.daysInMonth();
    return range(1, days + 1);
  }

  render() {
    const {
      disabled,
      size,
      block,
      variant,
      showMonth,
      showDay,
      showYear,
      required,
      onBlur,
      onFocus,
      reset,
      validateError,
      isValid,
      getErrorMessage,
    } = this.props;

    const { month, day, year } = this.state;

    const availableYears = this.getAvailableYears().map(n => n.toString());
    const availableDays = this.getAvailableDays().map(n => n.toString());

    const now = new Date();
    const today = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;

    return (
      <div
        ref={this.container}
        className={classnames('date-picker', {
          'date-picker--block': block,
          'date-picker--small': size === 'small',
          'date-picker--smalt': variant === 'smalt',
          'date-picker--disabled': disabled,
        })}
      >
        <input
          type="date"
          onChange={this.onNativePickerChange}
          ref={this.nativePicker}
          className="date-picker--nativePicker"
          value={`${year}-${month}-${day}`}
          max={today}
          tabIndex="-1"
        />
        {showMonth && (
          <Select
            options={MONTHS_AS_STRING}
            placeholder="MM"
            type="number"
            value={month}
            onChange={this.getOnChange('month')}
            center={false}
            variant={variant}
            onKeyDown={this.getOnKeyDown}
            onBlur={this.getOnBlur('month')}
            onFocus={this.getOnFocus('month')}
            key=""
            required={required}
            requiredMark=""
            ignoreMatchedOption
            validateError={validateError}
            isValid={isValid}
            getErrorMessage={getErrorMessage}
          />
        )}

        {showDay && (
          <Select
            options={availableDays}
            placeholder="DD"
            type="number"
            value={day}
            onChange={this.getOnChange('day')}
            center={false}
            variant={variant}
            onKeyDown={this.getOnKeyDown}
            onBlur={this.getOnBlur('day')}
            onFocus={this.getOnFocus('day')}
            required={required}
            requiredMark=""
            ignoreMatchedOption
            validateError={validateError}
            isValid={isValid}
            getErrorMessage={getErrorMessage}
          />
        )}

        {showYear && (
          <Select
            reverseOptions={true}
            options={availableYears}
            placeholder="YYYY"
            type="number"
            value={year}
            onChange={this.getOnChange('year')}
            center={false}
            variant={variant}
            onKeyDown={this.getOnKeyDown}
            required={required}
            onBlur={onBlur}
            onFocus={onFocus}
            requiredMark=""
            ignoreMatchedOption
            reset={reset}
            validateError={validateError}
            isValid={isValid}
            getErrorMessage={getErrorMessage}
          />
        )}
      </div>
    );
  }
}
