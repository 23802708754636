import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import easeOutQuint from 'eases/quint-out';
import easeInOutSine from 'eases/sine-in-out';

import smoothScroll from '@/utils/smoothScroll';
import isSSR from '@/utils/isSSR';

import copy from './copy';
import './style.css';

const CountUp = !isSSR ? require('countup.js').CountUp : null;

const available_decades = [
  '1910',
  '1920',
  '1930',
  '1940',
  '1950',
  '1960',
  '1970',
  '1980',
  '1990',
  '2000',
  '2010',
];

class Decades extends Component {
  static defaultProps = {
    onChildhood: f => f,
    onAdulthood: f => f,
  };

  constructor(props) {
    super(props);

    const { lifeline } = props;
    const {
      data: {
        remembering: { birthDate, deathDate },
      },
    } = lifeline;

    const birthYear = birthDate.slice(0, 4);
    const twentyYear = parseInt(birthYear) + 21;
    const deathYear = deathDate
      ? deathDate.slice(0, 4)
      : new Date().getFullYear();

    this.ref = createRef();

    this.hasTwenties = deathYear > twentyYear;

    this.touchFirstY = this.touchLastY = this.touchStartTime = 0;
    this.currentStep = 0;
    this.steps = [
      0,
      [
        '.lifeline-decades__decade--first .lifeline-decades__copy__beacon.beacon_1',
        'center',
      ],
      '.lifeline-decades__decade--first .lifeline-decades__copy__beacon.beacon_2',
      '.lifeline-decades__decade--first .lifeline-decades__copy__beacon.beacon_3',
    ];

    if (this.hasTwenties) {
      this.steps.push('.lifeline-decades__decade_transition');
      this.steps.push([
        '.lifeline-decades__decade--second .lifeline-decades__copy__beacon.beacon_1',
        'center',
      ]);
      this.steps.push(
        '.lifeline-decades__decade--second .lifeline-decades__copy__beacon.beacon_2'
      );
      this.steps.push(
        '.lifeline-decades__decade--second .lifeline-decades__copy__beacon.beacon_3'
      );
    }
  }

  onExitEnd = e => {
    e.currentTarget.removeEventListener('transitionend', this.onExitEnd);
    e.currentTarget.classList.remove('exit', 'active');
  };

  setImageEnter = (el, reverse = false) => {
    el.classList.remove('image_exit', 'active', 'reverse');
    el.classList.add('image_enter');
    if (reverse) {
      el.classList.add('reverse');
    }
    window.setTimeout(() => {
      el.classList.add('active');
    }, 20);
  };

  setImageExit = (el, reverse = false) => {
    el.classList.remove('image_enter', 'active', 'reverse');
    el.classList.add('image_exit');
    if (reverse) {
      el.classList.add('reverse');
    }
  };

  startImageUpdate = el => {
    if (!this.rafImage) {
      const container = el.querySelector('.lifeline-decades__images-container');
      const images = container.querySelectorAll('span');

      let isFirstFrame = true;

      const clamp = val => Math.max(0, Math.min(1, val));

      const update = () => {
        const pos =
          -(el.getBoundingClientRect().top + window.innerHeight / 2) /
          (2 * window.innerHeight);

        if (pos <= 1.5) {
          //pos 0 => transY 100vh | pos 1 => transY 0
          const pos1 = easeOutQuint(clamp(pos));
          images[0].style.transform = `translateY(${(1 - pos1) * 105}vh)`;

          const pos2 = easeOutQuint(clamp(pos - 0.1));
          images[1].style.transform = `translateY(${(1 - pos2) * 105}vh)`;

          const pos3 = easeOutQuint(clamp(pos - 0.2));
          images[2].style.transform = `translateY(${(1 - pos3) * 105}vh)`;

          const pos4 = easeOutQuint(clamp(pos - 0.3));
          images[3].style.transform = `translateY(${(1 - pos4) * 105}vh)`;

          const pos5 = easeOutQuint(clamp(pos - 0.4));
          images[4].style.transform = `translateY(${(1 - pos5) * 105}vh)`;

          const pos6 = easeOutQuint(clamp(pos - 0.5));
          images[5].style.transform = `translateY(${(1 - pos6) * 105}vh)`;
        } else {
          //pos 1.5 => transY 0 | pos 2 => transY -105vh
          const exit_pos = (pos - 1.5) * 3;
          const pos1 = easeInOutSine(clamp(exit_pos));
          images[0].style.transform = `translateY(${-pos1 * 105}vh)`;

          const pos2 = easeInOutSine(clamp(exit_pos - 0.1));
          images[1].style.transform = `translateY(${-pos2 * 105}vh)`;

          const pos3 = easeInOutSine(clamp(exit_pos - 0.2));
          images[2].style.transform = `translateY(${-pos3 * 105}vh)`;

          const pos4 = easeInOutSine(clamp(exit_pos - 0.3));
          images[3].style.transform = `translateY(${-pos4 * 105}vh)`;

          const pos5 = easeInOutSine(clamp(exit_pos - 0.4));
          images[4].style.transform = `translateY(${-pos5 * 105}vh)`;

          const pos6 = easeInOutSine(clamp(exit_pos - 0.5));
          images[5].style.transform = `translateY(${-pos6 * 105}vh)`;
        }

        if (isFirstFrame) {
          // show images only when they are correctly positioned on first frame
          container.style.display = 'block';
          isFirstFrame = false;
        }

        this.rafImage = requestAnimationFrame(update);
      };

      this.rafImage = requestAnimationFrame(update);
    }
  };

  stopImageUpdate = () => {
    cancelAnimationFrame(this.rafImage);
    const containers = document.querySelectorAll(
      '.lifeline-decades__images-container'
    );
    for (const container of containers) {
      container.style.display = '';
    }
    this.rafImage = false;
  };

  setYearFaded = faded => {
    const el = document.querySelector('.lifeline-decades__year');
    if (faded) {
      el.classList.add('faded');
      el.classList.add('faded--subtitle');
    } else {
      el.classList.remove('faded');
      el.classList.remove('faded--subtitle');
    }
  };

  componentDidMount() {
    // CHECK: Is this observer obsolete?
    this.observer = new IntersectionObserver(
      entries => {
        const el = document.querySelector('.lifeline-decades');
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.9) {
            el.classList.add('exit');
          } else if (entry.boundingClientRect.top > 0) {
            el.classList.remove('exit');
          }
        });
      },
      { threshold: [0, 0.9] }
    );

    // This observer trigger the count animation on the year passing from birth
    // to twenty and viceversa
    this.observerTransition = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio < 0.1) {
            if (entry.boundingClientRect.top > 0) {
              this.animateYearToBirth();
              //console.log('exit at top');
            } else {
              //console.log('exit at bottom');
            }
          } else {
            if (entry.boundingClientRect.top > 0) {
              this.animateYearToTwenty();
              //console.log('enter at top');
            } else {
              //console.log('enter at bottom');
            }
          }
        });
      },
      { threshold: [0.1] }
    );

    // This observer manages the copy change
    this.observerCopy = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const el = entry.target.nextSibling;
          if (entry.isIntersecting) {
            this.setYearFaded(true);
            //beacon 1 entered, images enter
            if (entry.target.classList.contains('beacon_1')) {
              //this.setImageEnter(el.closest('.lifeline-decades__decade'));
              this.startImageUpdate(el.closest('.lifeline-decades__decade'));
            }
            //beacon 3 entered, if scolling up, reverse image enter
            if (entry.target.classList.contains('beacon_3')) {
              //this.setImageEnter(el.closest('.lifeline-decades__decade'), true);
              this.startImageUpdate(el.closest('.lifeline-decades__decade'));
            }
            el.classList.remove('exit', 'active');
            el.classList.add('enter');
            el.removeEventListener('transitionend', this.onExitEnd);
            window.setTimeout(() => {
              el.classList.add('active');
            }, 20);
          } else {
            /* if we leave beacon_3 to top or beacon_1 to bottom */
            if (
              entry.target.classList.contains('beacon_3') &&
              entry.boundingClientRect.top < 0
            ) {
              if (
                el
                  .closest('.lifeline-decades__decade')
                  .classList.contains('lifeline-decades__decade--first')
              ) {
                this.setYearFaded(false);
              }
              //this.setImageExit(el.closest('.lifeline-decades__decade'));
              this.stopImageUpdate();
            }
            if (
              entry.target.classList.contains('beacon_1') &&
              entry.boundingClientRect.top > 0
            ) {
              this.setYearFaded(false);
              //this.setImageExit(el.closest('.lifeline-decades__decade'), true);
              this.stopImageUpdate();
            }

            if (el.classList.contains('enter')) {
              el.classList.remove('enter', 'active');
              el.classList.add('exit');
              window.setTimeout(() => {
                el.addEventListener('transitionend', this.onExitEnd);
                el.classList.add('active');
              }, 20);
            }
          }
        });
      },
      { threshold: [0, 1] }
    );

    if (this.hasTwenties) {
      this.observer.observe(
        document.querySelector('.lifeline-decades__decade:last-child')
      );
      // lifeline-decades__decade_transition
      this.observerTransition.observe(
        document.querySelector('.lifeline-decades__decade_transition')
      );
    }

    const beacons = document.querySelectorAll(
      '.lifeline-decades__copy__beacon'
    );
    beacons.forEach(el => this.observerCopy.observe(el));

    window.addEventListener('keydown', this.handleKeydown, true);
  }

  componentWillUnmount() {
    this.observer.disconnect();
    this.observerCopy.disconnect();
    this.observerTransition.disconnect();

    window.removeEventListener('keydown', this.handleKeydown, true);
  }

  animateYearToBirth = () => {
    const trans = document.querySelector(
      '.lifeline-decades__decade_transition'
    );

    trans.classList.remove('twenty');

    const data_from = parseInt(trans && trans.getAttribute('data-decade-from'));
    const data_to = parseInt(trans && trans.getAttribute('data-decade-to'));

    this.isYearAtFrom = true;
    this.isYearAtTo = false;

    this.animateYear(data_to, data_from);
  };

  animateYearToTwenty = () => {
    const trans = document.querySelector(
      '.lifeline-decades__decade_transition'
    );

    trans.classList.add('twenty');

    const data_from = parseInt(trans && trans.getAttribute('data-decade-from'));
    const data_to = parseInt(trans && trans.getAttribute('data-decade-to'));

    this.isYearAtFrom = false;
    this.isYearAtTo = true;

    this.animateYear(data_from, data_to);
  };

  animateYear = (startVal, dest) => {
    document.body.style.overflow = 'hidden';
    const decades = document.querySelector('.lifeline-decades');

    const year = decades.querySelector('.lifeline-decades__year--number');

    var countUp = new CountUp(year, dest, {
      useGrouping: false,
      startVal,
    });

    countUp.start(() => {
      document.body.style.overflow = '';
    });

    const el = decades.querySelector('.lifeline-decades__year');
    if (this.isYearAtTo) {
      this.props.onAdulthood();
      decades.classList.add('lifeline-decades--adulthood');
      el.classList.add('show-second-decade');
    } else {
      this.props.onChildhood();
      decades.classList.remove('lifeline-decades--adulthood');
      el.classList.remove('show-second-decade');
    }
  };

  scrollToStep(step) {
    if (step > -1 && step < this.steps.length) {
      const scrollParent = this.ref.current.parentElement.parentElement;

      this.currentStep = step;

      let s = 0;
      let p = 'top';

      if (step === this.steps.length - 1) {
        s = scrollParent.scrollHeight - scrollParent.offsetHeight;
      } else {
        s = this.steps[this.currentStep];
      }

      if (Array.isArray(s)) {
        p = s.length > 1 ? s[1] : 'top';
        s = s.length ? s[0] : 'top';
      }

      if (!this.smoothScroll) {
        this.smoothScroll = new smoothScroll(s, null, {
          scrollParent,
          duration: 3000,
          position: p,
        });
      } else {
        this.smoothScroll.setPosition(p);
        this.smoothScroll.scroll(s);
      }
    }
  }

  handleTouchStart = e => {
    const { clientY } = e.touches[0];
    this.touchFirstY = this.touchLastY = clientY;
    this.touchStartTime = new Date().getTime();
  };
  handleTouchMove = e => {
    const { clientY } = e.touches[0];
    this.touchLastY = clientY;
  };
  handleTouchEnd = () => {
    const deltaY = -(this.touchLastY - this.touchFirstY);
    this.touchFirstY = this.touchLastY = 0;

    if (!deltaY) {
      // console.log('SKIP: no delta', deltaY);
      return;
    }

    const direction = deltaY > 0 ? 1 : -1;
    const newStep = this.currentStep + direction;

    if (newStep < 0 || newStep >= this.steps.length) {
      // already on first/last step, nothing to change
      // console.log('SKIP: already on first/last step, nothing to change');
      return;
    }

    const now = new Date().getTime();
    let isSwipe = false;

    // check for velocity
    // if speed is higher then 400px/s, it's a swipe
    const deltaTime = (now - this.touchStartTime) / 1000;
    const absDeltaY = Math.abs(deltaY);
    const speed = absDeltaY / deltaTime;

    // console.log(`${speed} px/s --- ${absDeltaY}px`);

    if (speed >= 400 && absDeltaY > 40) {
      isSwipe = true;
    }

    if (isSwipe) {
      this.scrollToStep(newStep);
    }
  };

  handleKeydown = e => {
    const { active: isActive } = this.props;
    const { key } = e;

    if (!isActive) {
      return;
    }

    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      return;
    }

    e.skipArrowScroll = true;

    const direction = key === 'ArrowDown' ? 1 : -1;
    const newStep = this.currentStep + direction;

    if (newStep < 0 || newStep >= this.steps.length) {
      // already on first/last step, nothing to change
      // console.log('SKIP: already on first/last step, nothing to change');
      return;
    }

    e.stopPropagation(); // deny scroll management on output page level

    this.scrollToStep(newStep);
  };

  render() {
    const {
      lifeline,
      exiting: _,
      entering: __,
      active: ___,
      onChildhood: ____,
      onAdulthood: _____,
      ...props
    } = this.props;
    const {
      data: {
        remembering: { firstName, birthDate },
      },
    } = lifeline;

    const birthYear = birthDate.slice(0, 4);
    const twentyYear = parseInt(birthYear) + 21;

    const birthDecade = Math.floor(birthYear / 10) * 10;
    const copyBirthDecade = copy[birthDecade];

    const twentyDecade = Math.floor(twentyYear / 10) * 10;
    const copyTwentyDecade = copy[twentyDecade];

    const birthDecadeFolder =
      available_decades.find(el => birthDecade == el) || available_decades[0];
    const twentyDecadeFolder =
      available_decades.find(el => twentyDecade == el) || available_decades[0];

    return (
      <div
        ref={this.ref}
        className={classnames('lifeline-decades', {
          twenties: this.hasTwenties,
        })}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
        onTouchCancel={this.handleTouchEnd}
        {...props}
      >
        <div className="lifeline-decades__year">
          <span className="lifeline-decades__year--number">{birthYear}</span>
          <span className="lifeline-decades__year--subtitle">
            <span className="first-decade">The year {firstName} was born</span>
            <span className="second-decade">
              The year {firstName} turned 21
            </span>
          </span>
        </div>
        <div className="lifeline-decades__decade lifeline-decades__decade--first">
          <div className="lifeline-decades__copy-container">
            <div
              className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--1"
              data-autoscroll-waypoint
            />
            <div
              className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--2"
              data-autoscroll-waypoint
            />
            <div
              className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--3"
              data-autoscroll-waypoint
            />

            <div className="lifeline-decades__copy__beacon beacon_1" />
            <p className="lifeline-decades__copy copy_1">
              {firstName} joined the world during {copyBirthDecade[0]}
            </p>
            <div className="lifeline-decades__copy__beacon beacon_2" />
            <p className="lifeline-decades__copy copy_2">
              {copyBirthDecade[1]}
            </p>
            <div className="lifeline-decades__copy__beacon beacon_3" />
            <p className="lifeline-decades__copy copy_3">
              {copyBirthDecade[2]}
            </p>
          </div>
          <div className="lifeline-decades__images-container lifeline-decades__images-container--birth">
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-1.jpg`)}
              />
            </span>
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-2.jpg`)}
              />
            </span>
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-3.jpg`)}
              />
            </span>
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-4.jpg`)}
              />
            </span>
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-5.jpg`)}
              />
            </span>
            <span>
              <img
                src={require(`#/photos/decades/${birthDecadeFolder}/${birthDecadeFolder}-6.jpg`)}
              />
            </span>
            <div className="lifeline-decades__beacon__background1__end"></div>
          </div>
        </div>
        {this.hasTwenties && (
          <>
            <div
              className="lifeline-decades__decade_transition"
              data-decade-from={birthYear}
              data-decade-to={twentyYear}
            ></div>
            <div className="lifeline-decades__decade lifeline-decades__decade--second">
              <div className="lifeline-decades__copy-container">
                <div
                  className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--1"
                  data-autoscroll-waypoint
                />
                <div
                  className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--2"
                  data-autoscroll-waypoint
                />
                <div
                  className="lifeline-decades__autoscroll-waypoint lifeline-decades__autoscroll-waypoint--3"
                  data-autoscroll-waypoint
                />

                <div className="lifeline-decades__copy__beacon beacon_1" />
                <p className="lifeline-decades__copy copy_1">
                  Turning 21 in the {twentyDecade}
                  <span className="smallcap">s</span>, {copyTwentyDecade[0]}
                </p>
                <div className="lifeline-decades__copy__beacon beacon_2" />
                <p className="lifeline-decades__copy copy_2">
                  {copyTwentyDecade[1]}
                </p>
                <div className="lifeline-decades__copy__beacon beacon_3" />
                <p className="lifeline-decades__copy copy_3">
                  {copyTwentyDecade[2]}
                </p>
              </div>
              <div className="lifeline-decades__images-container lifeline-decades__images-container--twenty">
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-1.jpg`)}
                  />
                </span>
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-2.jpg`)}
                  />
                </span>
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-3.jpg`)}
                  />
                </span>
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-4.jpg`)}
                  />
                </span>
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-5.jpg`)}
                  />
                </span>
                <span>
                  <img
                    src={require(`#/photos/decades/${twentyDecadeFolder}/${twentyDecadeFolder}-6.jpg`)}
                  />
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Decades;
