import React, { Component } from 'react';
import classnames from 'classnames';
import Button from '@/components/Button';

import './style.css';
import LoadingShape from '../LoadingShape';

export default class SimpleModal extends Component {
  render() {
    const {
      title,
      subtitle,
      onAccept,
      onDecline,
      children,
      acceptLabel,
      declineLabel,
      className,
      loadingWheel,
    } = this.props;

    return (
      <div className={classnames('simple-modal', className)}>
        {title && <h2>{title}</h2>}
        {subtitle && <p>{subtitle}</p>}
        {children}
        {(acceptLabel || declineLabel) && (
          <div className="simple-modal--buttons">
            {acceptLabel && (
              <Button variant="alt-primary" onClick={onAccept}>
                {acceptLabel}
              </Button>
            )}
            {declineLabel && (
              <Button variant="secondary" onClick={onDecline}>
                {declineLabel}
              </Button>
            )}
          </div>
        )}
        {loadingWheel && (
          <LoadingShape
            isActive={true}
            variant="export"
            showMessage
            text="Exporting Film"
          />
        )}
      </div>
    );
  }
}
