import React, { createRef, Component } from 'react';
import classnames from 'classnames';
import easeInOutSine from 'eases/sine-in-out';

import LifelineShape from '@/components/LifelineShape';
import LifelineNotPublishedBanner from '@/components/LifelineNotPublishedBanner';
import LoadingScreen from '@/components/LoadingScreen';
import Svg from '@/components/Svg';
import { tablet } from '@/components/App/breakpoints';

import getOffsetTopInAncestor from '@/utils/getOffsetTopInAncestor';

import Section from './Section';

import MusicPlayer from './MusicPlayer';
import Cover from './Cover';
import Intro from './Intro';
import Birthday from './Birthday';
import BirthPlace from './BirthPlace';
import Decades from './Decades';
import Family from './Family';
import Interests from './Interests';
import InterestsDescriptions from './InterestsDescriptions';
import InterestsDetails from './InterestsDetails';
import Achievements from './Achievements';
import EthicalWill from './EthicalWill';
import DeathPlace from './DeathPlace';
import Closure from './Closure';
import End from './End';

import './style.css';

const DECADES_MIN_YEAR = 1910;

const SLIDE_DEBOUNCE_TIME = 1200;
const SLIDE_DEBOUNCE_TIME_ON_AUTOSCROLL = 3000;
const SLIDE_EXTRA_SCROLL = 300;

const AUTOSCROLL_SPEED = 3;
const AUTOSCROLL_DURATION_OVER_H = 4000;
const AUTOSCROLL_DEBOUNCE_TIME = 3000;

export const DEFAULT_PROGRESS_BAR_COLORS = {
  pri: '#ff004c',
  sec: '#000',
  bg: '#ffffff',
};

export const SECTIONS = {
  // Cover + Intro
  intro: {
    name: 'intro',
    color: '#FFFFFF',
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
    progressBarName: 'Introduction',
    autoScrollNoWaitAtTheEnd: true,
  },
  // Birthday
  birthday: {
    name: 'birthday',
    color: '#1081DA',
    foreground: 'light',
    backgroundColor: '#1081DA',
    foregroundColor: '#FFF',
    mergeInProgressBar: true,
    progressBarColors: {
      pri: '#def',
      sec: '#fff',
      bg: '#1081DA',
    },
    autoScrollNoWaitAtTheEnd: true,
    autoscrollDebounceTime: 8000,
  },
  // Birthplace
  'birth-place': {
    name: 'birth-place',
    color: '#000000',
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
    progressBarName: 'Birth place',
    progressBarColors: {
      bg: 'transparent',
    },
  },
  // Decades
  decades: {
    name: 'decades',
    color: '#003698',
    foreground: 'light',
    backgroundColor: '#37BFD1',
    foregroundColor: '#FFF',
    progressBarColors: {
      pri: '#def',
      sec: '#fff',
      bg: '#37bfd1',
    },
  },
  // Family
  family: {
    name: 'family',
    color: '#FFFFFF',
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
    progressBarName: 'Family and friends',
    autoScrollNoWaitAtTheEnd: true,
    autoScrollDurationOverH: 7000,
  },
  // Intrests
  interests: {
    name: 'interests',
    color: '#E4406D',
    foreground: 'light',
    backgroundColor: '#E4406D',
    foregroundColor: '#FFF',
    progressBarColors: {
      pri: '#def',
      sec: '#fff',
      bg: '#E4406D',
    },
  },
  // Achievements
  achievements: {
    name: 'achievements',
    color: '#FEFCEF',
    foreground: 'dark',
    backgroundColor: '#FEFCEF',
    foregroundColor: '#003698',
    progressBarColors: {
      bg: '#FEFCEF',
    },
    autoScrollNoWaitAtTheEnd: true,
    autoScrollDurationOverH: 12000,
  },
  // Ethical Will
  'ethical-will': {
    name: 'ethical-will',
    color: '#37BFD1',
    foreground: 'light',
    backgroundColor: '#37BFD1',
    foregroundColor: '#FFF',
    progressBarName: 'Final thoughts',
    progressBarColors: {
      pri: '#def',
      sec: '#fff',
      bg: '#37BFD1',
    },
    autoscrollDebounceTime: 12000,
  },
  // Death place
  'death-place': {
    name: 'death-place',
    color: '#FFFFFF',
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
    progressBarName: 'Closure',
    // mergeInProgressBar: true,
    progressBarColors: {
      bg: 'transparent',
    },
    autoscrollDebounceTime: 10000,
  },
  // Closure
  closure: {
    name: 'closure',
    color: '#FFFFFF',
    mergeInProgressBar: true,
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
    autoscrollDebounceTime: 6000,
  },
  // End (+ Footer)
  end: {
    name: 'end',
    color: '#FFFFFF',
    mergeInProgressBar: true,
    foreground: 'dark',
    backgroundColor: '#FFF',
    foregroundColor: '#003698',
  },
};

class LifelineOutput extends Component {
  state = {
    exitingSectionIndex: -1,
    enteringSectionIndex: -1,
    currentSectionIndex: 0,
    shouldCloudAnimate: false,
    isDecadesOnAdulthood: false,
    isCoverBadgeVisible: true,
    availableSections: [],
    isAutoScrolling: false,
    isMusicPlayerDisabled: true,
    isMobileView: false,
  };

  constructor(props) {
    super(props);

    this.background = createRef();
    this.cover = createRef();
    this.cloudComponent = createRef();

    // create ref for all sections
    this.sections = {};
    Object.keys(SECTIONS).forEach(sectionName => {
      this.sections[sectionName] = createRef();
    });

    this.lastSectionChangeTime = 0;
  }

  componentDidMount() {
    this.scooter = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--scooter')
      .trim();

    window.virtualScrollY = 0;
    window.isFirstOrLastSection = true;

    document.body.classList.add('lifeline-output-body');

    Object.keys(SECTIONS).forEach(sectionName => {
      if (this.sections[sectionName].current) {
        this.sections[sectionName].current.addEventListener(
          'touchmove',
          this.handleTouchMove,
          { passive: false }
        );

        this.sections[sectionName].current.addEventListener(
          'wheel',
          this.handleScroll,
          { passive: false }
        );
      }
    });

    if (window.innerWidth < tablet) {
      this.setState({ isMobileView: true });
    }

    window.addEventListener('keydown', this.handleScroll);
    window.addEventListener('navigateSection', this.navigateSection);
    window.addEventListener('resize', this.handleResize);
  }

  navigateSection = ({ detail }) => {
    const newSectionIndex = detail;
    const { availableSections } = this.state;

    this.setState(
      state => ({
        ...state,
        exitingSectionIndex: state.currentSectionIndex,
        enteringSectionIndex: newSectionIndex,
        currentSectionIndex: newSectionIndex,
        isAutoScrolling: false,
      }),
      this.sectionIndexStateChangeHandler(newSectionIndex)
    );
    this.sections[availableSections[newSectionIndex]].current.scrollTop = 0;

    setTimeout(
      // simulating an empty keystroke in order to recalculate scroll positions
      () => this.handleScroll({ type: 'keydown', key: '__none__' }),
      500
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const { lifeline } = this.props;
    const {
      exitingSectionIndex,
      currentSectionIndex,
      availableSections,
      isAutoScrolling,
      isDecadesOnAdulthood,
    } = this.state;

    if (!lifeline) {
      return;
    }

    if (!prevProps.lifeline) {
      // we want to run this just once, (no multiple touchmove handlers for same section)
      Object.keys(SECTIONS).forEach(sectionName => {
        if (this.sections[sectionName].current) {
          this.sections[sectionName].current.addEventListener(
            'touchmove',
            this.handleTouchMove,
            { passive: false }
          );
          this.sections[sectionName].current.addEventListener(
            'wheel',
            this.handleScroll,
            { passive: false }
          );
        }
      });
    }

    if (!prevState.isAutoScrolling && isAutoScrolling) {
      this.autoScrollStartTime = null;
      this.lastAutoScrollTime = null;
      requestAnimationFrame(this.handleAutoScrollUpdate);
    }

    window.dispatchEvent(
      new CustomEvent('setProgressSections', {
        detail: availableSections,
      })
    );

    const exitingSection =
      availableSections.length > exitingSectionIndex
        ? SECTIONS[availableSections[exitingSectionIndex]]
        : null;
    const currentSection =
      availableSections.length > currentSectionIndex
        ? SECTIONS[availableSections[currentSectionIndex]]
        : null;

    let backgroundColor = '#FFFFFF';
    let progressBarColors = null;
    if (
      (currentSection && currentSection.name === 'birth-place') ||
      (exitingSection && exitingSection.name === 'birth-place')
    ) {
      backgroundColor = SECTIONS['birth-place'].color;
      progressBarColors = SECTIONS['birth-place'].progressBarColors;
    } else if (
      (currentSection && currentSection.name === 'death-place') ||
      (exitingSection && exitingSection.name === 'death-place')
    ) {
      backgroundColor = SECTIONS['death-place'].color;
      progressBarColors = SECTIONS['death-place'].progressBarColors;
    } else if (
      exitingSectionIndex >= 0 &&
      exitingSection &&
      exitingSection.color
    ) {
      backgroundColor = exitingSection.color;
      progressBarColors =
        exitingSection.progressBarColors || DEFAULT_PROGRESS_BAR_COLORS;
      if (exitingSection.name === 'decades') {
        progressBarColors = {
          ...progressBarColors,
          bg: isDecadesOnAdulthood ? '#003698' : '#37bfd1',
        };
      }
    } else if (currentSection && currentSection.color) {
      backgroundColor = currentSection.color;
      progressBarColors =
        currentSection.progressBarColors || DEFAULT_PROGRESS_BAR_COLORS;
      if (currentSection.name === 'decades') {
        progressBarColors = {
          ...progressBarColors,
          bg: isDecadesOnAdulthood ? '#003698' : '#37bfd1',
        };
      }
    }

    if (backgroundColor && this.cloudComponent.current) {
      this.cloudComponent.current.bg_color = backgroundColor;
    }

    window.dispatchEvent(
      new CustomEvent('setProgressColors', {
        detail: progressBarColors,
      })
    );
  }

  componentWillUnmount() {
    document.body.classList.remove('lifeline-output-body');
    window.removeEventListener('keydown', this.handleScroll);
    window.removeEventListener('navigateSection', this.navigateSection);

    window.dispatchEvent(
      new CustomEvent('toggleProgressBar', {
        detail: false,
      })
    );

    Object.keys(SECTIONS).forEach(sectionName => {
      if (this.sections[sectionName].current) {
        this.sections[sectionName].current.removeEventListener(
          'touchmove',
          this.handleTouchMove,
          { passive: false }
        );
        this.sections[sectionName].current.removeEventListener(
          'wheel',
          this.handleScroll,
          { passive: false }
        );
      }
    });
  }

  static getDerivedStateFromProps(props) {
    const availableSections = [];

    const { lifeline } = props;

    const birthDate =
      lifeline &&
      lifeline.data &&
      lifeline.data.remembering &&
      lifeline.data.remembering.birthDate &&
      lifeline.data.remembering.birthDate.slice(0, 4);

    const hasDecades = birthDate && birthDate >= DECADES_MIN_YEAR;

    const hasFamily =
      lifeline &&
      lifeline.data &&
      lifeline.data.relationships &&
      lifeline.data.relationships.importantRelationships &&
      lifeline.data.relationships.importantRelationships.length != 0;

    const hasInterests =
      lifeline &&
      lifeline.data &&
      lifeline.data.interests &&
      lifeline.data.interests.interests &&
      Object.keys(lifeline.data.interests.interests).length != 0;

    const hasAchievements =
      lifeline &&
      lifeline.data.achievements &&
      lifeline.data.achievements.achievements &&
      lifeline.data.achievements.achievements.length > 0;

    const hasEthicalWill =
      lifeline &&
      lifeline.data &&
      lifeline.data.remembering &&
      lifeline.data.remembering.lastWords;

    const hasDeathPlace =
      lifeline &&
      lifeline.data &&
      lifeline.data.remembering &&
      lifeline.data.remembering.deathCountry;

    availableSections.push('intro');
    availableSections.push('birthday');
    availableSections.push('birth-place');
    if (hasDecades) {
      availableSections.push('decades');
    }
    if (hasFamily) {
      availableSections.push('family');
    }
    if (hasInterests) {
      availableSections.push('interests');
    }
    if (hasAchievements) {
      availableSections.push('achievements');
    }
    if (hasEthicalWill) {
      availableSections.push('ethical-will');
      // need to configure 'death-place' section depending on existence of "ethical-will"
      SECTIONS['death-place'].mergeInProgressBar = true;
    } else {
      SECTIONS['death-place'].mergeInProgressBar = false;
    }
    if (hasDeathPlace) {
      availableSections.push('death-place');
    }
    availableSections.push('closure');
    availableSections.push('end');

    return { availableSections };
  }

  setTimoutsForTransitionStates() {
    const {
      availableSections,
      exitingSectionIndex,
      enteringSectionIndex,
    } = this.state;

    if (this.transitionTimout) {
      clearTimeout(this.transitionTimout);
    }

    if (exitingSectionIndex < 0 && enteringSectionIndex < 0) {
      return;
    }

    let wait = 400;

    if (exitingSectionIndex >= 0) {
      const exitingSection =
        availableSections.length > exitingSectionIndex
          ? availableSections[exitingSectionIndex]
          : null;

      if (
        exitingSection === 'birth-place' ||
        exitingSection === 'death-place'
      ) {
        wait = 600;
      }
    }

    this.transitionTimout = setTimeout(() => {
      this.setState({
        exitingSectionIndex: -1,
        enteringSectionIndex: -1,
      });
    }, wait);
  }

  handleResize = () => {
    if (window.innerWidth < tablet) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  };

  handleAutoScrollUpdate = t => {
    const {
      currentSectionIndex,
      availableSections,
      isAutoScrolling,
    } = this.state;

    const autoscrollDebounceTime =
      this.autoScrollWait ||
      SECTIONS[availableSections[currentSectionIndex]].autoscrollDebounceTime ||
      AUTOSCROLL_DEBOUNCE_TIME;

    if (this.lastAutoScrollTime === null) {
      this.lastAutoScrollTime = t - autoscrollDebounceTime - 1;
    }

    if (t - this.lastAutoScrollTime > autoscrollDebounceTime) {
      const currentSection =
        availableSections.length > currentSectionIndex
          ? availableSections[currentSectionIndex] &&
            this.sections[availableSections[currentSectionIndex]] &&
            this.sections[availableSections[currentSectionIndex]].current
          : null;

      if (currentSection) {
        if (this.endOfSectionReached) {
          this.handleScroll({ type: 'autoscroll' });
        } else {
          const h = currentSection.offsetHeight;
          const sh = currentSection.scrollHeight;
          const st = Math.min(Math.round(currentSection.scrollTop), sh - h);

          if (this.autoScrollStartTime === null) {
            this.autoScrollStartTime = t;
            this.autoScrollStartY = st;
            this.autoScrollTargetY = st;

            const waypoints = currentSection.querySelectorAll(
              '[data-autoscroll-waypoint]'
            );

            let i = 0;
            let offsetTop =
              i < waypoints.length
                ? getOffsetTopInAncestor(waypoints[i], currentSection)
                : 0;

            const tolerance = 5;
            while (
              i < waypoints.length &&
              (offsetTop <= st + tolerance || offsetTop >= sh - h - tolerance)
            ) {
              i++;
              offsetTop =
                i < waypoints.length
                  ? getOffsetTopInAncestor(waypoints[i], currentSection)
                  : 0;
            }
            if (i < waypoints.length) {
              // found next waypoint (transition target)
              this.autoScrollTargetY = offsetTop;
              this.autoScrollNextWait = parseInt(
                waypoints[i].dataset.autoscrollWait || '0',
                10
              );
              // console.log(
              //   '%c AUTOSCROLL ',
              //   'background: dodgerblue; color: white',
              //   'Found next waypoint',
              //   {
              //     autoScrollTargetY: offsetTop,
              //   }
              // );
            } else if (sh > h) {
              // last waypoint reached and there is still content to scroll
              this.autoScrollTargetY = sh - h;
              // console.log(
              //   '%c AUTOSCROLL ',
              //   'background: dodgerblue; color: white',
              //   'Last waypoint reached, heading section end',
              //   {
              //     autoScrollTargetY: sh - h,
              //   }
              // );
            } else {
              this.endOfSectionReached = true;
              this.handleScroll({ type: 'autoscroll' });

              // in this case this.autoScrollDuration will be 0, so no transition occurs
            }

            this.autoScrollDuration =
              ((this.autoScrollTargetY - this.autoScrollStartY) / h) *
              (SECTIONS[availableSections[currentSectionIndex]]
                .autoScrollDurationOverH || AUTOSCROLL_DURATION_OVER_H);
          }

          if (this.autoScrollDuration > 0) {
            const progress = easeInOutSine(
              Math.min(
                (t - this.autoScrollStartTime) / this.autoScrollDuration,
                1
              )
            );

            // Actual scroll on current section
            currentSection.scrollTop =
              this.autoScrollStartY +
              (this.autoScrollTargetY - this.autoScrollStartY) * progress;

            // console.log(
            //   '%c SCROLL ',
            //   'background: #DADADA; color: #999',
            //   availableSections[currentSectionIndex],
            //   `Current section scroll top set to: ${currentSection.scrollTop}`,
            //   {
            //     t,
            //     autoScrollStartTime: this.autoScrollStartTime,
            //     autoScrollDuration: this.autoScrollDuration,
            //     progress,
            //     autoScrollStartY: this.autoScrollStartY,
            //     autoScrollTargetY: this.autoScrollTargetY,
            //   }
            // );

            if (progress === 1) {
              this.lastAutoScrollTime = t;
              this.autoScrollStartTime = null;
              this.autoScrollWait = this.autoScrollNextWait;
              this.autoScrollNextWait = 0;

              if (this.autoScrollTargetY === sh - h) {
                // we are at the end of the section
                this.endOfSectionReached = true;

                if (
                  !SECTIONS[availableSections[currentSectionIndex]]
                    .autoScrollNoWaitAtTheEnd
                ) {
                  // we need to wait for next transition
                  // update last section change time so that debounce check will work
                  this.lastSectionChangeTime = new Date().getTime();
                }
              }
            }
            this.handleScroll({ type: 'autoscroll' });
          } else {
            // there is no scroll to do but we reached the end
            this.lastAutoScrollTime = t;
            this.autoScrollStartTime = null;
          }
        }
      }
    }

    // keep autoscrolling if the toggle is enabled and the last section is not reached
    if (isAutoScrolling) {
      requestAnimationFrame(this.handleAutoScrollUpdate);
    }
  };

  handleAutoScrollToggle = () => {
    if (!this.state.isAutoScrolling) {
      // fire custom event to notify the user that autoscroll is enabled
      window.dispatchEvent(new CustomEvent('autoscrollStart'));
    } else {
      window.dispatchEvent(new CustomEvent('autoscrollStop'));
    }

    this.setState({
      isAutoScrolling: !this.state.isAutoScrolling,
    });
  };

  handleScroll = e => {
    const {
      currentSectionIndex,
      availableSections,
      isAutoScrolling,
      isMusicPlayerDisabled,
      isMobileView,
    } = this.state;

    let deltaY = 0;
    let direction = 0;
    let isWheelEvent = false;
    let isTouchEvent = false;
    let isAutoScrollEvent = false;
    let isKeyboardEvent = false;
    const allowedKeys = ['ArrowUp', 'ArrowDown', '__none__'];

    if (e.type !== 'autoscroll') {
      if (isAutoScrolling) {
        this.setState({ isAutoScrolling: false });
      }
    }

    switch (e.type) {
      case 'autoscroll':
        deltaY = 0;
        direction = 1;

        isAutoScrollEvent = true;
        break;
      case 'keydown':
        if (!allowedKeys.includes(e.key)) {
          return;
        }

        if (e.key === 'ArrowUp') {
          if (!e.skipArrowScroll) {
            this.sections[
              availableSections[currentSectionIndex]
            ].current.scrollTop -= 32;
          }

          deltaY = 0;
          direction = -1;
        } else if (e.key === 'ArrowDown') {
          if (!e.skipArrowScroll) {
            this.sections[
              availableSections[currentSectionIndex]
            ].current.scrollTop += 32;
          }

          deltaY = 0;
          direction = 1;
        } else {
          deltaY = 0;
          direction = 0;
        }

        isKeyboardEvent = true;

        break;
      case 'wheel':
        deltaY = e.deltaY;
        direction = deltaY > 0 ? 1 : -1;

        if (document.body.classList.contains('lifeline-output-body--touch')) {
          document.body.classList.remove('lifeline-output-body--touch');
        }

        isWheelEvent = true;

        break;
      case 'touchcancel':
      case 'touchend':
        deltaY = -(this.touchLastY - this.touchFirstY);
        direction = deltaY > 0 ? 1 : -1;
        this.touchFirstY = this.touchLastY = 0;

        isTouchEvent = true;

        break;
    }

    if (!isKeyboardEvent && !isAutoScrollEvent && !deltaY) {
      // console.log('%c SKIP ', 'background: red; color: white', 'no delta');
      return false;
    }

    if (direction > 0) {
      // going down
      window.dispatchEvent(new CustomEvent('keepHeaderHidden'));
    } else if (direction < 0) {
      // going up
      window.dispatchEvent(new CustomEvent('allowHeader'));
    }

    const currentSection =
      isKeyboardEvent || isAutoScrollEvent
        ? this.sections[availableSections[currentSectionIndex]].current
        : e.currentTarget;
    const { offsetHeight, scrollHeight } = currentSection;
    const scrollTop = Math.min(
      Math.ceil(currentSection.scrollTop),
      scrollHeight - offsetHeight
    );

    // update virtualScrollY
    window.virtualScrollY = 0;
    // add scrllHeight of previous sections
    availableSections.slice(0, currentSectionIndex).forEach(sectionName => {
      window.virtualScrollY += this.sections[sectionName].current.scrollHeight;
    });
    // add scrollTop of current section
    window.virtualScrollCurrentY = this.sections[
      availableSections[currentSectionIndex]
    ].current.scrollTop;
    window.virtualScrollCurrentHeight = this.sections[
      availableSections[currentSectionIndex]
    ].current.scrollHeight;

    window.virtualScrollY += window.virtualScrollCurrentY;

    const sectionProgress =
      virtualScrollCurrentHeight === window.innerHeight
        ? 1
        : window.virtualScrollCurrentY /
          (virtualScrollCurrentHeight - window.innerHeight);

    window.dispatchEvent(
      new CustomEvent('setProgress', {
        detail: {
          sectionIdx: currentSectionIndex,
          sectionProgress,
          extraScroll: this.extraScroll / SLIDE_EXTRA_SCROLL,
        },
      })
    );

    if (isMusicPlayerDisabled && window.virtualScrollY > 60 && !isMobileView) {
      this.setState({ isMusicPlayerDisabled: false });
    } else if (!isMusicPlayerDisabled && window.virtualScrollY <= 60) {
      this.setState({ isMusicPlayerDisabled: true });
    }

    const now = new Date().getTime();
    if (
      now - this.lastSectionChangeTime <
      (isAutoScrollEvent
        ? SLIDE_DEBOUNCE_TIME_ON_AUTOSCROLL
        : SLIDE_DEBOUNCE_TIME)
    ) {
      // console.log('%c SKIP ', 'background: red; color: white', 'debounce');
      return false;
    }

    const tolerance =
      !isAutoScrollEvent && availableSections[currentSectionIndex] === 'decades'
        ? window.innerHeight * 0.1
        : 0;

    if (
      (direction > 0 && isAutoScrollEvent && !this.endOfSectionReached) ||
      (direction > 0 &&
        !isAutoScrollEvent &&
        scrollTop + offsetHeight + tolerance < scrollHeight) ||
      (direction < 0 && scrollTop > 0)
    ) {
      // there is still content to scroll
      // console.log(
      //   '%c SKIP ',
      //   'background: red; color: white',
      //   'there is still content to scroll'
      // );

      this.extraScroll = 0;

      return false;
    }

    const newSectionIndex = currentSectionIndex + direction;

    if (newSectionIndex < 0 || newSectionIndex >= availableSections.length) {
      // already on first/last section, nothing to change
      // console.log(
      //   '%c SKIP ',
      //   'background: red; color: white',
      //   'already on first/last section, nothing to change'
      // );
      return false;
    }

    if (e.type === 'wheel') {
      e.preventDefault();
    }

    this.extraScroll += deltaY;

    let isSwipe = false;

    if (isTouchEvent) {
      // check for velocity
      // if speed is higher then 400px/s, it's a swipe
      const deltaTime = (now - this.touchStartTime) / 1000;
      const absDeltaY = Math.abs(deltaY);
      const speed = absDeltaY / deltaTime;

      if (speed >= 400 && absDeltaY > 40) {
        isSwipe = true;
      }
    }

    if (
      (isWheelEvent && Math.abs(this.extraScroll) < SLIDE_EXTRA_SCROLL) ||
      (isTouchEvent &&
        !isSwipe &&
        Math.abs(this.extraScroll) < window.innerHeight / 3)
    ) {
      // not scrolled enough
      // console.log(
      //   '%c SKIP ',
      //   'background: red; color: white',
      //   'not scrolled enough',
      //   this.extraScroll
      // );

      // update virtualScrollY
      // add extraScroll in proportion with window innerHeight
      window.virtualScrollY +=
        (this.extraScroll / SLIDE_EXTRA_SCROLL) * window.innerHeight;

      return false;
    }

    // update virtualScrollY
    // add innerHeight
    window.virtualScrollY += window.innerHeight;

    this.lastSectionChangeTime = now;
    this.endOfSectionReached = false;
    this.extraScroll = 0;

    this.setState(
      state => ({
        ...state,
        exitingSectionIndex: state.currentSectionIndex,
        enteringSectionIndex: newSectionIndex,
        currentSectionIndex: newSectionIndex,
        isAutoScrolling:
          state.isAutoScrolling &&
          newSectionIndex === availableSections.length - 1
            ? false
            : isAutoScrolling,
      }),
      this.sectionIndexStateChangeHandler(newSectionIndex)
    );

    return true;
  };

  sectionIndexStateChangeHandler = newSectionIndex => () => {
    const { availableSections } = this.state;
    // subtract scrollTop of new section
    // NOTE: this is needed when going on a previous section that has
    //       already been scrolled
    window.virtualScrollY += this.sections[
      availableSections[newSectionIndex]
    ].current.scrollTop;

    window.isFirstOrLastSection =
      newSectionIndex === 0 || newSectionIndex + 1 === availableSections.length;

    this.setTimoutsForTransitionStates();

    // deselect any selected range
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  };

  handleTouchStart = e => {
    const { clientY } = e.touches[0];
    this.touchFirstY = this.touchLastY = clientY;
    this.touchStartTime = new Date().getTime();

    if (!document.body.classList.contains('lifeline-output-body--touch')) {
      document.body.classList.add('lifeline-output-body--touch');
    }
  };
  handleTouchMove = e => {
    // e.stopPropagation();

    const { clientY } = e.touches[0];
    this.touchLastY = clientY;
  };
  handleChildhood = () => {
    // console.log('childhood');
    this.setState({
      isDecadesOnAdulthood: false,
    });
  };

  handleAdulthood = () => {
    // console.log('adulthood');
    this.setState({
      isDecadesOnAdulthood: true,
    });
  };
  handleIntroComplete = () => {
    this.setState({
      shouldCloudAnimate: true,
    });
  };
  handleCoverBadgeToggle = isCoverBadgeVisible => {
    this.setState({ isCoverBadgeVisible });
  };

  render() {
    const { lifeline } = this.props;
    let {
      exitingSectionIndex,
      enteringSectionIndex,
      currentSectionIndex,
      shouldCloudAnimate,
      isDecadesOnAdulthood,
      isCoverBadgeVisible,
      availableSections,
      isAutoScrolling,
      isMusicPlayerDisabled,
    } = this.state;

    const exitingSection =
      availableSections.length > exitingSectionIndex
        ? SECTIONS[availableSections[exitingSectionIndex]]
        : null;
    const currentSection =
      availableSections.length > currentSectionIndex
        ? SECTIONS[availableSections[currentSectionIndex]]
        : null;
    let currentSectionName = 'untitled';
    if (
      (currentSection && currentSection.name === 'birth-place') ||
      (exitingSection && exitingSection.name === 'birth-place')
    ) {
      currentSectionName = 'birth-place';
    } else if (
      exitingSectionIndex >= 0 &&
      exitingSection &&
      exitingSection.name
    ) {
      currentSectionName = exitingSection.name;
    } else if (currentSection && currentSection.name) {
      currentSectionName = currentSection.name;
    }

    const backgroundClasses = classnames(
      'lifeline-output__background',
      `lifeline-output__background--${currentSectionName}`,
      {
        'lifeline-output__background--decades-adulthood':
          isDecadesOnAdulthood && currentSectionName === 'decades',
      }
    );

    let sectionBackgroundColor = '#FFF';
    let sectionForegroundColor = '#003698';

    if (isDecadesOnAdulthood && currentSectionName === 'decades') {
      sectionBackgroundColor = '#003698';
      sectionForegroundColor = '#FFF';
    } else {
      sectionBackgroundColor = SECTIONS[currentSectionName].backgroundColor;
      sectionForegroundColor = SECTIONS[currentSectionName].foregroundColor;
    }

    const style = {
      '--lifeline-output--background-color': sectionBackgroundColor,
      '--lifeline-output--foreground-color': sectionForegroundColor,
    };

    return (
      <div className="lifeline-output" style={style}>
        <button
          className={classnames('lifeline-output__autoscroll-toggle', {
            'lifeline-output__autoscroll-toggle--active': isAutoScrolling,
            'lifeline-output__autoscroll-toggle--disabled':
              !lifeline || currentSectionIndex === availableSections.length - 1,
            'lifeline-output__autoscroll-toggle--light':
              currentSection.foreground === 'light',
            'lifeline-output__autoscroll-toggle--dark':
              currentSection.foreground === 'dark',
          })}
          type="button"
          onClick={this.handleAutoScrollToggle}
        >
          <Svg name="play-toggle" />

          <span>{isAutoScrolling ? 'Pause' : 'Play the lifeline'}</span>
        </button>

        <MusicPlayer
          lifeline={lifeline}
          disabled={!lifeline || isMusicPlayerDisabled}
        />

        {!lifeline && (
          <LoadingScreen
            className="loading-screen--output"
            isActive={true}
            loading={true}
          />
        )}
        {lifeline && <div className={backgroundClasses} />}
        {lifeline && (
          <LifelineShape
            ref={this.cloudComponent}
            data={lifeline.data}
            lines={false}
            cloud={400}
            transparent
            outputCloud={true}
            paused={!shouldCloudAnimate}
          />
        )}
        {lifeline &&
          availableSections.map((sectionName, i) => {
            const sectionStateProps = {
              exiting: i === exitingSectionIndex,
              entering: i === enteringSectionIndex,
              active: exitingSectionIndex < 0 && i === currentSectionIndex,
            };

            if (sectionName === 'birth-place') {
              sectionStateProps.active = i === currentSectionIndex;
            }

            const hasInterestsDescriptions =
              sectionName === 'interests' &&
              lifeline &&
              lifeline.data &&
              lifeline.data.interests &&
              lifeline.data.interests.interestsDescriptions &&
              Object.keys(lifeline.data.interests.interestsDescriptions)
                .length != 0;

            return (
              <Section
                key={`section--${sectionName}`}
                innerRef={this.sections[sectionName]}
                sectionName={sectionName}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={this.handleScroll}
                onTouchCancel={this.handleScroll}
                {...sectionStateProps}
              >
                {sectionName === 'intro' && (
                  <>
                    <Cover
                      lifeline={lifeline}
                      onIntroComplete={this.handleIntroComplete}
                      onBadgeToggle={this.handleCoverBadgeToggle}
                      {...sectionStateProps}
                      data-autoscroll-waypoint
                    />
                    <Intro
                      lifeline={lifeline}
                      showBadge={!isCoverBadgeVisible}
                      {...sectionStateProps}
                      data-autoscroll-waypoint
                    />
                  </>
                )}
                {sectionName === 'birthday' && (
                  <Birthday
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'birth-place' && (
                  <BirthPlace
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'decades' && (
                  <Decades
                    lifeline={lifeline}
                    onChildhood={this.handleChildhood}
                    onAdulthood={this.handleAdulthood}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'family' && (
                  <Family
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'interests' && (
                  <>
                    <Interests
                      lifeline={lifeline}
                      {...sectionStateProps}
                      data-autoscroll-waypoint
                    />
                    <InterestsDetails
                      lifeline={lifeline}
                      {...sectionStateProps}
                      data-autoscroll-waypoint
                    />
                    {hasInterestsDescriptions && (
                      <InterestsDescriptions
                        lifeline={lifeline}
                        {...sectionStateProps}
                        data-autoscroll-waypoint
                      />
                    )}
                  </>
                )}
                {sectionName === 'achievements' && (
                  <Achievements
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'ethical-will' && (
                  <EthicalWill
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'death-place' && (
                  <DeathPlace
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'closure' && (
                  <Closure
                    lifeline={lifeline}
                    {...sectionStateProps}
                    data-autoscroll-waypoint
                  />
                )}
                {sectionName === 'end' && (
                  <>
                    <End
                      lifeline={lifeline}
                      {...sectionStateProps}
                      data-autoscroll-waypoint
                    />
                  </>
                )}
              </Section>
            );
          })}
        {lifeline && lifeline.status === 'draft' && (
          <LifelineNotPublishedBanner lifeline={lifeline} />
        )}
      </div>
    );
  }
}

export default LifelineOutput;
