export default {
  isPaidPlanEnabled: false,
  paidPlanPrice: 4900,

  // Uploading images
  uploadingImageMinKBSize: 1,
  uploadingImageMaxKBSize: 6000,
  uploadingImageMinWidth: 200, // **jc**
  uploadingImageMaxWidth: 960,
  uploadingImageMinHeight: 200, // **jc**
  uploadingImageMaxHeight: 640,
  get uploadingImageErrorMessages() {
    return {
      'min-res': `Sorry, the file is too small. Minimum required resolution  ${this.uploadingImageMinWidth}x${this.uploadingImageMinHeight}px`,
      'too-small': `Error! Minimum file size ${this.uploadingImageMinKBSize} kB`,
      'too-big': `Error! Maximum file size ${this.uploadingImageMinKBSize >>
        10} mB`,
      fail: 'Image upload failed!',
    };
  },
  uploadingImageMimeType: 'image/jpeg',
  uploadingImageQuality: 0.8,
  relationshipGroups: ['Family', 'Important people', 'Friendship'],
  defaultRelationshipGroup: 'Important people',
  maxRelationshipGroupPhotos: 6,
  musicPlayerMaxSongs: 3,
  musicPlayerCloseDelay: 5000,
};
