const descriptions = {
  Alabama: 'the Yellowhammer State',
  Alaska: 'the Last Frontier',
  Arizona: 'the Grand Canyon State',
  Arkansas: 'the Natural State',
  California: 'the Golden State',
  Colorado: 'the Centennial State',
  Connecticut: 'the Constitution State',
  Delaware: 'the First State',
  Florida: 'the Sunshine State',
  Georgia: 'the Peach State',
  Hawaii: 'the Aloha State',
  Idaho: 'the Gem State',
  Illinois: 'the Prairie State',
  Indiana: 'the Hoosier State',
  Iowa: 'the Hawkeye State',
  Kansas: 'the Sunflower State',
  Kentucky: 'the Bluegrass State',
  Louisiana: 'the Pelican State',
  Maine: 'the Pine Tree State',
  Maryland: 'the Old Line State',
  Massachusetts: 'the Bay State',
  Michigan: 'the Great Lakes State',
  Minnesota: 'the North Star State',
  Mississippi: 'the Magnolia State',
  Missouri: 'the Show-Me State',
  Montana: 'the Treasure State',
  Nebraska: 'the Cornhusker State',
  Nevada: 'the Silver State',
  'New Hampshire': 'the Granite State',
  'New Jersey': 'the Garden State',
  'New Mexico': 'Land of Enchantment',
  'New York': 'the Empire State',
  'North Carolina': 'the Tar Heel State',
  'North Dakota': 'the Peace Garden State',
  Ohio: 'the Buckeye State',
  Oklahoma: 'the Sooner State',
  Oregon: 'the Beaver State',
  Pennsylvania: 'the Keystone State',
  'Rhode Island': 'the Ocean State',
  'South Carolina': 'the Palmetto State',
  'South Dakota': 'the Mount Rushmore State',
  Tennessee: 'the Volunteer State',
  Texas: 'the Lone Star State',
  Utah: 'the Beehive State',
  Vermont: 'the Green Mountain State',
  Virginia: 'the Old Dominion State',
  Washington: 'the Evergreen State',
  'West Virginia': 'the Mountain State',
  Wisconsin: 'the Badger State',
  Wyoming: 'the Equality State',
  'Washington D.C.': 'the capital of the United States',
};

export default descriptions;
