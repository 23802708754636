import { createBrowserHistory, createMemoryHistory } from 'history';

import isSSR from '@/utils/isSSR';

const history = isSSR ? createMemoryHistory() : createBrowserHistory();

history.listen(() => {
  window.dispatchEvent(new CustomEvent('allowHeader'));
  window.dispatchEvent(new CustomEvent('allowMenuProgress')); // **jc**

  window.scrollTo({
    top: 0,
    left: 0,
  });
});

export default history;
