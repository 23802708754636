export const vertex = `
precision highp float;
precision highp int;

uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;

uniform float time;
uniform float freq;
uniform float ampl;
uniform float time_freq;

attribute vec3 position;

attribute float size;
attribute float texture_index;
varying float vTexture_index;
attribute float opacity;
varying float vOpacity;
attribute float blur;
attribute float hoverState;
varying float vHoverState;
attribute vec3 color;
varying vec3 vColor;
attribute float isLine;
varying float vLine;

//  Simplex 3D Noise
//  by Ian McEwan, Ashima Arts
//
vec4 permute(vec4 x){
  return mod(((x*34.0)+1.0)*x, 289.0);
}
vec4 taylorInvSqrt(vec4 r){
  return 1.79284291400159 - 0.85373472095314 * r;
}

float snoise(vec3 v){
  const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
  const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

// First corner
  vec3 i  = floor(v + dot(v, C.yyy) );
  vec3 x0 =   v - i + dot(i, C.xxx) ;

// Other corners
  vec3 g = step(x0.yzx, x0.xyz);
  vec3 l = 1.0 - g;
  vec3 i1 = min( g.xyz, l.zxy );
  vec3 i2 = max( g.xyz, l.zxy );

  //  x0 = x0 - 0. + 0.0 * C
  vec3 x1 = x0 - i1 + 1.0 * C.xxx;
  vec3 x2 = x0 - i2 + 2.0 * C.xxx;
  vec3 x3 = x0 - 1. + 3.0 * C.xxx;

// Permutations
  i = mod(i, 289.0 );
  vec4 p = permute( permute( permute(
             i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
           + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
           + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

// Gradients
// ( N*N points uniformly over a square, mapped onto an octahedron.)
  float n_ = 1.0/7.0; // N=7
  vec3  ns = n_ * D.wyz - D.xzx;

  vec4 j = p - 49.0 * floor(p * ns.z *ns.z);  //  mod(p,N*N)

  vec4 x_ = floor(j * ns.z);
  vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

  vec4 x = x_ *ns.x + ns.yyyy;
  vec4 y = y_ *ns.x + ns.yyyy;
  vec4 h = 1.0 - abs(x) - abs(y);

  vec4 b0 = vec4( x.xy, y.xy );
  vec4 b1 = vec4( x.zw, y.zw );

  vec4 s0 = floor(b0)*2.0 + 1.0;
  vec4 s1 = floor(b1)*2.0 + 1.0;
  vec4 sh = -step(h, vec4(0.0));

  vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
  vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

  vec3 p0 = vec3(a0.xy,h.x);
  vec3 p1 = vec3(a0.zw,h.y);
  vec3 p2 = vec3(a1.xy,h.z);
  vec3 p3 = vec3(a1.zw,h.w);

//Normalise gradients
  vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
  p0 *= norm.x;
  p1 *= norm.y;
  p2 *= norm.z;
  p3 *= norm.w;

// Mix final noise value
  vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
  m = m * m;
  return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                dot(p2,x2), dot(p3,x3) ) );
}

void main() {
  #include <begin_vertex>
  float t = time * time_freq;
  float px = snoise(vec3(freq * position.xy, t));
  float py = snoise(vec3(freq * position.yz, t));
  float pz = snoise(vec3(freq * position.zx, t));
  transformed += ampl * vec3(px,py,pz);
  #include <project_vertex>
  gl_PointSize =  (1. + 0.2 * hoverState) * size;
  vTexture_index = texture_index;
  vOpacity = opacity;
  vHoverState = hoverState;
  vColor = color;
  vLine = isLine;
  #include <worldpos_vertex>
}`;

export const fragment = `
precision highp float;
precision highp int;

uniform float time;
uniform sampler2D map1;
uniform sampler2D map2;
uniform sampler2D map1_blur;
uniform sampler2D map2_blur;
uniform sampler2D map1_hover;
uniform sampler2D map2_hover;
uniform mat3 uvTransform;
uniform vec3 bg_color;
uniform float cloud_line_pos;
varying float vTexture_index;
varying float vOpacity;
varying float vHoverState;
varying vec3 vColor;
varying float vLine;

float rand(vec2 co){
    return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);
}

void main() {
  vec2 uv = ( uvTransform * vec3( gl_PointCoord.x, 1.0 - gl_PointCoord.y, 1 ) ).xy;
  vec4 mapTexel;
  if(vTexture_index < 0.25) {
    mapTexel = mix(texture2D( map1, uv ), texture2D( map1_hover, uv ), vHoverState);
  } else if(vTexture_index < 0.50) {
    mapTexel = mix(texture2D( map1_blur, uv ), texture2D( map1_hover, uv ), vHoverState);
  } else if(vTexture_index < 0.75) {
    mapTexel = mix(texture2D( map2, uv ), texture2D( map2_hover, uv ), vHoverState);
  } else {
    mapTexel = mix(texture2D( map2_blur, uv ), texture2D( map2_hover, uv ), vHoverState);
  }

  gl_FragColor.rgb = clamp(vColor, 0.05 * vHoverState, 1. - 0.05 * vHoverState);
  gl_FragColor.rgb +=  1. - min(min(bg_color.r, bg_color.g), bg_color.b);
  gl_FragColor.a = mix(vOpacity, 2. * vOpacity, vHoverState) * mapTexel.a;

  gl_FragColor.a *= (vLine > 0.) ? 1. : cloud_line_pos;
}`;
