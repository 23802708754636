/**
 * Every change applied to this component should
 * be appropriately reflected to ./ServerApp.js
 */

import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/browser';
import Helmet from 'react-helmet';

import Header from '@/components/Header';
import ProgressBar from '@/components/ProgressBar';
import Footer from '@/components/Footer';
import ProtectedRoute from '@/components/ProtectedRoute';
import LoadingScreen from '@/components/LoadingScreen';
import CookieBanner from '@/components/CookieBanner';

import config from '@/config';

import getProgressFromURL from '@/utils/getProgressFromURL';
import isEdge from '@/utils/isEdge';
import getSentryDNS from '@/utils/getSentryDNS';
import getBaseURL from '@/utils/getBaseURL';

import loadable from '@loadable/component';

import './normalize.css';
import './fonts.css';
import './typography.css';
import './style.css';
import './theme.css';

if (process.env.BUILD_TYPE !== 'local') {
  Sentry.init({
    dsn: getSentryDNS(),
    environment: process.env.BUILD_TYPE,
  });
}

const loading = <div style={{ minHeight: 'var(--page-height)' }} />;

function load(f, fallback = loading) {
  return loadable(f, {
    fallback,
  });
}

const Homepage = load(() =>
  import(/* webpackChunkName: "home" */ '@/pages/Homepage')
);

const LifelineEditor = load(() =>
  import(/* webpackChunkName: "editor" */ '@/pages/LifelineEditor')
);

const Lifeline = load(
  () => import(/* webpackChunkName: "lifeline" */ '@/pages/Lifeline'),
  <LoadingScreen
    className="loading-screen--output"
    isActive={true}
    loading={true}
  />
);

const Dashboard = load(() =>
  import(/* webpackChunkName: "dashboard" */ '@/pages/Dashboard')
);

const Pricing = load(() =>
  import(/* webpackChunkName: "pricing" */ '@/pages/Pricing')
);

const Login = load(() =>
  import(/* webpackChunkName: "login" */ '@/pages/Login')
);

const Signup = load(() =>
  import(/* webpackChunkName: "signup" */ '@/pages/Signup')
);

const EditProfile = load(() =>
  import(/* webpackChunkName: "editprofile" */ '@/pages/EditProfile')
);

const RequestResetPassword = load(() =>
  import(
    /* webpackChunkName: "requestresetpassword" */ '@/pages/RequestResetPassword'
  )
);

const ResetPassword = load(() =>
  import(/* webpackChunkName: "resetpassword" */ '@/pages/ResetPassword')
);

const AddLifeline = load(() =>
  import(/* webpackChunkName: "addlifeline" */ '@/pages/AddLifeline')
);

const FindLifeline = load(() =>
  import(/* webpackChunkName: "findlifeline" */ '@/pages/FindLifeline')
);

const DeleteAccountSuccess = load(() =>
  import(
    /* webpackChunkName: "deleteaccountsuccess" */ '@/pages/DeleteAccountSuccess'
  )
);

const WatchVideo = load(() =>
  import(/* webpackChunkName: "watchvideo" */ '@/pages/WatchVideo')
);

const AnswerHelpRequest = load(() =>
  import(
    /* webpackChunkName: "answerhelprequest" */ '@/pages/AnswerHelpRequest'
  )
);

const ReviewHelpRequest = load(() =>
  import(
    /* webpackChunkName: "reviewhelprequest" */ '@/pages/ReviewHelpRequest'
  )
);

const NotFound = load(() =>
  import(/* webpackChunkName: "notfound" */ '@/pages/NotFound')
);

const Terms = load(() =>
  import(/* webpackChunkName: "terms" */ '@/pages/Terms')
);

// const FAQs = load(() => import(/* webpackChunkName: "faqs" */ '@/pages/FAQs'));

const About = load(() =>
  import(/* webpackChunkName: "about" */ '@/pages/About')
);

function isNotLogged(state, store) {
  return !store.select.user.isLogged(state);
}

class App extends Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    if (isEdge()) {
      document.body.classList.add('is-edge');
    }

    this.lightModeTags = document.querySelectorAll(
      '[name="light-mode-favicon"]'
    );
    this.darkModeTags = document.querySelectorAll('[name="dark-mode-favicon"]');

    const schemaMatcher = window.matchMedia('(prefers-color-scheme: dark)');
    schemaMatcher.addListener(this.onColorSchemaUpdate);
    this.onColorSchemaUpdate(schemaMatcher);

    this.setState({ isMounted: true });
  }

  onColorSchemaUpdate = match => {
    if (match.matches) {
      this.lightModeTags.forEach(el => el.remove());
      this.darkModeTags.forEach(el => document.head.appendChild(el));
    } else {
      this.darkModeTags.forEach(el => el.remove());
      this.lightModeTags.forEach(el => document.head.appendChild(el));
    }
  };

  renderFooter(props) {
    /* the footer should not render in the creation pages */
    if (
      props.location.pathname.startsWith('/new') ||
      props.location.pathname.startsWith('/edit') ||
      props.location.pathname.startsWith('/lifeline') ||
      props.location.pathname.startsWith('/help') ||
      props.location.pathname.startsWith('/review')
    ) {
      return null;
    }

    return (
      <Fragment>
        <CookieBanner />
        <Footer {...props} />
      </Fragment>
    );
  }

  canAccessLifelineEditorUnlogged = (state, store) => {
    const {
      location: { pathname },
    } = this.props;
    const [section, _, type, __] = getProgressFromURL(pathname);

    if (section === 'remembering' && type === 'new') {
      return true;
    }

    return store.select.user.isLogged(state);
  };

  render() {
    const { isMounted } = this.state;

    const classes = classnames('app', {
      'app--mounted': isMounted,
    });

    return (
      <div className={classes}>
        <Helmet
          titleTemplate="%s | Memories.com"
          defaultTitle="Create a memory for a loved one | Memories.com"
        >
          <meta property="og:title" content="Memories" />
          <meta
            property="og:description"
            content="Memories.com is a place and a memorial website to celebrate lives, share stories and remember your loved ones. Create a unique personalized memorial page to remember those who passed away."
          />
          <meta
            property="og:image"
            content={`${getBaseURL()}memories-share.jpg`}
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>

        <Header />
        <ProgressBar />

        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/add-lifeline" component={AddLifeline} />
          <Route exact path="/lifeline/:slug" component={Lifeline} />
          <Route exact path="/search" component={FindLifeline} />
          {config.isPaidPlanEnabled && (
            <Route exact path="/pricing" component={Pricing} />
          )}
          <Route
            exact
            path="/delete-account"
            component={DeleteAccountSuccess}
          />
          <Route
            exact
            path="/lifeline/:slug/watch-video"
            component={WatchVideo}
          />

          <ProtectedRoute exact path="/my-account" component={Dashboard} />
          <ProtectedRoute exact path="/edit-profile" component={EditProfile} />

          <ProtectedRoute
            exact
            path="/login"
            redirectTo="/"
            component={Login}
            allowed={isNotLogged}
          />
          <ProtectedRoute
            exact
            path="/signup"
            redirectTo="/"
            component={Signup}
            allowed={isNotLogged}
          />

          <Route
            exact
            path="/request-reset-password"
            component={RequestResetPassword}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />

          <Route
            path="/help/:lifeline/:helprequest"
            component={AnswerHelpRequest}
          />

          <Route
            path="/review/:lifeline/:helprequest"
            component={ReviewHelpRequest}
          />

          <ProtectedRoute
            key="lifeline-editor"
            allowed={this.canAccessLifelineEditorUnlogged}
            path={['/new', '/edit']}
            component={LifelineEditor}
          />

          <Route
            path={['/copyright-policy', '/privacy-policy', '/use-terms']}
            component={Terms}
          />

          {/* <Route
            path={['/services-faqs', '/support-faqs', '/billing-faqs']}
            component={FAQs}
          /> */}

          <Route exact path="/about" component={About} />

          <Route path="*" component={NotFound} />
        </Switch>

        <Route path="/" component={this.renderFooter} />
      </div>
    );
  }
}

export default hot(withRouter(App));
