import apiFetch from '@/api/apiFetch';

export default async (uuid, to) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      to,
    }),
  };

  const response = await apiFetch(
    `lifelines/${uuid}/share-video`,
    options,
    204
  );

  return response;
};
