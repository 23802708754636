import apiFetch from '@/api/apiFetch';

export default async () => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch('me', options, 200);

  console.log('Get user data...', response);

  return await response.json();
};
