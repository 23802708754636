import React, { Component, createRef } from 'react';
import classnames from 'classnames';

import {
  SECTIONS,
  DEFAULT_PROGRESS_BAR_COLORS,
} from '@/components/LifelineOutput';

import './style.css';

export class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      allSections: [],
      sectionSpans: [],
      visible: false,
      progress: {
        sectionIdx: 0,
        sectionProgress: 0,
      },
      colors: DEFAULT_PROGRESS_BAR_COLORS,
    };

    this.header = createRef();
  }

  componentDidMount() {
    window.addEventListener('setProgress', this.setProgress);
    window.addEventListener('setProgressSections', this.setProgressSections);
    window.addEventListener('setProgressColors', this.setProgressColors);
    window.addEventListener('toggleProgressBar', this.toggleProgressBar);
  }

  componentWillUnmount() {
    window.removeEventListener('setProgress', this.setProgress);
    window.removeEventListener('setProgressSections', this.setProgressSections);
    window.removeEventListener('setProgressColors', this.setProgressColors);
    window.removeEventListener('toggleProgressBar', this.toggleProgressBar);
  }

  setProgress = ({ detail }) => {
    this.setState({ progress: detail });
  };

  setProgressColors = ({ detail }) => {
    this.setState({ colors: detail });
  };

  toggleProgressBar = ({ detail }) => {
    this.setState({ visible: detail });
  };

  setProgressSections = ({ detail }) => {
    const allSections = detail.map(id => ({ ...SECTIONS[id] }));
    const sections = allSections.filter(s => !s.mergeInProgressBar);

    let wIdx = 0;
    let spans = 0;
    let inSpanIdx = 0;
    allSections.forEach((section, idx) => {
      if (!section.mergeInProgressBar) {
        spans += allSections[wIdx].span || 0;
        wIdx = idx;
        inSpanIdx = 0;
      } else {
        inSpanIdx++;
      }

      allSections[wIdx].span = (allSections[wIdx].span || 0) + 1;
      allSections[idx].realIdx = spans + inSpanIdx;
      allSections[idx].width = 1 / allSections[wIdx].span / allSections.length;
    });
    const sectionSpans = sections.map(s => s.span);
    let factor = 1;
    let widthSum = 0;
    allSections.forEach((section, idx) => {
      if (section.span) {
        factor = 1 / section.span;
      }
      section.width = factor / sections.length;
      section.widthSum = widthSum;
      widthSum += section.width;
    });
    this.setState({ sections, sectionSpans, allSections, visible: true });
  };

  render() {
    const {
      sections,
      allSections,
      progress: { sectionIdx, sectionProgress, extraScroll },
      visible,
      colors,
    } = this.state;

    if (!visible) {
      return null;
    }

    const classes = {
      progressBar: classnames('progress-bar'),
      line: classnames('progress-bar__line'),
      lineEdge: classnames('progress-bar__line-edge'),
      lineWrapper: classnames('progress-bar__line-wrapper'),
      item: classnames('progress-bar__item'),
      itemLabel: classnames('progress-bar__item-label'),
      underlay: classnames('progress-bar__item-underlay'),
    };

    let baseWidth = 0;
    let sectWidth = 0;
    if (allSections.length) {
      // console.log({ sectionIdx, allSections });
      baseWidth = allSections[sectionIdx].widthSum * 100;
      sectWidth = Number.isFinite(sectionProgress)
        ? sectionProgress * allSections[sectionIdx].width * 100
        : Math.max(0, extraScroll * allSections[sectionIdx].width * 100);
    }

    return (
      <header
        className={classes.progressBar}
        ref={this.header}
        style={{
          backgroundColor:
            (colors && colors.bg) || DEFAULT_PROGRESS_BAR_COLORS.bg,
        }}
      >
        <div
          className={classes.lineEdge}
          style={{
            backgroundColor:
              (colors && colors.pri) || DEFAULT_PROGRESS_BAR_COLORS.pri,
          }}
        ></div>

        <div className={classes.lineWrapper}>
          <div
            className={classes.line}
            style={{
              width: `${baseWidth + sectWidth}%`,
              backgroundColor:
                (colors && colors.pri) || DEFAULT_PROGRESS_BAR_COLORS.pri,
            }}
          ></div>
          {sections.map((section, idx) => (
            <div
              key={idx}
              className={classnames(classes.item, {
                'progress-bar__item--current': idx === sectionIdx,
              })}
              style={{
                flex: 1,
                color:
                  (colors && colors.pri) || DEFAULT_PROGRESS_BAR_COLORS.pri,
              }}
              onClick={() => {
                window.dispatchEvent(
                  new CustomEvent('navigateSection', {
                    detail: section.realIdx,
                  })
                );
              }}
            >
              <span
                className={classnames(classes.itemLabel, {
                  'progress-bar__item-label--current': idx === sectionIdx,
                })}
                style={{
                  borderColor:
                    (colors && colors.pri) || DEFAULT_PROGRESS_BAR_COLORS.pri,
                  color:
                    (colors && colors.sec) || DEFAULT_PROGRESS_BAR_COLORS.sec,
                }}
              >
                {section.progressBarName
                  ? section.progressBarName
                  : section.name}
              </span>
              {/* <div className={classes.underlay} /> */}
            </div>
          ))}
        </div>
        <div
          className={classes.lineEdge}
          style={{
            backgroundColor: 'transparent',
          }}
        ></div>
      </header>
    );
  }
}

export default ProgressBar;
