import React, { useRef, useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import { findDOMNode } from 'react-dom';

import getPossessivePronoun from '@/utils/getPossessivePronoun';
import getThirdPersonPronoun from '@/utils/getThirdPersonPronoun';
import getGenderPronoun from '@/utils/getGenderPronoun';

import Button from '@/components/Button';

import { useOutputSectionContext } from '@/contexts/OutputSection';

import './style.css';

const EthicalWill = ({
  lifeline: {
    data: {
      remembering: { sex, firstName, photo, lastWords },
      isLegacy,
    },
  },
  active: isActive,
  exiting: _,
  entering: __,
  ...props
}) => {
  const isHidden = lastWords.length > 450;
  const [fullText, showFullText] = useState(!isHidden);
  const [textFullHeight, setTextHeight] = useState(null);
  const textWallRef = useRef();
  let wall;

  const onResize = () => {
    wall.style.transition = `none`;
    wall.style.height = 'auto';

    requestAnimationFrame(() => {
      setTextHeight(wall.offsetHeight);

      wall.style.transition = ``;
      wall.style.height = ``;
    });
  };

  useEffect(() => {
    if (!textWallRef.current) {
      return;
    }

    wall = findDOMNode(textWallRef.current);

    onResize();
  }, [textWallRef]);

  const { updateScrollMetrics } = useOutputSectionContext();

  const onShowMore = useCallback(() => {
    showFullText(!fullText);
  }, [fullText]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const isCollapsed = !fullText;

  return (
    <div className="lifeline-ethical-will" {...props}>
      <p
        className={classnames(
          'lifeline-ethical-will__heading',
          'anim-slide-in',
          'anim-slide-in--delay-1',
          {
            'anim-slide-in--enter': isActive,
          }
        )}
      >
        <span>
          {isLegacy && (
            <>
              Our achievements teach us many things. If {firstName} could pass
              on one bit of advice it would be…
            </>
          )}

          {!isLegacy && (
            <>
              {getPossessivePronoun(sex)} achievements taught{' '}
              {getGenderPronoun(sex)} many things. If {firstName} could have
              taught the world anything {getThirdPersonPronoun(sex)} would have
              wanted us to...
            </>
          )}
        </span>
        <img
          className={classnames('anim-slide-in', 'anim-slide-in--delay-2', {
            'anim-slide-in--enter': isActive,
          })}
          src={photo.remoteUrl}
        />
      </p>
      <div
        className={classnames(
          'lifeline-ethical-will__text-wrapper',
          'anim-slide-in',
          'anim-slide-in--delay-3',
          {
            'anim-slide-in--enter': isActive,
            'lifeline-ethical-will__text-wrapper--collapsed': isCollapsed,
          }
        )}
      >
        <p
          style={
            textFullHeight && !isCollapsed
              ? { height: `${textFullHeight}px` }
              : {}
          }
          ref={textWallRef}
          className={classnames('lifeline-ethical-will__text', {
            'lifeline-ethical-will__text--collapsed': isCollapsed,
          })}
          onTransitionEnd={updateScrollMetrics}
        >
          {lastWords}
        </p>

        {isHidden && (
          <Button
            onClick={() => {
              onShowMore(updateScrollMetrics);
            }}
            size="small"
            variant="primary"
            icon={isCollapsed ? 'round-plus' : 'round-minus'}
            iconBackground="relationships"
          >
            {isCollapsed && `Show more`}
            {!isCollapsed && `Hide`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EthicalWill;
