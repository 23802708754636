const NEW_SECTION_REGEX = /^\/new\/(remembering|relationships|achievements|interests)(?:\/([\w-]+))?\/?/m;
const EDIT_SECTION_REGEX = /^\/edit\/([a-zA-Z0-9-]*)\/(remembering|relationships|achievements|interests)(?:\/([\w-]+))?\/?/m;

export default url => {
  if (!url) {
    return [null, null, null, null];
  }

  const newMatch = url.match(NEW_SECTION_REGEX);

  if (!newMatch) {
    const editMatch = url.match(EDIT_SECTION_REGEX);

    if (!editMatch) {
      return [null, null, null, null];
    }

    return [editMatch[2], editMatch[3], 'edit', editMatch[1]];
  }

  return [newMatch[1], newMatch[2] || '1', 'new', null];
};
