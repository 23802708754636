export default sex => {
  if (!sex || sex === 'x') {
    return 'them';
  }
  if (sex === 'f') {
    return 'her';
  }
  if (sex === 'm') {
    return 'him';
  }
};
