import React, { createRef, Component } from 'react';
import classnames from 'classnames';

import Circle from './Circle';

const CIRCLE_ENTER_INTERVAL = 500;

/**
 * Circles component
 */
class Circles extends Component {
  state = {
    interests: [],
  };

  constructor(props) {
    super(props);

    this.circlesContainer = createRef(null);
    this.raf = null;
    this.circles = [];
    this.t0 = 0;
  }

  componentDidMount() {
    const { interests } = this.state;
    this.circles = this.getCircles(interests);

    window.addEventListener('resize', this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.active && this.props.active) {
      this.circles.forEach(c => c.circle.reset());
      this.handleCirclesUpdate();
    } else if (prevProps.active && !this.props.active) {
      this.handleStopCirclesUpdate();
    }
  }

  static getDerivedStateFromProps(props) {
    const { interests: interestsObj } = props;

    const interests = Object.keys(interestsObj)
      .map(k => ({
        name: k,
        value: interestsObj[k],
      }))
      .filter(v => v.value > 1)
      .sort((a, b) => b.value - a.value)
      .slice(0, 7);

    return {
      interests,
    };
  }

  getCircles(interests) {
    return interests.map((interest, i) => ({
      ...interest,
      circle: new Circle(
        this.circlesContainer.current.children[i],
        this.circlesContainer.current,
        interest.value,
        i,
        interests.length
      ),
    }));
  }

  // handlers
  handleResize = () => {
    const circles = this.circles.map(c => c.circle);

    circles.forEach(c => {
      c.resize();
    });
  };
  handleCirclesUpdate = (t = 0) => {
    if (!this.t0 && t > 0) {
      this.circlesStartTime = t;
      this.t0 = t;
    }

    const dt = (t - this.t0) / 1000;
    this.t0 = t;

    const circles = this.circles
      .map(c => c.circle)
      .slice(0, Math.ceil((t - this.circlesStartTime) / CIRCLE_ENTER_INTERVAL));

    circles.forEach(c => {
      c.update(dt);
    });

    circles.forEach(c => {
      c.staticCollision(circles);
    });

    circles.forEach(c => {
      c.ballCollision(circles);
    });

    circles.forEach(c => {
      c.draw();
    });

    this.raf = requestAnimationFrame(this.handleCirclesUpdate);
  };
  handleStopCirclesUpdate = () => {
    if (this.raf) {
      this.t0 = 0;
      this.raf = cancelAnimationFrame(this.raf);
    }
  };

  // render
  render() {
    const { interests } = this.state;
    const { active: isActive } = this.props;

    const maxValue = interests.reduce((a, b) => (b.value > a ? b.value : a), 0);

    return (
      <div
        className={classnames(
          'lifeline-interests-details__circles',
          {
            'lifeline-interests-details__circles--visible': isActive,
          },
          `lifeline-interests-details__circles--count-${interests.length}`
        )}
      >
        <ul ref={this.circlesContainer}>
          {interests.map(c => (
            <li
              key={`interest--${c.name}`}
              style={{
                transform: 'translate(-100%, -50%) translateX(-2.1rem)',
              }}
              className={classnames(
                'lifeline-interests-details__circle',
                `lifeline-interests-details__circle--value-${c.value}`,
                {
                  'lifeline-interests-details__circle--main':
                    c.value === maxValue,
                  'lifeline-interests-details__circle--visible': c.isVisible,
                }
              )}
            >
              {c.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Circles;
