import apiFetch from '@/api/apiFetch';

export default async () => {
  const options = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  await apiFetch('me', options, 204);

  return {};
};
