import jwt_decode from 'jwt-decode';

import loginUser from '@/api/loginUser';
import registerUser from '@/api/registerUser';
import editUser from '@/api/editUser';
import getUser from '@/api/getUser';
import deleteUser from '@/api/deleteUser';
import getLifelineByUUID from '@/api/getLifelineByUUID';
import deleteLifelineByUUID from '@/api/deleteLifelineByUUID';
import publishLifeline from '@/api/publishLifeline';
import updateLifelinePrivacy from '@/api/updateLifelinePrivacy';

import cleanLifeline from '@/utils/cleanLifeline';

import { getState } from '@/store';

const isTokenValid = token => {
  const jwt = jwt_decode(token);
  if (jwt.exp === undefined) {
    return true;
  }

  const current_time = Date.now().valueOf() / 1000;
  return jwt.exp > current_time;
};

export default {
  state: {
    firstName: '',
    lastName: '',
    email: '',
    token: '',
    verified: false,
    legacy: null,
    lifelines: [],
    cookiePolicyAccepted: false,
  },

  reducers: {
    clear() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        token: '',
        verified: false,
        legacy: null,
        lifelines: [],
      };
    },

    set(state, payload) {
      return { ...state, ...payload };
    },

    addLifeline(state, lifeline) {
      return {
        ...state,
        lifelines: [
          ...state.lifelines,
          {
            ...lifeline,
            data: { ...cleanLifeline(lifeline.data) },
          },
        ],
      };
    },

    updateLifeline(state, lifeline) {
      const uuid = lifeline.uuid;
      const lifelines = [...state.lifelines];
      const index = lifelines.findIndex(l => l.uuid === uuid);
      lifelines[index] = lifeline;

      return { ...state, lifelines };
    },

    changeLifelineProperty(state, { uuid, key, value }) {
      const lifelines = [...state.lifelines];
      const index = lifelines.findIndex(l => l.uuid === uuid);
      lifelines[index] = {
        ...lifelines[index],
        [key]: value,
      };

      return { ...state, lifelines };
    },

    deleteLifeline(state, uuid) {
      const lifelines = state.lifelines.filter(function(lifeline) {
        return lifeline.uuid !== uuid;
      });
      return {
        ...state,
        lifelines: lifelines,
        legacy:
          !!state.legacy && state.legacy.uuid === uuid ? null : state.legacy,
      };
    },

    setCookiePolicyAccepted(state, value) {
      return {
        ...state,
        cookiePolicyAccepted: value,
      };
    },
  },

  effects: dispatch => ({
    async login(payload) {
      if (getState().app.loginOrSignupRunning) {
        return false;
      }

      dispatch.app.setLoginSignupRunningState(true);
      const editorState = getState().editor;

      if (!editorState.clean && editorState.lifelineUUID === null) {
        const editor = {
          remembering: editorState.remembering,
          relationships: editorState.relationships,
          achievements: editorState.achievements,
          interests: editorState.interests,
          isLegacy: !!editorState.isLegacy ? editorState.isLegacy : null, // **jc**
        };

        payload.lifelineData = editor;
      }

      let auth;

      try {
        auth = await loginUser(payload);
        dispatch.user.set({
          token: auth.token,
        });

        console.log('User logged in...', auth);

        if (auth.lifeline_uuid) {
          dispatch.editor.clearEditor();
        }

        const user = await getUser();
        dispatch.user.set({
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          verified: user.is_verified,
          lifelines: user.lifelines.map(l => ({
            ...l,
            data: { ...cleanLifeline(l.data) },
          })),
        });
      } catch (e) {
        throw e;
      } finally {
        dispatch.app.setLoginSignupRunningState(false);
      }

      dispatch.app.setLoginSignupRunningState(false);

      return auth;
    },

    async register(payload) {
      if (getState().app.loginOrSignupRunning) {
        return false;
      }

      dispatch.app.setLoginSignupRunningState(true);

      const editorState = getState().editor;

      if (!editorState.clean && editorState.lifelineUUID === null) {
        const editor = {
          isLegacy: editorState.isLegacy,
          remembering: editorState.remembering,
          relationships: editorState.relationships,
          achievements: editorState.achievements,
          interests: editorState.interests,
        };

        payload.lifelineData = editor;
      }

      let auth;

      try {
        auth = await registerUser(payload);
        dispatch.user.set({
          token: auth.token,
        });

        if (auth.lifeline_uuid) {
          dispatch.editor.clearEditor();
        }

        const user = await getUser();
        dispatch.user.set({
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          verified: user.is_verified,
        });
      } catch (e) {
        throw e;
      } finally {
        dispatch.app.setLoginSignupRunningState(false);
      }

      return auth;
    },

    async edit(payload) {
      // TODO: check if errors like token expired OR invalid token are handled

      const user = await editUser(payload);
      dispatch.user.set({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        verified: user.is_verified,
        legacy: user.legacy,
      });
    },

    async fetchLifelines(payload) {
      const user = await getUser();

      dispatch.user.set({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        verified: user.is_verified,
        legacy: user.legacy,
        lifelines: user.lifelines.map(l => ({
          ...l,
          data: { ...cleanLifeline(l.data) },
        })),
      });
    },

    async deactivate() {
      await deleteUser();
      dispatch.user.clear();
    },

    async deleteLifelineByUUID(uuid) {
      await deleteLifelineByUUID(uuid);
      dispatch.user.deleteLifeline(uuid);
    },

    async publishLifeline({ uuid, status }) {
      await dispatch.user.changeLifelineProperty({
        uuid,
        key: 'status',
        value: status,
      });

      const response = await publishLifeline(uuid, status);
      await dispatch.lifelines.remove(response.slug);
      dispatch.user.updateLifeline(response);
    },

    async changeLifelinePrivacy({ uuid, isPubliclySearchable }) {
      const response = await updateLifelinePrivacy(uuid, isPubliclySearchable);
      await dispatch.lifelines.remove(response.slug);
      dispatch.user.updateLifeline(response);
    },

    async updateLifelineProperty({ uuid, key, value }) {
      await dispatch.user.changeLifelineProperty({
        uuid,
        key: key,
        value: value,
      });
    },

    async fetchLifeline(uuid) {
      try {
        const lifeline = await getLifelineByUUID(uuid);

        if (!lifeline.is_owner) {
          throw new Error('Not found');
        }

        await dispatch.lifelines.remove(lifeline.slug);
        dispatch.user.updateLifeline(lifeline);
      } catch (error) {
        console.log('e is:', error);
      }
    },

    acceptCookiePolicy() {
      dispatch.user.setCookiePolicyAccepted(true);
    },
  }),

  selectors: {
    isLogged() {
      return state => !!state.user.token && isTokenValid(state.user.token);
    },

    isLoggedAndVerified() {
      return state =>
        state.user.token &&
        isTokenValid(state.user.token) &&
        state.user.verified;
    },

    isCookiePolicyAccepted() {
      return state => !!state.user.cookiePolicyAccepted;
    },
  },
};
