// TODO: Replace key with warren's account
export default () => {
  switch (process.env.CURRENT_ENV) {
    case 'local':
      return 'pk.eyJ1Ijoici1yb2xsIiwiYSI6ImNrMXVsa3IwZDB6eDUzZm51b2hheGM5cnAifQ.vVwfKx-kliea3XnKeJrMSg';
    case 'dev':
      return 'pk.eyJ1Ijoici1yb2xsIiwiYSI6ImNrMXVsa3IwZDB6eDUzZm51b2hheGM5cnAifQ.vVwfKx-kliea3XnKeJrMSg';
    case 'stage':
      return 'pk.eyJ1Ijoici1yb2xsIiwiYSI6ImNrMXVsa3IwZDB6eDUzZm51b2hheGM5cnAifQ.vVwfKx-kliea3XnKeJrMSg';
    case 'prod':
      return 'pk.eyJ1Ijoici1yb2xsIiwiYSI6ImNrMXVsa3IwZDB6eDUzZm51b2hheGM5cnAifQ.vVwfKx-kliea3XnKeJrMSg';
  }
};
