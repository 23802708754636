import React from 'react';
import classnames from 'classnames';

import capitalize from '@/utils/capitalize';

import Circles from './Circles';
import './style.css';

const InterestsDetails = ({
  lifeline,
  active: isActive,
  exiting: _,
  entering: __,
  'data-autoscroll-waypoint': ___,
  ...props
}) => {
  const {
    ownerFirstName,
    data: {
      remembering: { firstName, personalRelationship: ownerRelationship },
      interests: { interests, description, descriptionAuthor },
      isLegacy, // **jc**
    },
  } = lifeline;

  let descriptionAuthorName = null;
  let descriptionAuthorRelationship = null;
  if (description) {
    if (descriptionAuthor) {
      descriptionAuthorName = capitalize(descriptionAuthor.name, {
        lowercaseOtherLetters: true,
        ignorePrepositions: true,
      });
      descriptionAuthorRelationship = descriptionAuthor.relationship.toLowerCase();
    } else {
      descriptionAuthorName = capitalize(ownerFirstName, {
        lowercaseOtherLetters: true,
        ignorePrepositions: true,
      });
      descriptionAuthorRelationship = ownerRelationship
        ? ownerRelationship.toLowerCase()
        : ''; // **jc**
    }
  }

  return (
    <div
      className={classnames('lifeline-interests-details', {
        'lifeline-interests-details--no-description': !description,
      })}
      {...props}
    >
      <div
        className="lifeline-interests-details__waypoint"
        data-autoscroll-waypoint
      />
      <div className="lifeline-interests-details__inner">
        <Circles interests={interests} active={isActive} />

        {description && (
          <div
            className={classnames(
              'lifeline-interests-details__quote',
              'anim-slide-in',
              {
                'anim-slide-in--enter': isActive,
              }
            )}
          >
            <span className="lifeline-interests-details__quote-symbol">“</span>
            <div className="lifeline-interests-details__quote-wrapper">
              <blockquote>{description}</blockquote>
              <cite>
                {descriptionAuthorName}
                {!isLegacy /* **jc** */ && (
                  <>
                    , {firstName}’s {descriptionAuthorRelationship}
                  </>
                )}
              </cite>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterestsDetails;
