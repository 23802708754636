import { useMemo } from 'react';
import getInterestDescription from '@/utils/getInterestDescription';

export default (interests, interestsDescriptions, lifeline) =>
  useMemo(() => {
    const ii = Object.keys(interests)
      .map(k => ({
        name: k,
        value: interests[k],
        title: interestsDescriptions[k] || null,
        description:
          interestsDescriptions[k] &&
          getInterestDescription({
            interest: k,
            title: interestsDescriptions[k],
            scale: interests[k], // **jc**
            lifeline,
          }),
      }))
      .filter(i => !!i.title)
      .sort((a, b) => {
        return b.value - a.value;
      });

    if (ii.length > 3) {
      return ii.slice(0, 3);
    }

    return ii;
  }, [interests, interestsDescriptions]);
