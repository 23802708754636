const mobile = 375;
const tablet = 768;
const laptop = 1024;
const wide = 1200;
const bigScreen = 1441;
const hugeScreen = 1600;

const smallHeight = 850;

module.exports = {
  mobile,
  tablet,
  laptop,
  wide,
  bigScreen,

  smallHeight,

  customMedia: {
    '--small-height': `(max-height: ${smallHeight}px)`,

    '--mobile': `(min-width: ${mobile}px)`,
    '--tablet': `(min-width: ${tablet}px)`,
    '--laptop': `(min-width: ${laptop}px)`,
    '--wide': `(min-width: ${wide}px)`,
    '--big-screen': `(min-width: ${bigScreen}px)`,
    '--huge-screen': `(min-width: ${hugeScreen}px)`,
  },
};
