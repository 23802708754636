const adjectives = [
  { label: 'frank', color: '#E62F1F', group: 'bright', personality: null },
  { label: 'artistic', color: '#EE751F', group: 'pastel', personality: null },
  { label: 'reliable', color: '#F17E7E', group: 'pastel', personality: null },
  { label: 'joyful', color: '#FF5151', group: 'bright', personality: null },
  { label: 'happy', color: '#FF5151', group: 'bright', personality: null },
  { label: 'intuitive', color: '#FF5151', group: 'cool', personality: null },
  { label: 'young', color: '#FF5151', group: 'bright', personality: null },
  { label: 'intelligent', color: '#E62F1F', group: 'cool', personality: null },
  {
    label: 'perfectionist',
    color: '#FFB32C',
    group: 'cool',
    personality: null,
  },
  { label: 'powerful', color: '#E62F1F', group: 'bright', personality: null },
  { label: 'influential', color: '#EE751F', group: 'cool', personality: null },
  { label: 'comedian', color: '#FF9B25', group: 'bright', personality: null },
  { label: 'stubborn', color: '#E62F1F', group: 'cool', personality: null },
  { label: 'dedicated', color: '#F17E7E', group: 'pastel', personality: null },
  { label: 'sporty', color: '#E62F1F', group: 'bright', personality: null },
  { label: 'athletic', color: '#E62F1F', group: 'cool', personality: null },
  { label: 'honest', color: '#F17E7E', group: 'pastel', personality: null },
  {
    label: 'enthusiastic',
    color: '#F17E7E',
    group: 'pastel',
    personality: null,
  },
  { label: 'hard-working', color: '#FFB32C', group: 'cool', personality: null },
  { label: 'troubled', color: '#003698', group: 'pastel', personality: null },
  { label: 'complex', color: '#EE751F', group: 'cool', personality: null },
  { label: 'artist', color: '#EE751F', group: 'bright', personality: null },
  {
    label: 'adventurous',
    color: '#E62F1F',
    group: 'bright',
    personality: null,
  },
  { label: 'superhuman', color: '#FFB32C', group: 'bright', personality: null },
  {
    label: 'enlightening',
    color: '#FF5151',
    group: 'pastel',
    personality: null,
  },
  { label: 'famous', color: '#500064', group: 'cool', personality: null },
  { label: 'driven', color: '#FFB32C', group: 'cool', personality: null },
  { label: 'hilarious', color: '#FF9B25', group: 'bright', personality: null },
  { label: 'silly', color: '#FF9B25', group: 'bright', personality: null },
  { label: 'resourceful', color: '#E62F1F', group: 'cool', personality: null },
  {
    label: 'compassionate',
    color: '#FF5151',
    group: 'pastel',
    personality: null,
  },
  { label: 'talented', color: '#EE751F', group: 'cool', personality: null },
  { label: 'insightful', color: '#EE751F', group: 'pastel', personality: null },
  { label: 'polite', color: '#FF5151', group: 'pastel', personality: null },
  { label: 'inspiring', color: '#EE751F', group: 'pastel', personality: null },
  {
    label: 'inquisitive',
    color: '#FF9B25',
    group: 'bright',
    personality: null,
  },
  { label: 'cheerful', color: '#FF5151', group: 'bright', personality: null },
  { label: 'sensitive', color: '#003698', group: 'pastel', personality: null },
  { label: 'free', color: '#E62F1F', group: 'pastel', personality: null },
  { label: 'spiritual', color: '#0081DA', group: 'pastel', personality: null },
  { label: 'strong', color: '#FFB32C', group: 'bright', personality: null },
  { label: 'focused', color: '#FFB32C', group: 'cool', personality: null },
  { label: 'tough', color: '#FFB32C', group: 'cool', personality: null },
  { label: 'admired', color: '#E4406D', group: 'cool', personality: null },
  {
    label: 'thoughtful',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'attached',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'devoted',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'amiable',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'valued',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'considerate',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'reverential',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'fervent',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'warm-hearted',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'anxious',
    color: '#E4406D',
    group: 'pastel',
    personality: 'eccentric',
  },
  {
    label: 'amorous',
    color: '#E4406D',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'benevolent',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'admiring',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'loving',
    color: '#E4406D',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'sentimental',
    color: '#E4406D',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'worshipful',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'dear',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'caring',
    color: '#E4406D',
    group: 'pastel',
    personality: 'protective',
  },
  {
    label: 'enamored',
    color: '#E4406D',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'zealous',
    color: '#E4406D',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'respectful',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'infatuated',
    color: '#E4406D',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'reverent',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'passionate',
    color: '#E4406D',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'doting',
    color: '#E4406D',
    group: 'bright',
    personality: 'protective',
  },
  {
    label: 'earnest',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'faithful',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'affectionate',
    color: '#E4406D',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'attentive',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'romantic',
    color: '#E4406D',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'appreciative',
    color: '#E4406D',
    group: 'cool',
    personality: 'protective',
  },
  {
    label: 'erotic',
    color: '#E4406D',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'impassioned',
    color: '#E4406D',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'generous',
    color: '#E4406D',
    group: 'bright',
    personality: 'protective',
  },
  {
    label: 'expressive',
    color: '#E4406D',
    group: 'bright',
    personality: 'protective',
  },
  { label: 'friendly', color: '#F17E7E', group: 'bright', personality: null },
  { label: 'good', color: '#F17E7E', group: 'pastel', personality: null },
  { label: 'solicitous', color: '#F17E7E', group: 'bright', personality: null },
  {
    label: 'fond',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'tender',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'welcoming',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'beneficial',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'kindly',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'loyal',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'sympathetic',
    color: '#F17E7E',
    group: 'cool',
    personality: 'altruistic',
  },
  {
    label: 'civil',
    color: '#F17E7E',
    group: 'cool',
    personality: 'altruistic',
  },
  {
    label: 'outgoing',
    color: '#F17E7E',
    group: 'cool',
    personality: 'sociable',
  },
  {
    label: 'helpful',
    color: '#F17E7E',
    group: 'cool',
    personality: 'altruistic',
  },
  {
    label: 'peaceful',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'comradely',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'familiar',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'confiding',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'kind',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'well-disposed',
    color: '#F17E7E',
    group: 'pastel',
    personality: 'altruistic',
  },
  {
    label: 'approachable',
    color: '#FF5151',
    group: 'pastel',
    personality: 'sociable',
  },
  { label: 'warm', color: '#FF5151', group: 'pastel', personality: 'sociable' },
  {
    label: 'neighborly',
    color: '#FF5151',
    group: 'pastel',
    personality: 'sociable',
  },
  { label: 'social', color: '#FF5151', group: 'cool', personality: 'sociable' },
  { label: 'close', color: '#FF5151', group: 'cool', personality: 'sociable' },
  {
    label: 'intimate',
    color: '#FF5151',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'cordial',
    color: '#FF5151',
    group: 'pastel',
    personality: 'sociable',
  },
  {
    label: 'good-natured',
    color: '#FF5151',
    group: 'pastel',
    personality: 'sociable',
  },
  {
    label: 'sociable',
    color: '#FF5151',
    group: 'bright',
    personality: 'sociable',
  },
  {
    label: 'chatty',
    color: '#FF5151',
    group: 'bright',
    personality: 'sociable',
  },
  {
    label: 'optimist',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'headstrong',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'willful',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'self-willed',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'impulsive',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'heedless',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'unruly',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'foolhardy',
    color: '#FFB32C',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'hard-core',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'determined',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'strong-minded',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'strong-willed',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  {
    label: 'hard-nosed',
    color: '#FFB32C',
    group: 'bright',
    personality: 'leadership',
  },
  { label: 'low-key', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'restful', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'tranquil', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'soothing', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'calm', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'serene', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'smooth', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'hushed', color: '#0081DA', group: 'pastel', personality: 'calm' },
  {
    label: 'breathless',
    color: '#0081DA',
    group: 'pastel',
    personality: 'calm',
  },
  { label: 'still', color: '#0081DA', group: 'pastel', personality: 'calm' },
  {
    label: 'cool',
    color: '#0081DA',
    group: 'pastel',
    personality: 'charismatic',
  },
  { label: 'rural', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'slow', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'mild', color: '#0081DA', group: 'pastel', personality: 'calm' },
  { label: 'placid', color: '#0081DA', group: 'pastel', personality: 'calm' },
  {
    label: 'freakish',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'oddball',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'quizzical',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'bizarre',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'wild',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'peculiar',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  { label: 'odd', color: '#FF9B25', group: 'cool', personality: 'eccentric' },
  { label: 'weird', color: '#FF9B25', group: 'cool', personality: 'eccentric' },
  {
    label: 'quaint',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'offbeat',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  { label: 'kooky', color: '#FF9B25', group: 'cool', personality: 'eccentric' },
  {
    label: 'strange',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'off-center',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  { label: 'nutty', color: '#FF9B25', group: 'cool', personality: 'eccentric' },
  {
    label: 'quirky',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'idiosyncratic',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'unconventional',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'abnormal',
    color: '#FF9B25',
    group: 'cool',
    personality: 'eccentric',
  },
  {
    label: 'capricious',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'curious',
    color: '#FF9B25',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'whimsical',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'queer',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'outlandish',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'funny',
    color: '#FF9B25',
    group: 'bright',
    personality: 'sociable',
  },
  {
    label: 'crazy',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'funky',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'eccentric',
    color: '#FF9B25',
    group: 'bright',
    personality: 'eccentric',
  },
  {
    label: 'inventive',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'visionary',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'stimulating',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'creative',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'innovative',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'prolific',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'clever',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'bright',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'original',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'ingenious',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'gifted',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'inspired',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  { label: 'hip', color: '#EE751F', group: 'bright', personality: 'innovator' },
  {
    label: 'remarkable',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'genial',
    color: '#EE751F',
    group: 'bright',
    personality: 'innovator',
  },
  {
    label: 'cheeky',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'intrepid',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'brave',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'smart',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'obtrusive',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'reckless',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'spunky',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'fearless',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'daring',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'gutsy',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'valiant',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'gritty',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'temerarious',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'plucky',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'audacious',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'courageous',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'bold',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'forward',
    color: '#E62F1F',
    group: 'bright',
    personality: 'entrepreneur',
  },
  {
    label: 'impudent',
    color: '#E62F1F',
    group: 'pastel',
    personality: 'eccentric',
  },
  {
    label: 'introvert',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'introverted',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'distrustful',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'nervous',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'bashful',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'diffident',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  { label: 'shy', color: '#003698', group: 'pastel', personality: 'reserved' },
  {
    label: 'demure',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'timid',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'sheepish',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'introverted',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'backward',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  { label: 'coy', color: '#003698', group: 'pastel', personality: 'reserved' },
  {
    label: 'apprehensive',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'cautious',
    color: '#003698',
    group: 'pastel',
    personality: 'reserved',
  },
  {
    label: 'discreet',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'unassured',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'humble',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'reserved',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'nice',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'seemly',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'modest',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'quiet',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'moderate',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'proper',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'self-conscious',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'simple',
    color: '#37BFD1',
    group: 'pastel',
    personality: 'thoughtful',
  },
  {
    label: 'unassuming',
    color: '#500064',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'charming',
    color: '#500064',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'magnificent',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'shapely',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'refined',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'pleasing',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'sublime',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'fine',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'comely',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'wonderful',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'appealing',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'exquisite',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'gorgeous',
    color: '#500064',
    group: 'cool',
    personality: 'charismatic',
  },
  {
    label: 'sightly',
    color: '#500064',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'fair',
    color: '#500064',
    group: 'pastel',
    personality: 'charismatic',
  },
  {
    label: 'lovely',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'foxy',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'good-looking',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'handsome',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'bewitching',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'ideal',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'splendid',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'ravishing',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'elegant',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'stunning',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'pretty',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'graceful',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'classy',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'cute',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'fascinating',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'resplendent',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'enticing',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'angelic',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'beautiful',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'superb',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'marvelous',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'excellent',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'dazzling',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'beauteous',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'delightful',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'radiant',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'grand',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'divine',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'alluring',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'admirable',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'delicate',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'amazing',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
  {
    label: 'extraordinary',
    color: '#500064',
    group: 'bright',
    personality: 'charismatic',
  },
];

export default adjectives;

export const adjectivesNames = adjectives.map(a => a.label);

export const defaultColor = '#18A0A3';
export const defaultColorGroup = 'bright';

export const getColor = word => {
  const lowerWord = word.toLowerCase();
  const result = adjectives.find(a => a.label === lowerWord);

  if (!result) {
    return defaultColor;
  }

  return result.color;
};

export const getColorGroup = word => {
  const lowerWord = word.toLowerCase();
  const result = adjectives.find(a => a.label === lowerWord);

  if (!result) {
    return defaultColorGroup;
  }

  return result.group;
};

export const getPersonality = word => {
  const lowerWord = word.toLowerCase();
  const result = adjectives.find(a => a.label === lowerWord);

  if (!result) {
    return null;
  }

  return result.personality;
};
