import React, { PureComponent, createRef } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Svg from '@/components/Svg';
import Input from '@/components/Input';

import './style.css';

export default class Select extends PureComponent {
  static defaultProps = {
    options: [],
    style: 'manual',
    type: 'text',
    variant: 'white',
    onKeyDown: () => null,
    onBlur: () => null,
    onFocus: () => null,
    ignoreMatchedOption: false,
    reverseOptions: false,
    isValid: _ => undefined,
    getErrorMessage: _ => undefined,
  };

  static getDerivedStateFromProps(props, state) {
    const { options: inputOptions, reverseOptions, value, style } = props;
    const { dropdownSelectedOption, dropdownOpened } = state;

    function isValueInOption(value, option) {
      const valueLower = value.toLowerCase();
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(valueLower)) {
        return true;
      }

      const filterOption = opt => isValueInOption(value, opt);
      const words = option.split(' ');
      if (words.length > 1 && words.findIndex(filterOption) !== -1) {
        return true;
      }

      return false;
    }

    function filterMatchedOption(option) {
      const filterOption = opt => isValueInOption(value, opt);
      return Array.isArray(option)
        ? option.findIndex(filterOption) !== -1
        : filterOption(option);
    }

    function filterDropdownSelectedOption(option) {
      if (Array.isArray(option)) {
        const filterOption = opt =>
          isValueInOption(dropdownSelectedOption, opt);
        return option.findIndex(filterOption) === -1;
      }
      return option !== dropdownSelectedOption;
    }

    function getActiveRowIndex(options, match) {
      let dropdownActiveRow = 0;

      for (let index = 0; index < options.length; index++) {
        const opt = options[index];
        const optList = Array.isArray(opt) ? opt : [opt];

        for (const optVal of optList) {
          if (match(optVal)) {
            return index;
          }
        }
      }

      return dropdownActiveRow;
    }

    function isDropdownVisible(options) {
      if (style === 'manual') {
        return dropdownOpened;
      }

      if (!value || (value ? value.length : 0) === 0) {
        // **jc**
        return false;
      } else if (options.length === 0) {
        return false;
      } else if (options.length === 1 && options[0] === value) {
        return false;
      }

      return dropdownOpened;
    }

    let options = reverseOptions
      ? inputOptions.slice().reverse()
      : inputOptions;

    if (!props.ignoreMatchedOption && dropdownSelectedOption !== undefined) {
      options = [
        dropdownSelectedOption,
        ...options.filter(filterDropdownSelectedOption),
      ];
    } else if (value === undefined || value === '') {
      state.dropdownActiveRow = 0;
    } else if (style !== 'manual') {
      options = options.filter(filterMatchedOption);
    } else {
      const valueLower =
        props.type === 'number'
          ? parseInt(value.toLowerCase(), 10)
          : value.toLowerCase();

      state.dropdownActiveRow = getActiveRowIndex(
        options,
        opt => opt.toLowerCase() === valueLower
      );

      // check initial if not perfect match
      if (state.dropdownActiveRow === 0) {
        state.dropdownActiveRow = getActiveRowIndex(options, opt =>
          opt.toLowerCase().startsWith(`${valueLower}`)
        );
      }
    }

    const dropdownVisible = isDropdownVisible(options);

    return { ...state, options, dropdownVisible, onSelect: false };
  }

  state = {
    focused: false,
    showError: false,
    dropdownActiveRow: 0,
    dropdownOpened: false,
    dropdownVisible: false,
    dropdownSelectedOption: undefined,
  };

  select = createRef();

  componentDidUpdate(prevProps) {
    const { ignoreMatchedOption, style } = this.props;

    if (
      ignoreMatchedOption &&
      style === 'manual' &&
      this.select.current &&
      this.props.value !== prevProps.value
    ) {
      const opt = this.select.current.querySelector('li.highlighted');
      if (opt) {
        opt.scrollIntoView();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.hideDropdown);
    window.removeEventListener('inputActive', this.onSelectOpened);
  }

  selectOption = (option, e) => {
    const { onChange, onBlur, style } = this.props;

    onChange(option);
    onBlur(e);

    let newState = {
      showError: false,
      dropdownActiveRow: -1,
      focused: true,
    };

    if (style === 'manual') {
      newState = {
        ...newState,
        dropdownActiveRow: 0,
        dropdownOpened: false,
        dropdownSelectedOption: option,
      };
    }

    this.setState(newState);
  };

  onKeyPress = e => {
    const { value, style, onChange } = this.props;
    const { dropdownActiveRow, options, dropdownOpened } = this.state;

    if (e.key === 'Enter' && options.length < 1) {
      e.preventDefault();
      return;
    }

    if (
      options.length > 0 &&
      ((value ? value.length : 0) > 0 || style === 'manual') && // **jc**
      e.key === 'Enter'
    ) {
      if (dropdownActiveRow === -1) {
        e.preventDefault();
        return;
      }

      if (!dropdownOpened) {
        return;
      }

      const option = this.getOptionValue(options[dropdownActiveRow]);
      onChange(option);

      this.setState({
        dropdownActiveRow: 0,
        dropdownOpened: false,
        dropdownSelectedOption: option,
        onSelect: true,
      });

      e.preventDefault();
    }
  };

  onKeyDown = e => {
    // this handler is needed to managing the tab behaviour, that doesn't fire onPressed/onKeyUp events
    const { value, style, onChange, onKeyDown } = this.props;
    const { dropdownActiveRow, options, dropdownOpened } = this.state;

    if (onKeyDown) {
      onKeyDown(e);
    }

    if (
      options.length > 0 &&
      ((value ? value.length : 0) > 0 || style === 'manual') && // **jc**
      e.key === 'Tab'
    ) {
      if (dropdownActiveRow === -1) {
        // if now items is selected return
        return;
      }

      if (!dropdownOpened) {
        // if the dropdown is already closed return
        return;
      }

      const option = this.getOptionValue(options[dropdownActiveRow]);
      onChange(option);

      this.setState({
        dropdownActiveRow: 0,
        dropdownOpened: false,
        dropdownSelectedOption: option,
        onSelect: true,
      });
    }
  };

  onKeyUp = e => {
    const { dropdownActiveRow, options } = this.state;
    const { onChange } = this.props;

    let newActiveRow = dropdownActiveRow;

    switch (e.key) {
      case 'ArrowDown':
        if (dropdownActiveRow >= options.length - 1) {
          return;
        }
        e.preventDefault();
        newActiveRow = dropdownActiveRow + 1;
        onChange(this.getOptionValue(options[newActiveRow]));

        break;

      case 'ArrowUp':
        if (dropdownActiveRow < 1) {
          return;
        }
        e.preventDefault();
        newActiveRow = dropdownActiveRow - 1;
        onChange(this.getOptionValue(options[newActiveRow]));
        break;
    }
  };

  onInputChange = e => {
    this.props.onChange(e.target.value);

    const wasDropdownOpened = this.state.dropdownOpened;
    if (!wasDropdownOpened) {
      window.dispatchEvent(new CustomEvent('inputActive'));
    }

    this.setState(
      {
        dropdownSelectedOption: undefined,
        dropdownOpened: wasDropdownOpened,
        dropdownActiveRow: -1,
      },
      () => {
        if (!wasDropdownOpened) {
          window.addEventListener('inputActive', this.onSelectOpened);
          window.addEventListener('click', this.hideDropdown);
        }
      }
    );
  };

  toggleDropdown = e => {
    if (!this.state.dropdownOpened) {
      window.dispatchEvent(new CustomEvent('inputActive'));

      this.setState({ dropdownOpened: true }, () => {
        window.addEventListener('inputActive', this.onSelectOpened);
        window.addEventListener('click', this.hideDropdown);
      });
    } else {
      this.setState({ dropdownOpened: false });
    }
  };

  hideDropdown = e => {
    const parentNode = this.select.current;
    if (parentNode.contains(e.target)) {
      return;
    }

    this.setState({ dropdownOpened: false });
  };

  showDropdown = () => {
    window.dispatchEvent(new CustomEvent('inputActive'));

    this.setState({ dropdownOpened: true }, () => {
      window.addEventListener('inputActive', this.onSelectOpened);
      window.addEventListener('click', this.hideDropdown);
    });
  };

  onSelectOpened = () => {
    window.removeEventListener('inputActive', this.onSelectOpened);
    window.removeEventListener('click', this.hideDropdown);
    this.setState({ dropdownOpened: false });
  };

  getOptionValue(option) {
    return Array.isArray(option) ? option[0] : option;
  }

  _isAllowedValue = value => {
    const { options, type } = this.props;

    if (!value) {
      return false;
    }

    const allOptions = options.flat().map(v => v.toLowerCase());

    let lowerValue = value.toLowerCase();
    if (type == 'number') {
      lowerValue = parseInt(lowerValue).toString();
    }

    return allOptions.includes(lowerValue);
  };

  onBlur = () => {
    if (!this.state.focused) {
      return;
    }

    const { freeText } = this.props;

    if (freeText) {
      this.setState({ showError: false, focused: false });
    } else {
      this.setState({
        showError: !this._isAllowedValue(this.props.value),
        focused: false,
      });
    }
  };

  onFocus = () => {
    if (this.state.focused) {
      return;
    }
    this.setState({ showError: false, focused: true });
    this.showDropdown();
  };

  isValid = value => {
    let valid = true;

    if (!this.state.dropdownVisible) {
      const { freeText } = this.props;

      valid = this.props.isValid(value);
      if (valid === undefined && !freeText) {
        valid = this._isAllowedValue(value);
      }
    }

    return valid;
  };

  render() {
    const {
      className: inputClassName,
      style,
      variant,
      validateError,
      ignoreMatchedOption: _,
      options: __,
      freeText: ___,
      reverseOptions: ____,
      type: _____,
      ...rest
    } = this.props;

    const disabled = rest.disabled; // *jc**

    const {
      dropdownActiveRow,
      options,
      showError,
      dropdownVisible,
    } = this.state;

    let min = options[0];
    let max = options.slice(-1).pop();

    if (Array.isArray(min)) {
      min = min[0];
    }
    if (Array.isArray(max)) {
      max = max[0];
    }

    const className = classnames(
      'select',
      {
        'select--manual': style === 'manual',
        'select--autocomplete': style === 'autocomplete',
        'select--smalt': variant === 'smalt' || variant === 'alt-smalt',
        'select--black': variant === 'black',
      },
      inputClassName
    );

    return (
      <div
        className={className}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        ref={this.select}
      >
        <Input
          {...rest}
          type="text"
          variant={variant}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          onKeyPress={this.onKeyPress}
          onChange={this.onInputChange}
          min={min}
          max={max}
          validateError={validateError || showError}
          isValid={this.isValid}
        >
          <CSSTransition
            in={dropdownVisible}
            classNames="select--autocomplete-box-"
            timeout={{
              enter: 200,
              exit: 0,
            }}
            unmountOnExit
          >
            <ul className="select--autocomplete-box">
              {options.map((option, i) => (
                <li
                  onClick={
                    disabled
                      ? null
                      : this.selectOption.bind(
                          this,
                          this.getOptionValue(option)
                        ) /* **jc** */
                  }
                  className={classnames({
                    highlighted: dropdownActiveRow === i,
                  })}
                  key={this.getOptionValue(option)}
                >
                  {this.getOptionValue(option)}
                </li>
              ))}
            </ul>
          </CSSTransition>
        </Input>

        {style === 'manual' && (
          <Svg
            onClick={this.toggleDropdown}
            className="select--arrow"
            name="down-arrow"
          />
        )}
      </div>
    );
  }
}
