export default () => {
  switch (process.env.CURRENT_ENV) {
    case 'local':
      return '';
    case 'dev':
      return 'https://caf80f3cc191479e80b25ad648977f4b@sentry.io/1859658';
    case 'stage':
      return 'https://caf80f3cc191479e80b25ad648977f4b@sentry.io/1859658';
    case 'prod':
      return 'https://caf80f3cc191479e80b25ad648977f4b@sentry.io/1859658';
  }
};
