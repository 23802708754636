import apiFetch from '@/api/apiFetch';

export default async ({
  email,
  firstName,
  lastName,
  passwordCurrent,
  passwordNew,
}) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify({
      email,
      first_name: firstName,
      last_name: lastName,
      current_password: passwordCurrent,
      password: passwordNew,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch('me', options, 200);

  return await response.json();
};
