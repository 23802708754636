export default sex => {
  if (!sex || sex === 'x') {
    return 'they';
  }
  if (sex === 'f') {
    return 'she';
  }
  if (sex === 'm') {
    return 'he';
  }
};
