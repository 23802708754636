export default (element, ancestor) => {
  let cursor = element;
  let offsetTop = 0;

  while (cursor !== ancestor && cursor !== document.body) {
    offsetTop += cursor.offsetTop;
    cursor = cursor.offsetParent;
  }

  if (cursor === document.body && ancestor !== document.body) {
    console.error(
      'getOffsetTopInAncestor: given ancestor is not in offsetParent chain. Using offset top in body.',
      ancestor
    );
  }

  return offsetTop;
};
