import React, { Component } from 'react';

import Button from '@/components/Button';

import './style.css';

export default class About extends Component {
  close = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div className="about">
        <div className="about__panel">
          <div className="about__panel__background"></div>
          <div className="about__panel__close" onClick={this.close}></div>

          <h1>About us</h1>

          <p>
            The idea for Memories.com was born following the death of founder
            Asi Ohana’s grandmother. Asi was living thousands of miles away from
            the rest of his family at the time and was unable to celebrate her
            life in the company of loved ones. After consulting with friends and
            family, he learned that his story was not uncommon. So he created an
            online platform in which members of virtually any community can come
            together to share stories, photos, videos and love – regardless of
            location.
          </p>

          <Button
            size="large"
            variant="tertiary"
            to="/add-lifeline"
            onClick={this.close}
          >
            Start creating a lifeline
          </Button>
        </div>
      </div>
    );
  }
}
