import React, { Component } from 'react';
import classnames from 'classnames';

const ICONS = {
  'plane-blue': require('#/images/plane-blue.svg').default,
  'desk-blue': require('#/images/desk-blue.svg').default,
  'check-blue': require('#/images/check-blue.svg').default,
  'info-blue': require('#/images/info-blue.svg').default,
  'info-green': require('#/images/info-green.svg').default,

  'round-plus': require('#/images/round-plus.svg').default,
  'round-plus-green': require('#/images/round-plus-green.svg').default,
  calendar: require('#/images/calendar.svg').default,
  arrow: require('#/images/arrow.svg').default,
  'white-arrow': require('#/images/arrow-white.svg').default,
  'down-arrow': require('#/images/down-arrow.svg').default,
  cross: require('#/images/cross-white.svg').default,
  'big-cross': require('#/images/cross-white-big.svg').default,
  'cross-black': require('#/images/cross-black.svg').default,
  'check-green': require('#/images/check-green.svg').default,
  check: require('#/images/check-no-bg.svg').default,
  crop: require('#/images/crop.svg').default,
  rotate: require('#/images/rotate.svg').default,
  tick: require('#/images/tick.svg').default,
  'round-tick': require('#/images/round-tick.svg').default,
  'round-minus': require('#/images/round-minus.svg').default,
  alert: require('#/images/alert-red.svg').default,

  profile_placeholder: require('#/images/profile-placeholder.svg').default,

  'navbar-icon': require('#/images/navbar-icon.svg').default,
  'navbar-icon-white': require('#/images/navbar-icon-white.svg').default, // **jc**

  'burger-menu': require('#/images/burger-menu.svg').default,
  'loading-shape': require('#/images/loading-shape.svg').default,
  loader_color: require('#/images/loader_color.svg').default,
  'loading-shape__blue': require('#/images/loading-shape__blue.svg').default,

  twitter: require('#/images/twitter-blue.svg').default,
  'twitter-white': require('#/images/twitter-white.svg').default,
  facebook: require('#/images/facebook-blue.svg').default,
  'facebook-white': require('#/images/facebook-white.svg').default,
  linkedin: require('#/images/linkedin-blue.svg').default,
  'linkedin-white': require('#/images/linkedin-white.svg').default,

  // **jc**
  twitter_white_48: require('#/images/twitter_white_48.svg').default,
  twitter_green_48: require('#/images/twitter_green_48.svg').default,
  facebook_white_48: require('#/images/facebook_white_48.svg').default,
  facebook_green_48: require('#/images/facebook_green_48.svg').default,
  linkedin_white_48: require('#/images/linkedin_white_48.svg').default,
  linkedin_green_48: require('#/images/linkedin_green_48.svg').default,

  footer_logo: require('#/images/footer-logo.svg').default,
  twitter_blue: require('#/images/twitter_blue.svg').default,
  twitter_green: require('#/images/twitter_green.svg').default,
  facebook_blue: require('#/images/facebook_blue.svg').default,
  facebook_green: require('#/images/facebook_green.svg').default,
  linkedin_blue: require('#/images/linkedin_blue.svg').default,
  linkedin_green: require('#/images/linkedin_green.svg').default,
  'tag-arrow': require('#/images/tag_arrow.svg').default,

  'facebook-login': require('#/images/facebook-login.svg').default,
  'google-login': require('#/images/google-login.svg').default,
  messenger: require('#/images/messenger.svg').default,

  /* military badges */
  'military-badge': require('#/images/military-badge.svg').default,

  /* seasons */
  wintry: require('#/images/wintry.svg').default,
  spring: require('#/images/spring.svg').default,
  summery: require('#/images/summery.svg').default,
  autumn: require('#/images/autumn.svg').default,

  tagEdit: require('#/images/edit-tag.svg').default,
  tagEmail: require('#/images/email-tag.svg').default,
  tagDelete: require('#/images/delete-tag.svg').default,

  flower: require('#/images/flower.svg').default,
  'play-toggle': require('#/images/play-toggle.svg').default, // **jc**

  play: require('#/images/play.svg').default,
  download: require('#/images/download.svg').default,
  music: require('#/images/music.svg').default,
  'music-control': require('#/images/music-control.svg').default,
  'music-pause': require('#/images/music-pause.svg').default,
  'music-play': require('#/images/music-play.svg').default,

  /* pricing */ 'video-pink': require('#/images/video-pink.svg').default,
  'video-blue': require('#/images/video-blue.svg').default,
  'search-pink': require('#/images/search-pink.svg').default,
  'link-pink': require('#/images/link-pink.svg').default,
};

export default class Svg extends Component {
  render() {
    const { className: inputClassName, name, ...props } = this.props;
    const Component = ICONS[name];

    if (!Component) {
      console.error('SVG:', name, 'not found.');
      return null;
    }

    const className = classnames('svg', `svg--${name}`, inputClassName);

    return (
      <span className={className} {...props}>
        <Component />
      </span>
    );
  }
}
