import React, { Component } from 'react';

import './style.css';

class PartLabel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, color } = this.props;

    return (
      <div className="lifeline-family--label">
        <h3 style={{ color }}>{children}</h3>
      </div>
    );
  }
}

export default PartLabel;
