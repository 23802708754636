import React, { Component, cloneElement } from 'react';
import Modal from '@/components/Modal';

import SimpleModal from '@/components/SimpleModal';

export default class DashboardCTA extends Component {
  static defaultProps = {
    modal: null,
    trigger: null,
    acceptLabel: 'Yes, delete it',
    declineLabel: 'No, don’t delete it',
    modalSize: 'auto',
  };
  state = {
    modalVisible: false,
  };

  toggleModal = e => {
    if (e) {
      e.preventDefault();
    }

    this.setState(state => ({
      modalVisible: !state.modalVisible,
    }));
  };

  render() {
    const { modalVisible } = this.state;
    const {
      children,
      className,
      title,
      subtitle,
      onAccept,
      onDecline,
      modal: NestedModal,
      trigger,
      acceptLabel,
      declineLabel,
      modalSize,
      disabled,
    } = this.props;

    return (
      <>
        {!trigger && (
          <a onClick={this.toggleModal} className={className} href={'#'}>
            {children}
          </a>
        )}
        {trigger &&
          React.cloneElement(trigger, {
            onClick: !disabled ? this.toggleModal : null,
          })}
        <Modal
          size={modalSize}
          onClose={this.toggleModal}
          visible={modalVisible}
        >
          {NestedModal !== null &&
            cloneElement(NestedModal, {
              toggleModal: this.toggleModal,
            })}
          {NestedModal === null && (
            <SimpleModal
              title={title}
              subtitle={subtitle}
              acceptLabel={acceptLabel}
              declineLabel={declineLabel}
              onAccept={e => {
                e.preventDefault();
                this.toggleModal(e);

                if (onAccept) {
                  onAccept(e);
                }
              }}
              onDecline={e => {
                e.preventDefault();
                this.toggleModal(e);

                if (onDecline) {
                  onDecline(e);
                  console.log('onDecline');
                }
              }}
            />
          )}
        </Modal>
      </>
    );
  }
}
