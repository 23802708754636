export const OPTIONS = {
  'Military Army': 'military-badge',
  Police: 'military-badge',
  Firefighter: 'military-badge',
  Construction: 'military-badge',
  'Marine Corps': 'military-badge',
  Navy: 'military-badge',
  'Air Force': 'military-badge',
  'Space Force': 'military-badge',
  'Coast Guard': 'military-badge',
  'PHS Commissioned Corps': 'military-badge',
  NOAA: 'military-badge',
};

export const OPTIONS_LABELS = Object.keys(OPTIONS);
