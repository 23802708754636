export default () => {
  switch (process.env.CURRENT_ENV) {
    case 'local':
      return 'http://localhost:8000/';
    case 'dev':
      return 'https://memories.dev.rollstudio.it/';
    case 'stage':
      return 'https://memories-stage.dev.rollstudio.it/';
    case 'prod':
      return 'https://www.memories.com/';
  }
};
