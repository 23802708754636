import React from 'react';
import Svg from '@/components/Svg';
import { OPTIONS } from '@/components/LifelineEditor/Remembering/ArmedForces/options';

import classnames from 'classnames';

import './style.css';

const Intro = ({
  lifeline,
  showBadge,
  active: isActive,
  exiting: _,
  entering: __,
  ...props
}) => {
  const { data } = lifeline;

  const funDescriptions =
    data.remembering &&
    data.remembering.funDescriptions &&
    data.remembering.funDescriptions
      .map(v => (typeof v === 'object' ? v.label : v))
      .filter(v => !!v);

  return (
    <div className="lifeline-intro" id="lifeline-intro" {...props}>
      <div className="lifeline-intro__photo">
        <div className="lifeline-intro__photo__wrapper">
          <img
            src={data.remembering.photo ? data.remembering.photo.remoteUrl : ''}
            className="anim-slide-in"
          />
          {data.remembering.military && (
            <div
              className={classnames('lifeline-intro__military', {
                'lifeline-intro__military--visible': showBadge && isActive,
              })}
            >
              <Svg
                name={
                  OPTIONS[data.remembering.militaryCategory] || 'military-badge'
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="lifeline-intro__adjectives__wrapper">
        {funDescriptions && (
          <ul className="lifeline-intro__adjectives">
            {funDescriptions.map((v, index) => (
              <li key={`${v}-${index}`}>
                <h3
                  className={`lifeline-intro__adjective anim-slide-in`}
                  style={{
                    '--slide-in-delay': `${index * 0.2}s`,
                  }}
                >
                  {v}.
                </h3>
              </li>
            ))}
          </ul>
        )}
        <div
          className="lifeline-intro__copy anim-slide-in"
          style={{
            '--slide-in-delay': `${funDescriptions.length * 0.2}s`,
          }}
        >
          <span>
            {data.remembering.firstName} {data.remembering.lastName}
          </span>{' '}
          is all of these things, and a million more.
        </div>
      </div>
    </div>
  );
};

export default Intro;
