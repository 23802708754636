import React, { createRef, Component } from 'react';
import classnames from 'classnames';
import ScrollBooster from 'scrollbooster';

import { Provider as ContextProvider } from '@/contexts/OutputSection';

import './style.css';

export const removeAnim = el => {
  const callback = () => {
    el.classList.remove('anim-slide-in', 'anim-slide-in--enter');
    el.removeEventListener('transitionend', callback);
  };

  el.addEventListener('transitionend', callback);
};

class Section extends Component {
  constructor(props) {
    super(props);

    this.inner = createRef();
    this._isMounted = false;

    this.observer = null;
    this.animTargets = [];
  }

  state = {
    updateScrollMetrics: f => f,
  };

  componentDidMount() {
    const {
      innerRef,
      active: isActive,
      entering: isEntering,
      sectionName,
    } = this.props;

    this._isMounted = true;

    if (sectionName !== 'decades') {
      this.sb = new ScrollBooster({
        viewport: innerRef.current,
        content: this.inner.current,
        bounce: false,
        textSelection: false,
        onUpdate: data => {
          if (!this._isMounted) {
            return;
          }

          if (!innerRef.current) {
            /*
              should never happen, but if somehow ScrollBooster gets called when
              react is recreating the dom (so the ref is null) we do not crash
            */
            return;
          }

          innerRef.current.scrollTop = data.position.y;
        },
      });

      // DEV: Uncomment this to manual call updateMetrics for testing
      // if (!window.updateScrollMetrics) {
      //   window.updateScrollMetrics = {};
      // }

      // window.updateScrollMetrics[this.props.sectionName] = () =>
      //   this.sb.updateMetrics();

      this.setState({
        updateScrollMetrics: () => {
          if (this._isMounted) {
            this.sb.updateMetrics();
          }
        },
      });

      window.addEventListener('resize', this.handleResize);
      if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', this.handleResize);
      }
      setTimeout(() => {
        this.sb.updateMetrics();
      }, 1000);
    }

    this.observer = new IntersectionObserver(
      entries => {
        const canObserveForAnim = this.props.active && !this.props.entering;

        entries.forEach(entry => {
          if (entry.intersectionRatio >= 0.3 && canObserveForAnim) {
            entry.target.classList.add('anim-slide-in--enter');
          }
        });
      },
      {
        threshold: [0.1, 0.3, 0.9],
      }
    );

    if (innerRef && innerRef.current) {
      this.animTargets = innerRef.current.querySelectorAll(`.anim-slide-in`);
    }

    const canObserveForAnim = isActive && !isEntering;
    if (canObserveForAnim) {
      // console.log(
      //   '%c Observing ',
      //   'background: dodgerblue; color: white',
      //   this.props.sectionName
      // );
      this.animTargets.forEach(t => {
        this.observer.observe(t);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevCanObserveForAnim = prevProps.active && !prevProps.entering;
    const currentCanObserveForAnim = this.props.active && !this.props.entering;

    if (this.props.active && this.sb) {
      this.sb.updateMetrics();
    }

    if (!prevCanObserveForAnim && currentCanObserveForAnim) {
      // console.log(
      //   '%c Observing ',
      //   'background: dodgerblue; color: white',
      //   this.props.sectionName
      // );
      this.animTargets.forEach(t => {
        this.observer.observe(t);
      });
    } else if (prevCanObserveForAnim && !currentCanObserveForAnim) {
      // console.log(
      //   '%c Pause ',
      //   'background: red; color: white',
      //   this.props.sectionName
      // );
      this.observer.disconnect();

      this.animTargets.forEach(t => {
        t.classList.remove('anim-slide-in--enter');
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.sb) {
      this.sb.destroy();

      window.removeEventListener('resize', this.handleResize);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', this.handleResize);
      }
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleResize = () => {
    if (this.sb) {
      this.sb.updateMetrics();
    }
  };

  render() {
    const {
      sectionName,
      exiting: isExiting,
      entering: isEntering,
      active: isActive,
      innerRef,
      children,
      className: inputClassName,
      onWheel = f => f,
      onTouchStart = f => f,
      onTouchMove = f => f,
      onTouchEnd = f => f,
    } = this.props;
    const { updateScrollMetrics } = this.state;

    const className = classnames(
      'lifeline-output-section',
      {
        [`lifeline-output-section--${sectionName}`]: sectionName,
        'lifeline-output-section--exit': isExiting,
        'lifeline-output-section--enter': isEntering,
        'lifeline-output-section--visible': isActive,
      },
      inputClassName
    );

    return (
      <section
        ref={innerRef}
        className={className}
        onWheel={onWheel}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <div className="lifeline-output-section__inner" ref={this.inner}>
          <ContextProvider value={{ updateScrollMetrics }}>
            {children}
          </ContextProvider>
        </div>
      </section>
    );
  }
}

export default Section;
