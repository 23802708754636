import React, { Component, createRef } from 'react';
import classnames from 'classnames';

import { tablet } from '@/components/App/breakpoints';

import './style.css';

import Perlin from 'perlin-simplex';

const pics = {
  f: [
    require('#/photos/new-relationships-section/profiles/nopic-female1.png'),
    require('#/photos/new-relationships-section/profiles/nopic-female2.png'),
  ],
  m: [require('#/photos/new-relationships-section/profiles/nopic-male1.png')],
  x: [require('#/photos/new-relationships-section/profiles/nopic-female1.png')],
};

class Profiles extends Component {
  constructor(props) {
    super(props);

    this.state = { isMounted: false };

    this.container = React.createRef();
    this.windowWidth = tablet + 1; // on SSO act like desktop
  }

  componentDidMount() {
    if (this.container.current) {
      this.profiles = [
        ...this.container.current.querySelectorAll('.lifeline-family--profile'),
      ];
      this.seeds = this.profiles.map(p => ({
        s1: Math.random(),
        s2: Math.random(),
      }));
      this.raf = requestAnimationFrame(this.update);
      this.perlin = new Perlin();
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    cancelAnimationFrame(this.raf);
  }

  handleResize = () => {
    this.windowWidth = window.innerWidth;
  };

  update = () => {
    const time = Date.now() / 10000;

    this.profiles.forEach((p, index) => {
      const r = p.classList.contains('lifeline-family--profile--big') ? 65 : 40;

      const r1 =
        -10 + 20 * Math.sin(this.perlin.noise(time, this.seeds[index].s1)) - r;
      const r2 =
        -10 + 20 * Math.cos(this.perlin.noise(time, this.seeds[index].s2)) - r;
      p.style.transform = `translate(${r1}px,${r2}px) rotate(0.01deg)`;
    });

    this.raf = requestAnimationFrame(this.update);
  };

  render() {
    const { profiles } = this.props;
    const isDesktop = this.windowWidth > tablet;

    const width = isDesktop ? `${10 + 25 + profiles.length * 15}rem ` : `100%`;
    const height = !isDesktop
      ? `${10 + 5 * profiles.length + profiles.length * 14.5}rem `
      : '';

    const positions = [{ top: '50%' }, { top: '15%' }, { bottom: '10%' }];
    const mobilePositions = [{ left: '50%' }, { left: '30%' }, { left: '50%' }];

    let left = 15;
    let top = 20;

    return (
      <div
        className="lifeline-family--profiles"
        style={{
          width,
          minHeight: height,
          flex: `0 0 ${width}`,
        }}
        ref={this.container}
      >
        {profiles.map((p, index) => {
          if (Number.isInteger(p)) {
            p = {
              pic: '',
              isPet: false,
              name: 'Name Surname',
              title: 'TItle',
              gender: 'm',
            };
          }

          const big = p.pic && !p.isPet;

          const pic =
            p.pic ||
            pics[p.gender][
              Math.round((pics[p.gender].length - 1) * Math.random())
            ];

          const style = {
            ...positions[index % 3],
            left: `${10 + left * index}rem`,
          };

          const mobileStyle = {
            ...mobilePositions[index % 3],
            top: `${15 + top * index}rem`,
          };

          const nameParts = p.name.split(' ');
          nameParts.splice(1, 0, <br key="br" />);

          return (
            <div
              className={classnames('lifeline-family--profile', {
                'lifeline-family--profile--big': big,
              })}
              style={isDesktop ? style : mobileStyle}
              key={`profile--${index}`}
            >
              <div className="lifeline-family--profile--main">
                <img className="lifeline-family--profile--pic" src={pic} />
                <div className="lifeline-family--profile--info">
                  <div className="lifeline-family--profile--name">
                    {nameParts}
                  </div>
                  <div className="lifeline-family--profile--title">
                    {p.title}
                  </div>
                </div>
              </div>
              {p.quote && (
                <div className="lifeline-family--profile--quote">
                  <span>{p.quote}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Profiles;
