import React, { useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from '@/components/Button';

import CrossWhite from '#/images/cross-white.svg';

import './style.css';

export default ({ lifeline }) => {
  const [showBanner, setShowState] = useState(true);
  const removeBanner = useCallback(() => {
    setShowState(false);
  }, []);

  const firstName = lifeline.data.remembering.firstName;
  const lastName = lifeline.data.remembering.lastName;

  return (
    <CSSTransition
      classNames="lifeline-not-published-banner-"
      in={showBanner}
      timeout={400}
      unmountOnExit
    >
      <div className="lifeline-not-published-banner">
        <div className="lifeline-not-published-banner__text">
          <p className="lifeline-not-published-banner__desktop-text">
            {lifeline.ownerFirstName}, you haven't published {firstName}{' '}
            {lastName}
            's lifeline yet. Please go to the My Account page to publish and
            share this lifeline.
          </p>

          <p className="lifeline-not-published-banner__mobile-text">
            {lifeline.ownerFirstName}, this is just a preview. Please go to My
            Account to publish this lifeline
          </p>

          <div
            onClick={removeBanner}
            className="lifeline-not-published-banner__cross"
          >
            <CrossWhite />
          </div>
        </div>

        <Button variant="alt-primary" size="medium" to="/my-account">
          Go to My Account
        </Button>
      </div>
    </CSSTransition>
  );
};
