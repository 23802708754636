import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { tablet } from '@/components/App/breakpoints';

import './style.css';

export default ({
  visible = false,
  onClose = () => {},
  size = 'auto',
  children,
  variant = 'blue',
  hideClose = false,
  className = '',
}) => {
  const modal = useRef(null);

  useEffect(() => {
    if (visible) {
      if (window.innerWidth <= tablet) {
        window.dispatchEvent(new CustomEvent('keepHeaderHidden'));
      }
      if (modal.current) {
        disableBodyScroll(modal.current);
      }
    } else {
      window.dispatchEvent(new CustomEvent('allowHeader'));
      if (modal.current) {
        enableBodyScroll(modal.current);
      }
    }
  }, [visible]);

  useEffect(() => {
    return () => {
      window.dispatchEvent(new CustomEvent('allowHeader'));
      if (modal.current) {
        enableBodyScroll(modal.current);
      }
    };
  }, []);

  return process.env.SSR_RENDERING
    ? null
    : ReactDOM.createPortal(
        <CSSTransition
          in={visible}
          classNames="modal-"
          timeout={200}
          unmountOnExit
        >
          <div className={classnames('modal', className)} ref={modal}>
            <div
              onClick={onClose}
              className={classnames('modal--overlay', {
                'modal--overlay--white': variant === 'white',
              })}
            />
            <div
              className={classnames('modal--content', {
                'modal--content--medium': size === 'medium',
                'modal--content--large': size === 'large',
              })}
            >
              {children}
              {!hideClose && (
                <span onClick={onClose} className="modal--close" />
              )}
            </div>
          </div>
        </CSSTransition>,
        document.querySelector('#modals-root')
      );
};
