import React, { useContext } from 'react';

const OutputSectionContext = React.createContext({
  updateScrollMetrics: f => f,
});

export const Provider = OutputSectionContext.Provider;
export const Consumer = OutputSectionContext.Consumer;
export const useOutputSectionContext = () => useContext(OutputSectionContext);

export default OutputSectionContext;
