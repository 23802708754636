import * as THREE from 'three';

import texture_blue_light from '../textures/light blue.png';
import texture_blue from '../textures/blue.png';
import texture_blue_light_hover from '../textures/light blue hover.png';
import texture_blue_hover from '../textures/blue hover.png';
import texture_blue_light_blur from '../textures/light blue blur.png';
import texture_blue_blur from '../textures/blue blur.png';

import { vertex, fragment } from './shaders.js';

const line_min_y = -10;
const line_max_y = 10;

export class Physics {
  constructor(particles, options) {
    this.particles = particles;
    this.options = {
      viscosity: 3,
      elastic: 1,
      mouseDrag: 100,
      scrollDrag: 0,
      ...options,
    };

    this.options.initialElastic = this.options.elastic;
    this.options.initialViscosity = this.options.viscosity;

    const scrollY =
      window.virtualScrollY !== undefined
        ? window.virtualScrollY
        : window.scrollY;

    this.prevMouse = { x: 0, y: 0 };
    this.mouse = { x: 0, y: 0 };
    this.prevScroll = scrollY;
    this.transitions = [];
    this.prevPointStatus = 'cloud';
    this.cloudLinePos = 1;
  }

  startCloudLineTransition = status => {
    this.transitions.push({
      startTime: performance.now(),
      status,
      fromToCloud: status == 'cloud' || this.prevPointStatus == 'cloud',
      toCloud: status == 'cloud',
      fromCloud: this.prevPointStatus == 'cloud',
    });

    setTimeout(() => {
      this.transitions.shift();
    }, 6000);
  };

  update = (time, delta, intersects = []) => {
    const scrollY =
      window.virtualScrollY !== undefined
        ? window.virtualScrollY
        : window.scrollY;

    const cloud_line_transition =
      window.virtualScrollY !== undefined
        ? !window.isFirstOrLastSection || scrollY > 100
        : Math.min(
            scrollY / window.innerHeight,
            Math.max(
              document.body.scrollHeight - scrollY - 2 * window.innerHeight,
              0
            ) / window.innerHeight,
            1
          );

    if (cloud_line_transition) {
      const left = Math.round(scrollY / 3000) % 2;
      this.pointStatus = left ? 'left' : 'right';
    } else {
      this.pointStatus = 'cloud';
    }

    if (this.prevPointStatus && this.prevPointStatus != this.pointStatus) {
      this.startCloudLineTransition(this.pointStatus);
    }
    this.prevPointStatus = this.pointStatus;

    const l = this.particles.length;
    const viscosity = this.options.viscosity;
    const elastic = this.options.elastic;
    const mouseDrag = this.options.mouseDrag;
    const scrollDrag = this.options.scrollDrag;

    //calc mouse movement
    const vmx = (this.mouse.x - this.prevMouse.x) / delta;
    const vmy = (this.mouse.y - this.prevMouse.y) / delta;

    this.prevMouse.x = this.mouse.x;
    this.prevMouse.y = this.mouse.y;

    //calc scroll movement
    const vsy = (scrollY - this.prevScroll) / delta;
    this.prevScroll = scrollY;

    const interectionPoints = [];
    intersects.forEach(i => (interectionPoints[i.index] = i));

    if (l) {
      const particle = this.particles[0];
      for (let k = this.transitions.length - 1; k >= 0; k--) {
        const trans = this.transitions[k];
        if (trans.fromCloud) {
          this.cloudLinePos = Math.max(this.cloudLinePos - 0.01, 0);
          particle.material.uniforms.cloud_line_pos.value = this.cloudLinePos;
        }
        if (trans.toCloud) {
          this.cloudLinePos = Math.min(this.cloudLinePos + 0.01, 1);
          particle.material.uniforms.cloud_line_pos.value = this.cloudLinePos;
        }
      }
    }

    const now = performance.now();
    for (let i = 0; i < l; i++) {
      const particle = this.particles[i];
      const p = particle.geometry.getAttribute('position');
      for (let j = 0, k = p.count; j < k; j++) {
        const vel = particle.vel[j];

        let target = particle.target[j];
        if (cloud_line_transition) {
          for (let k = this.transitions.length - 1; k >= 0; k--) {
            const trans = this.transitions[k];
            const transition_delta = now - trans.startTime;
            const duration = 6000;
            const transition_pos = transition_delta / duration;

            const threshold = -10 + 20 * transition_pos;

            const line =
              trans.status == 'left'
                ? particle.leftLinePoints[j]
                : particle.linePoints[j];

            if (trans.fromToCloud || line.y <= threshold) {
              particle.target[j] = line;
              target = line;
              break;
            }
          }
        } else {
          particle.target[j] = particle.cloudPoints[j];
          target = particle.cloudPoints[j];
        }

        //update velocity
        vel.addScaledVector(particle.acc[j], delta);
        //update acc
        const index = j * 3;
        const px = p.array[index];
        const py = p.array[index + 1];
        const pz = p.array[index + 2];

        const dist =
          mouseDrag *
          (interectionPoints[j] ? 1 - interectionPoints[j].distanceToRay : 0);

        particle.acc[j].set(
          elastic * (target.x - particle.dynamicTarget[j].x) -
            viscosity * vel.x +
            dist * vmx,
          elastic * (target.y - particle.dynamicTarget[j].y) -
            viscosity * vel.y +
            dist * vmy +
            scrollDrag * vsy,
          elastic * (target.z - particle.dynamicTarget[j].z) - viscosity * vel.z
        );

        particle.dynamicTarget[j].x += vel.x * delta;
        particle.dynamicTarget[j].y += vel.y * delta;
        particle.dynamicTarget[j].z += vel.z * delta;

        const damping = 0.005;
        p.setXYZ(
          j,
          px + (particle.dynamicTarget[j].x - px) * damping,
          py + (particle.dynamicTarget[j].y - py) * damping,
          pz + (particle.dynamicTarget[j].z - pz) * damping
        );
      }
      p.needsUpdate = true;
    }
  };

  setMouse = mouse => {
    this.mouse.x = mouse.x;
    this.mouse.y = mouse.y;
  };
}

const startColor = new THREE.Color();
const middleColor = new THREE.Color();
const endColor = new THREE.Color();

const gradients = {
  bright: [
    ['#f94e00', '#ffb900'],
    ['#019e79', '#c7f65a'],
    ['#00b2be', '#5ecaf0'],
    ['#ffdb00', '#fffc01'],
    ['#fe207d', '#f94da7'],
    ['#00f1d3', '#fffc01'],
  ],
  cool: [
    ['#2e7eff', '#4e3ef2'],
    ['#00ffff', '#0080ff'],
    ['#2e7eff', '#4bddb7'],
    ['#f5f4ce', '#70a2f9'],
    ['#a98fef', '#0080ff'],
    ['#b6c3ff', '#ff40ff'],
  ],
  pastel: [
    ['#f2c3fb', '#fffe6d'],
    ['#f7ca6d', '#f2c4fb'],
    ['#67a0f9', '#f0c1fb'],
    ['#d9dffd', '#ed67f8'],
    ['#bbff80', '#a98fef'],
  ],
};

Object.keys(gradients).forEach(type => {
  gradients[type].forEach(grad =>
    grad.splice(
      1,
      0,
      new THREE.Color(grad[0]).lerp(new THREE.Color(grad[1]), 0.5).getStyle()
    )
  );
});

const getLineColor = options => {
  const colorGroups = [];
  const adjectives = [];
  adjectives.forEach(adj => {
    colorGroups.push(getColorGroup(adj.label));
  });
  if (colorGroups.length === 0) {
    colorGroups.push('bright', 'bright', 'bright');
  }

  let colors = gradients[colorGroups[0]][Math.round(5 * Math.random())];

  startColor.set(colors[0]);
  middleColor.set(colors[1]);
  endColor.set(colors[2]);

  const middleIndex = 30;
  const lineIndex = 60 * Math.random();
  return (lineIndex <= middleIndex ? startColor : middleColor)
    .clone()
    .lerp(
      lineIndex <= middleIndex ? middleColor : endColor,
      (lineIndex <= middleIndex ? lineIndex : lineIndex - middleIndex) /
        (lineIndex <= middleIndex
          ? middleIndex
          : options.numLines - middleIndex - 1)
    );
};

const getLineCurve = (options, side) => {
  const cpoints = [];

  const x = side == 'left' ? options.min_x : options.max_x;

  for (let i = -10; i < 10; i++) {
    cpoints.push(
      new THREE.Vector3(x * 0.5 + Math.random(), i + Math.random(), 10)
    );
  }

  const curve = new THREE.CatmullRomCurve3(
    cpoints,
    false,
    options.curveType,
    options.tension
  );

  return curve;
};

const getLinePoints = (options, side = 'left') => {
  const curve2 = getLineCurve(options, side);
  const points = curve2.getSpacedPoints(options.cloud_count / 4);
  const max_radius = 0.2;

  for (let i = 0; i < points.length; i++) {
    points[i].x += -2 + 4 * Math.random();
    points[i].y += -0.1 + 0.2 * Math.random();
  }

  const new_points = [];

  points.forEach(p => {
    new_points.push(p, p, p, p);
  });

  return new_points;
};

const getCPoints = (options, lineIndex, time, middleLine) => {
  const cpoints = [];

  let deg = 0;
  for (let i = 0; i < options.axes; i++) {
    let radius = Math.max(
      options.axesParams[i].radius,
      options.axesParams[i].minRadius
    );
    radius = Math.min(radius, options.axesParams[i].maxRadius);
    radius =
      options.minRadius + (options.maxRadius - options.minRadius) * radius;

    radius = options.maxRadius;

    const middleIndex = 30;
    const deltaRadius =
      (lineIndex - middleIndex) *
      options.axesParams[i].lineGap *
      options.lineGapScale;
    // const middleRadius = radius;
    if (options.axesParams[i].spread) {
      radius += deltaRadius;
    }
    const point = new THREE.Vector3(
      radius * Math.sin(deg),
      radius * Math.cos(deg),
      10
    );

    /*
    if (middleLine) {
      const t = i / (options.axes - 1);
      const tangent = middleLine.curve.getTangent(t);
      const mp = new THREE.Vector3(
        middleRadius * Math.sin(deg),
        middleRadius * Math.cos(deg),
        10
      );
      point.sub(mp);
      var quaternion = new THREE.Quaternion();
      quaternion.setFromAxisAngle(tangent, options.axesParams[i].twist);

      point.applyQuaternion(quaternion);
      point.add(mp);
    }
    */

    cpoints.push(point);
    deg += (2 * Math.PI) / options.axes;
  }

  return cpoints;
};

export const createCloud = options => {
  //get middle curve
  const cpoints = getCPoints(options, 30, 0);
  const middle = new THREE.CatmullRomCurve3(
    cpoints,
    true,
    options.curveType,
    options.tension
  );
  //get external curve
  const cpoints2 = getCPoints(options, 60, 0, {
    curve: middle,
  });

  const curve = new THREE.CatmullRomCurve3(
    cpoints2,
    true,
    options.curveType,
    options.tension
  );

  const points = curve.getSpacedPoints(options.cloud_count);

  //const points = new Array(cloud_count);
  let min_radius = Number.POSITIVE_INFINITY;
  let max_radius = Number.NEGATIVE_INFINITY;

  options.min_x = Number.POSITIVE_INFINITY;
  options.min_y = Number.POSITIVE_INFINITY;
  options.max_x = Number.NEGATIVE_INFINITY;
  options.max_y = Number.NEGATIVE_INFINITY;

  for (let i = 0; i < points.length; i++) {
    options.min_x = Math.min(options.min_x, points[i].x);
    options.min_y = Math.min(options.min_y, points[i].y);
    options.max_x = Math.max(options.max_x, points[i].x);
    options.max_y = Math.max(options.max_y, points[i].y);
  }

  const delta = 7;

  options.min_x -= delta;
  options.min_y -= delta;
  options.max_x += delta;
  options.max_y += delta;

  for (let i = 0; i < points.length; i++) {
    const radius =
      Math.sqrt(points[i].x * points[i].x + points[i].y * points[i].y) *
      (2 * Math.random());
    const radius2 = radius * radius;
    min_radius = Math.min(min_radius, radius2);
    max_radius = Math.max(max_radius, radius2);

    /*
    const angle =
      Math.atan2(points[i].y, points[i].x) +
      ((-1 + 2 * Math.random()) * Math.PI) / 6;

    points[i].x += radius * Math.sin(angle);
    points[i].y += -radius * Math.cos(angle);
    points[i].z += -1;
    */

    points[i].x =
      options.min_x + Math.random() * (options.max_x - options.min_x);
    points[i].y =
      options.min_y + Math.random() * (options.max_y - options.min_y);

    points[i].z += -1;
  }

  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const texture1 = new THREE.TextureLoader().load(texture_blue_light);
  const texture2 = new THREE.TextureLoader().load(texture_blue);
  const texture1_blur = new THREE.TextureLoader().load(texture_blue_light_blur);
  const texture2_blur = new THREE.TextureLoader().load(texture_blue_blur);
  const texture1_hover = new THREE.TextureLoader().load(
    texture_blue_light_hover
  );
  const texture2_hover = new THREE.TextureLoader().load(texture_blue_hover);

  const colors = new Float32Array(3 * points.length);
  const sizes = new Float32Array(points.length);
  const texture_index = new Float32Array(points.length);
  const opacities = new Float32Array(points.length);
  const hoversState = new Float32Array(points.length);
  const blurs = new Float32Array(points.length);
  const lines = new Float32Array(points.length);
  for (let i = 0; i < points.length; i++) {
    const color = getLineColor(options);
    colors[3 * i] = color.r;
    colors[3 * i + 1] = color.g;
    colors[3 * i + 2] = color.b;
    texture_index[i] = Math.random();
    blurs[i] =
      (texture_index[i] >= 0.25 && texture_index[i] < 0.5) ||
      texture_index[i] >= 0.75;
    sizes[i] =
      3 * window.devicePixelRatio * Math.round(Math.max(4 * Math.random(), 1));
    opacities[i] =
      0.2 +
      (points[i].x * points[i].x + points[i].y * points[i].y) / max_radius;
    hoversState[i] = 0;
    lines[i] = i % 4 == 0 ? 1 : 0;
  }

  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
  geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
  geometry.setAttribute(
    'texture_index',
    new THREE.BufferAttribute(texture_index, 1)
  );
  geometry.setAttribute('opacity', new THREE.BufferAttribute(opacities, 1));
  geometry.setAttribute(
    'hoverState',
    new THREE.BufferAttribute(hoversState, 1)
  );
  geometry.setAttribute('blur', new THREE.BufferAttribute(blurs, 1));
  geometry.setAttribute('isLine', new THREE.BufferAttribute(lines, 1));

  const material = new THREE.RawShaderMaterial({
    uniforms: {
      map1: { value: texture1 },
      map2: { value: texture2 },
      map1_blur: { value: texture1_blur },
      map2_blur: { value: texture2_blur },
      map1_hover: { value: texture1_hover },
      map2_hover: { value: texture2_hover },
      time: { value: 1.0 },
      freq: { value: 0.75 },
      ampl: { value: 0.37 },
      time_freq: { value: 0.1 },
      uvTransform: { value: texture1.matrix },
      bg_color: { value: new THREE.Color('rgb(255,255,255)') },
      cloud_line_pos: { value: 1 },
    },

    vertexShader: vertex,

    fragmentShader: fragment,

    transparent: true,

    depthTest: false,
  });

  const p = new THREE.Points(geometry, material);
  p.cloudPoints = points;
  p.linePoints = getLinePoints(options, 'right');
  p.leftLinePoints = getLinePoints(options, 'left');
  p.target = points.map(p => p.clone());
  p.dynamicTarget = points.map(p => p.clone());
  p.vel = [];
  p.acc = [];
  for (let i = 0; i < points.length; i++) {
    p.vel[i] = new THREE.Vector3(0, 0, 0);
    p.acc[i] = new THREE.Vector3(0, 0, 0);
  }

  return [p];
};
