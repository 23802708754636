import React from 'react';
import classnames from 'classnames';

import { Consumer as OutputSectionContextConsumer } from '@/contexts/OutputSection';
import getPossessivePronoun from '@/utils/getPossessivePronoun';

import './style.css';

const repeatEvery = 4;

const Achievements = ({
  lifeline: {
    data: {
      remembering: { firstName, sex },
      achievements: { achievements },
    },
  },
  active: _,
  exiting: __,
  entering: ___,
  ...props
}) => {
  const pronoun = getPossessivePronoun(sex);

  return (
    <div className="lifeline-achievements" {...props}>
      <span className="lifeline-achievements__beacon"></span>
      <h2
        style={{
          '--slide-in-delay': '0s',
        }}
        className="lifeline-achievements__title anim-slide-in"
      >
        Achievements
      </h2>

      <h3
        style={{
          '--slide-in-delay': '0.15s',
        }}
        className="lifeline-achievements__subtitle anim-slide-in"
      >
        In {pronoun} life, {firstName} achieved many things.
      </h3>

      <ul className="lifeline-achievements__list">
        {achievements
          .sort((a, b) => a.from - b.from)
          .map((achievement, index) => (
            <li
              style={{
                '--slide-in-delay': '0.3s',
              }}
              key={index}
              className={classnames(
                'lifeline-achievements__achievement',
                'anim-slide-in',
                `lifeline-achievements__achievement--style-${(index %
                  repeatEvery) +
                  1}`,
                {
                  'lifeline-achievements__achievement--no-photo': !achievement.image,
                }
              )}
            >
              {achievement.image && (
                <OutputSectionContextConsumer>
                  {({ updateScrollMetrics }) => (
                    <img
                      src={achievement.image.remoteUrl}
                      onLoad={updateScrollMetrics}
                    />
                  )}
                </OutputSectionContextConsumer>
              )}
              <div className="lifeline-achievements__achievement__info">
                {achievement.from && (
                  <h3>
                    <span>{achievement.from.substr(0, 4)}</span>
                    {achievement.to ? (
                      <>
                        <span>|</span>
                        <span>{achievement.to.substr(0, 4)}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </h3>
                )}
                <p>{achievement.text}</p>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Achievements;
