import config from '@/config';

const uuidv4 = require('uuid/v4');
const version = 2;

export default rawLifeline => {
  if (rawLifeline.version && rawLifeline.version === version) {
    return rawLifeline;
  }

  const lifeline = {
    ...rawLifeline,
    version,
  };

  if (lifeline.relationships) {
    const defaultRelationshipGroup = config.defaultRelationshipGroup;
    const friendshipRelationshipGroup = 'Friendship';

    if (lifeline.relationships.importantRelationships) {
      lifeline.relationships.importantRelationships = lifeline.relationships.importantRelationships.map(
        ir => {
          const name = ir.name;

          const newIr = Object.keys(ir).reduce((object, key) => {
            if (key !== 'name') {
              object[key] = ir[key];
            }
            return object;
          }, {});

          if (!newIr.uuid) {
            newIr.uuid = uuidv4();
          }

          if (!newIr.firstName) {
            newIr.firstName = name;
          }

          if (!newIr.defaultPicture) {
            newIr.defaultPicture = newIr.picture ? null : 0;
          }

          if (!newIr.relationshipGroup) {
            newIr.relationshipGroup =
              (newIr.relationship || '').toLowerCase() === 'friend'
                ? friendshipRelationshipGroup
                : defaultRelationshipGroup;
          }

          return newIr;
        }
      );
    }

    if (lifeline.relationships.photos) {
      const photosGrouped = {};
      lifeline.relationships.photos
        .filter(
          p =>
            p && // item not nullish
            p.image && // image obj is not missing
            (p.image.remoteUrl || p.image.localUrl) // image has an url available
        )
        .forEach((p, i) => {
          let relationshipGroup =
            p.relationshipGroup || defaultRelationshipGroup;

          if (relationshipGroup === 'Friends') {
            relationshipGroup = friendshipRelationshipGroup;
          }

          if (!photosGrouped[relationshipGroup]) {
            photosGrouped[relationshipGroup] = [];
          }

          photosGrouped[relationshipGroup].push({
            relationshipGroup,
            image: { ...p.image },
            isFav: p.isFav === true || p.isFav === false ? p.isFav : false,
          });
        });

      lifeline.relationships.photos = [];

      Object.keys(photosGrouped).forEach(g => {
        lifeline.relationships.photos = [
          ...lifeline.relationships.photos,
          ...photosGrouped[g].slice(0, 6),
        ];
      });
    }
  }

  return lifeline;
};
