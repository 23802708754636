import apiFetch from '@/api/apiFetch';

export default async ({ email, password, provider, token, lifelineData }) => {
  const body = {
    email,
    password,
    provider,
    token,
    lifeline_data: lifelineData,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch('login', options, 200);

  return await response.json();
};
