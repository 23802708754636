import React, { useState, useCallback, useRef } from 'react';
import { findDOMNode } from 'react-dom';

import Input from '@/components/Input';
import SimpleModal from '@/components/SimpleModal';
import Button from '@/components/Button';

import shareLifelineViaEmail from '@/api/shareLifelineViaEmail';
import shareVideoViaEmail from '@/api/shareVideoViaEmail';

import isValidEmail from '@/utils/isValidEmail';
import getLifelineUrl from '@/utils/getLifelineUrl';

import './style.css';

export default ({
  uuid,
  slug,
  shareUrl = null,
  isPubliclySearchable,
  shareVideo,
}) => {
  const lifelineUrl = shareUrl
    ? shareUrl
    : shareVideo
    ? `${window.location.origin}/lifeline/${slug || uuid}/watch-video`
    : `${window.location.origin}/${getLifelineUrl({ uuid, slug })}`;
  const copyUrlRef = useRef();
  const [copyCompleted, setCopyCompleted] = useState(false);
  const onCopy = useCallback(() => {
    findDOMNode(copyUrlRef.current.input.current).select();
    document.execCommand('copy');
    setCopyCompleted(true);
  }, [lifelineUrl, copyUrlRef]);
  const [shareIsRunning, setShareIsRunning] = useState(false);
  const [shareCompleted, setShareCompleted] = useState(false);
  const [shareToEmail, setShareToEmail] = useState('');
  const onShareViaEmail = useCallback(
    async e => {
      if (shareIsRunning.current) {
        return;
      }

      setShareIsRunning(true);

      e.preventDefault();

      try {
        if (shareVideo) {
          await shareVideoViaEmail(uuid, shareToEmail);
        } else {
          await shareLifelineViaEmail(uuid, shareToEmail);
        }

        setShareCompleted(true);
      } catch (e) {
        console.log('e:', e);
      } finally {
        setShareIsRunning(false);
      }
    },
    [uuid, shareToEmail]
  );
  const onChangeEmail = useCallback(
    e => {
      if (shareCompleted) {
        setShareCompleted(false);
        setShareIsRunning(false);
      }

      setShareToEmail(e.target.value);
    },
    [shareCompleted]
  );

  const subtitle = !isPubliclySearchable
    ? 'This is a private lifeline and only invitees may access it.'
    : shareVideo
    ? 'This is a public link and everyone might access it.'
    : 'This is a public lifeline and anyone may access it.';

  return (
    <SimpleModal
      title={
        shareVideo
          ? 'Do you want to share the lifeline video?'
          : 'Do you want to share the lifeline?'
      }
      subtitle={subtitle}
      className="share-lifeline-modal"
    >
      <form
        onSubmit={onShareViaEmail}
        className="share-lifeline-modal__section"
      >
        <label>
          Send it via email
          <div className="share-lifeline-modal__group">
            <Input
              placeholder="Start typing here..."
              success={shareCompleted}
              onChange={onChangeEmail}
              value={shareToEmail}
              variant="smalt"
            />
            <Button
              disabled={!isValidEmail(shareToEmail)}
              onClick={onShareViaEmail}
              success={shareCompleted}
              size="small"
              variant="secondary"
              loading={shareIsRunning}
            >
              Send it
            </Button>
          </div>
        </label>
      </form>
      <div className="share-lifeline-modal__section">
        <label>
          Copy the link
          <div className="share-lifeline-modal__group">
            <Input
              ref={copyUrlRef}
              value={lifelineUrl}
              readOnly
              variant="smalt"
            />
            <Button
              success={copyCompleted}
              onClick={onCopy}
              size="small"
              variant="secondary"
            >
              Copy
            </Button>
          </div>
        </label>
      </div>
    </SimpleModal>
  );
};
