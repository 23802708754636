import React, { Component, createRef } from 'react';
import * as THREE from 'three';
//import * as Stats from 'stats.js';
//import presetsNewShape from '@/presetsNewShape.js';
import calculateAxesParams, {
  getAgeInfo,
  getDistanceFromLatLonInKm,
} from './calculateAxesParams';
//import * as dat from 'dat.gui';
import * as MeshLine from 'three.meshline';
import perlin from 'perlin-simplex';
import { vertex, fragment } from './shaders.js';

import { createCloud, Physics } from './cloud';
import classnames from 'classnames';
import { laptop } from '@/components/App/breakpoints';

import eases from 'eases';

import cloneDeep from 'lodash.clonedeep';

import './style.css';

const DEFAULT_CLOUD_PARTICLES = 500;

const DEFAULT_SSAA =
  typeof window !== 'undefined' && window.devicePixelRatio > 1 ? 1.2 : 2;

/*
function getRandomRgb() {
  var num = Math.round(0xffffff * Math.random());
  var r = num >> 16;
  var g = num >> 8 & 255;
  var b = num & 255;
  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
}
*/

export default class LifelineShape extends Component {
  constructor(props) {
    super(props);
    this.canvas = createRef();
    this.time = 0;
    this.prevTime = 0;
    this.paused = false;

    const {
      animateOnMouseMove = false,
      transparent = false,
      cloud = true,
      lines = true,
      outputCloud = false,
      scale,
      SSAA,
      debug = false,
      editor = false,
    } = this.props;

    const defaultScale = lines ? 0.8 : 1;

    this.state = {
      isReady: false,
      isResizing: false,
    };

    this.bg_color = 'rgb(255,255,255)';

    this.options = {
      axes: 7,
      enabledAxes: 7,
      minRadius: 0.75,
      maxRadius: 2.75,
      axesParams: [],
      curveType: 'catmullrom',
      tension: 0.5,
      animateOnMouseMove: animateOnMouseMove,
      disturbOnMouseMove: true,
      antialias: lines,
      SSAA: !lines ? 1 : SSAA || DEFAULT_SSAA,
      noise_amplitude: 0.19, //0.16,
      freq_particles: 0.4, //0.3, //20,
      time_freq: 0.02, //0.15,
      cloud_count: parseInt(cloud) || DEFAULT_CLOUD_PARTICLES,
      cloud_noise_amplitude: 1.35,
      cloud_freq_particles: 0.36, //20,
      cloud_time_freq: 0.02,
      lineWidth: 1.5,
      alpha: transparent,
      cloud,
      outputCloud,
      lines,
      scale: scale || defaultScale,
      cloudLine: new THREE.Vector2(0, 0),
      debug,
      editor,
    };

    const colors = [
      {
        inner: '#fabbff',
        outer: '#ffb900',
      },
      {
        inner: '#00ffff',
        outer: '#0080ff',
      },
      {
        inner: '#2e7eff',
        outer: '#4bddb7',
      },
      {
        inner: '#b6c3ff',
        outer: '#ff40ff',
      },
      {
        inner: '#00f1d3',
        outer: '#fffb00',
      },
      {
        inner: '#bbff80',
        outer: '#a98fef',
      },
      {
        inner: '#4634ff',
        outer: '#ffff00',
      },
    ];

    for (let i = 0; i < this.options.axes; i++) {
      let ease, ease2;

      switch (i) {
        case 0:
          ease = 'bounceIn';
          ease2 = 'cubicIn';
          break;
        case 1:
          ease = 'cubicIn';
          ease2 = 'quadIn';
          break;
        case 2:
        case 6:
          ease = 'sineIn';
          ease2 = 'sineIn';
          break;
        case 3:
        case 5:
          ease = 'quadIn';
          ease2 = 'cubicIn';
          break;
        default:
          ease = 'cubicIn';
          ease2 = 'bounceIn';
          break;
      }

      this.options.axesParams.push({
        enabled: true,
        enabledIndex: i,
        spread: true,
        minRadius: 0.5,
        maxRadius: 1.0,
        radius: 0.3 + 0.5 * Math.random(),
        lineGap: 0.03, //0.05 + 0.02 * Math.random(),
        numLines: 60 + Math.round(20 * Math.random()),
        ease,
        ease2,
        sinFreq: 2 * Math.random(),
        sinOffset: -2 + 4 * Math.random(),
        sideTurn: 0,
        color0: colors[i].inner, //'rgb(55,191,209)',
        color1: new THREE.Color(colors[i].inner)
          .lerp(new THREE.Color(colors[i].outer), 0.5)
          .getStyle(), //'rgb(27,115,169)',
        color2: colors[i].outer, //'rgb(0,38,128)',
        random1: Math.random(),
        random2: Math.random(),
        reveal: false,
      });
    }

    if (this.options.lines && this.props.useData !== false) {
      this.updateDerivedData(this.props.data);
      this.calculateAxesParams();
    }

    this.linesScale = 1;
    this.pointsScale = 1;
    this.lineGapScale = 1;
    this.particleSizeScale = 1;

    this.startColor = new THREE.Color();
    this.middleColor = new THREE.Color();
    this.endColor = new THREE.Color();
  }

  componentDidMount() {
    this.perlin = new perlin();

    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Points.threshold = 1;
    //this.raycaster.linePrecision = 10; // **jc**

    window.THREE = THREE;
    this.container = this.canvas.current.parentNode;

    const height = this.options.outputCloud
      ? window.innerHeight
      : this.container.clientHeight;

    this.camera = new THREE.PerspectiveCamera(
      75,
      this.container.clientWidth / height,
      0.1,
      1000
    );
    this.camera.position.z = 15.2;

    this.renderer = new THREE.WebGLRenderer({
      antialias: this.options.antialias,
      canvas: this.canvas.current,
      alpha: this.options.alpha,
    });
    this.renderer.setSize(this.container.clientWidth, height);
    this.renderer.setClearColor('#FFF', this.options.alpha ? 0 : 1);

    if (this.props.debug) {
      this.createGUI();

      this.stats = new Stats();
      this.stats.showPanel(1); // 0: fps, 1: ms, 2: mb, 3+: custom
      document.body.appendChild(this.stats.dom);
    } else {
      this.stats = { begin: () => {}, end: () => {} };
    }

    if (this.options.lines && this.props.useData !== false) {
      this.updateDerivedData(this.props.data);
    }

    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('touchmove', this.onMouseMove, { passive: false });
    window.addEventListener('touchend', this.onTouchEnd);
    window.addEventListener('resize', this.onResize);

    this.resize();

    this.animate();

    if (this.props.onInitComplete) {
      this.props.onInitComplete();
    }
  }

  updateDerivedData = data => {
    const birthDate = data.remembering.birthDate;
    const deathDate = data.remembering.deathDate;

    const age = Math.min(
      (birthDate && getAgeInfo(birthDate, deathDate).age) || 85,
      85
    );

    this.options.age = age;

    const bornPlace = data.remembering.bornPlaceGeoJSON || { lat: 0, lng: 0 };
    const deathPlace = data.remembering.deathPlaceGeoJSON || { lat: 0, lng: 0 };

    const distance = Math.max(
      1,
      Math.min(
        getDistanceFromLatLonInKm(
          bornPlace.lat,
          bornPlace.lng,
          deathPlace.lat,
          deathPlace.lng
        ),
        20000
      )
    );
    this.options.distance = Math.log(distance) / Math.log(20000);

    if (this.gui) {
      this.gui.__folders['Person Params'].__controllers.forEach(c =>
        c.updateDisplay()
      );
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isResizing !== this.state.isResizing) {
      return true;
    }

    if (nextState.isReady !== this.state.isReady) {
      return true;
    }

    if (nextProps.className !== this.props.className) {
      return true;
    }

    if (this.props.paused && !nextProps.paused) {
      if (this.raf) {
        window.cancelAnimationFrame(this.raf);
        this.raf = null;
      }
      this.paused = false;
      this.animate();
    } else if (this.props.paused) {
      this.paused = true;
    }

    const needUpdate = nextProps.data !== this.props.data;

    return needUpdate;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.options.lines && prevProps.data !== this.props.data) {
      this.updateDerivedData(this.props.data);
      this.calculateAxesParams();
      this.createScene();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchmove', this.onMouseMove);
    window.removeEventListener('touchend', this.onTouchEnd);
    window.removeEventListener('resize', this.onResize);

    if (this.raf) {
      window.cancelAnimationFrame(this.raf);
      this.raf = null;
    }

    if (this.gui) {
      this.gui.destroy();
    }

    // reduce renderer size to 1x1 px
    this.renderer.setSize(1, 1);
    this.renderer.setPixelRatio(1);

    // nullify main references and make them elegible for garbage collector
    this.camera = null;
    this.canvas = null;
    this.cloud = null;
    this.container = null;
    this.endColor = null;
    this.lineGapScale = null;
    this.linesScale = null;
    this.middleColor = null;
    this.mouse = null;
    this.options = null;
    //this.particlePhysics = null;
    this.lines = null;
    this.particleSizeScale = null;
    this.perlin = null;
    this.pointsScale = null;
    this.raycaster = null;
    this.renderer = null;
    this.rendererHeight = null;
    this.rendererWidth = null;
    this.scene = null;
    this.shape = null;
    this.startColor = null;
  }

  eases = [
    'backInOut',
    'backIn',
    'backOut',
    'bounceInOut',
    'bounceIn',
    'bounceOut',
    'circInOut',
    'circIn',
    'circOut',
    'cubicInOut',
    'cubicIn',
    'cubicOut',
    'elasticInOut',
    'elasticIn',
    'elasticOut',
    'expoInOut',
    'expoIn',
    'expoOut',
    'linear',
    'quadInOut',
    'quadIn',
    'quadOut',
    'quartInOut',
    'quartIn',
    'quartOut',
    'quintInOut',
    'quintIn',
    'quintOut',
    'sineInOut',
    'sineIn',
    'sineOut',
  ];

  createGUI = () => {
    this.gui = new dat.GUI({ load: presetsNewShape });
    this.gui.width = 300;
    //this.gui.useLocalStorage = true;
    this.gui.remember(this.options);
    let c = this.gui.add(this.options, 'minRadius', 0, 5, 0.05);
    c.onChange(this.updateValues);
    c = this.gui.add(this.options, 'maxRadius', 0, 5, 0.05);
    c.onChange(this.updateValues);
    this.axesParamsFolder = this.gui.addFolder('Axes Params');
    this.axesParamsControllers = [];
    for (let l = 0; l < this.options.axes; l++) {
      const controllers = {
        spread: null,
        minRadius: null,
        maxRadius: null,
        radius: null,
        lineGap: null,
        colors0: null,
        colors1: null,
        colors2: null,
        numLines: null,
      };
      c = this.axesParamsFolder.add(this.options.axesParams[l], 'enabled');
      c.onChange(this.updateValues);
      let f = this.axesParamsFolder.addFolder('Axe ' + (l + 1));
      c = f.add(this.options.axesParams[l], 'spread');
      c.name('spread ' + (l + 1));
      controllers.spread = c;
      c.onChange(this.updateValues);
      c = f.add(this.options.axesParams[l], 'ease', this.eases);
      c.name('ease ' + (l + 1));
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'ease2', this.eases);
      c.name('inner ease ' + (l + 1));
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'sinFreq', 0.0, 2.0, 0.01);
      c.name('inner freq ' + (l + 1));
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'sinOffset', -2.0, 2.0, 0.01);
      c.name('inner offset ' + (l + 1));
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'minRadius', 0.0, 1.0, 0.01);
      c.name('min radius ' + (l + 1));
      controllers.minRadius = c;
      c.onChange(this.updateValues);
      c = f.add(this.options.axesParams[l], 'maxRadius', 0.0, 1.0, 0.01);
      c.name('max radius ' + (l + 1));
      controllers.maxRadius = c;
      c.onChange(this.updateValues);
      c = f.add(this.options.axesParams[l], 'radius');
      c.name('radius ' + (l + 1));
      controllers.radius = c;
      c.onChange(this.updateValues);
      c = f.add(this.options.axesParams[l], 'lineGap', 0.0, 0.1, 0.01);
      c.name('lineGap ' + (l + 1));
      controllers.lineGap = c;
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'sideTurn', -1, 1, 0.01);
      c.name('side turn ' + (l + 1));
      c.onChange(this.updateValues);

      c = f.add(this.options.axesParams[l], 'numLines', 1, 120, 1);
      c.name('numLines ' + (l + 1));
      controllers.numLines = c;
      c.onChange(this.updateValues);

      c = f.addColor(this.options.axesParams[l], 'color0');
      c.name('colors0 ' + (l + 1));
      c.onChange(this.updateValues);
      c = f.addColor(this.options.axesParams[l], 'color1');
      c.name('colors1 ' + (l + 1));
      c.onChange(this.updateValues);
      c = f.addColor(this.options.axesParams[l], 'color2');
      c.name('colors2 ' + (l + 1));
      c.onChange(this.updateValues);

      this.axesParamsControllers.push(controllers);
    }

    const personFolder = this.gui.addFolder('Person Params');

    c = personFolder.add(this.options, 'age', 0, 120, 1);
    c.onChange(this.updateValues);
    c = personFolder.add(this.options, 'distance', 0, 1, 0.01);
    c.name('Birth-death distance');
    c.onChange(this.updateValues);

    c = this.gui.add(this.options, 'tension');
    c.onChange(this.updateValues);
    c = this.gui.add(this.options, 'animateOnMouseMove');
    c.name('mouse enabled');
    c = this.gui.add(this.options, 'antialias');
    c.name('webgl antialias');
    c.onChange(this.updateValues);

    c = this.gui.add(this.options, 'noise_amplitude');
    c.name('animation amount');
    c.onChange(this.updateValues);

    c = this.gui.add(this.options, 'time_freq');
    c.name('animation speed');
    c.onChange(this.updateValues);

    c = this.gui.add(this.options, 'freq_particles');
    c.name('animation randomness');
    c.onChange(this.updateValues);

    c = this.gui.add(this.options, 'lineWidth');
    c.name('line width');
    c.onChange(this.updateValues);
  };

  onTouchEnd = e => {
    this.mouse.x = -1;
    this.mouse.y = -1;
  };

  onMouseMove = e => {
    if (e.type != 'mousemove' && e.target.tagName == 'CANVAS') {
      e.preventDefault();
    }

    const mx = e.type == 'mousemove' ? e.clientX : e.touches[0].clientX;
    const my = e.type == 'mousemove' ? e.clientY : e.touches[0].clientY;

    const degY =
      (((window.innerWidth / 2 - mx) / (window.innerWidth / 2)) * Math.PI) / 12;
    const degX =
      (((window.innerHeight / 2 - my) / (window.innerHeight / 2)) * Math.PI) /
      12;
    const c = this.camera;
    const z = c.position.z;
    c.translateZ(-z);
    if (this.options.animateOnMouseMove) {
      if (e && e.type == 'mousemove') {
        c.rotation.set(degX, degY, 0);
      }
    } else {
      c.rotation.set(0, 0, 0);
    }

    c.translateZ(z);

    if (this.options.disturbOnMouseMove) {
      const pos = this.canvas.current.getBoundingClientRect();
      const x = Math.min(Math.max(mx - pos.left, 0), pos.width);
      const y = Math.min(Math.max(my - pos.top, 0), pos.height);
      this.mouse.x = (x / pos.width) * 2 - 1;
      this.mouse.y = -(y / pos.height) * 2 + 1;
      this.cloudPhysics && this.cloudPhysics.setMouse(this.mouse);
      //this.particlePhysics && this.particlePhysics.setMouse(this.mouse);
    }
  };

  onResize = () => {
    if (!this.isResizing && window.innerWidth >= laptop) {
      this.isResizing = true;
      this.setState({
        isResizing: true,
      });

      document.body.style.overflow = 'hidden';
    }

    if (this.resizeTimout) {
      clearTimeout(this.resizeTimout);
    }

    this.resizeTimout = setTimeout(() => {
      this.resize();

      this.isResizing = false;
      this.setState({
        isResizing: false,
      });

      document.body.style.overflow = null;
    }, 250);
  };

  resize = () => {
    const height = this.options.outputCloud
      ? window.innerHeight
      : this.container.clientHeight;

    this.camera.aspect = this.container.clientWidth / height;
    this.camera.updateProjectionMatrix(); //we need to call after every param change
    this.renderer.setSize(this.container.clientWidth, height);
    this.renderer.setPixelRatio(window.devicePixelRatio * this.options.SSAA);

    const minSize =
      Math.min(this.container.clientWidth, height) * this.options.scale;

    //this.options.minRadius = 0.75;
    //this.options.maxRadius = 2.75;

    const {
      linesScale,
      pointsScale,
      lineGapScale,
      particleSizeScale,
      linesWidthScale,
    } = this.getScaleRatio(minSize);

    this.linesScale = linesScale;
    this.pointsScale = pointsScale;
    this.lineGapScale = lineGapScale;
    this.options.lineGapScale = lineGapScale;
    this.particleSizeScale = particleSizeScale;
    this.linesWidthScale = linesWidthScale;

    this.rendererWidth = this.container.clientWidth;
    this.rendererHeight = height;

    this.raycaster.setFromCamera(new THREE.Vector2(0.7, 0), this.camera);
    var i_point = new THREE.Vector3();
    this.raycaster.ray.intersectPlane(
      new THREE.Plane(new THREE.Vector3(0, 0, 1), -10),
      i_point
    );
    this.options.cloudLine = i_point;

    i_point = new THREE.Vector3();
    this.raycaster.setFromCamera(new THREE.Vector2(-0.7, 0), this.camera);
    this.raycaster.ray.intersectPlane(
      new THREE.Plane(new THREE.Vector3(0, 0, 1), -10),
      i_point
    );
    this.options.cloudLineLeft = i_point;

    this.createScene();
  };

  getScaleRatio = size => {
    const minSize = 96;
    const maxSize = 320;
    const minLinesScale = 0.3;
    const maxLinesScale = 1;
    const minPointsScale = 0.2;
    const maxPointsScale = 1;
    const minLineGapScale = 1;
    const maxLineGapScale = 4;
    const minParticleSizeScale = 1;
    const maxParticleSizeScale = 1.5;
    const delta = Math.min(Math.max(size, minSize), maxSize) - minSize;
    const delta2 = Math.min(Math.max(size, minSize), 1.5 * maxSize) - minSize;

    return {
      linesScale:
        minLinesScale +
        (delta2 / (1.5 * maxSize - minSize)) * (maxLinesScale - minLinesScale),
      pointsScale:
        minPointsScale +
        (delta / (maxSize - minSize)) * (maxPointsScale - minPointsScale),
      lineGapScale:
        maxLineGapScale -
        (224 / (maxSize - minSize)) * (maxLineGapScale - minLineGapScale),
      particleSizeScale:
        size < 320 ? minParticleSizeScale : maxParticleSizeScale,
      linesWidthScale: Math.min(Math.max(size / 400, 1), 3),
    };
  };

  updateCameraPosition = () => {
    const x = {
      min: Infinity,
      max: -Infinity,
      pointMin: new THREE.Vector3(0, 0, 0),
      pointMax: new THREE.Vector3(0, 0, 0),
    };
    const y = {
      min: Infinity,
      max: -Infinity,
      pointMin: new THREE.Vector3(0, 0, 0),
      pointMax: new THREE.Vector3(0, 0, 0),
    };
    const z = {
      min: Infinity,
      max: -Infinity,
      pointMin: new THREE.Vector3(0, 0, 0),
      pointMax: new THREE.Vector3(0, 0, 0),
    };

    function calcEdges(l) {
      for (let i = 0; i < l.geometry.attributes.position.count; i++) {
        const px = l.geometry.attributes.position.getX(i);
        const py = l.geometry.attributes.position.getY(i + 1);
        const pz = l.geometry.attributes.position.getZ(i + 2);

        if (px < x.min) {
          x.min = px;
          x.pointMin.set(px, py, pz);
        }
        if (px > x.max) {
          x.max = px;
          x.pointMax.set(px, py, pz);
        }
        if (py < y.min) {
          y.min = py;
          y.pointMin.set(px, py, pz);
        }
        if (py > y.max) {
          y.max = py;
          y.pointMax.set(px, py, pz);
        }
        if (pz < z.min) {
          z.min = pz;
          z.pointMin.set(px, py, pz);
        }
        if (pz > z.max) {
          z.max = pz;
          z.pointMax.set(px, py, pz);
        }
      }
    }

    if (this.shape.children.length) {
      this.shape.children.forEach(g => {
        g.children.forEach(l => {
          if (!l.geometry.attributes) {
            return;
          }
          calcEdges(l);
        });
      });
    } else {
      //console.log(this.cloud);
      this.cloud.forEach(calcEdges);
    }

    // let maxZOnSideIndex = -1;
    let maxZOnSide = -Infinity;
    [x.pointMin, x.pointMax, y.pointMin, y.pointMax].forEach((p /* , i */) => {
      if (p.z > maxZOnSide) {
        maxZOnSide = p.z;
        // maxZOnSideIndex = i;
      }
    });

    const height = this.options.outputCloud
      ? window.innerHeight
      : this.container.clientHeight;

    const canvasAspect = this.container.clientWidth / height;
    const shapeAspect = (x.max - x.min) / (y.max - y.min);

    let scaledSize;
    if (
      (this.options.cloud && canvasAspect < shapeAspect) ||
      (this.options.lines && canvasAspect >= shapeAspect)
    ) {
      scaledSize = (y.max - y.min) / this.options.scale;
    } else {
      scaledSize = (x.max - x.min) / canvasAspect / this.options.scale;
    }

    const dist = scaledSize / 2 / Math.tan((Math.PI * this.camera.fov) / 360);

    this.camera.position.z = maxZOnSide + dist;

    // console.log({
    //   dist,
    //   x,
    //   y,
    //   z,
    //   maxZOnSide: ['x.pointMin', 'x.pointMax', 'y.pointMin', 'y.pointMax'][
    //     maxZOnSideIndex
    //   ],
    //   cameraz: maxZOnSide + dist,
    // });

    // if (this.sphereHelper) {
    //   this.shape.remove(this.sphereHelper);
    // } else {
    //   const geometry = new THREE.SphereGeometry(0.1, 5, 5);
    //   const material = new THREE.MeshBasicMaterial({
    //     color: 0xff0000,
    //     wireframe: true,
    //   });
    //   this.sphereHelper = new THREE.Mesh(geometry, material);
    // }
    // const maxZPoint = [x.pointMin, x.pointMax, y.pointMin, y.pointMax][
    //   maxZOnSideIndex
    // ];
    // this.sphereHelper.position.set(maxZPoint.x, maxZPoint.y, maxZPoint.z);
    // this.shape.add(this.sphereHelper);

    this.shape.position.x = -(x.min + x.max) / 2;
    this.shape.position.y = -(y.min + y.max) / 2;
  };

  createScene = () => {
    if (!this.scene) {
      this.scene = new THREE.Scene();
    }
    if (!this.shape) {
      this.shape = new THREE.Group();
      this.scene.add(this.shape);
    }

    if (this.cloud) {
      this.scene.remove(...this.cloud);
      this.cloud.forEach(p => {
        p.material.dispose();
        p.geometry.dispose();
      });
    }

    const el = this.canvas.current;
    if (!el || (el.offsetWidth <= 0 && el.offsetHeight <= 0)) {
      return;
    }

    if (this.options.cloud && window.innerWidth >= 1024) {
      this.cloud = createCloud(this.options);
      this.cloudPhysics = new Physics(this.cloud, {
        scrollDrag: this.options.outputCloud ? 0.02 : 0,
      });
      this.scene.add(...this.cloud);
    } else {
      this.cloud = [];
    }

    if (this.options.lines) {
      this.lines = this.createLines();

      // add shape particles to the shape group (at this time it is empty)
      this.lines.forEach((petal, axe) => {
        const obj = this.shape.getObjectByName(`lines-axe-${axe}`);

        if (this.options.axesParams[axe].changed) {
          if (obj) {
            this.shape.remove(obj);
            obj.children.forEach((p, index) => {
              const line = petal.children[index];
              if (line) {
                line.material = p.material;
              } else {
                p.material.dispose();
              }
              p.geometry.dispose();
            });
          }
          this.shape.add(petal);
        } else if (obj) {
          this.lines[axe] = obj;
        }
      });

      // this.boxHelper = new THREE.BoxHelper(this.shape, 0xff00ff);
      // this.scene.add(this.boxHelper);
    } else if (this.lines && this.lines.length) {
      this.shape.remove(...this.lines);
      this.lines.forEach(g => {
        g.children.forEach(p => {
          p.material.dispose();
          p.geometry.dispose();
        });
      });
      this.lines = [];
    }

    // // NOTE: at this point actual min and max are evaluated, so we can update
    // //       the camera position in the way it will stay in 75% of the screen
    // this.updateCameraPosition();

    const { data: lifeline = {} } = this.props;
    this.prevLifeline = cloneDeep(lifeline);
    this.prevOptions = cloneDeep(this.options);
    //console.log(this.scene);
  };

  createLines = (z_offset = 0, expand = false) => {
    const lines = [];

    for (let axe = 0; axe < this.options.axes; axe++) {
      const g = new THREE.Group();
      g.name = `lines-axe-${axe}`;

      if (this.options.axesParams[axe].changed) {
        const middleIndex = 0; //Math.round(this.options.axesParams[axe].numLines / 2);
        const middleLine = this.createLine(
          axe,
          middleIndex,
          false,
          z_offset,
          expand
        );

        const numLines = Math.round(
          this.linesScale * this.options.axesParams[axe].numLines
        );

        for (let l = 0; l < numLines; l++) {
          if (l != middleIndex) {
            g.add(this.createLine(axe, l, middleLine, z_offset, expand));
            lines.push();
          } else {
            g.add(middleLine);
          }
        }

        // we reset the reveal flag that we use to set the initial opacity on reveal, which is 0 while the target opacity remains 1
        this.options.axesParams[axe].reveal = false;

        const averageAngularAxeSpan = (2 * Math.PI) / this.options.axes;
        const middleDeg = averageAngularAxeSpan * (axe + 0.5);
        const startDeg =
          middleDeg -
          0.5 *
            averageAngularAxeSpan *
            (1 + 2 * this.options.axesParams[axe].random1);
        const endDeg =
          middleDeg +
          0.5 *
            averageAngularAxeSpan *
            (1 + 2 * this.options.axesParams[axe].random2);

        const myAngle = startDeg + (endDeg - startDeg) / 2;

        g.translateZ(10);
        g.setRotationFromAxisAngle(
          new THREE.Vector3(
            Math.sin(myAngle),
            Math.cos(myAngle),
            0
          ).normalize(),
          this.options.axesParams[axe].sideTurn
        );
        g.translateZ(-10);
      }

      lines.push(g);
    }

    return lines;
  };

  createLine = (axe, lineIndex, middleLine, z_offset, expand) => {
    const cpoints = this.getCPoints(axe, lineIndex, middleLine, expand);

    const curve = new THREE.CatmullRomCurve3(
      cpoints,
      false,
      this.options.curveType,
      this.options.tension
    );

    //console.log('index', lineIndex, 'length', curve.getLength());
    const ppl = 20;
    //ppp = point per pixel
    //const ppp = Math.max(
    //  Math.min(this.container.clientWidth, this.container.clientHeight) / 800,
    //  0.5
    //);

    //const points = curve.getPoints( Math.round(ppl * curve.getLength()) );
    const points = curve.getSpacedPoints(
      Math.round(/*ppp */ ppl * curve.getLength())
    );
    //const points = curve.getSpacedPoints( 2 );
    //const frames = curve.computeFrenetFrames(
    //  Math.round(ppl * curve.getLength()),
    //  true
    //);

    for (let i = 0; i < points.length; i++) {
      points[i].z += z_offset;
    }

    const line = new MeshLine.MeshLine();

    //const wc = pos => eases.cubicInOut(1 - 2 * Math.abs(0.5 - pos));
    const wc = pos => {
      const linearWidth = this.linesWidthScale * (1 - 2 * Math.abs(0.5 - pos));
      return Math.min(linearWidth, 1);
    };

    line.setGeometry(new THREE.BufferGeometry().setFromPoints(points), wc);

    //this.options.axesParams[axe].enabled = true;

    const material = new THREE.RawShaderMaterial({
      transparent: true,
      opacity: this.options.axesParams[axe].enabled ? 1 : 0,
      uniforms: {
        color: { value: this.getLineColor(axe, lineIndex) },
        time: { value: 1.0 },
        freq: { value: this.options.freq_particles },
        ampl: { value: this.options.noise_amplitude },
        time_freq: { value: this.options.time_freq },
        size: { value: window.devicePixelRatio },
        //mouse: { value: new THREE.Vector2(0, 0) },
        resolution: {
          value: new THREE.Vector2(this.rendererWidth, this.rendererHeight),
        },
        lineWidth: { value: this.options.lineWidth },
        sizeAttenuation: { value: 0 },
        opacity: {
          value:
            this.options.axesParams[axe].enabled &&
            !this.options.axesParams[axe].reveal
              ? 1
              : 0,
        },
      },

      depthFunc: this.options.axesParams[axe].enabled
        ? THREE.AlwaysDepth
        : THREE.NeverDepth,
      depthWrite: this.options.axesParams[axe].enabled,
      depthTest: this.options.axesParams[axe].enabled,

      vertexShader: vertex,

      fragmentShader: fragment,
    });

    const p = new THREE.Mesh(line.geometry, material); // this syntax could definitely be improved!

    p.curve = curve;
    p.target = points;
    p.vel = [];
    p.acc = [];
    //p.norm = frames.normals;
    //p.binorm = frames.binormals;
    //p.tangent = frames.tangents;
    p.lineIndex = lineIndex;
    p.axe = axe;
    p.targetOpacity = this.options.axesParams[axe].enabled ? 1 : 0;

    const numLines = Math.round(
      this.linesScale * this.options.axesParams[axe].numLines
    );

    const lineProgress = lineIndex / (numLines - 1);
    p.targetOpacitySpeed = 0.05;
    p.targetOpacityStagger =
      performance.now() +
      20 * lineIndex +
      eases.expoIn(lineProgress) * 40 * numLines;

    p.targetColorSpeed = 0.01;
    p.targetColor = this.getLineColor(axe, lineIndex);

    //const normal = new THREE.Vector3();
    //const vec = new THREE.Vector3();
    for (let i = 0; i < points.length; i++) {
      p.vel[i] = new THREE.Vector3(0, 0, 0);
      p.acc[i] = new THREE.Vector3(0, 0, 0);
    }
    return p;
  };

  getLineColor = (axe, lineIndex) => {
    const options = this.options;

    const numLines = Math.round(
      this.linesScale * this.options.axesParams[axe].numLines
    );

    this.startColor.set(options.axesParams[axe].color0);
    this.middleColor.set(options.axesParams[axe].color1);
    this.endColor.set(options.axesParams[axe].color2);

    const middleIndex = Math.round(numLines / 2);
    return (lineIndex <= middleIndex ? this.startColor : this.middleColor)
      .clone()
      .lerp(
        lineIndex <= middleIndex ? this.middleColor : this.endColor,
        (lineIndex <= middleIndex ? lineIndex : lineIndex - middleIndex) /
          (lineIndex <= middleIndex ? middleIndex : numLines - middleIndex - 1)
      );
  };

  getCPoints = (axe, lineIndex, middleLine) => {
    const cpoints = [];
    const axeParam = this.options.axesParams[axe];

    const numLines = Math.round(this.linesScale * axeParam.numLines);

    let radius = Math.max(axeParam.radius, axeParam.minRadius);
    radius = Math.min(radius, axeParam.maxRadius);
    radius =
      this.options.minRadius +
      (this.options.maxRadius - this.options.minRadius) * radius;

    const middleIndex = 0; //Math.round(axeParam.numLines / 2);

    const minDeltaRadius =
      -middleIndex * axeParam.lineGap * this.lineGapScale + radius;
    const maxDeltaRadius =
      (numLines - 1 - middleIndex) * axeParam.lineGap * this.lineGapScale +
      radius;

    const baseRadius = 1.2 * this.options.minRadius;

    //const lineGap = (minDeltaRadius > baseRadius ? minDeltaRadius/baseRadius : 1 ) * this.options.axesParams[axe].lineGap;
    const lineGap = axeParam.lineGap;

    const deltaRadius = (lineIndex - middleIndex) * lineGap * this.lineGapScale;

    radius = 0.4 + 0.3 * axeParam.random1 * this.options.minRadius;
    const middleRadius = this.options.minRadius; //radius;
    if (axeParam.spread) {
      radius += deltaRadius;
    }

    //get starting and ending point
    const averageAngularAxeSpan =
      (2 * Math.PI) / (this.options.enabledAxes || 7); //we do not want to divide by 0
    const middleDeg = averageAngularAxeSpan * (axeParam.enabledIndex + 0.5);
    let overlapCoeff;
    switch (this.options.enabledAxes) {
      case 7:
        overlapCoeff = 1.3;
        break;
      case 6:
        overlapCoeff = 1.1;
        break;
      case 5:
        overlapCoeff = 0.9;
        break;
      case 4:
        overlapCoeff = 0.7;
        break;
      default:
        overlapCoeff = 0.5;
        break;
    }

    const startDeg =
      middleDeg -
      0.5 * averageAngularAxeSpan * (1 + overlapCoeff * axeParam.random1);
    const endDeg =
      middleDeg +
      0.5 * averageAngularAxeSpan * (1 + overlapCoeff * axeParam.random2);

    const deltaDeg = endDeg - startDeg;

    //console.log(this.options.enabledAxes, axe, startDeg, endDeg);

    //const quaternion = new THREE.Quaternion();

    for (
      let deg = 0;
      deg <= deltaDeg;
      deg +=
        deg < 0.15 * deltaDeg || deg > 0.85 * deltaDeg
          ? 0.015 * deltaDeg
          : 0.1 * deltaDeg
    ) {
      const random =
        deg === 0 || deg >= deltaDeg ? 0.001 * (-0.5 + axeParam.random1) : 0;
      const randomizedBaseRadius = random + baseRadius;
      const degNormalized = Math.abs((2 * deg) / deltaDeg - 1);
      const param1 = eases[axeParam.ease](degNormalized);
      const param2 = eases[axeParam.ease2](degNormalized);
      const param =
        param2 *
          (1 - lineIndex / (numLines - 1)) *
          (1 + Math.sin(Math.PI * degNormalized)) +
        param1 * (lineIndex / (numLines - 1));
      let parametricRadius =
        param * randomizedBaseRadius + (1 - param) * radius;
      parametricRadius +=
        0.1 *
        Math.sin(
          ((axeParam.sinFreq + axeParam.sinOffset) * Math.PI * deg) / deltaDeg
        ) *
        (1 - lineIndex / (numLines - 1));

      const point = new THREE.Vector3(
        parametricRadius * Math.sin(startDeg + deg),
        parametricRadius * Math.cos(startDeg + deg),
        10 //+ (1 - param) * parametricRadius// + param * Math.sin(startDeg + deg) + (1 - param) * Math.cos(startDeg + deg)
      );

      /*
      if (middleLine) {
        const parametricMiddleRadius = param * randomizedBaseRadius + (1 - param) * middleRadius;
        const t = deg / deltaDeg;
        const tangent = middleLine.curve.getTangent(t);
        const mp = new THREE.Vector3(
          parametricMiddleRadius * Math.sin(startDeg + deg),
          parametricMiddleRadius * Math.cos(startDeg + deg),
          10 + (1 - param) * parametricRadius// + param * Math.sin(startDeg + deg) + (1 - param) * Math.cos(startDeg + deg)
        );
        point.sub(mp);
        quaternion.setFromAxisAngle(tangent, startDeg + deg);

        point.applyQuaternion(quaternion);

        point.add(mp);
      }
      */

      cpoints.push(point);
    }

    return cpoints;
  };

  updateValues = () => {
    if (this.props.useData !== false) {
      this.calculateAxesParams();
    }
    this.createScene();
  };

  updateRendererSize = () => {
    if (!this.container) {
      return;
    }

    const w = this.container.clientWidth;
    const h = this.options.outputCloud
      ? window.innerHeight
      : this.container.clientHeight;

    if (w !== this.rendererWidth || h !== this.rendererHeight) {
      this.resize();
    }
  };

  calculateAxesParams = calculateAxesParams.bind(this);

  i_point = new THREE.Vector3();

  bg_color_uniform = new THREE.Color();

  //mouse_uniform = new THREE.Vector3();

  resolution = new THREE.Vector2();

  shapeRotation = new THREE.Vector3(0, 0, 0);

  update = (time, delta) => {
    this.updateRendererSize();

    const el = this.canvas.current;
    if (!el || (el.offsetWidth <= 0 && el.offsetHeight <= 0)) {
      return;
    }

    const t = time / 500;
    const ampl = this.options.noise_amplitude; //0.15;//0.2 * Math.sin(t);
    const time_freq = this.options.time_freq;
    const freq = this.options.freq_particles;
    const lineWidth = this.options.lineWidth;

    const g = (this.lines && this.lines.length) || 0;
    // const threshold = g / 2;

    /*
    this.raycaster.ray.intersectPlane(
      new THREE.Plane(new THREE.Vector3(0, 0, 1), -10),
      this.i_point
    );
    */

    for (let i = 0; i < g; i++) {
      const group = this.lines[i];
      const lines = group.children;
      const l = lines.length;

      for (let j = 0; j < l; j++) {
        const uniforms = lines[j].material.uniforms;
        uniforms.time.value = t;
        uniforms.time_freq.value = time_freq;
        uniforms.ampl.value = ampl;
        uniforms.freq.value = freq;
        uniforms.lineWidth.value = lineWidth;
        uniforms.resolution.value = this.resolution.set(
          this.rendererWidth,
          this.rendererHeight
        );

        let opacity = uniforms.opacity.value;
        if (this.options.axesParams[i].enabled) {
          opacity +=
            time - lines[j].targetOpacityStagger > 0
              ? lines[j].targetOpacitySpeed *
                (lines[j].targetOpacity - uniforms.opacity.value)
              : 0;
        } else {
          opacity = 0;
        }

        uniforms.opacity.value = opacity;

        uniforms.color.value.lerp(
          lines[j].targetColor,
          lines[j].targetColorSpeed
        );
      }

      /*
        const shapeRotationAmount = 1;

        this.shapeRotation.set(1, 1, 0).normalize();

        this.shape.translateZ(10);

        this.shape.setRotationFromAxisAngle(this.shapeRotation, 0.02 * Math.PI);

        this.shape.translateZ(-10);
      */

      //uniforms.mouse.value = this.mouse_uniform.set(
      //  this.i_point.x,
      //  this.i_point.y,
      //  10
      //);

      /*
        this.particlePhysics.update(
          time,
          delta,
          this.raycaster.intersectObjects(this.particles)
        );
      */
    }

    if (this.cloud && this.cloud[0]) {
      const uniforms = this.cloud[0].material.uniforms;
      uniforms.time.value = t;
      uniforms.time_freq.value = this.options.cloud_time_freq;
      uniforms.ampl.value = this.options.cloud_noise_amplitude;
      uniforms.freq.value = this.options.cloud_freq_particles;
      uniforms.bg_color.value = this.bg_color_uniform.set(this.bg_color);

      const hoverState = this.cloud[0].geometry.getAttribute('hoverState');

      this.raycaster.setFromCamera(this.mouse, this.camera);

      // calculate objects intersecting the picking ray
      var intersects = this.raycaster.intersectObjects(this.cloud);

      const intersect_indexes = [];
      if (intersects.length) {
        for (let i = 0, il = intersects.length; i < il; i++) {
          intersect_indexes[intersects[i].index] = true;
        }
      }

      this.cloudPhysics.update(time, delta, intersects);

      for (let i = 0, l = hoverState.count; i < l; i++) {
        const val = hoverState.getX(i);
        if (intersect_indexes[i]) {
          if (val < 1) {
            hoverState.setX(i, Math.min(val + 15 * delta, 1));
            hoverState.needsUpdate = true;
          }
        } else {
          if (val > 0) {
            hoverState.setX(i, Math.max(val - 15 * delta, 0));
            hoverState.needsUpdate = true;
          }
        }
      }
    }
  };

  animate = time => {
    this.raf = null;
    if (!this.canvas) {
      // component is unmounted
      return;
    }

    this.stats.end();
    let delta = 1 / 60;
    if (this.prevTime) {
      delta = (time - this.prevTime) / 1000;
    }

    this.time += 1000 / 60;

    this.update(time, 1 / 60);
    //this.composer.render();

    if (!time || !this.prevTime) {
      this.renderer.render(this.scene, this.camera);
      this.prevTime = time;
    } else if (time - this.prevTime > 1000 / 60) {
      this.renderer.render(this.scene, this.camera);
      this.prevTime = time;
    }

    if (!this.paused) {
      if (!this.isReady) {
        this.isReady = true;

        this.setState({
          isReady: true,
        });
      }

      this.raf = requestAnimationFrame(this.animate);
    } else {
      if (this.isReady) {
        this.isReady = false;

        this.setState({
          isReady: false,
        });
      }
    }
    this.stats.begin();
  };

  render() {
    const { isReady, isResizing } = this.state;

    return (
      <canvas
        ref={this.canvas}
        className={classnames(`lifeline-shape`, this.props.className, {
          cloud: !this.options.lines,
          'fade-in': isReady && !isResizing,
        })}
      />
    );
  }
}
