import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

const SpotifyPlayer = ({ url, artist, title }) => {
  const iframeUrl = url.replace(/spotify\.com/, 'spotify.com/embed');

  const [isActive, setActive] = useState(false);

  const showPlayer = useCallback(() => {
    if (!isActive) {
      setActive(true);
    }
  }, [isActive]);

  const className = classnames('lifeline-birthday__spotify', {
    'lifeline-birthday__spotify--active': isActive,
  });

  return (
    <div className={className}>
      <div
        className="lifeline-birthday__spotify-start-wrapper"
        onMouseOver={showPlayer}
        onClick={showPlayer}
      >
        <button className="lifeline-birthday__spotify-start">
          <i />
        </button>

        <div className="lifeline-birthday__spotify-start-info">
          <div className="lifeline-birthday__spotify-title">{title}</div>
          <div className="lifeline-birthday__spotify-artist">{artist}</div>
        </div>
      </div>

      <iframe
        className="lifeline-birthday__spotify-iframe"
        src={iframeUrl}
        width="300"
        height="80"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  );
};

export default SpotifyPlayer;
