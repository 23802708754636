import React, { useState, useCallback, useEffect, useRef } from 'react';
import classnames from 'classnames';

import './style.css';

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

export default ({
  ownerFirstName,
  ownerRelationship,
  lifelineName,
  memories,
  active: isActive,
  color,
}) => {
  const quoteSymbol = useRef(null);
  const playerTimerID = useRef(null);
  const isInView = useRef(false);
  const sliderWrapper = useRef(null);
  const memoriesRef = useRef(null);

  const [x0, setX0] = useState(null);
  const [x, setX] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const quotesRef = useRef();

  const getTimeout = useCallback(
    i => {
      const index = typeof i === 'undefined' ? currentIndex : i;

      let wait = 3000;

      if (
        memories.length > index &&
        memories[index] &&
        memories[index].description
      ) {
        wait = Math.max(
          (memories[index].description.split(' ').length / 200) * 60000,
          3000
        );
      }

      return wait;
    },
    [memories, currentIndex]
  );

  const goToIndex = (index, noTransition = false) => {
    const activeQuote = memoriesRef.current.querySelector(
      '.lifeline-memories__quote.active'
    );

    if (!activeQuote) {
      return;
    }

    const activeQuoteHeight = activeQuote.clientHeight;

    if (noTransition) {
      quotesRef.current.style.transition = `none`;
    }

    quotesRef.current.style.height = `${activeQuoteHeight}px`;

    requestAnimationFrame(() => {
      if (!quotesRef.current) {
        return;
      }

      const nextElement = memoriesRef.current.querySelector(
        `.lifeline-memories__quote[data-index="${index}"]`
      );
      quotesRef.current.style.height = `${nextElement.clientHeight}px`;

      if (noTransition) {
        quotesRef.current.style.transition = ``;
      }

      if (playerTimerID.current) {
        clearTimeout(playerTimerID.current);
        playerTimerID.current = null;
      }

      if (isInView.current) {
        playerTimerID.current = setTimeout(autoPlayTimer, getTimeout(index));
      }

      setCurrentIndex(index);
    });
  };

  const onTouchStart = useCallback(e => {
    setX0(e.touches[0].clientX);
  }, []);

  const onTouchMove = useCallback(
    e => {
      if (x0 === null) {
        return;
      }

      setX(e.touches[0].clientX);
    },
    [x0]
  );

  const onTouchEnd = useCallback(() => {
    if (x !== 0) {
      let index = currentIndex;
      const dX = x0 - x;

      if (Math.abs(dX) > 25) {
        index = dX > 0 ? index + 1 : index - 1;
        index = mod(index, memories.length);

        goToIndex(index);
      }
    }

    setX(0);
    setX0(null);
  }, [x0, x, currentIndex, memories]);

  const onMouseDown = useCallback(e => {
    setX0(e.clientX);
  }, []);

  const onMouseMove = useCallback(
    e => {
      if (x0 === null) {
        return;
      }

      setX(e.clientX);
    },
    [x0]
  );

  const onMouseUp = useCallback(() => {
    if (x !== 0) {
      let index = currentIndex;
      const dX = x0 - x;

      index = dX > 0 ? index + 1 : index - 1;
      index = mod(index, memories.length);

      goToIndex(index);
    }

    setX(0);
    setX0(null);
  }, [currentIndex, x0, x, memories]);

  const navigateTo = useCallback(index => {
    goToIndex(index);
  }, []);

  useEffect(() => {
    goToIndex(0);
  }, []);

  const autoPlayTimer = () => {
    if (!isInView.current) {
      return;
    }

    setCurrentIndex(i => {
      let next = i + 1;

      if (next === memories.length) {
        next = 0;
      }

      goToIndex(next);
      return i;
    });
  };

  const onResize = useCallback(() => {
    let height = 0;

    const quotes = memoriesRef.current.querySelectorAll(
      '.lifeline-memories__quote'
    );
    const dots = memoriesRef.current.querySelector(
      '.lifeline-memories__quotes-dots'
    );
    const quotesSymbol = memoriesRef.current.querySelector(
      '.lifeline-memories__quotes-symbol'
    );

    quotes.forEach(q => {
      height = Math.max(q.offsetHeight, height);
    });

    height += quotesSymbol.offsetHeight;
    height += dots ? dots.offsetHeight : 0;

    height += 200; // add v padding

    memoriesRef.current.style.height = `${height}px`;

    const activeQuote = memoriesRef.current.querySelector(
      '.lifeline-memories__quote.active'
    );

    if (activeQuote) {
      goToIndex(parseInt(activeQuote.getAttribute('data-index'), 10), true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    if (!isActive) {
      if (playerTimerID.current) {
        clearTimeout(playerTimerID.current);
        playerTimerID.current = null;
      }

      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        const inView = entries[0].intersectionRatio >= 0.1;
        isInView.current = inView;

        if (inView) {
          if (!playerTimerID.current) {
            playerTimerID.current = setTimeout(autoPlayTimer, getTimeout());
          }
        } else {
          if (playerTimerID.current) {
            clearTimeout(playerTimerID.current);
            playerTimerID.current = null;
          }
        }
      },
      {
        threshold: [0.1],
      }
    );

    observer.observe(memoriesRef.current);

    return () => {
      observer.disconnect();

      if (playerTimerID.current) {
        clearTimeout(playerTimerID.current);
        playerTimerID.current = null;
      }
    };
  }, [isActive]);

  return (
    <div
      className="lifeline-memories anim-slide-in"
      ref={memoriesRef}
      style={{ color }}
    >
      <span ref={quoteSymbol} className="lifeline-memories__quotes-symbol">
        “
      </span>
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        ref={sliderWrapper}
        className="lifeline-memories__quotes-wrapper"
      >
        <ul
          ref={quotesRef}
          className="lifeline-memories__quotes"
          style={{
            transform: `translateX(-${currentIndex * 100}%) translateZ(0)`,
          }}
        >
          {memories.map((memory, index) => {
            return (
              <li
                key={`family-memory--${index}`}
                data-index={`${index}`}
                className={classnames('lifeline-memories__quote', {
                  active: currentIndex === index,
                })}
              >
                <div className="lifeline-memories__quote-text-wrapper">
                  <p className="lifeline-memories__quote-text">
                    {memory.description}
                  </p>
                </div>
                {memory.author && (
                  <p className="lifeline-memories__quote-author">
                    {memory.author}
                  </p>
                )}
                {!memory.author && (
                  <p className="lifeline-memories__quote-author">
                    {ownerFirstName}
                  </p>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {memories.length > 1 && (
        <div
          className="lifeline-memories__quotes-dots"
          style={{
            pointerEvents: 'auto',
          }}
        >
          {memories.map((_, index) => (
            <span
              key={index}
              onClick={() => navigateTo(index)}
              className={classnames('lifeline-memories__quotes-dot-wrapper', {
                active: index === currentIndex,
              })}
            >
              <span
                style={{
                  color,
                }}
                className={classnames('lifeline-memories__quotes-dot', {
                  active: index === currentIndex,
                })}
              ></span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
