import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import DatePicker from '@/components/DatePicker';
import getPossessivePronoun from '@/utils/getPossessivePronoun';

import Map from '../Map';

import './style.css';

const DeathPlace = ({
  lifeline,
  active: isActive,
  exiting: _,
  entering: __,
  ...props
}) => {
  const {
    data: {
      remembering: {
        firstName,
        lastName,
        sex,
        birthDate,
        deathDate,
        deathCountry,
        deathTown,
        deathState,
        deathDetails,
        deathPlaceGeoJSON,
      },
    },
  } = lifeline;

  const coords = deathPlaceGeoJSON
    ? [parseFloat(deathPlaceGeoJSON.lng), parseFloat(deathPlaceGeoJSON.lat)]
    : [0, 0];

  const deathDateAsMoment = moment(deathDate, 'YYYYMMDD');
  const numOfDays =
    deathDateAsMoment.diff(moment(birthDate, DatePicker.dateFormat), 'days') +
    1;

  const formattedNumOfDays = new Intl.NumberFormat('en-GB').format(numOfDays);
  const pronoun = getPossessivePronoun(sex);
  const fullNameWithPossessive = lastName.endsWith('s')
    ? `${firstName} ${lastName}'`
    : `${firstName} ${lastName}'s`;

  const deathPlace = `in ${[deathTown, deathState, deathCountry]
    .filter(v => v)
    .join(', ')}.`;

  const cleanupDeathDetails = deathDetails ? deathDetails.trim() : '';

  return (
    <div
      className={classnames('lifeline-death-place', {
        'lifeline-death-place--enter': isActive,
      })}
      {...props}
    >
      <div className="lifeline-death-place__beacon" />
      <div className="lifeline-death-place__map">
        <Map
          id="deathMap"
          coords={coords}
          useZoom={4}
          markers={[
            {
              coordinates: coords,
              children: (
                <div className="map-pin map-pin--death-place map-pin0">
                  <span />
                </div>
              ),
            },
          ]}
          topBannerAnimation={true}
          topBanner={
            <h2 className="lifeline-death-place__heading">
              {cleanupDeathDetails ||
                `${firstName} died on ${deathDateAsMoment.format(
                  'D MMMM YYYY'
                )} ${deathPlace}`}
            </h2>
          }
          bottomBanner={
            <h2 className="lifeline-death-place__days">
              After {formattedNumOfDays} days on this earth, this marks the
              completion of {fullNameWithPossessive} lifeline. But by no means
              the end of {pronoun} memory.
            </h2>
          }
        />
      </div>
    </div>
  );
};

export default DeathPlace;
