import React, { useState } from 'react';
import classnames from 'classnames';

import useDescriptions from './useDescriptions';
import './style.css';

const InterestsDescriptions = ({
  lifeline,
  active: isActive,
  exiting: _,
  entering: __,
  ...props
}) => {
  const {
    data: {
      interests: { interests, interestsDescriptions = [] },
    },
  } = lifeline;

  const descriptions = useDescriptions(
    interests,
    interestsDescriptions,
    lifeline
  );

  return (
    <div className="lifeline-interests-descriptions" {...props}>
      <ul>
        {descriptions.map((d, index) => (
          <li
            key={`interest-description--${d.name}`}
            className={classnames(
              'lifeline-interests-descriptions__item',
              'anim-slide-in'
            )}
            style={{
              '--slide-in-delay': `${index * 0.2}s`,
            }}
          >
            <div className="lifeline-interests-descriptions__item-title">
              {d.title}
            </div>
            <div className="lifeline-interests-descriptions__item-details">
              {d.description}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InterestsDescriptions;
