import apiFetch from '@/api/apiFetch';

export default async (uuid, data) => {
  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data,
    }),
  };

  const response = await apiFetch(`lifelines/${uuid}`, options, 200);

  return await response.json();
};
