import 'react-hot-loader';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import smoothScroll from 'smoothscroll-polyfill';
smoothScroll.polyfill();
import './polyfills';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import App from '@/components/App';
import history from '@/utils/history';

import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={getPersistor()}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.querySelector('#app')
);
