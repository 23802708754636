const songs = [
  {
    id: '5062dd09-245a-4077-8799-e0ae9ba599d0',
    url:
      'https://cdn.memories.com/songs/a-mighty-fortress-is-our-god-hymn-121-from-the-army-and-navy-hymnal.mp3',
    name:
      'A Mighty Fortress Is Our God, Hymn 121 from The Army and Navy Hymnal',
    personalities: ['protective', 'calm', 'reserved', 'thoughtful'],
  },
  {
    id: '2958ba1c-6405-4b45-8f38-8e59fca820fe',
    url:
      'https://cdn.memories.com/songs/air-on-the-g-string-performed-by-the-air-force-strings-of-the-united-states-air-force-band.mp3',
    name:
      'Air on the G String performed by the Air Force Strings of the United States Air Force Band',
    personalities: ['protective', 'altruistic', 'sociable', 'thoughtful'],
  },
  {
    id: '3ada2700-0ac6-4863-93bf-8ed5c323a22e',
    url: 'https://cdn.memories.com/songs/amazing-grace-us-marine-band.mp3',
    name: 'Amazing Grace US Marine Band',
    personalities: ['leadership', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: 'ec2fcdfa-3b5e-47ef-9a60-f580a2b71676',
    url: 'https://cdn.memories.com/songs/amazing-grace.mp3',
    name: 'Amazing Grace',
    personalities: ['calm', 'reserved', 'thoughtful'],
  },
  {
    id: 'a490323c-612c-483e-87d4-45d3a61cf046',
    url:
      'https://cdn.memories.com/songs/america-the-beautiful-brass-quintet-united-states-navy-band.mp3',
    name: 'America the Beautiful - Brass Quintet - United States Navy Band',
    personalities: ['altruistic', 'sociable', 'calm', 'reserved'],
  },
  {
    id: '17aefa2e-c6bd-472a-b22e-9bf3f268235d',
    url:
      'https://cdn.memories.com/songs/bach-ave-maria-in-due-performed-by-the-concert-band-of-the-united-states-air-force-heritage-of-america-band.mp3',
    name:
      'Bach_Ave Maria in Due performed by the Concert Band of the United States Air Force Heritage of America Band',
    personalities: ['leadership', 'eccentric', 'innovator'],
  },
  {
    id: 'b78a6c5b-394a-4659-a72c-d69234d85791',
    url:
      'https://cdn.memories.com/songs/battle-hymn-of-the-republic-traditional.mp3',
    name: 'Battle Hymn of the Republic, Traditional',
    personalities: ['sociable', 'innovator', 'charismatic'],
  },
  {
    id: '1fab5f6a-e73f-4c86-8c54-d2cfeeb4b34c',
    url: 'https://cdn.memories.com/songs/be-thou-my-vision.mp3',
    name: 'Be thou my vision',
    personalities: ['sociable', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '9dcf3782-af74-436d-a793-520bcb174ff7',
    url:
      'https://cdn.memories.com/songs/beethoven-piano-sonata-no-14-in-c-sharp-minor-op-27-no-2-moonlight-adagio-sostenuto.mp3',
    name:
      'Beethoven Piano Sonata No. 14 in C sharp minor Op. 27 No. 2 - Moonlight - Adagio Sostenuto',
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: '614d1821-db9e-4c45-9484-7571c0cea054',
    url: 'https://cdn.memories.com/songs/beethoven-moonlight-2nd-movement.mp3',
    name: 'Beethoven_Moonlight_2nd_movement',
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: 'd4354055-cb8d-46b2-bf3e-eff0956e5ed6',
    url:
      'https://cdn.memories.com/songs/bertha-houston-we-are-americans-praise-the-lord.mp3',
    name: 'Bertha Houston - We are Americans, Praise the Lord',
    personalities: ['eccentric', 'innovator', 'entrepreneur'],
  },
  {
    id: '4d97804c-fd74-4368-8ac0-e99bdb6d9de3',
    url:
      'https://cdn.memories.com/songs/blessed-assurance-jesus-is-mine-hymn-212-from-the-army-and-navy-hymnal.mp3',
    name:
      'Blessed Assurance, Jesus is Mine, Hymn 212 from The Army and Navy Hymnal',
    personalities: ['protective', 'reserved', 'thoughtful'],
  },
  {
    id: 'f59cb72e-5906-443e-8f7a-d8de27150a6d',
    url: 'https://cdn.memories.com/songs/chopin-etude-op-10-n-3.mp3',
    name: 'Chopin Etude op 10 n 3',
    personalities: ['protective', 'thoughtful'],
  },
  {
    id: '1f432252-6b6c-4889-863b-298ef51aeea4',
    url: 'https://cdn.memories.com/songs/chopin-fantasie-impromptu-op-66.mp3',
    name: 'Chopin Fantasie-Impromptu(Op.66)',
    personalities: ['leadership', 'eccentric'],
  },
  {
    id: '580064db-abcf-45c5-8b28-22779cdd12ae',
    url:
      'https://cdn.memories.com/songs/chopin-nocturne-in-e-flat-major-op-9-no-2.mp3',
    name: 'Chopin_Nocturne in E flat major, Op. 9 no. 2',
    personalities: ['calm', 'reserved', 'thoughtful'],
  },
  {
    id: '40ace9e1-1efb-406a-84c8-4eb6884cbee1',
    url:
      'https://cdn.memories.com/songs/chopin-waltz-in-a-minor-op-34-no-2.mp3',
    name: 'Chopin_Waltz in A minor, Op. 34 no. 2',
    personalities: ['leadership', 'eccentric', 'charismatic'],
  },
  {
    id: 'fd95d1b7-b23e-4ca5-9bb8-9202721ca616',
    url:
      'https://cdn.memories.com/songs/chopin-piano-concerto-no-1-ii-romance-larghetto.mp3',
    name: 'Chopin; Piano Concerto No. 1, II. Romance-Larghetto',
    personalities: ['altruistic', 'calm', 'reserved'],
  },
  {
    id: 'b2e70890-eafa-4d6b-b080-f3c089a69528',
    url:
      'https://cdn.memories.com/songs/coastline-state-of-mind-silver-wings-united-states-air-force-band.mp3',
    name:
      'Coastline State of Mind - Silver Wings - United States Air Force Band',
    personalities: ['calm', 'reserved'],
  },
  {
    id: '1f9a0556-3dc6-4bcc-9555-8f09fa59baf5',
    url:
      'https://cdn.memories.com/songs/cochran-field-singers-my-lord-is-writin-.mp3',
    name: "Cochran Field Singers - My Lord Is Writin'",
    personalities: ['sociable', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '4524c3a2-3f2b-4695-880c-e001b0d7489a',
    url:
      'https://cdn.memories.com/songs/come-thou-fount-of-every-blessing-hymn-112-from-the-army-and-navy-hymnal.mp3',
    name:
      'Come, Thou Fount of Every Blessing, Hymn 112 from The Army and Navy Hymnal',
    personalities: ['protective', 'thoughtful'],
  },
  {
    id: '2fd9803a-941b-460f-861a-5401f894ddf2',
    url:
      'https://cdn.memories.com/songs/crown-him-with-many-crowns-1851-christian-hymn-written-by-matthew-bridges-and-godfrey-thring.mp3',
    name:
      'Crown Him with Many Crowns, 1851 Christian hymn written by Matthew Bridges and Godfrey Thring',
    personalities: ['leadership', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '4b936d52-108f-4f85-b0bf-4acc41f65e59',
    url: 'https://cdn.memories.com/songs/deep-river-traditional.mp3',
    name: 'Deep River, Traditional',
    personalities: ['altruistic', 'calm', 'reserved'],
  },
  {
    id: '593192bf-c882-47ad-8200-f560e2042954',
    url:
      'https://cdn.memories.com/songs/f-schubert-ave-maria-in-ukrainian-translation-by-yuri-otroshenkо.mp3',
    name: 'F. Schubert. Ave Maria in Ukrainian translation by Yuri Otroshenkо',
    personalities: ['leadership', 'eccentric', 'innovator'],
  },
  {
    id: '835b34cd-59ef-4c03-90db-519b25cb0446',
    url:
      'https://cdn.memories.com/songs/go-tell-it-on-the-mountain-airmen-of-note-united-states-air-force-band.mp3',
    name:
      'Go Tell It on the Mountain - Airmen of Note - United States Air Force Band',
    personalities: ['leadership', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '5994a48e-bff4-44a9-8067-ad3f0450e48f',
    url:
      'https://cdn.memories.com/songs/hello-dolly-ladra-alexandre-imitation-of-louis-armstrong.mp3',
    name: 'Hello Dolly, LADRA Alexandre Imitation of Louis Armstrong',
    personalities: ['sociable', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '35fd45a6-353a-4ed3-a4ac-5fbc27f7445d',
    url: 'https://cdn.memories.com/songs/holy-holy-holy.mp3',
    name: 'Holy, Holy, Holy',
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: 'b327183d-15d6-41b1-ba01-50ac978c0cb2',
    url:
      'https://cdn.memories.com/songs/i-love-what-i-do-silver-wings-united-states-air-force-band.mp3',
    name: 'I Love What I Do - Silver Wings - United States Air Force Band',
    personalities: ['leadership', 'innovator', 'entrepreneur', 'charismatic'],
  },
  {
    id: '8c1b0cc6-d6b4-4493-8441-1a3e55c1764a',
    url:
      'https://cdn.memories.com/songs/i-will-silver-wings-united-states-air-force-band.mp3',
    name: 'I Will - Silver Wings - United States Air Force Band',
    personalities: ['eccentric', 'innovator', 'entrepreneur'],
  },
  {
    id: '3f5e2929-f98d-40d0-b2a6-da3661a2fbdf',
    url:
      'https://cdn.memories.com/songs/la-bayamesa-united-states-navy-band.mp3',
    name: 'La Bayamesa, United States Navy Band',
    personalities: ['altruistic', 'sociable', 'charismatic'],
  },
  {
    id: '617544a7-37cb-4f57-be88-b070775550db',
    url:
      'https://cdn.memories.com/songs/lift-every-voice-and-sing-united-states-navy-band.mp3',
    name: 'Lift Every Voice and Sing - United States Navy Band',
    personalities: ['protective', 'calm', 'thoughtful'],
  },
  {
    id: 'c4cbc6d0-d1bd-47d3-b1ab-6c9daf4fb8ee',
    url:
      'https://cdn.memories.com/songs/livin-the-air-force-life-silver-wings-united-states-air-force-band.mp3',
    name:
      "Livin' the Air Force Life - Silver Wings - United States Air Force Band",
    personalities: ['sociable', 'innovator', 'charismatic'],
  },
  {
    id: '6a51548f-5fb4-4019-821e-160871b189ec',
    url: 'https://cdn.memories.com/songs/mamie-smith-crazy-blues.mp3',
    name: 'Mamie Smith, Crazy Blues',
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: 'b685388f-739c-4842-bfed-7b428b7b7dc5',
    url: 'https://cdn.memories.com/songs/marines-hymn-slow-u-s-marine-band.mp3',
    name: "Marines' Hymn (slow) - U.S. Marine Band",
    personalities: ['eccentric'],
  },
  {
    id: 'ad800178-a9cb-4d6e-a4b5-cc3a07cf0c50',
    url:
      'https://cdn.memories.com/songs/middle-georgia-singers-death-is-an-awful-thing.mp3',
    name: 'Middle Georgia Singers - Death is an Awful Thing',
    personalities: ['eccentric', 'innovator', 'entrepreneur'],
  },
  {
    id: 'cc7a3258-6e4e-4859-aacc-43e30de822a8',
    url:
      'https://cdn.memories.com/songs/mozart-eine-kleine-nachtmusik-1-allegro.mp3',
    name: 'Mozart - Eine kleine Nachtmusik - 1. Allegro',
    personalities: ['sociable', 'leadership', 'charismatic'],
  },
  {
    id: '457f653a-5915-4fea-82e0-138752cac85e',
    url:
      'https://cdn.memories.com/songs/mozart-us-army-mozart-overture-to-the-marriage-of-figaro.mp3',
    name: 'Mozart - US ARMY - Mozart overture to the marriage of figaro',
    personalities: ['sociable', 'innovator', 'charismatic'],
  },
  {
    id: '8a61195d-794f-44f1-969d-b143c466716f',
    url:
      'https://cdn.memories.com/songs/mozart-piano-concerto-no-24-ii-larghetto.mp3',
    name: 'Mozart Piano Concerto No. 24, II. Larghetto',
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: 'e4efd8cc-b815-4af8-929b-517403f4605d',
    url:
      'https://cdn.memories.com/songs/mozart-concerto-no-12-in-a-i-allegro.mp3',
    name: 'Mozart; Concerto No. 12 in A- I. Allegro',
    personalities: ['altruistic', 'innovator', 'charismatic'],
  },
  {
    id: 'ba4de93f-0785-4113-9370-cdfefed9b141',
    url:
      'https://cdn.memories.com/songs/mozart-concerto-no-12-in-a-ii-andante.mp3',
    name: 'Mozart; Concerto No. 12 in A- II. Andante',
    personalities: ['altruistic', 'calm', 'thoughtful'],
  },
  {
    id: '9526633d-9fac-4b61-ac7d-08e2eef53d81',
    url:
      'https://cdn.memories.com/songs/mozart-concerto-no-20-in-d-minor-ii-romanza.mp3',
    name: 'Mozart; Concerto No. 20 in D Minor- II. Romanza',
    personalities: ['altruistic', 'sociable', 'charismatic'],
  },
  {
    id: 'ff21f1e4-b666-4f9e-a279-296fabdec4a7',
    url: 'https://cdn.memories.com/songs/mozart-clarinet-concerto-2-adagio.mp3',
    name: 'Mozart: Clarinet Concerto - 2. Adagio',
    personalities: ['protective', 'altruistic', 'thoughtful'],
  },
  {
    id: 'e133843b-3655-42be-a79a-c2783ca88f86',
    url:
      'https://cdn.memories.com/songs/mozart-die-zauberflöte-performed-1953-released-1955-.mp3',
    name: 'Mozart: Die Zauberflöte; performed 1953, released 1955.',
    personalities: ['innovator', 'charismatic'],
  },
  {
    id: '8955175b-2475-44e1-bd4a-ab01cdbde162',
    url:
      'https://cdn.memories.com/songs/mozart-symphony-40-g-moll-kv-550-1-molto-allegro.mp3',
    name: 'Mozart: Symphony 40 g-moll, KV 550 - 1. Molto allegro',
    personalities: ['leadership', 'innovator', 'charismatic'],
  },
  {
    id: '79374492-b338-40aa-86bc-ec955d5e4179',
    url: 'https://cdn.memories.com/songs/o-canada-united-states-navy-band.mp3',
    name: 'O Canada, United States Navy Band',
    personalities: ['leadership', 'innovator', 'charismatic'],
  },
  {
    id: '99e7928c-9c23-4fdf-b11a-f019e8a2f3ed',
    url:
      'https://cdn.memories.com/songs/pachebel-canon-in-d-major-isrc-usuan1100301.mp3',
    name: 'Pachebel_Canon in D Major ISRC USUAN1100301',
    personalities: ['altruistic', 'sociable', 'leadership'],
  },
  {
    id: '15e994fa-2845-473c-b06e-f3dae2dc7263',
    url: 'https://cdn.memories.com/songs/pachelbel-s-canon.mp3',
    name: "Pachelbel's Canon",
    personalities: ['eccentric', 'reserved'],
  },
  {
    id: '5c1ea071-6f7f-4138-ac07-f31b9dce1294',
    url:
      'https://cdn.memories.com/songs/shenandoah-united-states-air-force-academy-band.mp3',
    name: 'Shenandoah - United States Air Force Academy Band',
    personalities: ['calm', 'reserved', 'thoughtful'],
  },
  {
    id: 'a3a11552-6800-4790-affe-5448a622e07e',
    url:
      'https://cdn.memories.com/songs/still-in-love-silver-wings-united-states-air-force-band.mp3',
    name: 'Still in Love - Silver Wings - United States Air Force Band',
    personalities: ['calm', 'reserved'],
  },
  {
    id: '1698360e-4a53-4871-ad2a-ec9d0af07a30',
    url: 'https://cdn.memories.com/songs/summertime.mp3',
    name: 'Summertime',
    personalities: ['protective', 'altruistic', 'thoughtful'],
  },
  {
    id: '294344f5-c360-4027-95f8-ceffd5d5c7d5',
    url:
      'https://cdn.memories.com/songs/taps-concert-band-united-states-air-force-heritage-of-america-band.mp3',
    name:
      'Taps - Concert Band - United States Air Force Heritage of America Band',
    personalities: ['calm', 'reserved'],
  },
  {
    id: '0ad6280b-e35f-41c3-b3c0-3c96fcf55977',
    url:
      'https://cdn.memories.com/songs/taps-marine-corps-band-manual-version.mp3',
    name: 'Taps (Marine Corps Band Manual version)',
    personalities: ['calm', 'reserved', 'thoughtful'],
  },
  {
    id: '37ad0cc5-e8f9-424a-b406-d9bd9fd55560',
    url:
      'https://cdn.memories.com/songs/that-s-just-me-crazy-over-you-silver-wings-united-states-air-force-band.mp3',
    name:
      "That's Just Me (Crazy Over You) - Silver Wings - United States Air Force Band",
    personalities: ['leadership', 'innovator', 'entrepreneur'],
  },
  {
    id: '9d7a2e25-577d-4c75-9974-5acb57a012a4',
    url:
      'https://cdn.memories.com/songs/the-four-brothers-death-come-a-knockin-.mp3',
    name: "The Four Brothers - Death Come a-Knockin'",
    personalities: ['sociable', 'innovator', 'charismatic'],
  },
  {
    id: '55c3a0e2-d2a5-49dd-befc-1b560552f5b0',
    url:
      'https://cdn.memories.com/songs/the-golden-jubilee-quartet-oh-jonah.mp3',
    name: 'The Golden Jubilee Quartet - Oh Jonah',
    personalities: ['leadership', 'entrepreneur', 'charismatic'],
  },
  {
    id: '744b25a8-b85b-40d9-9580-cfc06a13a627',
    url:
      'https://cdn.memories.com/songs/the-spiritual-four-quartet-john-the-revelator.mp3',
    name: 'The Spiritual Four Quartet - John the Revelator',
    personalities: ['leadership', 'eccentric'],
  },
  {
    id: '2d9ddc7f-c975-46ba-919f-984ce9ae005e',
    url: 'https://cdn.memories.com/songs/to-god-be-the-glory.mp3',
    name: 'To God Be The Glory',
    personalities: ['innovator', 'charismatic'],
  },
  {
    id: '4f5d4fc3-fc71-4124-832d-64d6a4a24f01',
    url: 'https://cdn.memories.com/songs/us-marine-band-rhapsody-in-blue.mp3',
    name: 'US Marine Band Rhapsody in Blue',
    personalities: ['leadership', 'eccentric'],
  },
  {
    id: '56a52eee-ef15-45b0-9451-c9204b2f6a8a',
    url:
      'https://cdn.memories.com/songs/what-does-it-mean-silver-wings-united-states-air-force-band.mp3',
    name: 'What Does It Mean - Silver Wings - United States Air Force Band',
    personalities: ['protective', 'calm', 'reserved', 'thoughtful'],
  },
  {
    id: 'fd733f7f-285d-40b6-9350-2974a04bf5d3',
    url: 'https://cdn.memories.com/songs/when-i-survey-the-wondrous-cross.mp3',
    name: 'When I Survey The Wondrous Cross',
    personalities: ['protective', 'calm', 'reserved', 'thoughtful'],
  },
];

export default songs;
