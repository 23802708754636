import moment from 'moment';

import { getState } from '@/store';
import cleanLifeline from '@/utils/cleanLifeline';

import getLifelineByUUID from '@/api/getLifelineByUUID';
import createLifeline from '@/api/createLifeline';
import updateLifeline from '@/api/updateLifeline';
import createHelpRequest from '@/api/createHelpRequest';

const TYPES = {
  remembering: {
    military: 'nullable-bool',
    funDescriptions: 'array',
    placesLived: 'array',
    adjectives: 'array',
    photo: 'nullable',
    deathDetails: 'nullable',
  },
  relationships: {
    importantRelationships: 'array',
    memories: 'array',
    photos: 'array',
  },
  achievements: {
    achievements: 'array',
  },
  interests: {
    interests: 'object',
  },
};

const BASE_LIFELINE_DATA = {
  remembering: {
    sex: 'x',
    military: false,
  },
  relationships: {},
  achievements: {},
  interests: {}, // **jc**
};

export default {
  state: {
    lifelineUUID: null,
    clean: true,
    isLegacy: null,
    errors: {},
    helpRequests: {},
    sectionEditor: {},
    sectionItemEditor: {},
    /* data */
    ...BASE_LIFELINE_DATA,
  },
  reducers: {
    replaceData(state, data) {
      return {
        ...state,
        clean: false,
        ...data,
      };
    },
    changeAnswer(state, { section, id, value }) {
      if (section === 'isLegacy') {
        // **jc** I need null value here.
        let newValue;
        if (value !== true && value != false) {
          newValue = null;
        } else {
          newValue = value;
        }
        return {
          ...state,
          isLegacy: newValue,
        };
      }

      return {
        ...state,
        clean: false,
        [section]: {
          ...state[section],
          [id]: value,
        },
      };
    },
    setIsLegacy(state, value) {
      // **jc** I need null value here.
      let newValue;
      if (value !== true && value != false) {
        newValue = null;
      } else {
        newValue = value;
      }
      return {
        ...state,
        isLegacy: newValue,
      };
    },
    markHelpRequest(state, { node, ref, mode }) {
      return {
        ...state,
        helpRequests: {
          ...state.helpRequests,
          [node]: [
            ...(state.helpRequests[node] || []),
            {
              node,
              ref,
              created_at: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS\\Z'),
              updated_at: null,
              status: 'waiting',
              mode,
            },
          ],
        },
      };
    },
    loadLifeline(state, { uuid, helpRequests, data, isLegacy }) {
      return {
        ...state,
        clean: false,
        lifelineUUID: uuid,
        isLegacy,
        helpRequests,
        ...cleanLifeline(data),
      };
    },
    setLifelineUUID(state, uuid) {
      return {
        ...state,
        clean: false,
        lifelineUUID: uuid,
      };
    },
    clearEditor(state) {
      return {
        ...state,
        clean: true,
        lifelineUUID: null,
        isLegacy: null,
        helpRequests: {},
        ...BASE_LIFELINE_DATA,
      };
    },
    openSectionEditor(state, { name, item, mode }) {
      return {
        ...state,
        sectionEditor: { name, item, mode },
      };
    },
    onDeleteEditorItem(state, { name, index }) {
      return {
        ...state,
        sectionItemEditor: { name, index },
      };
    },
    setError(state, { effect, error }) {
      return {
        ...state,
        errors: {
          ...state.errors,
          [effect]: {
            ...error,
          },
        },
      };
    },
    clearError(state, effect) {
      return {
        ...state,
        errors: {
          ...state.errors,
          [effect]: null,
        },
      };
    },
  },
  effects: dispatch => ({
    async fetchLifeline(uuid) {
      try {
        const lifeline = await getLifelineByUUID(uuid);

        if (!lifeline.is_owner) {
          throw new Error('Not found');
        }

        dispatch.editor.loadLifeline({
          uuid,
          helpRequests: lifeline.help_requests,
          data: lifeline.data,
          isLegacy: lifeline.is_legacy,
        });
        dispatch.editor.clearError('fetchLifeline');
      } catch (error) {
        console.log('e is:', error);
        dispatch.editor.setError({
          effect: 'fetchLifeline',
          error,
        });
      }
    },

    async createHelpRequest(payload) {
      // TODO: lifeline creation in memoryhelp call on BE
      try {
        const [
          created,
          lifelineUUID,
        ] = await dispatch.editor.createOrUpdateLifeline();
        payload.lifelineId = lifelineUUID;

        dispatch.editor.markHelpRequest({
          node: payload.node,
          ref: payload.ref,
          mode: payload.social ? 'social' : 'email',
        });

        const response = await createHelpRequest(payload);

        dispatch.editor.clearError('createHelpRequest');

        return {
          response,
          lifelineUUID,
          created,
        };
      } catch (error) {
        dispatch.editor.setError({
          effect: 'createHelpRequest',
          error,
        });
      }
    },

    async createOrUpdateLifeline() {
      const editorState = getState().editor;
      const payload = {
        isLegacy: editorState.isLegacy,
        remembering: editorState.remembering,
        relationships: editorState.relationships,
        achievements: editorState.achievements,
        interests: editorState.interests,
      };

      try {
        if (editorState.lifelineUUID === null) {
          const response = await createLifeline(payload);

          dispatch.editor.setLifelineUUID(response.uuid);
          dispatch.editor.replaceData(response.data);
          dispatch.editor.clearError('createOrUpdateLifeline');

          dispatch.user.addLifeline(response);
          dispatch.lifelines.removeAll();

          return [true, response.uuid];
        }

        const response = await updateLifeline(
          editorState.lifelineUUID,
          payload
        );

        dispatch.lifelines.removeAll();

        dispatch.editor.replaceData(response.data);

        dispatch.editor.clearError('createOrUpdateLifeline');

        dispatch.user.updateLifeline(response);

        return [false, response.uuid];
      } catch (error) {
        dispatch.editor.setError({
          effect: 'createOrUpdateLifeline',
          error,
        });
      }
    },
  }),
  selectors: {
    getError() {
      return ({ editor: { errors } }) => effect => errors[effect];
    },
    getLatestHelpRequestStatus() {
      return ({ editor: { helpRequests } }) => (node, ref = null) => {
        if (!helpRequests.hasOwnProperty(node)) {
          return null;
        }

        let requests = helpRequests[node];

        if (!Array.isArray(requests)) {
          return null;
        }

        if (!!ref) {
          requests = requests.filter(r => r.ref === ref);
        }

        const latestHelpRequest = requests.reduce(
          (a, { created_at: date, status }) => {
            if (!a) {
              return { date, status };
            }

            if (date > a.date) {
              return { date, status };
            }

            return a;
          },
          null
        );

        return latestHelpRequest && latestHelpRequest.status
          ? latestHelpRequest.status
          : null;
      };
    },
    hasPendingHelpRequest() {
      return ({ editor: { helpRequests } }) => (node, ref = null) => {
        if (!helpRequests.hasOwnProperty(node)) {
          return false;
        }

        let requests = helpRequests[node];

        if (!Array.isArray(requests)) {
          return false;
        }

        if (!!ref) {
          requests = requests.filter(r => r.ref === ref);
        }

        let lastAccepted;
        for (let i = 0; i < requests.length; i++) {
          const request = requests[i];

          if (request.status == 'accepted') {
            lastAccepted = request.changed_at;
            continue;
          }

          if (
            (request.status == 'waiting' || request.status == 'answered') &&
            (!lastAccepted ||
              (!!lastAccepted && request.created_at > lastAccepted))
          ) {
            return true;
          }
        }
      };
    },
    getAnswer() {
      return ({ editor }) => (section, id) => {
        // **jc** I need null value here.
        if (section === 'isLegacy') {
          return editor.isLegacy;
        }

        const rootSection = editor[section];

        if (rootSection[id] === undefined) {
          const type = TYPES[section][id] || 'string';

          switch (type) {
            case 'array':
              return [];
            case 'object':
              return {};
            case 'bool':
              return false;
            case 'nullable-bool':
              return null;
            case 'nullable':
              return null;
            default:
              return '';
          }
        }

        return rootSection[id];
      };
    },
    getLatestHelpRequestMode() {
      return ({ editor: { helpRequests } }) => (node, ref = null) => {
        if (!helpRequests.hasOwnProperty(node)) {
          return null;
        }

        let requests = helpRequests[node];

        if (!Array.isArray(requests)) {
          return null;
        }

        if (!!ref) {
          requests = requests.filter(r => r.ref === ref);
        }

        const latestHelpRequest = requests.reduce(
          (a, { created_at: date, mode }) => {
            if (!a) {
              return { date, mode };
            }

            if (date > a.date) {
              return { date, mode };
            }

            return a;
          },
          null
        );

        return latestHelpRequest && latestHelpRequest.mode
          ? latestHelpRequest.mode
          : null;
      };
    },
  },
};
