import getBaseApiURL from '@/utils/getBaseApiURL';
import store from '@/store';

export default async (endpoint, configuration, okStatus) => {
  let { appendSlash = true, ...options } = configuration;

  const state = store.getState();

  console.log('state', state);

  const userLogged = store.select.user.isLogged(state);
  const userToken = store.getState().user.token;

  if (userLogged) {
    options.headers = { ...options.headers, 'x-jwt': userToken };
  }

  let apiUrl = `${getBaseApiURL()}${endpoint}`;

  if (appendSlash) {
    apiUrl = `${apiUrl}/`;
  }

  let response;

  console.log('fetch', apiUrl, options);

  try {
    response = await fetch(apiUrl, options);
  } catch (e) {
    throw await errorHandling(null, e);
  }

  if (response.status !== okStatus) {
    throw await errorHandling(response);
  }

  return response;
};

async function errorHandling(response, error = null) {
  if (!response) {
    return {
      code: 'internet_connection',
      message: 'Please check your internet connection and try again',
      validation: [],
      error,
    };
  }

  if (response.status === 500) {
    return {
      code: 'internal_server_error',
      message:
        'Something went wrong while handling your request, please try again',
      validation: [],
      response: response,
    };
  }

  const body = await response.json();
  const originalStatusCode = body.original_status_code;

  if (
    response.status === 400 &&
    originalStatusCode &&
    originalStatusCode === 403
  ) {
    /* If the request fails with a 403, logout the user and redirect to the home */
    store.dispatch({
      type: 'user/clear',
    });

    if (typeof window !== 'undefined') {
      window.location.href = '/';
    }
  }

  const errorCode = body.code;

  if (typeof errorCode === 'string') {
    return {
      code: errorCode,
      message: body.detail,
      validation: [],
      response: response,
    };
  }

  return {
    code: 'validation',
    message: 'Please make sure all the fields are correct',
    validation: Object.entries(errorCode).reduce((c, [key, value]) => {
      c[key] = value.map((e, i) => ({
        code: e,
        message: body[key][i],
      }));

      return c;
    }, {}),
    response: response,
  };
}
