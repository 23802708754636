import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { tablet } from '@/components/App/breakpoints';

import capitalize from '@/utils/capitalize';
import getGenitiveNoun from '@/utils/getGenitiveNoun';
import getThirdPersonPronoun from '@/utils/getThirdPersonPronoun';
import isSSR from '@/utils/isSSR';

import Map from '../Map';
import stateDescriptions from './stateDescriptions';

const mapboxgl = !isSSR ? require('mapbox-gl') : null;

import './style.css';

class BirthPlace extends Component {
  static AUTOPLAY_PLACE_TIMER = 6000;

  overlay = createRef();

  containerWrapperRef = createRef();

  constructor(props) {
    super(props);

    const {
      data: { remembering },
    } = props.lifeline;

    const coords = remembering.bornPlaceGeoJSON
      ? [
          parseFloat(remembering.bornPlaceGeoJSON.lng),
          parseFloat(remembering.bornPlaceGeoJSON.lat),
        ]
      : [0, 0];

    this.state = {
      placesLived: [],
      sameContinent: true,
      activePlaceIndex: 0,
      isOnPlaces: false,
      markers: [],
      coords,
      useZoom: 4,
    };

    this.placesInView = [];
  }

  handleWindowKeyDown = e => {
    const { active: isActive } = this.props;
    const { key } = e;

    if (!isActive) {
      return;
    }

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      e.preventDefault();

      e.skipArrowScroll = true;

      const section = this.containerWrapperRef.current.parentElement
        .parentElement;

      if (key === 'ArrowUp') {
        if (section.scrollTop > 0) {
          e.stopPropagation();
        }
        section.scrollTop = 0;
      } else if (key === 'ArrowDown') {
        if (section.scrollTop + section.offsetHeight < section.scrollHeight) {
          e.stopPropagation();
        }
        section.scrollTop = section.scrollHeight;
      }
    }
  };

  setMap = map => {
    this.map = map;
    if (!this.state.sameContinent) {
      const bounds = new mapboxgl.LngLatBounds();
      this.markers.forEach(m => {
        bounds.extend(m.coords);
      });
      const data = this.map.cameraForBounds(bounds);
      console.log('different continents init', data);

      this.setState({
        coords: data.center,
        useZoom: Math.floor(data.zoom / 1.6, 10),
      });
    } else {
      this.setState({
        coords: this.markers[0].coords,
        useZoom: 4,
      });
    }
  };

  setMarkerAsActive(index) {
    const markerDom = this.containerWrapperRef.current.querySelector(
      `.map-pin${index + 1}`
    );

    // **jc**
    if (!markerDom) return;

    if (this.lastActiveDot) {
      this.lastActiveDot.classList.remove('active');
    }

    this.lastActiveDot = markerDom;
    if (markerDom === null) console.log(index, '+ 1');
    markerDom.classList.add('active');

    this.setState({
      activePlaceIndex: index,
    });
  }

  static getDerivedStateFromProps(props) {
    if (
      props.lifeline.data &&
      props.lifeline.data.remembering &&
      props.lifeline.data.remembering.placesLived
    ) {
      const placesLived = props.lifeline.data.remembering.placesLived.filter(
        p => p.geojson
      );

      return {
        placesLived,
        sameContinent: true,
      };
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleWindowKeyDown, true);
    window.addEventListener('resize', this.onResize);

    // setup beacons observers
    this.observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];

        if (this.state.isOnPlaces) {
          return;
        }

        if (entry.intersectionRatio > 0 && this.props.active) {
          this.addBornPlace();
          this.removePlaces();
          // this.stopActivePlaceTimer();

          this.flyToBornPlace();
        } else {
          this.removeBornPlace();
        }
      },
      { threshold: [0.1, 1] }
    );

    this.placesObserver = new IntersectionObserver(
      entries => {
        if (window.innerWidth < tablet) {
          return;
        }

        entries.forEach(entry => {
          const index = parseInt(entry.target.dataset.index || '-1', 10);

          // console.log(`place-waypoint #${index} observed`, entry);

          if (index < 0) {
            return;
          }

          if (entry.isIntersecting) {
            // console.log(`place-waypoint #${index} entered`, entry);

            this.placesInView.push(index);

            if (!this.state.isOnPlaces) {
              this.removeBornPlace();
              this.addPlaces();
              this.flyToPlaces();

              this.setState({
                isOnPlaces: true,
              });
            }

            clearTimeout(this.setMarkerTimeout);
            this.setMarkerTimeout = setTimeout(() => {
              this.setMarkerAsActive(index);
            }, 300);
          } else if (
            index === 0 &&
            entry.boundingClientRect.top >= window.innerHeight
          ) {
            // console.log(`place-waypoint #${index} exited`, entry);

            this.placesInView = [];

            this.removePlaces();
            this.addBornPlace();
            this.flyToBornPlace();

            this.setState({
              isOnPlaces: false,
            });
          } else {
            // console.log(`place-waypoint #${index} exited`, entry);

            this.placesInView = this.placesInView.filter(i => i !== index);

            if (this.placesInView.length) {
              setTimeout(() => {
                this.setMarkerAsActive(this.placesInView[0]);
              }, 300);
            }
          }
        });
      },
      { threshold: [0.1, 1] }
    );

    this.onInitMap();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.active && this.props.active) {
      this.observer.observe(document.querySelector('.lifeline-birthplace'));

      if (this.state.placesLived && this.state.placesLived.length != 0) {
        [
          ...document.querySelectorAll('.lifeline-birthplace__place-waypoint'),
        ].forEach(waypoint => {
          this.placesObserver.observe(waypoint);
        });
      }
    } else if (prevProps.active && !this.props.active) {
      this.observer.disconnect();
      this.placesObserver.disconnect();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.activePlaceTimer);

    window.removeEventListener('keydown', this.handleWindowKeyDown, true);
    window.removeEventListener('resize', this.onResize);

    this.observer.disconnect();
    this.placesObserver.disconnect();

    if (this.flyToBornPlaceAF) {
      this.flyToBornPlaceAF = cancelAnimationFrame(this.flyToBornPlaceAF);
    }
  }

  onResize = e => {
    if (this.map) {
      this.map.resize();
    }

    //if places
    if (this.state.isOnPlaces) {
      this.flyToPlaces();
    } else {
      this.flyToBornPlace();
    }
  };

  addBornPlace = () => {
    this.removing = false;
    this.removeBornPlace();

    const title = <h1>{this.markers[0].label}</h1>;
    const html = (
      <>
        <span />
        {title}
      </>
    );

    this.setState({
      markers: [
        {
          coordinates: this.markers[0].coords,
          children: (
            <div className="map-pin map-pin--birth-place map-pin0">{html}</div>
          ),
        },
      ],
    });
  };

  removeBornPlace = () => {
    if (this.state.markers.length) {
      this.setState({ markers: [] });
    }
  };

  flyToBornPlace = () => {
    // if (!this.state.sameContinent || !this.map) {
    //   return;
    // }
    if (!this.map) {
      return;
    }

    if (this.flyToBornPlaceAF) {
      this.flyToBornPlaceAF = cancelAnimationFrame(this.flyToBornPlaceAF);
    }
    this.flyToBornPlaceAF = requestAnimationFrame(() => {
      if (!this.flyToBornPlaceAF) {
        return;
      }
      const {
        data: {
          remembering: { bornPlaceGeoJSON },
        },
      } = this.props.lifeline;

      const coords = bornPlaceGeoJSON
        ? [parseFloat(bornPlaceGeoJSON.lng), parseFloat(bornPlaceGeoJSON.lat)]
        : [0, 0];

      this.setState({
        coords,
        useZoom: 4,
      });
    });
  };

  flyToPlaces = () => {
    //if (!this.state.sameContinent || !this.map) {
    //  return;
    //}
    if (this.state.placesLived.length > 1) {
      const bounds = new mapboxgl.LngLatBounds();
      this.markers.slice(1).forEach(m => {
        bounds.extend(m.coords);
      });
      const data = this.map.cameraForBounds(bounds);
      this.setState({
        coords: data.center,
        useZoom: Math.floor(data.zoom / 1.2, 10),
      });
    } else if (this.state.placesLived.length === 1) {
      this.setState({
        coords: this.markers[1].coords,
        useZoom: 4,
      });
    }
  };

  addPlaces = () => {
    this.removing = false;
    this.markerIndex = 1;

    this.setState({
      markers: this.markers.slice(1).map((m, index) => {
        const title = (
          <h1
            style={{
              animationDelay: `${0.2 + Math.random() * (1 - 0.2)}s`,
            }}
          >
            {m.label}
          </h1>
        );

        const html = (
          <>
            <span />
            {title}
          </>
        );

        return {
          coordinates: m.coords,
          children: (
            <div
              label={m.label}
              index={index + 1}
              className={`map-pin map-pin${index + 1}`}
            >
              {html}
            </div>
          ),
        };
      }),
    });
  };

  removePlaces = () => {
    this.removing = true;
    this.setState(state => {
      state.markers = state.markers.slice(0, 1);
      return state;
    });
  };

  onInitMap = ref => {
    const { lifeline } = this.props;
    const { placesLived } = this.state;
    const {
      data: {
        remembering: { bornPlaceGeoJSON },
      },
    } = lifeline;

    const coords = bornPlaceGeoJSON
      ? [parseFloat(bornPlaceGeoJSON.lng), parseFloat(bornPlaceGeoJSON.lat)]
      : [0, 0];

    this.markers = [
      {
        coords,
        object: null,
        label: '',
        subTitle: 'Place of birth',
      },
    ];

    placesLived.forEach(p => {
      const coords = [parseFloat(p.geojson.lng), parseFloat(p.geojson.lat)];

      this.markers.push({
        coords,
        object: null,
        label: p.town || p.state || p.country,
        subTitle: p.specification,
      });
    });
  };

  /*
                onMouseOver={() => {
                this.stopActivePlaceTimer();
                this.setMarkerAsActive(index - 1);
              }}
              onMouseOut={() => {
                this.restartActivePlaceTimer();
              }}

              */

  changeActiveClusterTimer = () => {
    if (!this.state.isOnPlaces) {
      return;
    }

    if (!this.props.active) {
      return;
    }

    let nextIndex = this.clusterCurrentView + 1;

    if (nextIndex >= this.clusterIndexes.length) {
      nextIndex = 0;
    }

    this.clusterCurrentView = nextIndex;

    this.setMarkerAsActive(this.clusterIndexes[nextIndex]);
    this.activeClusterTimer = setTimeout(
      this.changeActiveClusterTimer,
      BirthPlace.AUTOPLAY_PLACE_TIMER
    );
  };

  render() {
    const {
      lifeline,
      active: isActive,
      exiting: _,
      entering: __,
      ...props
    } = this.props;
    const {
      placesLived,
      activePlaceIndex,
      isOnPlaces,
      markers,
      coords,
      useZoom,
    } = this.state;
    const {
      data: { remembering, relationships },
    } = lifeline;

    const name = remembering.firstName && remembering.firstName;
    const town = remembering.bornTown;
    const desc =
      remembering.bornState && stateDescriptions[remembering.bornState]
        ? `, ${stateDescriptions[remembering.bornState]}`
        : '';

    const copy = [
      town,
      remembering.bornState ? remembering.bornState : remembering.bornCountry,
    ]
      .filter(v => !!v)
      .join(', ');

    const state = `${copy}${desc}` || remembering.bornCountry;
    const importantRelationships = relationships.importantRelationships || [];

    const mother = importantRelationships
      .filter(item => item.relationship.toLowerCase() == 'mother')
      .pop();
    const father = importantRelationships
      .filter(item => item.relationship.toLowerCase() == 'father')
      .pop();

    const parents =
      mother && father
        ? `, to proud parents ${mother.name} and ${father.name}...`
        : '' || mother
        ? `, to proud mother ${mother.name}...`
        : '' || father
        ? `, to proud father ${father.name}...`
        : '.';

    const havePlaces = placesLived && placesLived.length != 0;

    let copyPlaces = '';
    if (havePlaces) {
      if (placesLived.length == 1) {
        copyPlaces = (
          <>
            And this place played an important part in{' '}
            {getGenitiveNoun(remembering.firstName)} life.
          </>
        );
      } else {
        copyPlaces = (
          <>
            And these places played an important part in{' '}
            {getGenitiveNoun(remembering.firstName)} life.
          </>
        );
      }
    }

    const sectionHeightRatio = 2 + 1 * placesLived.length;
    const mapHeightRatio = 1 / sectionHeightRatio;

    return (
      <div
        className={classnames('lifeline-birthplace', {
          'lifeline-birthplace--enter': isActive,
          'lifeline-birthplace--places': havePlaces,
        })}
        ref={this.containerWrapperRef}
        {...props}
        style={{ height: `${sectionHeightRatio * 100}%` }}
      >
        <div
          className="lifeline-birthplace__container"
          style={{ height: `${mapHeightRatio * 100}%` }}
        >
          {!isSSR && (
            <Map
              id="birthMap"
              coords={coords}
              useZoom={useZoom}
              markers={markers}
              setMap={this.setMap}
              topBannerAnimation={true}
              topBanner={
                <>
                  <p
                    className={classnames('lifeline-birthplace__intro', {
                      'lifeline-birthplace__intro--enter':
                        isActive && !isOnPlaces,
                      'lifeline-birthplace__intro--exit':
                        !isActive || isOnPlaces,
                    })}
                  >
                    {name} was born in {state}.
                  </p>

                  {havePlaces && (
                    <p
                      className={classnames('lifeline-birthplace__places', {
                        'lifeline-birthplace__places--enter':
                          isActive && isOnPlaces,
                        'lifeline-birthplace__places--exit':
                          !isActive || !isOnPlaces,
                      })}
                    >
                      {copyPlaces}
                    </p>
                  )}
                </>
              }
              bottomBanner={
                havePlaces && (
                  <div
                    className={classnames('lifeline-birthplace__place-info', {
                      'lifeline-birthplace__place-info--enter':
                        isActive && isOnPlaces,
                    })}
                  >
                    <SwitchTransition>
                      <CSSTransition
                        key={activePlaceIndex}
                        timeout={1400}
                        classNames="lifeline-birthplace__place-anim"
                      >
                        <div className="lifeline-birthplace__place-anim">
                          <h2>
                            {[
                              placesLived[activePlaceIndex].town,
                              placesLived[activePlaceIndex].state,
                              placesLived[activePlaceIndex].country,
                            ]
                              .filter(v => !!v)
                              .join(', ')}
                          </h2>
                          <p>{placesLived[activePlaceIndex].specification}</p>
                        </div>
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                )
              }
            />
          )}
        </div>

        <div className="lifeline-birthplace__overlay" ref={this.overlay} />

        {havePlaces &&
          placesLived.map((_, i) => {
            // - 0%~150% is the birth place
            // - 150% is the first place waypoint (mapHeightRatio * 1.5)
            // - end-50% is the last place waypoint (
            //     the escursion is 1 - mapHeightRatio * (1.5 + 0.5)
            //   )

            return (
              <div
                key={`lifeline-birthplace__place-waypoint--${i}`}
                className="lifeline-birthplace__place-waypoint"
                data-index={i}
                data-autoscroll-waypoint
                style={{
                  top: `${(mapHeightRatio * 1.5 +
                    ((1 - mapHeightRatio * 2) / (placesLived.length - 1)) * i) *
                    100}%`,
                }}
              />
            );
          })}
      </div>
    );
  }
}

export default BirthPlace;
