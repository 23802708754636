const copy = {
  '1910': [
    'a decade that endured World War I and witnessed the sinking of The Titanic, but that gave rise to Oreos, motorized movie cameras, traffic lights, bras and Brillo pads.',
    'It was a time when people danced defiantly in ballrooms, Vaudeville raged, fashioned evolved from exotic opulence to simple practicality.',
    'Dixieland jazz was first recorded, Babe Ruth made his Major League debut, and Picasso pioneered the revolutionary artistic style of Cubism.',
  ],
  '1920': [
    'a decade that ‘roared’, thanks to its spirit of liberation and the economic boom that followed World War I.',
    'Before the stock market collapse, this was an era of social and cultural dynamism. Women got the vote, flappers flaunted their disdain for what was considered ‘acceptable’ behavior, cheeseburgers and bubblegum were invented.',
    'Hopeful young things danced the Lindy Bug, Bing Crosby and Bessie Smith rang out on the phonograph, and the Surrealist movement was born.',
  ],
  '1930': [
    'a decade that was dealt the Great Depression and the beginning of World War II, yet saw an amazing proliferation of new inventions and technologies: drive-in-theaters, stereo records, frozen food, Airmail, canned beer, ballpoint pens.',
    'Hollywood stars such as Bette Davis and Fred Astaire graced screens and stole hearts.',
    'The Art Deco movement reached its zenith, swing music was popularized, and Django Reinhardt led the development of gypsy jazz.',
  ],
  '1940': [
    'a decade that, despite the atrocities of World War II, still found time to put on its swing-dancing shoes.',
    'Post-war America boomed, with innovations including nuclear power, jet-engine technology, bikinis, microwave ovens and the first atomic clock.',
    'Bold geometric prints appeared in fashion magazines, and a new female silhouette in the form of pant suits. Hell’s Angels became a thing, Honky Tonk took root, and the dulcet sounds of Sammy Kaye and Frankie Laine filled the air.',
  ],
  '1950': [
    'a decade that was dominated by clashes between Communism and Capitalism, yet also brought the world rock ‘n’ roll, power steering, Velcro, hard disks, hovercrafts and Hula Hoops.',
    'This was the golden age of television, the era that youth style emerged.',
    '‘Greasers’ became style icons, baseball players became heroes, Elvis Presley’s new sounds upset the parents and Jackson Pollock took the art world by storm.',
  ],
  '1960': [
    'a decade that was as flamboyant as it was hedonistic, and that bravely broke free of social constraints.',
    "The anti-war and Civil Rights movements gained momentum, the hippie phenomenon was born and more than 100,000 young people converged in San Francisco's neighborhood of Haight-Ashbury for the Summer of Love.",
    'This was also a decade that saw the audio-cassette and ATM invented, Kennedy assassinated, and Neil Armstrong take a stroll on the moon.',
  ],
  '1970': [
    'a decade that proved itself a pivot of change, in which progressive values, political awareness and the economic liberty of women grew.',
    'It was a poor time for economic performance, but a golden age for music and movies. Skirts got shorter, boots got taller, music funkier and scandals juicier.',
    'Gene Wilder starred as Willy Wonka, the World Trade Center opened, Saturday Night Live premiered, Concorde took to the skies and bright minds brought us the bar code, Betamax and Rubik’s Cube.',
  ],
  '1980': [
    'a decade that fell hard for mullets, shoulder pads and neon shellsuits, that played Pac-Man, watched The Wonder Years, waltzed around on rollerboots and broke a sweat to Jane Fonda.',
    'Grandmaster Flash and Public Enemy blasted out on the boom box. New Wave and Synth Pop followed suit.',
    'MTV launched, Roger Moore was still rocking James Bond, Lobster bisque was the pinnacle of food fashion, and the world cheered as the Berlin Wall came down.',
  ],
  '1990': [
    'a decade that continues to inspire the pop imagination, be it for Radiohead, Pulp Fiction, the Dodge Viper or Fresh Prince of Bel Air.',
    'This was the era when grunge surged, the rave scene exploded and the Internet went global, that brought us Tamagotchis, text messaging and Britney Spears.',
    'T-Shirts were big, shorts were long, sitcoms were cheesy, and Michael Jordan became the greatest basketball player of all time.',
  ],
  '2000': [
    'a decade that was marked by financial crisis and the tragedy of 9/11, yet saw the legalization of same-sex marriage and the inauguration of the first black President.',
    'Hip hop reached its peak, garage hit the mainstream, and the Internet allowed people access to music like never before.',
    'Bey and Jay released Crazy In Love, Britney and Madonna kissed at the VMAs, fashion fads ranged from Uggs to Crocs to Burberry baseball caps, Facebook exploded, and the world was stunned by reality TV.',
  ],
  '2010': [
    'a decade that was marked by political polarization and Donald Trump’s controversial arrival at the White House, while seeing the LGBT movement rise.',
    'The influence of the first generation of true digital natives started to radiate outwards, and electric cars became commonplace.',
    'Sustainable design matured in answer to climate change, countless meat-eaters ditched their steak dinners for veganism, eco-conscious became the new normal, and beards went from cool to cooler.',
  ],
};

export default copy;
