import editor from './editor';
import lifelines from './lifelines';
import user from './user';
import app from './app';

export default {
  editor,
  lifelines,
  user,
  app,
};
