import { tablet, hugeScreen } from '@/components/App/breakpoints';

const ELASTIC = 2;
const VISCOSITY = 1.25;

export default class Circle {
  constructor(ref, container, value, i, circlesCount) {
    this.index = i;
    this.circlesCount = circlesCount;
    this.ref = ref;
    this.container = container;
    this.value = value;
    this.E = ELASTIC;
    this.V = VISCOSITY;

    this.containerWidth = this.container.offsetWidth;
    this.containerHeight = this.container.offsetHeight;
    this.containerLeft = this.container.getBoundingClientRect().left;
    this.containerCenter = {
      x: this.containerWidth / 2,
      y: this.containerHeight / 2,
    };

    this.radius = this._getRadius(this.value);
    this.mass = 1 + i / 5;

    this.x = this._randomX(this.radius);
    this.y = this._randomY(this.radius);

    this.ax = 0;
    this.ay = 0;
    this.vx = 0;
    this.vy = 0;
    this.dx = this._randomDx();
    this.dy = this._randomDy();
  }

  _getRadius(value) {
    const ww = window.innerWidth;
    let BASE_RADIUS = 55 + 5;
    let VALUE_RADIUS_RATIO = 17.5;
    // let MAX_RADIUS = BASE_RADIUS + 4 * VALUE_RADIUS_RATIO;

    if (ww < tablet) {
      BASE_RADIUS = 24 + 5;
      VALUE_RADIUS_RATIO = 12;
      // MAX_RADIUS = BASE_RADIUS + 4 * VALUE_RADIUS_RATIO;
    } else if (ww < hugeScreen) {
      BASE_RADIUS = 30 + 5;
      VALUE_RADIUS_RATIO = 15;
      // MAX_RADIUS = BASE_RADIUS + 4 * VALUE_RADIUS_RATIO;
    }

    return BASE_RADIUS + value * VALUE_RADIUS_RATIO;
  }

  _randomX(radius) {
    return -(2 * radius + this.containerLeft);
    // return -this.containerWidth;

    // return (
    //   -Math.floor(Math.random() * this.containerWidth) - this.containerWidth * 2
    // );
  }

  _randomY(radius) {
    let y;

    if (this.circlesCount < 2) {
      y = this.containerHeight / 2;
    } else {
      let i = this.index;
      if (this.index % 2) {
        i = this.circlesCount - this.index;
      }

      y = (i / (this.circlesCount - 1)) * this.containerHeight;
    }

    // let y = Math.floor(Math.random() * this.containerHeight);
    if (y < radius) {
      y = radius;
    } else if (y + radius > this.containerHeight) {
      y = this.containerHeight - radius;
    }
    return y;
  }

  _randomDx() {
    return Math.floor(Math.random() * 10 - 5);
  }

  _randomDy() {
    return Math.floor(Math.random() * 10 - 5);
  }

  _move() {
    this.x += this.dx;
    this.y += this.dy;
  }

  _applyAcceleration(dt) {
    this.ax = this.E * (this.containerCenter.x - this.x) - this.V * this.vx;
    this.vx += this.ax * dt;
    this.dx = this.vx * dt;
    this.ay = this.E * (this.containerCenter.y - this.y) - this.V * this.vy;
    this.vy += this.ay * dt;
    this.dy = this.vy * dt;
  }
  _applyDrag() {
    if (this.outOfXBounds) {
      this.E = Math.min(this.E + 0.001, ELASTIC / 2);
    } else {
      this.E = Math.max(this.E - 0.001, 0);
    }

    // this.containerCenter.x += (this.x - this.containerCenter.x) * 0.001;
    // this.containerCenter.y += (this.y - this.containerCenter.y) * 0.001;
  }

  _distance(a, b) {
    return Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);
  }
  _distanceNextFrame(a, b) {
    return (
      Math.sqrt(
        (a.x + a.dx - b.x - b.dx) ** 2 + (a.y + a.dy - b.y - b.dy) ** 2
      ) -
      a.radius -
      b.radius
    );
  }

  _speed = () => {
    // magnitude of velocity vector
    return Math.hypot(this.dx, this.dy);
  };
  _angle = () => {
    //angle of the circle with the x axis
    return Math.atan2(this.dy, this.dx);
  };

  reset = () => {
    this.E = ELASTIC;

    this.containerWidth = this.container.offsetWidth;
    this.containerHeight = this.container.offsetHeight;
    this.containerLeft = this.container.getBoundingClientRect().left;
    this.containerCenter = {
      x: this.containerWidth / 2,
      y: this.containerHeight / 2,
    };

    this.x = this._randomX(this.radius);
    this.y = this._randomY(this.radius);

    this.ax = 0;
    this.ay = 0;
    this.vx = 0;
    this.vy = 0;
    this.dx = this._randomDx();
    this.dy = this._randomDy();

    this.draw();
  };

  update = () => {
    // const dt = 0.016666667;
    const dt = 0.03;

    this._move(dt);
    this._applyAcceleration(dt);
    this._applyDrag(dt);
  };
  draw = () => {
    this.ref.style.transform = `translate(-50%, -50%) translate(${this.x}px, ${this.y}px)`;
  };
  resize = () => {
    this.radius = this._getRadius(this.value);

    if (
      this.containerWidth !== this.container.offsetWidth ||
      this.containerHeight !== this.container.offsetHeight
    ) {
      this.containerWidth = this.container.offsetWidth;
      this.containerHeight = this.container.offsetHeight;
      this.containerCenter = {
        x: this.containerWidth / 2,
        y: this.containerHeight / 2,
      };

      this.E = ELASTIC;
    }
  };

  staticCollision(circles) {
    circles.forEach(c => {
      const d = this._distance(this, c);

      if (this !== c && d < this.radius + c.radius) {
        const theta = Math.atan2(this.y - c.y, this.x - c.x);
        const overlap = this.radius + c.radius - d;
        const smallerObject = this.radius < c.radius ? this : c;
        smallerObject.x -= overlap * Math.cos(theta);
        smallerObject.y -= overlap * Math.sin(theta);
      }
    });
  }

  ballCollision(circles) {
    circles.forEach(c => {
      if (this !== c && this._distanceNextFrame(this, c) <= 0) {
        var theta1 = this._angle();
        var theta2 = c._angle();
        var phi = Math.atan2(c.y - this.y, c.x - this.x);
        var m1 = this.mass;
        var m2 = c.mass;
        var v1 = this._speed();
        var v2 = c._speed();

        var dx1F =
          ((v1 * Math.cos(theta1 - phi) * (m1 - m2) +
            2 * m2 * v2 * Math.cos(theta2 - phi)) /
            (m1 + m2)) *
            Math.cos(phi) +
          v1 * Math.sin(theta1 - phi) * Math.cos(phi + Math.PI / 2);
        var dy1F =
          ((v1 * Math.cos(theta1 - phi) * (m1 - m2) +
            2 * m2 * v2 * Math.cos(theta2 - phi)) /
            (m1 + m2)) *
            Math.sin(phi) +
          v1 * Math.sin(theta1 - phi) * Math.sin(phi + Math.PI / 2);
        var dx2F =
          ((v2 * Math.cos(theta2 - phi) * (m2 - m1) +
            2 * m1 * v1 * Math.cos(theta1 - phi)) /
            (m1 + m2)) *
            Math.cos(phi) +
          v2 * Math.sin(theta2 - phi) * Math.cos(phi + Math.PI / 2);
        var dy2F =
          ((v2 * Math.cos(theta2 - phi) * (m2 - m1) +
            2 * m1 * v1 * Math.cos(theta1 - phi)) /
            (m1 + m2)) *
            Math.sin(phi) +
          v2 * Math.sin(theta2 - phi) * Math.sin(phi + Math.PI / 2);

        const ddx1 = dx1F - this.dx;
        const ddy1 = dy1F - this.dy;
        const ddx2 = dx2F - c.dx;
        const ddy2 = dy2F - c.dy;

        if (!this.outOfXBounds) {
          this.dx += ddx1 * 0.1;
          this.dy += ddy1 * 0.1;
        }
        if (!c.outOfXBounds) {
          c.dx += ddx2 * 0.1;
          c.dy += ddy2 * 0.1;
        }
      }
    });

    this.wallCollision();
  }

  wallCollision() {
    if (this.x - this.radius + this.dx < 0) {
      this.outOfXBounds = true;

      if (this.dx < 1) {
        this.dx = 0;
      }
    } else if (this.x + this.radius + this.dx > this.containerWidth) {
      this.outOfXBounds = true;

      if (this.dx > 0) {
        this.dx = 0;
      }
    } else {
      this.outOfXBounds = false;
    }

    // if (
    //   this.y - this.radius + this.dy < 0 ||
    //   this.y + this.radius + this.dy > this.containerHeight
    // ) {
    //   this.dy *= -1;
    // }
    // if (this.y + this.radius > this.containerHeight) {
    //   this.y = this.containerHeight - this.radius;
    // }
    // if (this.y - this.radius < 0) {
    //   this.y = this.radius;
    // }
    // if (this.x + this.radius > this.containerWidth + 20) {
    //   this.x = this.containerWidth + 20 - this.radius;
    // }
    // if (this.x - this.radius < -20) {
    //   this.x = this.radius - 20;
    // }
  }
}
