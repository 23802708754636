import apiFetch from '@/api/apiFetch';

export default async ({
  email,
  firstName,
  lastName,
  password,
  provider,
  token,
  lifelineData,
}) => {
  const body = {
    email,
    first_name: firstName,
    last_name: lastName,
    password,
    provider,
    token,
    lifeline_data: lifelineData,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await apiFetch('register', options, 201);

  return await response.json();
};
