export default (array = [], seed = 1) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;
  let progressingSeed = seed;

  let shuffledArray = array.slice();

  let random = () => {
    const x = Math.sin(progressingSeed++) * 10000;
    return x - Math.floor(x);
  };

  // while there remain elements to shuffle
  while (0 !== currentIndex) {
    // pick a remaining element
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;

    // and swap it with the current element.
    temporaryValue = shuffledArray[currentIndex];
    shuffledArray[currentIndex] = shuffledArray[randomIndex];
    shuffledArray[randomIndex] = temporaryValue;
  }

  return shuffledArray;
};
