import React, { Fragment, Component } from 'react';

import SVGCatmullRomSpline from 'svg-catmull-rom-spline';

import Perlin from 'perlin-simplex';
import './style.css';

const SEGMENTS = 8;

class FamilyPathsMobile extends Component {
  constructor(props) {
    super(props);
    this.points = [];
    this.points2 = [];
    this.points3 = [];

    this.tpoints = [];
    this.tpoints2 = [];
    this.tpoints3 = [];

    this.svg = React.createRef();
    this.path = React.createRef();
    this.path2 = React.createRef();
    this.path3 = React.createRef();

    this.state = {
      width: '100%',
      height: '100%',
      circles: [],
    };
  }

  componentDidMount() {
    if (this.svg.current) {
      this.container = this.svg.current.parentElement.parentElement.parentElement;
      this.labels = [
        ...this.container.querySelectorAll('.lifeline-family--label'),
      ];

      this.seed = [];
      for (let i = 0; i < this.labels.length + 1; i++) {
        this.seed[i] = [];
        for (let j = 0; j < SEGMENTS; j++) {
          this.seed[i][j] = Math.random();
        }
      }

      this.handleResize();

      this.perlin = new Perlin();

      this.calcPoints();
    }
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
  }

  componentDidUpdate() {
    this.calcPoints();
  }

  handleResize = () => {
    const circles = [];

    this.labels.forEach(l => {
      const heading = l.querySelector('h3');
      const circleY = l.offsetTop + heading.offsetHeight / 2;
      circles.push([heading.offsetLeft + 30, circleY, heading.style.color]);
    });

    this.setState({
      timestamp: Date.now(),
      width: `${this.container.offsetWidth}px`,
      height: `${this.container.scrollHeight}px`,
      circles,
    });
  };

  getLabelY = index => {
    const l = this.labels[index];
    if (l) {
      return l.offsetTop + l.querySelector('h3').offsetHeight / 2;
    } else {
      return this.container.scrollHeight;
    }
  };

  calcPoints = () => {
    if (!this.path.current) return;

    const width = this.container.offsetWidth;

    const circleX = (this.state.circles[0] && this.state.circles[0][0]) || 30;

    const numPoints = this.labels.length;

    let x = circleX;
    let y = this.getLabelY(0); // window.innerHeight / 3;

    this.points[0] = [x, y];

    for (let i = 0; i < numPoints; i++) {
      // console.log('CIRCLES', this.state.circles);
      const circleX = (this.state.circles[i] && this.state.circles[i][0]) || 30;

      const nextLabelY = this.getLabelY(i + 1);
      const deltaY = Math.round((nextLabelY - y) / SEGMENTS);
      // console.log({ width, deltaY });

      if (i == 1) {
        this.points2[0] = [circleX, this.getLabelY(i)];
      }
      if (i == 2) {
        this.points3[0] = [circleX, this.getLabelY(i)];
      }

      for (let j = 0; j < SEGMENTS; j++) {
        const isFirstHalf = j < SEGMENTS / 2;
        const isLow = x < 0.15 * width;
        x += (isFirstHalf || isLow ? 1 : -1) * width * (0.15 * this.seed[i][j]);
        y += deltaY;
        // console.log({ x });

        this.points[SEGMENTS * i + j + 1] =
          j == SEGMENTS - 1 ? [circleX, nextLabelY] : [x, y];

        if (i > 0) {
          this.points2[SEGMENTS * (i - 1) + j + 1] =
            j == SEGMENTS - 1
              ? [circleX, nextLabelY]
              : [
                  x + (-30 + 60 * this.seed[i - 1][j]),
                  y + (-30 + 60 * this.seed[i - 1][j]),
                ];
        }
        if (i > 1) {
          this.points3[SEGMENTS * (i - 2) + j + 1] =
            j == SEGMENTS - 1
              ? [circleX, nextLabelY]
              : [
                  x + (-30 + 60 * this.seed[i - 2][j]),
                  y + (-30 + 60 * this.seed[i - 2][j]),
                ];
        }
      }
    }

    if (this.points.length != numPoints * SEGMENTS + 1) {
      this.points = this.points.slice(0, numPoints * SEGMENTS + 1);
    }

    if (this.points2.length != (numPoints - 1) * SEGMENTS + 1) {
      this.points2 = this.points2.slice(0, (numPoints - 1) * SEGMENTS + 1);
    }

    if (this.points3.length != (numPoints - 2) * SEGMENTS + 1) {
      this.points3 = this.points3.slice(0, (numPoints - 2) * SEGMENTS + 1);
    }

    if (!this.raf) {
      this.update();
    }
  };

  update = () => {
    const time = Date.now() / 4000;
    const amp = 20;

    // console.log({ points: this.points });
    this.points.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(Math.cos(time + index), Math.sin(time + index));
      this.tpoints[index] = [p[0], p[1] + n];
    });

    this.points2.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(
              Math.cos(time + index * 2),
              Math.sin(time + index * 2)
            );
      this.tpoints2[index] = [p[0], p[1] + n + 10];
    });

    this.points3.forEach((p, index) => {
      const n =
        index % SEGMENTS == 0
          ? 0
          : amp *
            this.perlin.noise(
              Math.cos(time + index * 4),
              Math.sin(time + index * 4)
            );
      this.tpoints3[index] = [p[0], p[1] + n];
    });

    if (this.tpoints.length) {
      this.d = SVGCatmullRomSpline.toPath(this.tpoints); //, 4, true);
      this.path.current.setAttribute('d', this.d);
      this.path.current.style.clipPath = `inset(0 0 ${parseFloat(
        this.state.height
      ) -
        this.container.scrollTop -
        0.7 * window.innerHeight}px 0)`;
    }

    if (this.tpoints2.length) {
      this.d2 = SVGCatmullRomSpline.toPath(this.tpoints2); //, 4, true);
      this.path2.current.setAttribute('d', this.d2);
      this.path2.current.style.clipPath = `inset(0 0 ${parseFloat(
        this.state.height
      ) -
        this.container.scrollTop -
        0.77 * window.innerHeight}px 0)`;
    }

    if (this.tpoints3.length) {
      this.d3 = SVGCatmullRomSpline.toPath(this.tpoints3); //, 4, true);
      this.path3.current.setAttribute('d', this.d3);
      this.path3.current.style.clipPath = `inset(0 0 ${parseFloat(
        this.state.height
      ) -
        this.container.scrollTop -
        0.63 * window.innerHeight}px 0)`;
    }

    this.raf = requestAnimationFrame(this.update);
  };

  render() {
    const { width, height, labels, circles } = this.state;

    return (
      <svg
        className="lifeline-family-mobile--paths"
        ref={this.svg}
        style={{ width }}
        width={parseFloat(width)}
        height={parseFloat(height)}
      >
        <path
          d={this.d}
          ref={this.path}
          fill="none"
          stroke={circles[0] && circles[0][2]}
        />
        <path
          d={this.d2}
          ref={this.path2}
          fill="none"
          stroke={circles[1] && circles[1][2]}
        />
        <path
          d={this.d3}
          ref={this.path3}
          fill="none"
          stroke={circles[2] && circles[2][2]}
        />
        {/* {this.points.map((p, index) => (
          <circle
            key={`cppp3-${index}`}
            cx={p[0]}
            cy={p[1]}
            r={3.5}
            fill={'#f70'}
          />
        ))} */}
        {circles.map((c, index) => (
          <Fragment key={`path-fragment--${index}`}>
            <circle
              key={`c3-${index}`}
              cx={c[0]}
              cy={c[1]}
              r={3.5}
              fill={c[2]}
            />
            <svg
              width="30"
              height="30"
              x={c[0] - 15}
              y={c[1] - 15}
              className="circle"
              key="index"
            >
              <circle
                key={`c1-${index}`}
                cx="15"
                cy="15"
                r={13.5}
                fill="none"
                stroke={c[2]}
              />
              <circle
                key={`c2-${index}`}
                cx="15"
                cy="15"
                r={9.5}
                fill="none"
                stroke={c[2]}
              />
            </svg>
          </Fragment>
        ))}
        {/*this.points.map((c, index) => <circle key={`p${index}`} cx={c[0]} cy={c[1]} r={5} />)*/}
      </svg>
    );
  }
}

export default FamilyPathsMobile;
