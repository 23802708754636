import React, { Component, createRef } from 'react';
import { Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '@/utils/history';

import store from '@/store';
import Svg from '@/components/Svg';
import NavItem from '@/components/NavItem';
import config from '@/config';

import './style.css';

const MIN_SCROLL_OFFSET = 55;
const SCROLL_THRESHOLD_OFFSET = 20;

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpened: false,
      editor: false, // **jc**
      burgerMenu: true, // **jc**
      currentLocation: { pathname: '' },
    };

    this.keepHeaderHidden = false;

    this.header = createRef();
    this.navbarVisible = true;
  }

  showMenu = enabled => {
    document.body.classList[enabled ? 'add' : 'remove']('no-scroll');
    this.setState({ menuOpened: enabled });
    // **jc**
    this.navbarVisible = true;
  };

  toggleMenu = () => {
    const { menuOpened } = this.state;
    this.showMenu(!menuOpened);
  };

  handleScroll = () => {
    const currentScrollOffset = window.pageYOffset;
    const scrollThreshold = this.navbarVisible ? 0 : SCROLL_THRESHOLD_OFFSET;

    const new_navbarVisible =
      currentScrollOffset < MIN_SCROLL_OFFSET ||
      this.prevScrollOffset - scrollThreshold > currentScrollOffset;

    this.prevScrollOffset = currentScrollOffset;

    if (this.keepHeaderHidden) {
      return;
    }

    if (this.navbarVisible !== new_navbarVisible) {
      if (this.header.current) {
        if (new_navbarVisible) {
          this.header.current.classList.remove('header--hidden');
        } else {
          this.header.current.classList.add('header--hidden');
        }
        this.navbarVisible = new_navbarVisible;
      }
      //this.setState({ navbarVisible });
    }
  };

  componentDidMount() {
    this.prevScrollOffset = window.pageYOffset;
    this.setState({ currentLocation: window.location });

    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('keepHeaderHidden', this.onKeepHeaderHidden);
    window.addEventListener('allowHeader', this.allowHeader);

    window.addEventListener('showEditorHeader', this.onShowEditorHeader);
    window.addEventListener('removeEditorHeader', this.onRemoveEditorHeader);

    window.addEventListener('noMenuOpened', this.onNoMenuOpened); // **jc**

    window.addEventListener('hideBurguerMenu', this.onHideBurguerMenu); // **jc**
    window.addEventListener('showBurguerMenu', this.onShowBurguerMenu); // **jc**

    this.historyUnlisten = history.listen(this.onHistoryChange);
  }

  componentWillUnmount() {
    if (this.historyUnlisten) {
      this.historyUnlisten();
    }

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keepHeaderHidden', this.onKeepHeaderHidden);
    window.removeEventListener('allowHeader', this.allowHeader);

    window.removeEventListener('showEditorHeader', this.onShowEditorHeader);
    window.removeEventListener('removeEditorHeader', this.onRemoveEditorHeader);

    window.removeEventListener('noMenuOpened', this.onNoMenuOpened); // **jc**

    window.removeEventListener('hideBurguerMenu', this.onHideBurguerMenu); // **jc**
    window.removeEventListener('showBurguerMenu', this.onShowBurguerMenu); // **jc**

    document.body.classList['remove']('no-scroll');
  }

  // **jc**
  onNoMenuOpened = () => {
    this.showMenu(false);
  };

  // **jc**
  onHideBurguerMenu = () => {
    this.setState({
      burgerMenu: false,
    });
  };

  // **jc**
  onShowBurguerMenu = () => {
    this.setState({
      burgerMenu: true,
    });
  };

  onHistoryChange = location => {
    this.setState({
      currentLocation: location,
    });
  };

  onKeepHeaderHidden = () => {
    this.header.current.classList.add('header--hidden');
    this.navbarVisible = false;
    this.keepHeaderHidden = true;
  };

  allowHeader = () => {
    this.navbarVisible = true;
    this.header.current.classList.remove('header--hidden');
    this.keepHeaderHidden = false;
  };

  onShowEditorHeader = () => {
    // **jc**
    //this.header.current.classList.add('header--editor');
    this.setState({
      editor: true,
      menuOpened: false, // **jc**
      burgerMenu: true, // **jc**
    });
  };

  onRemoveEditorHeader = () => {
    // **jc**
    //this.header.current.classList.remove('header--editor');
    this.setState({
      editor: false,
      menuOpened: false, // **jc**
      burgerMenu: true, // **jc**
    });
    document.body.classList['remove']('no-scroll');
  };

  renderDummyHeader = props => {
    if (props.location.pathname.startsWith('/lifeline')) {
      return null;
    }

    return <div className="dummy-header" />;
  };

  render() {
    const { userLogged, logoutUser } = this.props;
    const { menuOpened, currentLocation, editor, burgerMenu } = this.state; // **jc**

    const classes = {
      header: classnames('header', {
        'header--editor': editor, // **jc**
        'header--open': menuOpened,
        'header--hidden': !this.navbarVisible,
      }),
      logo: classnames('header__logo', {
        'header__logo--light': menuOpened,
      }),
      toggle: classnames({
        header__toggle: burgerMenu, // **jc**
        'header__toggle--cross': burgerMenu && menuOpened, // **jc**
      }),
      mobileNav: classnames('header__nav', 'header__nav--mobile', {
        'header__nav--active': menuOpened,
      }),
    };

    return (
      <>
        <header className={classes.header} ref={this.header}>
          <NavLink
            className={classes.logo}
            to="/"
            onClick={() => this.showMenu(false)}
            onFocus={this.allowHeader}
          >
            Memories.com
          </NavLink>

          {/* desktop nav with horizontally aligned items */}
          <nav className="header__nav header__nav--desktop">
            <ul className="header--main">
              <NavItem
                variant="primary"
                to="/add-lifeline"
                onFocus={this.allowHeader}
              >
                Create a lifeline
              </NavItem>
              <NavItem
                variant="primary"
                to="/search"
                onFocus={this.allowHeader}
              >
                Find a lifeline
              </NavItem>
              {config.isPaidPlanEnabled && (
                <NavItem
                  variant="primary"
                  to="/pricing"
                  onFocus={this.allowHeader}
                >
                  Pricing
                </NavItem>
              )}
            </ul>
            <ul className="header--login">
              {userLogged ? (
                <>
                  <NavItem
                    variant="secondary"
                    to="/my-account"
                    textOnHover="My account"
                    onFocus={this.allowHeader}
                  >
                    My account
                  </NavItem>
                </>
              ) : (
                <>
                  <NavItem
                    variant="secondary"
                    to={{
                      pathname: '/signup',
                      state: {
                        from: currentLocation.pathname,
                      },
                    }}
                    textOnHover="Sign up"
                    onFocus={this.allowHeader}
                  >
                    Sign up
                  </NavItem>
                  <NavItem
                    variant="secondary"
                    to={{
                      pathname: '/login',
                      state: {
                        from: currentLocation.pathname,
                      },
                    }}
                    textOnHover="Log in"
                    onFocus={this.allowHeader}
                  >
                    Log in
                  </NavItem>
                </>
              )}
            </ul>
          </nav>

          {/* mobile nav, fixed and stacked items */}
          <nav className={classes.mobileNav}>
            <div className="header__nav-inner">
              <div className="header__nav-content--top--mid">
                <div className="header__nav-content header__nav-content--top">
                  <NavItem
                    variant="secondary"
                    to="/add-lifeline"
                    textOnHover="Create a lifeline"
                    onClick={this.toggleMenu}
                  >
                    Create a lifeline
                  </NavItem>
                  <NavItem
                    variant="secondary"
                    to="/search"
                    textOnHover="Find a lifeline"
                    onClick={this.toggleMenu}
                  >
                    Find a lifeline
                  </NavItem>
                  {config.isPaidPlanEnabled && (
                    <NavItem
                      variant="secondary"
                      to="/pricing"
                      textOnHover="Pricing"
                      onClick={this.toggleMenu}
                    >
                      Pricing
                    </NavItem>
                  )}
                </div>

                <div className="header__nav-content header__nav-content--mid">
                  {userLogged ? (
                    <>
                      <NavItem
                        variant="secondary"
                        to="/my-account"
                        textOnHover="My account"
                        onClick={this.toggleMenu}
                      >
                        My account
                      </NavItem>
                      {/*
                        <Link
                          onClick={() => {
                            logoutUser();
                            this.toggleMenu();
                          }}
                          to="/"
                        >
                          Log out
                        </Link>
                      */}
                    </>
                  ) : (
                    <>
                      <NavItem
                        variant="secondary"
                        to={{
                          pathname: '/signup',
                          state: {
                            from: currentLocation.pathname,
                          },
                        }}
                        textOnHover="Sign up"
                        onClick={this.toggleMenu}
                      >
                        Sign up
                      </NavItem>
                      <NavItem
                        variant="secondary"
                        to={{
                          pathname: '/login',
                          state: {
                            from: currentLocation.pathname,
                          },
                        }}
                        textOnHover="Log in"
                        onClick={this.toggleMenu}
                      >
                        Log in
                      </NavItem>
                    </>
                  )}
                </div>
              </div>

              <div className="header__nav-content header__nav-content--bottom">
                <div className="button-wrapper">
                  <a href="https://twitter.com" target="_blank">
                    <div className="icons-wrapper">
                      <Svg name="twitter_white_48" />
                      <Svg name="twitter_green_48" />
                    </div>
                  </a>
                </div>

                <div className="button-wrapper">
                  <a href="https://facebook.com" target="_blank">
                    <div className="icons-wrapper">
                      <Svg name="facebook_white_48" />
                      <Svg name="facebook_green_48" />
                    </div>
                  </a>
                </div>

                <div className="button-wrapper">
                  <a href="https://linkedin.com" target="_blank">
                    <div className="icons-wrapper">
                      <Svg name="linkedin_white_48" />
                      <Svg name="linkedin_green_48" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="header__nav-inner-right"></div>
          </nav>

          {/* burger menu, shown only on mobile & desktop */}
          {burgerMenu /* **jc** */ && (
            <button className={classes.toggle} onClick={this.toggleMenu}>
              <Svg name="burger-menu" />
            </button>
          )}
        </header>

        <Route path="/" component={this.renderDummyHeader} />
      </>
    );
  }
}

const mapState = state => ({ userLogged: store.select.user.isLogged(state) });

const mapDispatch = ({ user: { clear } }) => ({
  logoutUser: clear,
});

export default connect(
  mapState,
  mapDispatch
)(Header);
