import React, { Component } from 'react';
import classnames from 'classnames';

import './style.css';

export default class Container extends Component {
  static defaultProps = {
    size: 'large',
  };

  render() {
    const { children, size, className } = this.props;
    return (
      <div
        className={classnames(
          'container',
          {
            'container--extra-small': size === 'extra-small',
            'container--small': size === 'small',
            'container--large': size === 'large',
            'container--extra-large': size === 'extra-large',
          },
          className
        )}
      >
        {children}
      </div>
    );
  }
}
