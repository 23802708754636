import React from 'react';
import classnames from 'classnames';
import './style.css';

import DEFAULT_PICTURE_1 from '#/images/default-profile-picture--a.png';
import DEFAULT_PICTURE_2 from '#/images/default-profile-picture--b.png';
import DEFAULT_PICTURE_3 from '#/images/default-profile-picture--c.png';
import DEFAULT_PICTURE_4 from '#/images/default-profile-picture--d.png';
import DEFAULT_PICTURE_5 from '#/images/default-profile-picture--e.png';
import DEFAULT_PICTURE_6 from '#/images/default-profile-picture--f.png';

export const DEFAULT_PICTURES = [
  DEFAULT_PICTURE_1,
  DEFAULT_PICTURE_2,
  DEFAULT_PICTURE_3,
  DEFAULT_PICTURE_4,
  DEFAULT_PICTURE_5,
  DEFAULT_PICTURE_6,
];

const DefaultPictureSelector = ({ value, onChange, disabled }) => {
  return (
    <div
      className={classnames('default-picture-selector', {
        'default-picture-selector--disabled': disabled,
      })}
    >
      {DEFAULT_PICTURES.map((src, i) => (
        <button
          key={`default-picture--${i}`}
          className={classnames('default-picture-selector__item', {
            'default-picture-selector__item--active': value === i,
          })}
          tabIndex={value === i ? -1 : 0}
          type="button"
          style={{ backgroundImage: `url(${src})` }}
          aria-label={`Default picture ${i}`}
          onClick={() => onChange(i)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

DefaultPictureSelector.defaultProps = {
  onChange: f => f,
};

export default DefaultPictureSelector;
