import ease from 'eases/quint-out';

export default class smoothScroll {
  constructor(target, callback, options) {
    const { scrollParent = null, duration = 1200, position = 'top' } =
      options || {};

    this.callback = callback;
    this.duration = duration;
    this.position = position;
    this.scrollInParent = !!scrollParent;
    this.smoothScrollTargetParent = document.documentElement;

    if (scrollParent) {
      this.smoothScrollTargetParent =
        typeof scrollParent === 'string'
          ? document.querySelector(scrollParent)
          : scrollParent;
    }

    this.scroll(target);
  }

  setPosition(p) {
    switch (p) {
      case 'top':
      case 'center':
      case 'bottom':
        this.position = p;
    }
  }

  scroll(target) {
    const smoothScrollTarget =
      typeof target === 'string' ? document.querySelector(target) : target;

    if (!smoothScrollTarget && smoothScrollTarget !== 0) {
      // section does not exist
      return;
    }

    this.smoothScrollTarget = smoothScrollTarget;

    if (typeof this.smoothScrollTarget === 'number') {
      this.smoothScrollTargetTop = this.smoothScrollTarget;
    } else {
      let nodeCursor = this.smoothScrollTarget;
      this.smoothScrollTargetTop = nodeCursor.offsetTop;
      while (
        nodeCursor.offsetParent &&
        nodeCursor.offsetParent !== this.smoothScrollTargetParent
      ) {
        nodeCursor = nodeCursor.offsetParent;
        this.smoothScrollTargetTop += nodeCursor.offsetTop;
      }

      if (this.position === 'center') {
        this.smoothScrollTargetTop -= window.innerHeight / 2;
      }
      if (this.position === 'bottom') {
        this.smoothScrollTargetTop -= window.innerHeight - 1;
      }
    }

    if (this.smoothScrollRAF) {
      cancelAnimationFrame(this.smoothScrollRAF);
    }

    this.smoothScrollStartTime = null;
    this.smoothScrollStartTop = null;
    this.smoothScrollRAF = requestAnimationFrame(this.smoothScrollUpdate);
  }

  smoothScrollUpdate = t => {
    if (this.smoothScrollStartTime === null) {
      this.smoothScrollStartTime = t;
      if (this.scrollInParent) {
        this.smoothScrollStartTop = this.smoothScrollTargetParent.scrollTop;
      } else {
        this.smoothScrollStartTop = window.scrollY;
      }
    }

    const dt = t - this.smoothScrollStartTime;

    const progress = ease(Math.min(dt / this.duration, 1));
    const currentTop =
      this.smoothScrollStartTop +
      (this.smoothScrollTargetTop - this.smoothScrollStartTop) * progress;

    this.smoothScrollTargetParent.scrollTop = currentTop;

    if (progress < 1) {
      this.smoothScrollRAF = requestAnimationFrame(this.smoothScrollUpdate);
    } else {
      this.callback && this.callback();
      this.smoothScrollRAF = null;
    }
  };
}
