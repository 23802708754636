import React, { Component } from 'react';
import classnames from 'classnames';

import Svg from '@/components/Svg';

import './style.css';

export default class LoadingShape extends Component {
  static defaultProps = {
    showMessage: false,
    variant: 'white',
    text: 'loading',
    name: 'loading-shape',
    isHidden: false,
  };

  render() {
    const {
      isActive,
      showMessage,
      variant,
      text,
      name,
      isHidden: hidden,
    } = this.props;

    const className = classnames('loading-shape', {
      active: isActive,
      colored: variant === 'colored',
      smalt: variant === 'smalt',
      export: variant === 'export',
      hidden,
    });

    return (
      <div className={className}>
        {showMessage && <span className="text">{text}</span>}
        {variant === 'homepage' ? (
          <span className="svg svg-homepage">
            <svg
              viewBox="0 0 663 641"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g className="path-group">
                <path
                  d="M612.724 182.194C701.605 326.405 651.126 511.541 499.969 595.727C348.813 679.913 154.242 631.258 65.3604 487.046C-23.5206 342.835 133.318 234.827 178.115 73.5129C220.942 -80.9259 523.843 37.9826 612.724 182.194Z"
                  stroke="#37BFD1"
                  strokeMiterlimit="10"
                />
              </g>
              <g className="path-group">
                <path
                  d="M317.53 609.593C194.989 729.058 0.334961 474.111 0.334961 306.972C0.334961 139.832 142.341 4.35059 317.53 4.35059C492.719 4.35059 511.4 188.316 634.725 306.972C752.824 420.579 467.505 463.392 317.53 609.593Z"
                  stroke="#017599"
                  strokeWidth="0.5"
                  strokeMiterlimit="10"
                />
              </g>

              <g className="path-group">
                <path
                  d="M91.8421 118.822C89.9478 -48.3172 365.272 122.25 540.461 118.822C694.878 115.769 664.308 428.61 540.461 546.829C416.614 665.048 267.031 547.515 91.8421 546.829C-75.8348 546.144 94.1937 323.353 91.8421 118.822Z"
                  stroke="#013799"
                  strokeWidth="0.5"
                  strokeMiterlimit="10"
                />
              </g>
            </svg>
          </span>
        ) : variant === 'export' ? (
          <span className="svg svg-export">
            <svg
              viewBox="0 0 663 641"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g className="path-group">
                <path
                  d="M612.724 182.194C701.605 326.405 651.126 511.541 499.969 595.727C348.813 679.913 154.242 631.258 65.3604 487.046C-23.5206 342.835 133.318 234.827 178.115 73.5129C220.942 -80.9259 523.843 37.9826 612.724 182.194Z"
                  stroke="#003698"
                  strokeMiterlimit="10"
                />
              </g>
              <g className="path-group">
                <path
                  d="M317.53 609.593C194.989 729.058 0.334961 474.111 0.334961 306.972C0.334961 139.832 142.341 4.35059 317.53 4.35059C492.719 4.35059 511.4 188.316 634.725 306.972C752.824 420.579 467.505 463.392 317.53 609.593Z"
                  stroke="#37BFD1"
                  strokeWidth="0.5"
                  strokeMiterlimit="10"
                />
              </g>

              <g className="path-group">
                <path
                  d="M91.8421 118.822C89.9478 -48.3172 365.272 122.25 540.461 118.822C694.878 115.769 664.308 428.61 540.461 546.829C416.614 665.048 267.031 547.515 91.8421 546.829C-75.8348 546.144 94.1937 323.353 91.8421 118.822Z"
                  stroke="#37BFD1"
                  strokeWidth="0.5"
                  strokeMiterlimit="10"
                />
              </g>
            </svg>
          </span>
        ) : (
          <Svg name={name} />
        )}
      </div>
    );
  }
}
